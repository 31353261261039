import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class AdminCalendar extends Component {
    render() {
        return (
            <main class="main-content calender-content">


                <section class="calendar-wrapper">
                    <div class="container-fluid">

                        <div class="calendar-header">
                            <div class="row align-items-center">
                                <div class="col-lg-3 col-md-12 col-sm-12 calendar-25-col">
                                    <div class="header-calendar-and-search">
                                        <div class="calendar-filter-btn">
                                            <button class="btn black-btn filter-btn">
                                                <span>
                                                    <i class="fi fi-sliders"></i>
                                                </span>
                                                <span>
                                                    Filter
                                                </span>
                                            </button>
                                        </div>
                                        <div class="calendar-search-btn">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fa fa-search"></i>
                                            </button>
                                            <div class="calendar-search-input hide-element">
                                                <input class="form-control" type="search" placeholder="Search here.." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-7 col-sm-7 col-12 calendar-50-col">
                                    <div class="calendar-ul-top">
                                        <ul class="nav nav-pills calendar-pills" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="pills-month-tab" data-toggle="pill"
                                                    data-target="#pills-month" type="button" role="tab"
                                                    aria-controls="pills-month" aria-selected="true">Month</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="pills-week-tab" data-toggle="pill"
                                                    data-target="#pills-week" type="button" role="tab"
                                                    aria-controls="pills-week" aria-selected="false">Week</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="pills-day-tab" data-toggle="pill"
                                                    data-target="#pills-day" type="button" role="tab" aria-controls="pills-day"
                                                    aria-selected="false">Day</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-5 col-sm-5 col-12 calendar-25-col">
                                    <div class="calendar-date-and-back-btn">
                                        <div class="calendar-date">
                                            <h4 class="title-20">28th April - 03 May 2023</h4>
                                            <div class="calendar-back-link">
                                                <Link to="/">
                                                    <a>
                                                        <span><i class="fa fa-long-arrow-left"></i></span>
                                                        <span class="small-text-red-16">Back</span>
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="calendar-filter-drawer">
                                <div class="filter-close-btn">
                                    <div class="filter-title">
                                        <span class="filter-img">
                                            <img src="assets/images/filter.svg" alt="Filter" />
                                        </span>
                                        <span>
                                            <h4 class="title-20">Apply Filter</h4>
                                        </span>
                                    </div>

                                    <button class="btn closeCalendarDrawer">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <div class="filter-dropdowns-main">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="custom-select-to">
                                                <label class="form-label" for="">Country </label>
                                                <select class="select-country" name="country">
                                                    <option disabled>Select Country</option>
                                                    <option value="AL">Switzerland</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="custom-select-to">
                                                <label class="form-label" for="">State </label>
                                                <select class="select-state" name="State">
                                                    <option disabled>Select State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label" for="">City </label>
                                            <div class="custom-select-to">
                                                <select class="select-city" name="City">
                                                    <option disabled>Select City</option>
                                                    <option value="AL">Bern</option>
                                                    <option value="WY">Zürich</option>
                                                    <option value="WY">Thun</option>
                                                    <option value="WY">Studen</option>
                                                    <option value="WY">Basel</option>
                                                    <option value="WY">Grünen </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="filter-apply-btn">
                                    <a class="btn red-btn">
                                        <span>
                                            <i class="fi fi-check-mark-circle"></i>
                                        </span>
                                        <span>
                                            Apply
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="filter-overlay"></div>
                        </div>

                        <div class="calendar-view">
                            <div class="tab-content" id="pills-tabContent">

                                <div class="tab-pane fade month-calendar-top" id="pills-month" role="tabpanel"
                                    aria-labelledby="pills-month-tab">

                                    <div class="calender-table-main month-view-table">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="active-table-th">
                                                            <div class="day-name">
                                                                Monday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Tuesday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Wednesday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Thursday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Friday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Saturday
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Sunday
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">1</p>
                                                                </div>
                                                                <div class="month-date">
                                                                    <span class="month-booked-span small-text-16">Booked</span>
                                                                    <span
                                                                        class="month-total-span small-text-black-16">Total</span>
                                                                </div>
                                                            </div>
                                                            <div class="month-tags" id="weekTabOpen">
                                                                <div class="month-city-and-booked-slots">
                                                                    <div class="month-city-name">
                                                                        <p class="small-text-black-16">Bern <span>-</span></p>
                                                                    </div>
                                                                    <div class="month-slot-number">
                                                                        <span class="all-booked-text small-text-16">100</span>
                                                                        <span class="small-text-black-16">|</span>
                                                                        <span class="small-text-black-16">100</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="month-tags">
                                                                <div class="month-city-and-booked-slots">
                                                                    <div class="month-city-name">
                                                                        <p class="small-text-black-16">Zurich <span>-</span></p>
                                                                    </div>
                                                                    <div class="month-slot-number">
                                                                        <span class="half-booked-text small-text-16">89</span>
                                                                        <span class="small-text-black-16">|</span>
                                                                        <span class="small-text-black-16">100</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="month-tags">
                                                                <div class="month-city-and-booked-slots">
                                                                    <div class="month-city-name">
                                                                        <p class="small-text-black-16">Bern</p>
                                                                    </div>
                                                                    <div class="month-slot-number">
                                                                        <span class="all-empty-text small-text-16">0</span>
                                                                        <span class="small-text-black-16">|</span>
                                                                        <span class="small-text-black-16">100</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">2</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">3</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">4</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">5</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">6</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">7</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">8</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">9</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">10</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">11</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">12</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">13</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">14</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">15</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">16</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">17</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">18</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">19</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">20</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">21</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">22</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">23</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">24</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">25</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">26</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">27</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">28</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">29</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">30</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">31</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">1</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">2</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">3</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="month-title">
                                                                <div>
                                                                    <p lass="month-date small-text-14">4</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                                <div class="tab-pane fade show active week-calendar-top" id="pills-week" role="tabpanel"
                                    aria-labelledby="pills-week-tab">

                                    <div class="calender-table-main week-view-calendar">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th scope="col" class="active-table-th">
                                                            <div class="day-name">
                                                                Monday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Tuesday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Wednesday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Thursday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Friday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Saturday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="day-name">
                                                                Sunday
                                                            </div>
                                                            <div class="day-slot">
                                                                <span>( 50</span>
                                                                <span>/</span>
                                                                <span>50 )</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">8:00</th>
                                                        <td>

                                                            <div class="day-view-tags" data-target="#appointmentList"
                                                                data-toggle="modal">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 00</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="day-view-tags" data-target="#appointmentList"
                                                                data-toggle="modal">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 04</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="day-view-tags" data-target="#appointmentList"
                                                                data-toggle="modal">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 08</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">9:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">10:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">11:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">12:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">13:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">14:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">15:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">16:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">17:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">18:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th scope="row">19:00</th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                                <div class="tab-pane fade" id="pills-day" role="tabpanel" aria-labelledby="pills-contact-day">

                                    <div class="calender-table-main day-view-table">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="active-table-th">
                                                            <div class="day-name">
                                                                <div class="day-view-name-date-main">
                                                                    <div class="day-view-day">
                                                                        <p class="small-text-16">Monday</p>
                                                                        <span class="small-text-14">29th June, 2023</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="city-name">
                                                                Bern
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="city-name">
                                                                Zurich
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="city-name">
                                                                Thun
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="city-name">
                                                                Studen
                                                            </div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="city-name">
                                                                Basel
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            8:00
                                                        </th>
                                                        <td>
                                                            <div class="day-view-tags">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 00</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="day-view-tags">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 04</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="day-view-tags">
                                                                <div class="day-view-tag" data-target="#appointmentList"
                                                                    data-toggle="modal">
                                                                    <div class="day-tag-time">
                                                                        <p class="small-text-black-16"><span
                                                                            class="small-text-black-16">:</span> 08</p>
                                                                    </div>
                                                                    <div class="day-tag-booking-number">
                                                                        <p class="small-text-red-16">1234578</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            9:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            10:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            11:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            12:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            13:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            14:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr class="empty-space-tr"></tr>
                                                    <tr>
                                                        <th>
                                                            15:00
                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                <div class="modal fade custom-modal appointment-list-modal" id="appointmentList" data-backdrop="static"
                    data-keyboard="false" tabindex="-1" aria-labelledby="appointmentListLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="appointmentListLabel">
                                    <span class="modal-title-img">
                                        <img src="assets/images/calendar.svg" alt="Calendar" />
                                    </span>
                                    <span class="modal-title-20">Appointment List </span>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="appointment-list-content">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:00</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type booked-appointmentList small-text-16">Booked</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:04</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type free-appointment small-text-16"
                                                        data-target="#confirmDetails" data-toggle="modal">Free</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:08</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type booked-appointmentList small-text-16">Booked</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:12</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type free-appointment small-text-16">Free</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:16</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type free-appointment small-text-16">Free</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:20</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type free-appointment small-text-16">Free</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                            <div class="appointment-content-top">
                                                <div class="appointment-span">
                                                    <p>8:24</p>
                                                </div>
                                                <div class="appointment-type-main">
                                                    <p class="appointment-type free-appointment small-text-16">Free</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade custom-modal customer-details-modal" id="confirmDetails" data-backdrop="static"
        data-keyboard="false" tabindex="-1" aria-labelledby="customerDetailsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="customerDetailsLabel">
                        <span class="modal-title-img">
                            <img src="assets/images/calendar.svg" alt="Calendar" />
                        </span>
                        <span class="modal-title-20">Confirm your details </span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="fa fa-edit modal-edit"></i>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="confirm-details-content">
                        <form action="">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="input-group custom-input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fi fi-calendar"></i></span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="DD.MM.YY"
                                            id="datepicker-modal" data-key="Date" />
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="input-group custom-input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fi fi-clock"></i></span>
                                        </div>
                                        <input type="text" id="start_time" class="form-control" name="start_time"
                                            placeholder="Time" autocomplete="off" data-key="Time" />
                                        <span class="error-message"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="customer-other-details">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Insurance Number <span
                                                    class="important-label">*</span></label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="ABC1234567890" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Order Id <span
                                                    class="important-label">*</span></label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="123456" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Registration Plate <span
                                                    class="important-label">*</span></label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="ABC1234567890" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Mileage <span
                                                    class="important-label">*</span></label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="60" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">First Name </label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="Muster" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Last Name </label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="Mann" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Email </label>
                                            <div class="form-control-top">
                                                <input type="email" class="form-control" placeholder="Insurance Number"
                                                    value="muster@muster.ch" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Mobile number</label>
                                            <div class="form-control-top mobile-input">
                                                <input type="tel" id="mobile_code" class="form-control"
                                                    placeholder="Phone Number" name="name" />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Street</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="lorem" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">House number</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="lorem" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Country</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="Germany" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">State</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="lorem" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">City</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="Lorem" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div class="input-group custom-input-group">
                                            <label for="" class="form-label">Postal code</label>
                                            <div class="form-control-top">
                                                <input type="text" class="form-control" placeholder="Insurance Number"
                                                    value="879867" disabled />
                                            </div>
                                            <span class="error-message"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="modal-button">
                        <button class="btn red-btn" data-target="#confirmAppointment" data-toggle="modal">
                            <span><i class="fi fi-check-mark-circle"></i></span>
                            <span>Confirm</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

            </main>
        );
    }
}

export default AdminCalendar;
