import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../redux/reducer/reducer';
import { composeWithDevTools } from 'redux-devtools-extension'
import Cookies from 'js-cookie';

const initialState = {
  accessToken: Cookies.get('authToken'),
  id: Cookies.get('id'),
  globalUserId: Cookies.get('globalUserId'),
  isAuthenticated: Cookies.get('isAuthenticated'),
  globalOrganizationId: Cookies.get('globalOrganizationId'),
  name: Cookies.get('name'),
  organizationName: Cookies.get('organizationName'),
  organizationCountry: Cookies.get('organizationCountry'),
  regionName: Cookies.get('regionName'),
  roleId: Cookies.get('roleId'),
  isLoggedIn: Cookies.get('isLoggedIn'),
};

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

export default store;
