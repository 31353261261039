import React, { Component } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/LanguageProvider";
import logo from "../../assets/images/adi-logo.png";
import savalogo from "../../assets/images/sava-logo.jpg";
import menu from "../../assets/images/menu.png";
import profile from "../../assets/images/profile-img.png.svg";
import "./DefaultHeader.css";
import { configuration } from "../../appConfig";
import axios from "axios";
import Cookies from "js-cookie";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import withRouter from "../../withRouter";
import { CookiesRemove } from "../../CookiesRemove";
let langArr = [
  {
    icon: "de",
    lang: "DE",
  },
  {
    icon: "en",
    lang: "US",
  },
  {
    icon: "sl",
    lang: "SL-SL",
  },
];

export class DefaultHeader extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      // selectedLanguage: null,
      selectedLanguage: "SL-SL",
      languageArr: [],
      client_logo: "",
      client_type: "",
    };
  }

  componentDidMount = async () => {
    this.getLanguageDropdownOption();
  };

  handleClick = (lang) => {
    const { userLanguageChange } = this.context;
    userLanguageChange(lang);
    this.setState({ selectedLanguage: lang });
  };

  getLanguageDropdownOption = async () => {
    const { userLanguageChange } = this.context;
    const url = configuration.getLanguageOption;
    try {
      const res = await axios.get(url);
      if (res.data.success) {
        Cookies.set("clientTypeSetting", res.data.data.client_type);
        Cookies.set("clientLogo", res.data.data.client_logo);
        Cookies.set("organization_country", res.data.data.organization_country);
        Cookies.set("footer_logo_1", res.data.data.footer_logo_1);
        Cookies.set("footer_logo_2", res.data.data.footer_logo_2);
        Cookies.set("footer_text", res.data.data.footer_text);
        Cookies.set("is_send_email", res.data.data.is_send_email);
        Cookies.set("is_send_sms", res.data.data.is_send_sms);
        Cookies.set("is_booking_pdf_view", res.data.data.is_booking_pdf_view);
        Cookies.set("theme_color", res.data.data.theme_color);
        const themeColor = res.data.data.theme_color;
        if (themeColor) {
          var element = document.getElementsByTagName("html")[0];
          var root = document.querySelector(":root");
          var rootStyles = getComputedStyle(root);
          root.style.setProperty("--thm-color-red", themeColor);
          root.style.setProperty("--dark-red", themeColor);
        }
        this.setState(
          {
            languageArr: res.data.data.langauge,
            client_logo: res.data.data.client_logo,
            client_type: res.data.data.client_type,
          },
          () => {
            if (this.state.languageArr.length) {
              userLanguageChange(this.state.languageArr[0].name);
            }
          }
        );
      }
    } catch (err) {
      toastAlert(err.response.data.message, "error");
    }
  };

  handleLogout = () => {
    let url = configuration.helpDeskLogout;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        if (res.data.success) {
          CookiesRemove();
          toastAlert("Logout Successfully", "success");
          this.props.router.navigate("/login");
          const myCookie = Cookies.get("organization_country");
          if (myCookie) {
            if (myCookie == "Slovenia") {
              var element = document.getElementsByTagName("html")[0];
              element.classList.add("otherclass");
            } else {
              var element = document.getElementsByTagName("html")[0];
              element.classList.remove("otherclass");
            }
          } else {
            var element = document.getElementsByTagName("html")[0];
            element.classList.remove("otherclass");
          }
          // Cookies.remove('id');
          // Cookies.remove('globalUserId');
          // Cookies.remove('isAuthenticated');
          // Cookies.remove('globalOrganizationId');
          // Cookies.remove('name');
          // Cookies.remove('organizationName');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('regionName');
          // Cookies.remove('roleId');
          // Cookies.remove('isLoggedIn');
          // Cookies.remove('authToken');
          // Cookies.remove('uuid');
          // Cookies.remove('userData');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('role_name');
          // Cookies.remove('clientTypeSetting');
          // Cookies.remove('organization_country');
          // // Cookies.remove('clientLogo');
          // localStorage.removeItem('access_token');
          // var element = document.getElementsByTagName("html")[0];
          // element.classList.remove("otherclass");
        }
      })
      .catch((error) => {
        CookiesRemove();
        this.props.router.navigate("/login");
        toastAlert(error.response.data.message, "error");
        // Cookies.remove('id');
        // Cookies.remove('globalUserId');
        // Cookies.remove('isAuthenticated');
        // Cookies.remove('globalOrganizationId');
        // Cookies.remove('name');
        // Cookies.remove('organizationName');
        // Cookies.remove('organizationCountry');
        // Cookies.remove('regionName');
        // Cookies.remove('roleId');
        // Cookies.remove('isLoggedIn');
        // Cookies.remove('authToken');
        // Cookies.remove('uuid');
        // Cookies.remove('userData');
        // Cookies.remove('organizationCountry');
        // Cookies.remove('organization_country');
        // Cookies.remove('role_name');
        // Cookies.remove('clientTypeSetting');
        // // Cookies.remove('clientLogo');
        // localStorage.removeItem('access_token');
        // var element = document.getElementsByTagName("html")[0];
        // element.classList.remove("otherclass");
      });
  };

  getLanguageFlag = (language = Cookies.get("lang")) => {
    const { languageArr } = this.state;
    let result = languageArr.filter((obj) => obj.name === language)[0];
    Cookies.set("dateFormat", result?.date_format);
    return result?.language_flag;
  };

  render() {
    const { lang, language, clientType } = this.context;
    const { selectedLanguage, languageArr, client_logo, client_type } =
      this.state;
    const name = Cookies.get("name");
    const role = Cookies.get("role_name");
    const isLoggedIn = Cookies.get("isLoggedIn");
    const clientLogo = Cookies.get("clientLogo");
    const val = Cookies.get('roleId')

    return (
      <header className="header-main">
        <div className="container-fluid">
          <nav className="navbar">
            <div className="header-logo-menu-main">
              <div className="header-logo">
                {/* <Link to={this.props.router?.location?.state?.uuid ? `/appointment/book/${this.props.router?.location?.state?.uuid || ''}` : "/appointment/book"}>
                                    <a className="navbar-brand">
                                       
                                        <img src={client_logo} alt={client_type} />
                                    </a>
                                </Link> */}
                {isLoggedIn == "true" ? (
                  <Link to="/helpdesk/options" className="navbar-brand">
                    <img src={clientLogo} alt={client_type} />
                  </Link>
                ) : (
                  <Link
                    to={
                      this.props.router?.location?.state?.uuid
                        ? `/appointment/book/${this.props.router?.location?.state?.uuid}`
                        : "/appointment/book"
                    }
                    className="navbar-brand"
                  >
                    <img src={clientLogo} alt={client_type} />
                  </Link>
                )}
              </div>
              <div className="header-logo-menu-main">
                <div className="select-language-custom">
                  {isLoggedIn == "true" ? (
                    <div className="d-flex align-items-center">
                      <div className="d-sm-none d-md-block d-lg-block mr-2 user-login-info">
                        <b>{lang("logged_in_as")}</b> - {name}
                      </div>
                      <button
                        class="navbar-toggler header-menu-button"
                        type="button"
                      >
                        <img src={menu} alt="Menu" />
                      </button>
                    </div>
                  ) : (
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="language-img">
                          <img
                            src={this.getLanguageFlag()}
                            alt={this.getLanguageFlag()}
                          />
                        </span>
                        <span>{language}</span>
                      </button>
                      <div
                        className="dropdown-menu pull-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {languageArr?.map((langObj) => (
                          <a
                            key={langObj.name}
                            className="dropdown-item pl-1 "
                            onClick={() => this.handleClick(langObj.name)}
                          >
                            <div className="language-icon-main">
                              <span className="language-img">
                                <img
                                  src={langObj.language_flag}
                                  alt={langObj?.name}
                                />
                              </span>
                              <span className="drawer-link-name">
                                {langObj?.name}
                              </span>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isLoggedIn == "true" ? (
              <>
                <div class="header-drawer-wrapper">
                  <div class="header-drawer-main">
                    <div class="drawer-close-btn">
                      <button class="btn closeHeaderDrawer">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                    <div class="drawer-links">
                      <ul>
                        <li class="active-drawer-link">
                          <Link to="/helpdesk/options">
                            <span class="drawer-icon">
                              <i class="fa fa-home"></i>
                            </span>
                            <span class="drawer-link-name">{lang("home")}</span>
                          </Link>
                        </li>
                       {val == 1 || val == 2 ? <li>
                        <Link to="/helpdesk/user-management">
                          <span class="drawer-icon">
                            <i class="fa fa-user"></i>
                          </span>
                          <span class="drawer-link-name">
                            {lang("user_management")}
                          </span>
                          </Link>
                        </li>:''}
                        <li>
                          <div class="dropdown drawer-language-select language-img">
                            <a
                              class="btn  dropdown-toggle"
                              role="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span class="drawer-icon language-img">
                                <img
                                  class="lang-drawer-inner-img"
                                  src={this.getLanguageFlag()}
                                  alt={this.getLanguageFlag()}
                                />
                              </span>
                              <span class="drawer-link-name">{language}</span>
                            </a>
                            <div class="dropdown-menu">
                              {languageArr?.map((langObj) => (
                                <a
                                  key={langObj.name}
                                  className="dropdown-item pl-1 "
                                  onClick={() => this.handleClick(langObj.name)}
                                >
                                  <div className="language-icon-main">
                                    <span className="language-img">
                                      <img
                                        src={langObj.language_flag}
                                        alt={langObj?.name}
                                      />
                                    </span>
                                    <span className="drawer-link-name">
                                      {langObj?.name}
                                    </span>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="drawer-logout-btn">
                      <div class="drawer-profile-main">
                        <div class="profile-img">
                          <img src={profile} alt="Profile" />
                        </div>
                        <div class="profile-name">
                          <h6 class="small-title-18">{name}</h6>
                          <p class="small-text-16">{lang(`${role}`)}</p>
                        </div>
                      </div>
                      <button
                        class="btn red-btn"
                        onClick={() => this.handleLogout()}
                      >
                        <span>{lang("logout")}</span>
                        <span>
                          <i class="fa fa-power-off"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="drawer-overlay"></div>
              </>
            ) : (
              ""
            )}
          </nav>
        </div>
      </header>
    );
  }
}

export default withRouter(DefaultHeader);
