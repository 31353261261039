import React, { Component } from 'react';
import LanguageContext from '../../context/LanguageProvider';
// import deleteappointment from '../../../public/assets/images/delete-appoinment.png'
import deleteappointment from '../../assets/images/delete-appoinment.png';
import axios from 'axios';
import { configuration } from '../../appConfig';
import Swal from 'sweetalert2';
import withRouter from '../../withRouter';
import moment from "moment/moment";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
export class DeleteAppointment extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            drivin_location: '',
            loading: false,
            isDeleted : false,
        }
    }

    componentDidMount = async () => {
        await this.getAppointmentData();
    }

    getAppointmentData = () => {
        this.setState({ loading: true })
        let uuid = this.props.router.params.id;
        const bookingInfoUrl = configuration.customerBookingInfo + uuid;
        axios.get(bookingInfoUrl).then(
            response => {
                if (response.data.success) {
                    if(response.data.data.is_canceled == 1){
                        this.props.router.navigate('/404')
                    }
                    else{
                        this.setState({
                            date: response.data.data.date,
                            time: response.data.data.from_time,
                            drivin_location: response.data.data.drivin_location,
                            loading: false,
                            isDeleted : false,
                        })
                    }
                }
            }
        ).catch(error => {
            this.setState({ loading: false })
            if (error.response.data.code == 404) {
                this.setState({ 
                    loading: false,
                    isDeleted : true, 
                })
                this.props.router.navigate('/404')
            }
            else{
                toastAlert(error.response.data.message, 'error');
            }
        });
    }

    handleDelete = () => {
        let uuid = this.props.router.params.id;
        console.log('uuid', uuid);
        const url = configuration.deleteAppointment + uuid;
        const { language, lang } = this.context
        Swal.fire({
            title: `${lang("confirm_delete")}`,
            text: `${lang("are_you_sure_you_want_to_delete_your_appointment?_this_can_not_be_undone.")}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: `${lang("cancel")}`,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `${lang("yes,_delete_it!")}`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(url)  //  <- Payload 
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire(`${lang("deleted!")}!`, `${lang("your_appointment_has_been_deleted.")}`, 'success');
                        }
                    })
                    .catch(error => {
                        Swal.fire(`${lang("deleted!")}!`, `${lang("you_already_deleted_your_appointment.")}`, 'success');
                        this.setState({ loading: false });
                    });

            }
        });

    }
    render() {
        const { language, lang } = this.context
        const { date, time, drivin_location, loading } = this.state
        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="thankyou-wrapper">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-7 col-md-12 col-sm-12">
                                    <div class="thank-you-content-main">
                                        <div class="thankyou-title">
                                            {/* <p class="small-text-black-16">Dear <span class="small-text-black-16">,</span>
                                <span class="small-text-red-16">Mr. Jhone Doe</span>
                            </p> */}
                                            <h2 class="black-thankyou-title thankyou-title">{lang("appointment_cancellation")} <span
                                                class="red-thankyou-title thankyou-title"></span> </h2>
                                        </div>
                                        <div class="booked-customer-details">
                                            <p class="small-text-red-16 mt-2">{lang("you_are_about_to_delete_the_selected_appointment_permanently._do_you_with_to_proceed?")}</p>
                                        </div>
                                        <div class="delete-content">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="thankyou-date-time">
                                                        <span class="small-text-black-16">{lang("date")}<span class="small-text-black-16">: </span></span>
                                                        <span class="small-text">{moment(date).format(['DE', 'CH-DE','SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="thankyou-date-time">
                                                        <span class="small-text-black-16">{lang("time")}<span class="small-text-black-16">: </span></span>
                                                        <span class="small-text">{time}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="thankyou-date-time">
                                                        <span class="small-text-black-16">{lang("location")}<span class="small-text-black-16">: </span></span>
                                                        <span class="small-text">{drivin_location}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="booked-customer-details mt-3">
                                            <button class="btn red-btn" onClick={this.handleDelete}>
                                                <span><i class="fa fa-trash"></i></span>&nbsp;
                                                <span data-key="Reschedule">{lang("appointment_cancellation")}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 cl-md-12 col-sm-12">
                                    <div class="thank-you-image">
                                        <img src={deleteappointment} alt="Thankyou" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Modal isOpen={this.state.isDeleted} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang("this_appointment_for_drive-in_has_already_been_cancelled.")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    {/* <p class="small-text-16" data-key="Areyousureyouwanttorescheduleyourappointment?">{lang("you_already_reschedule_your_appointment_please_contact_help_desk")}</p> */}
                                    <p class="small-text-16">{lang("please_make_a_new_appointment.")}</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    {/* <ModalFooter> */}
                        {/* <div class="modal-button two-modal-btn-custom"> */}
                            {/* <button class="btn gray-btn" data-dismiss="modal">
                                <span><i class="fa fa-trash"></i></span>
                                <span data-key="Delete">Delete</span>
                            </button> */}

                            {/* <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">Help Desk</span>
                                </button> */}
                            {/* <div class="modal-bottom-text">
                                <h6 class="small-text-red-16">{lang("contact")} : 031 389 8960</h6>
                            </div> */}
                            {/* </Link> */}
                        {/* </div> */}
                    {/* </ModalFooter> */}
                </Modal>
            </>

        )
    }
}

export default withRouter(DeleteAppointment)