import React, { Component } from 'react';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import LanguageContext from '../../context/LanguageProvider';
import { Link } from 'react-router-dom';
import withRouter from '../../withRouter';
import user from "../../assets/images/user.svg";
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import Cookies from 'js-cookie';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { CookiesRemove } from '../../CookiesRemove';
export class CreateAppointment extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            reg_noError: '',
            insurance_numberError: '',
            order_idError: '',
            userData: {
                reg_no: '',
                claim_number: '',
                order_id: '',
                data:''
            },
            country_code: '',
            isButtonClicked: false,
            isModalOpen: false,
            date: '',
            time: '',
            isDisableNextButton: 0,
            isDisableButton: false,
            checkOrderLoading: false,
            isBookingModal: false,
            loading: false,
            isTimeReached: false,
            enterCount: false,
            is_exiting: null,
            isFromResponse: false,
            message: '',
        }
    }

    // handleChange = (e) => {
    //     const { name, value } = e.target;
    //     this.setState(prevState => ({
    //         userData: {
    //             ...prevState.userData,
    //             [name]: value
    //         },
    //         [`${name}Error`]: ''
    //     }));
    // }

    handleChange = (e) => {
        const { name, value } = e.target;
        console.log("errrs", name, value);
        this.setState(prevState => ({
            userData: {
                ...prevState.userData,
                [name]: value
            },
            [`${name}Error`]: ''
        }),
            // () => {
            //     if (name === 'order_id') {
            //         this.setState({
            //             isDisableNextButton: true
            //         }, () => this.checkOrderId())
            //     }
            // }
        );
    }


    handleBlur = async () => {
        const reg_no = this.state.userData.reg_no
        const claim_number = this.state.userData.claim_number
     
        if((reg_no.trim() && claim_number.trim() ) || (!reg_no.trim() && !claim_number.trim() )) {
            return;
        }
        const { lang } = this.context;
        let url = `${configuration.sendDataSearch}insurance_no=${claim_number || ''}&&reg_no=${reg_no || ''}`

        try {
            // const isValid = this.validateFields();
            // if (isValid) {
            const res = await axios.get(url);
            if (res.data.success) {
                this.setState({
              
                    userData: {
                        reg_no: res.data.data.reg_no,
                        claim_number: res.data.data.claim_number,
                        order_id: res.data.data.order_id,
                        data:res.data.data
                    },
                })


                if (res.data.data.is_booking == 1) {
                    this.setState({
                        loading: false,
                        isBookingModal: true,
                        isDisableButton: true,
                    })
                } else {
                    const cookieName = "userData";
                    const cookieValue = JSON.stringify(res.data.data);
                    document.cookie = `${cookieName}=${cookieValue}; path=/`;

                    this.setState({
                        isDisableButton: false,
                        isBookingModal: false,
                        checkOrderLoading: false,
                        loading: false,
                        userData: {
                            reg_no: res.data.data.reg_no,
                            claim_number: res.data.data.claim_number,
                            order_id: res.data.data.order_id,
                        },
                        enterCount: true,
                        isFromResponse: true,
                    })
                }
            }

        } catch (err) {
            this.setState({ loading: false })
            // let order_idError = lang('order_id_is_already_exists.');
            if (err.response.data.code === 422) {
                // this.setState({
                //     isDisableButton: true,
                //     checkOrderLoading: false,
                //     order_idError: order_idError,
                //     loading: false,
                //     enterCount: false,
                //     reg_no: this.state.userData.reg_no,
                //     claim_number: this.state.userData.claim_number,
                // })
            }
            else if (err.response.data.code === 401) {
                CookiesRemove();
                toastAlert(err.response.data.message, 'error');
                this.props.router.navigate('/login');
            }
            // else {
            //     toastAlert(err.response.data.message, 'error');
            //     let order_idError = lang('order_id_is_required.');
            //     this.setState({
            //         loading: false,
            //         enterCount: false,
            //         order_idError: order_idError,
            //         checkOrderLoading: false,
            //         reg_no: this.state.userData.reg_no,
            //         claim_number: this.state.userData.claim_number,
            //     })
            // }
        }
    }


    handleKeyDown = (e) => {
        if (!this.state.checkOrderLoading && !this.state.isDisableButton && e && e.key === 'Enter') {
            e.preventDefault();
            if (this.state.enterCount == false) {
               
                this.checkOrderId();
            }
            else {
                this.handleNext(e);
            }
        }
    };

    validateFields = () => {
        const { lang } = this.context;
        const { reg_no, claim_number, order_id } = this.state.userData;
        console.log(typeof (claim_number));
        console.log(typeof (order_idError));
        console.log(typeof (reg_noError));
        let isError = false;

        if (reg_no.trim() === '') {
            this.setState({ reg_noError: lang('registration_plate_is_required.') });
            isError = true;
        }

        if (claim_number.trim() === '') {
            this.setState({ insurance_numberError: lang('insurance_number_is_required.') });
            isError = true;
        }
        if (order_id.trim() === '') {
            this.setState({ order_idError: lang('order_id_is_required.') });
            isError = true;
        }

        return !isError;
    }

    componentDidMount = () => {
        let uuid = Cookies.set('uuid', '')
        
        // uuid && this.getAppointmentDetails(uuid)
        const organizationCountry = Cookies.get('organizationCountry');
        if (organizationCountry === 'Slovenia') {
            const message = 'e.g_audatex,_eurotax,_dat_etc...';
            this.setState({
                message: message
            })
        }
    }

    // getAppointmentDetails = (uuid) => {
    //     const url = configuration.bookAppointment + uuid;
    //     const accessToken = Cookies.get('authToken')
    //     const headers = {
    //         'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
    //     }
    //     axios.get(url, { headers })
    //         .then((res) => {
    //             if (res.data.success) {
    //                 const { reg_no, claim_number, order_id } = res.data.data;
    //                 this.setState({
    //                     userData: {
    //                         "reg_no": reg_no,
    //                         "claim_number": claim_number,
    //                         "order_id": order_id,
    //                     }
    //                 })
    //             }
    //         })
    //         .catch((err) => {
    //             toastAlert(err.response.data.message, 'error');
    //         })
    // }

    // checkOrderId = () => {
    //     this.setState({
    //         checkOrderLoading: true,
    //         loading : true,
    //     })
    //     const { lang } = this.context;
    //     const order_id = this.state.userData.order_id
    //     const url = configuration.orderIdExistsOrNot;
    //     const accessToken = Cookies.get('authToken')
    //     const headers = {
    //         'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
    //     }
    //     const payload = {
    //         'order_id': order_id,
    //     }

    //     axios.post(url, payload, { headers })
    //         .then((res) => {
    //             if (res.data.success) {
    //                 if(res.data.data.is_over_time_resudule == true)
    //                 {
    //                     this.setState({
    //                         isTimeReached : true,
    //                     })
    //                 }
    //                 else{
    //                     if (res.data.data.is_exiting == true) {
    //                         if (res.data.data.is_booking == 1) {
    //                             this.setState({
    //                                 loading : false,
    //                                 isBookingModal: true,
    //                                 isDisableButton: true,
    //                             })
    //                         }
    //                         else {
    //                             const cookieName = "userData";
    //                             const cookieValue = JSON.stringify(res.data.data);
    //                             document.cookie = `${cookieName}=${cookieValue}; path=/`;

    //                             this.setState({
    //                                 isDisableButton: false,
    //                                 isBookingModal: false,
    //                                 checkOrderLoading: false,
    //                                 loading : false,
    //                                 userData: {
    //                                     reg_no: res.data.data.reg_no,
    //                                     claim_number: res.data.data.claim_number,
    //                                     order_id: res.data.data.order_id,
    //                                 },
    //                                 enterCount : true,
    //                             })
    //                         }
    //                     }
    //                     else {
    //                         Cookies.remove('userData');
    //                         this.setState({
    //                             isDisableButton: false,
    //                             checkOrderLoading: false,
    //                             loading : false,
    //                             userData: {
    //                                 reg_no: '',
    //                                 claim_number: '',
    //                                 order_id: order_id,
    //                             },
    //                             enterCount : true,
    //                         })
    //                     }
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             this.setState({ loading : false })
    //             let order_idError = lang('order_id_is_already_exists.');
    //             if (err.response.data.code === 422) {
    //                 this.setState({
    //                     isDisableButton: true,
    //                     checkOrderLoading: false,
    //                     order_idError: order_idError,
    //                     loading : false,
    //                     enterCount : false,
    //                 })
    //             } else {
    //                 toastAlert(err.response.data.message, 'error');
    //                 this.setState({ 
    //                     loading : false, 
    //                     enterCount : false,
    //                     checkOrderLoading: false, 
    //                 })
    //             }
    //         })
    // }

    checkOrderId = async () => {
        // this.setState({
        //     checkOrderLoading: true,
        //     loading: true,
        // })
        const { lang } = this.context;
        const order_id = this.state.userData.order_id
        if(!(order_id.trim() ) ) {
            return;
        }

        const url = configuration.orderIdExistsOrNot;
        const accessToken = Cookies.get('authToken')
        const headers = {
            'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
        }
        const payload = {
            'order_id': order_id,
        }

        try {
            // const isValid = this.validateFields();
            // if (isValid) {
            const res = await axios.post(url, payload, { headers });
            if (res.data.success) {
                Cookies.set('is_existing', res.data.data.is_exiting)
                if (res.data.data.is_over_time_resudule == true) {
                    this.setState({
                        isTimeReached: true,
                    })
                } else {
                    if (res.data.data.is_exiting == true) {
                        if (res.data.data.is_booking == 1) {
                            this.setState({
                                loading: false,
                                isBookingModal: true,
                                isDisableButton: true,
                                userData: {
                                    reg_no: res.data.data.reg_no,
                                    claim_number: res.data.data.claim_number,
                                    order_id: res.data.data.order_id,
                                    data:res.data.data
                                },
                            })
                        } else {
                            const cookieName = "userData";
                            const cookieValue = JSON.stringify(res.data.data);
                            document.cookie = `${cookieName}=${cookieValue}; path=/`;

                            this.setState({
                                isDisableButton: false,
                                isBookingModal: false,
                                checkOrderLoading: false,
                                loading: false,
                                userData: {
                                    reg_no: res.data.data.reg_no,
                                    claim_number: res.data.data.claim_number,
                                    order_id: res.data.data.order_id,
                                },
                                enterCount: true,
                                isFromResponse: true,
                            })
                        }
                    } else {
                        Cookies.remove('userData');
                        if (this.state.isFromResponse == true) {
                            this.setState({
                                isDisableButton: false,
                                checkOrderLoading: false,
                                loading: false,
                                userData: {
                                    reg_no: '',
                                    claim_number: '',
                                    order_id: order_id,
                                },
                                enterCount: true,
                                is_exiting: res.data.data.is_exiting,
                                isFromResponse: false,
                            })
                        }
                        else {
                            this.setState({
                                isDisableButton: false,
                                checkOrderLoading: false,
                                loading: false,
                                userData: {
                                    reg_no: this.state.userData.reg_no,
                                    claim_number: this.state.userData.claim_number,
                                    order_id: order_id,
                                },
                                enterCount: true,
                                is_exiting: res.data.data.is_exiting,
                            })
                        }
                    }
                }
            }
            // }
            // else {
            //     this.setState({
            //         loading: false,
            //     })
            // }
        } catch (err) {
            this.setState({ loading: false })
            let order_idError = lang('order_id_is_already_exists.');
            if (err.response.data.code === 422) {
                this.setState({
                    isDisableButton: true,
                    checkOrderLoading: false,
                    order_idError: order_idError,
                    loading: false,
                    enterCount: false,
                    reg_no: this.state.userData.reg_no,
                    claim_number: this.state.userData.claim_number,
                })
            }
            else if (err.response.data.code === 401) {
                CookiesRemove();
                toastAlert(err.response.data.message, 'error');
                this.props.router.navigate('/login');
            }
            // else {
            //     toastAlert(err.response.data.message, 'error');
            //     let order_idError = lang('order_id_is_required.');
            //     this.setState({
            //         loading: false,
            //         enterCount: false,
            //         order_idError: order_idError,
            //         checkOrderLoading: false,
            //         reg_no: this.state.userData.reg_no,
            //         claim_number: this.state.userData.claim_number,
            //     })
            // }
        }
    }

    handleNext = (e) => {
        e.preventDefault();
        const isValid = this.validateFields();
        const { reg_no, claim_number, order_id } = this.state.userData;
        if (isValid) {
            this.props.router.navigate('/helpdesk/create/appointment/details', {
                state: {
                    reg_no: reg_no,
                    order_id: order_id,
                    claim_number: claim_number,
                }
            });
        }
    }

    handleToggle = () => {
        this.setState(prevState => ({
            isBookingModal: !prevState.isBookingModal,
            loading: false,
            isTimeReached: false

        }));
    }

    render() {
        const { lang } = this.context;
        const { reg_noError, insurance_numberError, order_idError, isDisableButton, loading, message } = this.state;
        const { reg_no, claim_number, order_id } = this.state.userData;

        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-4">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src={user} alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18">{lang("create_new_appointment")}</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <form action="">
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">

                                                                <label for="" class="form-label">{lang('order_id')} {lang(`${message}`)}<span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" class="form-control" value={order_id} name="order_id" placeholder={lang("order_id")}  onChange={this.handleChange} onBlur={this.checkOrderId} onKeyDown={this.handleKeyDown} />
                                                                </div>
                                                                <span className="error-message">{order_idError}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('insurance_number')} <span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" class="form-control"
                                                                        placeholder={lang('insurance_number')} value={claim_number} name="claim_number" onChange={this.handleChange} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} />
                                                                </div>
                                                                <span className="error-message">{insurance_numberError}</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('registration_plate')} <span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input
                                                                        type="text"
                                                                        onBlur={this.handleBlur}
                                                                        value={reg_no}
                                                                        class="form-control"
                                                                        name="reg_no"
                                                                        onChange={this.handleChange}
                                                                        placeholder={lang('registration_plate')}
                                                                        onKeyDown={this.handleKeyDown}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{reg_noError}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer single-next-btn">
                                                <div class="card-btn next-btn-main">
                                                    <button
                                                        className="btn next-btn red-btn"
                                                        type="button"
                                                        onClick={this.handleNext}
                                                        // disabled={isDisableButton}
                                                    disabled={!claim_number.trim() || !order_id.trim() || !reg_no.trim()||isDisableButton?true:false}
                                                    >
                                                        {lang("next")}
                                                        <span><i className="fa fa-long-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Modal isOpen={this.state.isBookingModal} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader>
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang('an_appointment_been_created_for_this_order_id_:')} {order_id}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")} </span> <span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")} </span><span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16">{lang("kindly_click_on_the_reschedule_appointment_button_to_reschedule_appointment.")}</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            <Link to='/helpdesk/reschedule' state={{
                                userData: this.state.userData
                            }}>
                                <button class="btn red-btn" >
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("reschedule_appointment")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.isTimeReached} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    {/* <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader> */}
                    <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader>
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang('time_limit_reached_can_not_reschedule_before_24_hours.')}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")} </span> <span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")} </span><span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16">{lang("kindly_click_on_the_create_new_appointment_button_to_create_a_new_appointment")}</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            <Link to='/helpdesk/create/appointment'>
                                <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("create_new_appointment")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(CreateAppointment);
