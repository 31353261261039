import React, { Component } from 'react';
import withRouter from '../../withRouter';
import Location from "../../assets/images/select-location.svg"
import greenCircle from "../../assets/images/green-semi-circle.svg"
import redCircle from "../../assets/images/red-semi-circle.svg"
import orangeCircle from "../../assets/images/orange-semi-circle.svg"
import locationIcon from "../../assets/images/location.svg"
import moment from 'moment';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

export class BrowserViewComponent extends Component {
    render() {
        const { locationOrigin, lang, handelClick, handleMoreLocations, locationDestination } = this.props;
        const { language } = this.context
        let oldDateFormat = Cookies.get('dateFormat')
        // console.log(dateFormat, "dateFormat");
    

        oldDateFormat = oldDateFormat.substr(0, oldDateFormat.length - 4);
        var dateFormat = oldDateFormat.replace(/.$/, '');
        console.log(dateFormat,"dateFormat");
        return (
            <>
                <div class="large-device-locations">
                <div class="location-main-content location-main-content-v2">
                    <section class="customer-location-wrapper">
                        <div class="container">
                            <div class="row all-locations-row">
                                <div class="col-12">
                                    <div class="locations-top">
                                        <div class="customer-location-title">
                                            <div class="customer-location-image">
                                                <img src={locationIcon} alt="Your Location" />
                                            </div>
                                            <h4 class="small-title-18">{locationOrigin}</h4>
                                        </div>

                                        {/* Green-location-::Start  */}
                                        <div class="green-location-top">
                                            <div class="green-location-img location-circle-img">
                                                <img src={greenCircle} alt="Curve" />
                                            </div>
                                            <div class="green-location-row">
                                                <div class="green-location-name all-location-name">
                                                    {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(0, 2).map((location, i) => {
                                                        return (
                                                            <div class={`location-inner green-location-${i} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main left-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                            <p className='location-date-text'><span className='small-text-black-14'>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main left-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(2, 3).map((location, i) => {
                                                        return <div class={`location-inner green-location-2 ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main">
                                                                <div class="city-name-main center-side-location">
                                                                    <p class="small-text-black-16">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p></p>
                                                                </div>
                                                                <div class="location-circle-main center-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(3, 5).map((location, i) => {
                                                        return <div class={`location-inner green-location-${i + 3} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main option-three-city">
                                                                <div class="city-name-main right-side-location">
                                                                    <p class="small-text-black-16 d-block">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                    </p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[1] && locationDestination[1]?.length > 5 && (
                                                        <div class="location-inner green-location-4">
                                                            <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[1], 1)}>
                                                                <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                    <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Green-location-::End */}

                                        {/* Orange-location-::Start */}
                                        <div class="orange-location-top">
                                            <div class="orange-location-row">
                                                <div class="orange-location-img location-circle-img">
                                                    <img src={orangeCircle} alt="Curve" />
                                                </div>
                                                <div class="orange-location-name all-location-name">
                                                    {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(0, 2).map((location, i) => {
                                                        return <div class={`location-inner orange-location-${i} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main">
                                                                <div class="city-name-main left-side-location">
                                                                    <p class="small-text-black-16">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="location-circle-main left-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(2, 3).map((location, i) => {
                                                       return <div class={`location-inner orange-location-2 ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div
                                                                class="city-name-km-main">
                                                                <div class="city-name-main center-side-location">
                                                                    <p class="small-text-black-16">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                    </p>
                                                                </div>
                                                                <div class="location-circle-main center-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(3, 5).map((location, i) => {
                                                       return <div class={`location-inner orange-location-${i + 3} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main option-three-city">
                                                                <div class="city-name-main right-side-location">
                                                                    <p class="small-text-black-16">
                                                                        {location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                    </p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[2] && locationDestination[2]?.length > 5 && (
                                                        <div class="location-inner orange-location-4">
                                                            <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[2], 2)}>
                                                                <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                    <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Orange-location-::End */}

                                        {/* Red-location-::Start */}
                                        <div class="red-location-top">
                                            <div class="red-location-row">
                                                <div class="red-location-img location-circle-img">
                                                    <img src={redCircle} alt="Curve" />
                                                </div>
                                                <div class="red-location-name all-location-name">
                                                    {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(0, 2).map((location, i) => {
                                                        return <div class={`location-inner red-location-${i} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main">
                                                                <div class="city-name-main left-side-location">
                                                                    <p class="small-text-black-16">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p>
                                                                    </p>
                                                                </div>
                                                                <div class="location-circle-main left-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}

                                                    {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(2, 3).map((location, i) => {
                                                        return <div class={`location-inner red-location-2 ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div
                                                                class="city-name-km-main">
                                                                <div class="city-name-main center-side-location">
                                                                    <p class="small-text-black-16">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p></p>
                                                                </div>
                                                                <div
                                                                    class="location-circle-main center-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(3, 5).map((location, i) => (
                                                        <div class={`location-inner red-location-${i + 3} ${location.category === 'city' ? 'city-location' : 'other-location'}`} onClick={() => handelClick(location)}>
                                                            <div class="city-name-km-main">
                                                                <div class="city-name-main right-side-location">
                                                                    <p class="small-text-black-16   ">{location.location_name}
                                                                        <span class="small-text-14">({location.km}km)</span>
                                                                        <p className='location-date-text'><span>{moment(location.from_date).format(dateFormat)} -&nbsp;
                                                                                {moment(location.to_date).format(dateFormat)}</span></p></p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {locationDestination[3] && locationDestination[3]?.length > 5 && (
                                                        <div class="location-inner red-location-4">
                                                            <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[3], 3)}>
                                                                <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                    <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                </div>
                                                                <div class="location-circle-main right-side-dots">
                                                                    <div class="location-circle-out">
                                                                        <div class="location-circle-in">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Red-location-::End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="location-bottom-line"></div>
                    </section>
                </div>
                </div>
            </>
        );
    }
}

export default withRouter(BrowserViewComponent);
