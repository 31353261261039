import React, { Component } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

class PrivateRouteLogin extends Component {
  render() {
    const { children, userData, ...rest } = this.props;
    const isLoggedIn = Cookies.get('isLoggedIn');
    return isLoggedIn ?  <Navigate to="/helpdesk/options" />  : children;
  }
}

const mapStateToProps = state => ({
  userData: state,
});

export default connect(mapStateToProps)(PrivateRouteLogin);
