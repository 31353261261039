import React, { Component } from "react";
import { configuration, url } from "../../appConfig";
import axios from "axios";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Spinner } from "reactstrap";
import moment from "moment";
import Cookies from "js-cookie";
import CalendarComponent from "../../components/calendar/CalendarComponent";
import LanguageContext from "../../context/LanguageProvider";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CookiesRemove } from "../../CookiesRemove";
import CalendarDayView from "../../components/calendarDayView/CalendarDayView";
import PhoneInput from "react-phone-input-2";
import withRouter from "../../withRouter";
import Adminweek from "../../components/admin-Week/AdminWeek";
import AdminWeek from "../../components/admin-Week/AdminWeek";
import AdminDay from "../../components/admin-day/AdminDay";
// const response = require("./response.json")

const POSTAL_CODE = 2000;
const CITY = "Maribor";

class HelpDeskAdminView extends React.Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      momentDate: moment().format("DD/MM/YYYY"),
      Month: new Date().getMonth() + 1,
      Year: new Date().getFullYear(),
      currentDate: new Date(),
      data: "",
      isToggle: false,
      weekView: false,
      monthView: true,
      isDayView: false,
      monthData: {},
      daysOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      allDates: [],
      loading: false,
      rows: [],
      lastDate: moment().endOf("month").format("DD/MM/YYYY"),
      firstDate: moment().startOf("month").format("DD/MM/YYYY"),
      countOnClick: 0,
      allSlot: "",
      detail: [],
      timeSlot: [],
      slots: {},
      headerTotalSlot: {},
      sortedTimeArray: [],
      startOfWeek: "",
      is_booking: "",
      is_email: "",
      is_sms: "",
      is_print_pdf: "",
      // is_backoffice_mail: true,
      driveinId: "",
      confirmEdit: false,
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      mobileNumber: "",
      mobileNumberError: "",
      street: "",
      streetError: "",
      houseNumber: "",
      houseNumberError: "",
      country: "",
      countryError: "",
      state: "",
      stateError: "",
      city: CITY,
      cityError: "",
      postalCode: POSTAL_CODE,
      postalCodeError: "",
      km_stand: "",
      mileageError: "",
      showModal: false,
      showAppointmentList: false,
      showFinalModal: false,
      // modal states
      isOpenAppointmentListModal: false,
      appointmentSlotTime: null,
      appointmentDay: null,
      isOpenConfirmDetailsModal: false,
      selectedAppointmentTime: "",
      isOpenConfirmAppointmentModal: false,
      selectedAppointmentId: null,
      isOpenAppointmentSuccessModal: false,
      loading: false,
      buttonClicked: false,
      isNextDisable: false,
      country_code: "",
      profession: "not specified",
      salutation: "Mr.",
      slots: "",
      timeData: [],
      selectDatw: "",
      location_name: "",
      daytoWeek: "",
      viewMode: "Month",
      selectedAppointmentDate: false,
      isButtonClicked: false,
      message: "",
      countOnClick: 0,
      dayviewDate: "",
    };
  }
  componentDidMount = async () => {
    await this.adminViewSlotMonthWise();
    const organizationCountry = Cookies.get("organizationCountry");
    if (organizationCountry === "Slovenia") {
      const message = "e.g_audatex,_eurotax,_dat_etc...";
      this.setState({
        message: message,
        fromDate: moment().format("YYYY-MM-DD"),
        firstDate: moment().startOf("month").format("DD/MM/YYYY"),
      });
    }
    Cookies.set("DrivinId", "");
  };

  handleMonthclick = () => {
    this.setState(
      {
        monthView: true,
        weekView: false,
        isDayView: false,
        viewMode: "Month",
      },
      () => this.adminViewSlotMonthWise()
    );
  };

  adminViewSlotMonthWise = () => {
    this.setState({
      loading: true,
    });

    const url = `${configuration.adminViewSlotMonthWise}?month=${this.state.Month}&year=${this.state.Year}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        let tempMonthData = {};
        Object.keys(res.data.data).forEach((key) => {
          tempMonthData = { ...tempMonthData, ...res.data.data[key] };
        });

        if (moment().month() + 1 === this.state.Month) {
          tempMonthData[
            moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          ].length !=0?
          Cookies.set(
            "DrivinId",
            tempMonthData[
              moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            ][0].drivein_id
          ):""
        }
        this.setState({
          monthData: tempMonthData,
        });
        let datesArr = Object.keys(tempMonthData).sort();

        const { currentDate } = this.state;
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        const daysInMonth = new Date(
          currentYear,
          currentMonth + 1,
          0
        ).getDate();

        let firstDayOfWeek = moment(currentDate).startOf("month").day();
        if (firstDayOfWeek === 0) {
          firstDayOfWeek = 6;
        } else {
          firstDayOfWeek--;
        }
        let rows = [[]];
        let rowIndex = 0;

        for (let i = 0; i < firstDayOfWeek; i++) {
          rows[rowIndex].push("");
        }
        // Populate the rows with the month's dates
        for (let day = 1; day <= 32; day++) {
          if (rows[rowIndex].length === 7) {
            rows.push([]);
            rowIndex++;
          }
          rows[rowIndex].push(datesArr[day - 1]);
        }
        console.log(rows, rows[rowIndex], "line189");
        const dateFormat = Cookies.get("dateFormat");
        const firstDate = moment(currentDate)
          .startOf("month")
          .format(dateFormat);
        const lastDate = moment(currentDate).endOf("month").format(dateFormat);
        // console.log(firstDate,this.state.currentDate, "rowsrowsrows");

        if (moment().month() + 1 === this.state.Month) {
          tempMonthData[
            moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          ].length !=0 ?
          Cookies.set(
            "location_name",
            tempMonthData[
              moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            ][0].location_name
          ) :""
        }
        this.setState({
          rows: rows,
          // location_name:
          //   tempMonthData[
          //     moment(this.state.currentDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          //   ][0]?.location_name || "",
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error, "error");
        // if (error.response.data.code === 401) {
        //   CookiesRemove();
        //   toastAlert(error.response.data.message, "error");
        //   this.props.router.navigate("/login");
        // }
        this.setState({ isButtonClicked: false, loading: false });
        toastAlert(error.message, "error");
      });
  };
  handleCheck = () => {
    this.setState({
      isToggle: true,
    });
  };
  handleFilter = () => {
    this.setState({
      isToggle: false,
    });
  };

  // handlePrevClick = () => {
  //   const dateFormat = Cookies.get("dateFormat");
  //   const { currentDate, Month, Year } = this.state;

  //   const prevDate = moment(currentDate).subtract(1, "months");
  //   const firstDate = prevDate.startOf("month").format(dateFormat);
  //   const lastDate = prevDate.endOf("month").format(dateFormat);

  //   this.setState(
  //     {
  //       Month: prevDate.month() + 1,
  //       Year: prevDate.year(),
  //       lastDate: lastDate,
  //       currentDate: prevDate.toDate(),
  //       firstDate: firstDate,
  //       isNextDisable: false
  //     },
  //     () => this.adminViewSlotMonthWise()
  //   );
  // };

  handlePrevClick = () => {
    const dateFormat = Cookies.get("dateFormat");
    const { currentDate, Month, Year } = this.state;

    let monthAndYear = `${Month}/${Year}`;
    let monthAndDate = moment(monthAndYear, "MM/YYYY")
      .subtract(1, "months")
      .format("MM/YYYY");
    const firstDate = moment(monthAndDate, "MM/YYYY")
      .startOf("month")
      .format("DD/MM/YYYY");
    const lastDate = moment(monthAndDate, "MM/YYYY")
      .endOf("month")
      .format("DD/MM/YYYY");
    console.log(monthAndDate, lastDate, firstDate, "prevMonth");
    const prevDate = new Date(currentDate);
    prevDate.setMonth(prevDate.getMonth() - 1);
    console.log(this.state.Month == 1, "ss");
    this.setState(
      {
        Month: this.state.Month == 1 ? 12 : this.state.Month - 1,
        Year: this.state.Month == 1 ? Year - 1 : Year,
        lastDate: lastDate,
        currentDate: prevDate,
        firstDate: firstDate,
        isNextDisable: false,
      },
      () => this.adminViewSlotMonthWise()
    );
  };

  // handleNextClick = () => {
  //   const dateFormat = Cookies.get("dateFormat");
  //   const { currentDate, Month, Year } = this.state;

  //   const nextDate = moment(currentDate).add(1, "months");
  //   const firstDate = nextDate.startOf("month").format(dateFormat);
  //   const lastDate = nextDate.endOf("month").format(dateFormat);

  //   this.setState(
  //     {
  //       Month: nextDate.month() + 1,
  //       Year: nextDate.year(),
  //       lastDate: lastDate,
  //       currentDate: nextDate.toDate(),
  //       firstDate: firstDate,
  //       isNextDisable: moment().isSame(nextDate, "month")
  //     },
  //     () => this.adminViewSlotMonthWise()
  //   );
  // };

  handleNextClick = () => {
    const dateFormat = Cookies.get("dateFormat");
    const { currentDate, Month, Year } = this.state;

    let monthAndDate =
      this.state.Month == 12 ? `1/${Year + 1}` : `${Month + 1}/${Year}`;
    let nextMonth = moment(monthAndDate, "MM/YYYY")
      .add(1, "months")
      .format("MM/YYYY");

    const firstDate = moment(monthAndDate, "MM/YYYY")
      .startOf("month")
      .format("DD/MM/YYYY");
    const lastDate = moment(monthAndDate, "MM/YYYY")
      .endOf("month")
      .format("DD/MM/YYYY");
    console.log(monthAndDate, lastDate, firstDate, "prevMonth");
    const prevDate = new Date(currentDate);
    prevDate.setMonth(prevDate.getMonth() + 1);
    this.setState(
      {
        Month: this.state.Month == 12 ? 1 : this.state.Month + 1,
        Year: this.state.Month == 12 ? Year + 1 : Year,
        lastDate: lastDate,
        currentDate: prevDate,
        firstDate: firstDate,
      },
      () => this.adminViewSlotMonthWise()
    );
  };
  handleLocationNameclick = (e, val, clickDate) => {
    this.setState({
      weekView: true,
      monthView: false,
      isDayView: false,
      loading: true,
      viewMode: "Week",
    });

    // const url1 = `${configuration.adminViewSlotMonthWise}?month=${moment().month()+1}&year=${this.state.Year}`;
    // const accessToken = localStorage.getItem("access_token");
    // axios
    //   .get(url1, {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res,"res")
    //   })

 
    // if(moment().month()+1 != this.state.Month){
    //   const currentMonth = moment().month()+1
    //   this.adminViewSlotMonthWise(currentMonth,true)
    // }
    
    var currentDate =
      clickDate ||
      moment(this.state.momentDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    const weeknumber = moment(currentDate, "YYYY-MM-DD").week();
    var startOfWeek = moment(currentDate).startOf("isoWeek");
    var endOfWeek = moment(currentDate).endOf("isoWeek");
    var startDate = startOfWeek.format("YYYY-MM-DD");
    var endDate = endOfWeek.format("YYYY-MM-DD");

    this.setState(
      {
        endDate: endDate,
        startDate: startDate,
        weeknumber: weeknumber,
        fromDate: currentDate,
        // fromDate: clickDate||this.state.daytoWeek,
      },
      () => {
        if (
          moment(this.state.firstDate, "DD/MM/YYYY").isBetween(
            startDate,
            endDate
          ) ||
          moment(this.state.firstDate, "DD/MM/YYYY").isSame(startDate)
        ) {
          this.setState({
            isPreviousDisable: true,
          });
        }
        console.log(
          moment(this.state.lastDate, "DD/MM/YYYY").isBetween(
            startDate,
            endDate
          ) || moment(this.state.lastDate, "DD/MM/YYYY").isSame(startDate),
          "this.state.lastDate"
        );

        if (
          moment(this.state.lastDate, "DD/MM/YYYY").isBetween(
            startDate,
            endDate
          ) ||
          moment(this.state.lastDate, "DD/MM/YYYY").isSame(startDate)
        ) {
          this.setState({
            isNextDisable: true,
          });
        } else {
          this.setState({
            isNextDisable: false,
          });
        }
      }
    );
    // var location_name=val.location_name ||val[0].location_name
    let location_name =
      val?.location_name ||
      Cookies.get('location_name') ||
      this.state.monthData[
        moment(this.state.currentDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      ][0]?.location_name;

    this.setState({
      location_name: location_name,
    });

    //     let driveinId =
    //       val?.drivein_id ||
    //       this.state.monthData[
    //         moment(this.state.currentDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    //       ][0].drivein_id;
    // this.setState({
    //   driveinId:driveinId
    // })
    let stopLoop = false;
    const dates = [];
    let driveinId = "";

    while (!stopLoop) {
      const date = moment(startOfWeek).format("YYYY-MM-DD");
      dates.push(date);

      console.log(this.state.monthData, "line480");
      if (this.state?.monthData[date]) {
        driveinId = this.state?.monthData[date][0]?.drivein_id;
        if (driveinId !== "") {
          stopLoop = true;
        }
      }

      if (startOfWeek.isSame(endOfWeek, "day")) {
        break;
      }

      startOfWeek.add(1, "day");
    }

    if (driveinId === "") {
      console.log("No data found for the week");
      driveinId = "";
      this.setState({ loading: false });
    }
    this.setState({
      driveinId: val?.drivein_id || driveinId,
    });
    console.log(
      startDate,
      endDate,
      driveinId,
      startOfWeek,
      endOfWeek,
      "line383"
    );
    let url = `${configuration.calendarSlotGet}drivein_id=${
      val?.drivein_id || Cookies.get('DrivinId')
    }&insurance_client_id=`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              allSlot: res.data.data.allSlot,
              loading: false,
            },
            () => {
              const { allSlot, dayOfWeek } = this.state;
              const currentDate = clickDate;
              let sortedTimeArray = [];
              let headerTotalSlot = {};

              const from_date = clickDate;
              // const startOfWeek1 = startOfWeek.format("YYYY-MM-DD");
              const to_date = endOfWeek.format("YYYY-MM-DD");
              const to_datedayOfWeek = endOfWeek.format("YYYY-MM-DD");
              let dateByDayArr = [];

              for (let i = 0; i < 7; i++) {
                const date = moment(startDate)
                  .add(i, "days")
                  .format("YYYY-MM-DD");
                dateByDayArr.push(date);
              }
              console.log(dateByDayArr, startDate, "405");

              const dayOfWeekName = moment(from_date, "YYYY-MM-DD").format(
                "dddd"
              );
              console.log(dateByDayArr, "9090");
              const index = this.state.daysOfWeek?.indexOf(dayOfWeekName);
              const slots = {};

              allSlot.forEach((slot) => {
                slot.details.forEach((detail) => {
                  const slotDate = moment(slot.date).format("YYYY-MM-DD");

                  if (
                    moment(slotDate, "YYYY-MM-DD").isSameOrAfter(
                      startDate,
                      "day"
                    ) &&
                    moment(slotDate, "YYYY-MM-DD").isSameOrBefore(
                      endDate,
                      "day"
                    )
                  ) {
                    // if (moment(slotDate).isSameOrBefore(startDate, endDate)) {
                    const time = detail.start_time;
                    if (!slots[time]) {
                      slots[time] = {};
                    }
                    if (!slots[time][slotDate]) {
                      slots[time][slotDate] = [];
                    }
                    slots[time][slotDate].push({
                      Date: slot.date,
                      Total_booked: slot.total_slot,
                      available: slot.available_slot,
                      sub_slots: detail,
                    });
                    headerTotalSlot[
                      moment(slot.date, "YYYY-MM-DD").format("dddd")
                    ] = {
                      total_slot: slot.total_slot,
                      available_slot: slot.available_slot,
                      Date: slot.date,
                    };
                    console.log(headerTotalSlot, "headerTotalSlot");
                  }
                });
              });

              sortedTimeArray = Object.keys(slots).sort((a, b) => {
                const timeA = new Date(`1970/01/01 ${a}`);
                const timeB = new Date(`1970/01/01 ${b}`);
                return timeA - timeB;
              });
              console.log(sortedTimeArray, dateByDayArr, "sortedTimeArray");
              // console.log(dateByDayArr,'123123')
              if (dateByDayArr.length > 0) {
                dateByDayArr.forEach((date) => {
                  sortedTimeArray.forEach((time) => {
                    if (!slots[time][date]) {
                      slots[time][date] = [];
                      console.log(
                        (slots[time][date] = []),
                        "slots[time][date] = [];"
                      );
                    }
                  });
                });
              }
              console.log(dateByDayArr, "dateByDayArr");
              var startOfWeeko = startDate;
              this.setState({
                headerTotalSlot,
                slots,
                sortedTimeArray,
                startOfWeek: startOfWeeko,
                isPreviousDisable: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
      });
  };

  // handleLocationNameclick = (e, val, clickDate) => {
  //   this.setState({
  //     weekView: true,
  //     monthView: false,
  //     loading: true,
  //     viewMode: "Week",
  //   });
  //   // console.log(val, "val");
  //   var currentDate =
  //     clickDate ||
  //     moment(this.state.currentDate, "DD/MM/YYYY").format("YYYY-MM-DD");
  //   console.log(currentDate, "currentDate");
  //   const weeknumber = moment(currentDate, "YYYY-MM-DD").week();
  //   var startOfWeek = moment(currentDate).startOf("isoWeek");
  //   var endOfWeek = moment(currentDate).endOf("isoWeek");
  //   var startDate = startOfWeek.format("YYYY-MM-DD");
  //   var endDate = endOfWeek.format("YYYY-MM-DD");
  //   this.setState(
  //     {
  //       endDate: endDate,
  //       startDate: startDate,
  //       weeknumber: weeknumber,
  //       fromDate: clickDate,
  //     },
  //     () => {

  //       if (
  //         moment(this.state.firstDate, "DD/MM/YYYY").isBetween(
  //           startDate,
  //           endDate
  //         ) ||
  //         moment(this.state.firstDate, "DD/MM/YYYY").isSame(startDate)
  //       ) {
  //         this.setState({
  //           isPreviousDisable: true,
  //         });
  //       }
  //       if (
  //         moment(this.state.lastDate, "DD/MM/YYYY").isBetween(
  //           startDate,
  //           endDate
  //         ) ||
  //         moment(this.state.lastDate, "DD/MM/YYYY").isSame(startDate)
  //       ) {
  //         this.setState({
  //           isNextDisable: true,
  //         });
  //       }
  //       console.log(this.state.lastDate, "lastDate");
  //     }
  //   );
  //   console.log(
  //     endDate,
  //     "startDate",
  //     startDate,
  //     moment(this.state.firstDate, "DD/MM/YYYY")
  //   );
  //   let driveinId =
  //     val?.drivein_id ||
  //     this.state.monthData[
  //       moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
  //     ][0].drivein_id;
  //   let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=`;
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       if (res.data.success) {
  //         this.setState(
  //           {
  //             allSlot: res.data.data.allSlot,
  //             loading: false,
  //           },
  //           () => {
  //             const { allSlot, dayOfWeek } = this.state;
  //             const currentDate = clickDate;
  //             let sortedTimeArray = [];
  //             let headerTotalSlot = {};
  //             const moment = require("moment");
  //             const from_date = clickDate;
  //             console.log(from_date, "from_date");
  //             const startOfWeek1 = startOfWeek.format("YYYY-MM-DD");
  //             console.log(startOfWeek1, "startOfWeek1");
  //             const to_date = endOfWeek.format("YYYY-MM-DD");
  //             console.log(to_date, "to_date");
  //             const to_datedayOfWeek = endOfWeek.format("YYYY-MM-DD");
  //             console.log(to_datedayOfWeek, "to_datedayOfWeek");
  //             for (let i = 0; i < 7; i++) {
  //               const date = moment(startOfWeek1)
  //                 .add(i, "days")
  //                 .format("YYYY-MM-DD");
  //             }
  //             const dayOfWeekName = moment(from_date, "YYYY-MM-DD").format(
  //               "dddd"
  //             );
  //             console.log(dayOfWeekName, "dayOfWeekName");
  //             const index = this.state.daysOfWeek?.indexOf(dayOfWeekName);
  //             const slots = {};
  //             // Check if the date is between the start and end dates
  //             const total_slot_empty = {};
  //             let dateByDayArr = []
  //             allSlot.forEach((slot) => {

  //               slot.details.forEach((detail) => {

  //                 const slotDate = moment(slot.date).format("YYYY-MM-DD")

  //                 console.log(slotDate,'slotDate')
  //                 if (
  //                   moment(slotDate).isBetween(startDate, endDate)
  //                 ) {
  //                   console.log(endDate,startDate,"line399")
  //                   const time = detail.start_time;
  //                   if (!slots[time]) {
  //                     slots[time] = {};
  //                   }
  //                   // Check if the date exists in the array
  //                   if (!slots[time][slotDate]) {
  //                     slots[time][slotDate] = [];
  //                   }
  //                   // Push the slot information into the array
  //                   slots[time][slotDate].push({
  //                     Date: slot.date,
  //                     Total_booked: slot.total_slot,
  //                     available: slot.available_slot,
  //                     sub_slots: detail,
  //                   });
  //                   headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
  //                     total_slot: slot.total_slot,
  //                     available_slot: slot.available_slot,
  //                     Date: slot.date,
  //                   };
  //                 }

  //               });
  //             });
  //             sortedTimeArray = Object.keys(slots).sort((a, b) => {
  //               const timeA = new Date(`1970/01/01 ${a}`);
  //               const timeB = new Date(`1970/01/01 ${b}`);
  //               return timeA - timeB;
  //             });
  //             // Create empty arrays if they don't have any values
  //             dateByDayArr.forEach((date) => {
  //               sortedTimeArray.forEach((time) => {
  //                 if (!slots[time][date]) {
  //                   slots[time][date] = [];
  //                   console.log(slots[time][date] ,'slots[time][date] ')
  //                 }
  //               });
  //             });
  //             // console.log(dateByDayArr,'datebyarr');
  //             this.setState({
  //               headerTotalSlot,
  //               slots,
  //               sortedTimeArray,
  //               startOfWeek,
  //               isPreviousDisable: false,
  //             });
  //           }
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ loading: false });
  //       toastAlert(error.response.data.message, "error");
  //     });
  // };

  getCountOnNext = () => {
    // this.state.isNextDisable==false?
    this.setState(
      {
        weeknumber: this.state.weeknumber + 1,
      },
      () => this.clickNext()
    );
  };

  getCountOnPrevious = () => {
    console.log(this.state.weeknumber - 1, "this.state.weeknumber");
    this.setState(
      {
        weeknumber: this.state.weeknumber - 1,
      },
      () => {
        // if (this.state.countOnClick == 0) {
        // this.clickPrevious();
        // } else {
        this.clickPreviousByDate();
        // }
      }
    );
  };

  clickNext = () => {
    console.log("Calledd");
    const { allSlot, daysOfWeek } = this.state;
    const currentDate = new Date();
    const { language } = this.context;
    const currentDayIndex = currentDate.getDay();
    let sortedTimeArray = [];
    let headerTotalSlot = {};
    // this.setState({
    //   weeknumber: this.state.weeknumber + 1,
    // });
    const moment = require("moment");
    const from_date = moment(this.state.startDate).format("DD/MM/YYYY"); // from_date from props
    const to_date = this.state.lastDate;

    const startDate = moment()
      .isoWeek(this.state.weeknumber)
      .startOf("isoWeek");
    // console.log(this.state.weeknumber, "this.state.weeknumber");
    const endDate = moment().isoWeek(this.state.weeknumber).endOf("isoWeek");
    const startOfWeek = moment(startDate, "YYYY-MM_DD").isoWeekday(1);
    const endOfWeek = moment(endDate, "YYYY-MM-DD").isoWeekday(7);
    // console.log(endDate, startDate, startOfWeek,endOfWeek, "start");
    this.setState({
      // weeknumber: this.state.weeknumber + 1,
      startDate: moment(startOfWeek).format("YYYY-MM-DD"),
      endDate: moment(endOfWeek).format("YYYY-MM-DD"),
    });

    if (startOfWeek.isAfter(endOfWeek)) {
      return;
    }

    const slots = {};

    const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    console.log(endOfCurrentWeek, "endOfCurrentWeek");
    const dateByDayArr = [];

    daysOfWeek.forEach((day, i) => {
      const dateByDay = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      dateByDayArr.push(dateByDay);
      console.log(dateByDay, "dateByDay", dateByDayArr);

      console.log(
        dateByDay ==
          moment(this.state.lastDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        dateByDay,
        moment(this.state.lastDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        "dateByDay"
      );
      if (
        dateByDay ==
        moment(this.state.lastDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      ) {
        this.setState({
          isNextDisable: true,
        });
      }

      console.log(dateByDayArr, startOfWeek, endOfCurrentWeek, "popopop");
    });

    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const slotDate = moment(slot.date).format("YYYY-MM-DD");

        if (
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]")
        ) {
          const time = detail.start_time;
          if (!slots[time]) {
            slots[time] = {};
          }

          // Check if the date exists in the array
          if (!slots[time][slotDate]) {
            slots[time][slotDate] = [];
          }

          // Push the slot information into the array
          slots[time][slotDate].push({
            Date: slot.date,
            Total_booked: slot.total_slot,
            available: slot.available_slot,
            sub_slots: detail,
          });
          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
      });
    });

    sortedTimeArray = Object.keys(slots).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA - timeB;
    });

    // Create empty arrays if they don't have any values
    dateByDayArr.forEach((date) => {
      sortedTimeArray.forEach((time) => {
        if (!slots[time][date]) {
          slots[time][date] = [];
        }
      });
    });
    console.log(
      headerTotalSlot,
      slots,
      sortedTimeArray,
      startOfWeek,
      "line607"
    );
    this.setState({
      headerTotalSlot,
      slots,
      sortedTimeArray,
      startOfWeek,
      startDate: startOfWeek,
      isPreviousDisable: false,
      weeknumber: this.state.weeknumber,
    });
  };

  handleEdit = () => {
    // if (!this.state.confirmEdit) {
    this.setState({
      confirmEdit: !this.state.confirmEdit,
    });
    // }
  };
  handleChange = (e, name) => {
    if (name == "mobileNumber") {
      this.setState({
        [name]: e,
      });
    } else {
      if (e && e.target) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
      }
    }
  };
  viewPdf = () => {
    const customer_id = this.props.router.location.state.customer_id;
    const driveIn = this.props.router.location.state.driveIn;
    const appointment_id = this.state.selectedAppointmentId;

    const url = `${configuration.viewPdf}appointment_id=${
      appointment_id || ""
    }&customer_id=${customer_id}&drivin_id=${driveIn}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          var windo = window.open("", "");
          var objbuilder = "";
          objbuilder +=
            "<embed width='100%' height='100%'  src=\"data:application/pdf;base64,";
          objbuilder += res.data.data;
          objbuilder += '" type="application/pdf" />';
          windo.document.write(objbuilder);
        } else {
          toastAlert("Data not Found", "error");
        }
      })
      .catch((error) => {
        // toastAlert('Failed to fetch PDF data', 'error');
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
      });
  };
  bookAppointment = () => {
    this.setState({ isButtonClicked: true });
    const uuid = this.props.router.location.state.uuid;
    const drive_through_id = this.state.driveinId;
    const customer_id = this.props.router.location.state.customer_id;
    const { is_email, is_sms, is_print_pdf, selectedAppointmentId } =
      this.state;
    const is_booking = this.state.is_booking;
    const pathname = this.props?.router?.location?.pathname;
    const helpDeskPage = pathname && pathname.startsWith("/helpdesk");

    if (is_booking == "0") {
      // const url = configuration.appointBook;
      const url = localStorage.getItem("access_token")
        ? configuration.helpdeskAppointBook
        : configuration.appointBook;

      const accessTokenHeader = localStorage.getItem("access_token")
        ? { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        : {};
      const payLoad = {
        uuid,
        appointment_id: selectedAppointmentId,
        drive_through_id,
        customer_id,
        is_email: is_email == true ? 1 : 0,
        is_sms: is_sms == true ? 1 : 0,
        is_print_pdf: is_print_pdf == true ? 1 : 0,
      };

      axios
        .post(url, payLoad, {
          headers: accessTokenHeader,
        })
        .then((response) => {
          if (response.data.success) {
            toastAlert(response.data.message, "success");
            Cookies.remove("uuid");
            Cookies.remove("clientTypeSetting");
            this.setState({
              isOpenConfirmAppointmentModal: false,
              isOpenAppointmentSuccessModal:
                !this.state.isOpenAppointmentSuccessModal,
            });

            this.getCalenderData();
          }
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
          }
          this.setState({ isButtonClicked: false });
          toastAlert(error.response.data.message, "error");
        });
    } else {
      const url = localStorage.getItem("access_token")
        ? configuration.helpDeskReschedule
        : configuration.reschedule;

      const accessTokenHeader = localStorage.getItem("access_token")
        ? { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        : {};

      axios
        .post(
          url,
          {
            appointment_id: selectedAppointmentId,
            drive_through_id,
            customer_id,
            is_email: is_email == true ? 1 : 0,
            is_sms: is_sms == true ? 1 : 0,
            is_print_pdf: is_print_pdf == true ? 1 : 0,
          },
          {
            headers: accessTokenHeader,
          }
        )
        .then((response) => {
          if (response.data.success) {
            toastAlert(response.data.message, "success");
            Cookies.remove("uuid");
            this.setState({
              isOpenConfirmAppointmentModal: false,
              isOpenAppointmentSuccessModal:
                !this.state.isOpenAppointmentSuccessModal,
            });

            this.getCalenderData();
          }
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
          }
          this.setState({ isButtonClicked: false });
          toastAlert(error.response.data.message, "error");
        });
    }
  };
  clickPrevious = () => {
    console.log("clickPrevious");
    const { allSlot, daysOfWeek } = this.state;
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    let sortedTimeArray = [];
    let headerTotalSlot = {};

    const moment = require("moment");

    const from_date = this.state.firstDate;
    const startOfWeek = moment(from_date, "YYYY-MM-DD").isoWeekday(1);

    for (let i = 0; i < 7; i++) {
      const date = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      console.log(date, "datedatedate");
    }

    const dayOfWeek = moment(from_date, "YYYY-MM-DD").format("dddd");

    const index = daysOfWeek.indexOf(dayOfWeek);

    const slots = {};

    const total_slot_empty = {};
    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const dayIndex = daysOfWeek.indexOf(
          moment(slot.date, "YYYY-MM-DD").format("dddd")
        );

        if (index <= dayIndex) {
          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
        this.setState({
          headerTotalSlot: headerTotalSlot,
        });
        const time = detail.start_time;
        const total_slot = detail.total_slot;

        if (!slots[`${time}`]) {
          slots[`${time}`] = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          };
        }

        if (!total_slot_empty[moment(slot.date, "YYYY-MM-DD").format("dddd")]) {
          total_slot_empty[moment(slot.date, "YYYY-MM-DD").format("dddd")] = [];
        }

        if (index <= dayIndex) {
          slots[`${time}`][moment(slot.date, "YYYY-MM-DD").format("dddd")].push(
            {
              Date: slot.date,
              Total_booked: slot.total_slot,
              available: slot.available_slot,
              sub_slots: detail,
            }
          );
        }
        this.setState({
          slots: slots,
        });

        sortedTimeArray = Object.keys(slots).sort((a, b) => {
          let timeA = new Date("1970/01/01 " + a);
          let timeB = new Date("1970/01/01 " + b);
          return timeA - timeB;
        });
        this.setState({
          sortedTimeArray: sortedTimeArray,
          startOfWeek,
          isPreviousDisable: true,
          isNextDisable: false,
        });
      });
    });
  };

  clickPreviousByDate = () => {
    console.log("clickPreviousByDate");
    const { allSlot, daysOfWeek } = this.state;
    const currentDate = new Date();
    const { language } = this.context;
    const currentDayIndex = currentDate.getDay();
    let sortedTimeArray = [];
    let headerTotalSlot = {};

    const moment = require("moment");
    const from_date = moment(this.state.firstDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    ); // from_date from props
    const to_date = moment(this.state.lastDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    console.log(from_date, to_date, "fffffff");

    const startDate = moment()
      .isoWeek(this.state.weeknumber)
      .startOf("isoWeek");
    const endDate = moment().isoWeek(this.state.weeknumber).endOf("isoWeek");
    const startOfWeek = moment(startDate, "YYYY-MM_DD").isoWeekday(1);
    const endOfWeek = moment(endDate, "YYYY-MM-DD").isoWeekday(7);
    console.log(endDate, startDate, startDate == startOfWeek, "start");

    if (startOfWeek.isAfter(endOfWeek)) {
      return;
    }

    const slots = {};

    const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    console.log(endOfCurrentWeek, "endOfCurrentWeek");
    const dateByDayArr = [];
    daysOfWeek.forEach((day, i) => {
      const dateByDay = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      console.log(dateByDay, "dateByDay");
      dateByDayArr.push(dateByDay);
      // console.log(dateByDay == moment(this.state.firstDate,'DD/MM/YYYY').format('YYYY-MM-DD'),dateByDay,moment(this.state.firstDate,'DD/MM/YYYY').format('YYYY-MM-DD'),"dateByDay == endOfWeek")
      if (
        dateByDay ==
        moment(this.state.firstDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      ) {
        this.setState({
          isPreviousDisable: true,
        });
      }
    });
    console.log(dateByDayArr, startOfWeek, endOfCurrentWeek, "popopop");

    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const slotDate = moment(slot.date).format("YYYY-MM-DD");
        console.log(
          slotDate,
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]"),
          "slotDate"
        );
        if (
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]")
        ) {
          const time = detail.start_time;
          if (!slots[time]) {
            slots[time] = {};
          }

          // Check if the date exists in the array
          if (!slots[time][slotDate]) {
            slots[time][slotDate] = [];
          }

          // Push the slot information into the array
          slots[time][slotDate].push({
            Date: slot.date,
            Total_booked: slot.total_slot,
            available: slot.available_slot,
            sub_slots: detail,
          });

          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
      });
    });

    sortedTimeArray = Object.keys(slots).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA - timeB;
    });

    // Create empty arrays if they don't have any values
    console.log(dateByDayArr, "1152");
    dateByDayArr.forEach((date) => {
      sortedTimeArray.forEach((time) => {
        if (!slots[time][date]) {
          slots[time][date] = [];
        }
      });
    });
    console.log(headerTotalSlot, slots, sortedTimeArray, startOfWeek, "ppp");
    this.setState({
      headerTotalSlot,
      slots,
      sortedTimeArray,
      startDate: startOfWeek,
      isNextDisable: false,
      weeknumber: this.state.weeknumber,
    });
  };
  OpenModal = (id) => {
    this.setState({ showModal: true, activeModalId: id });
  };

  openFinalModal = () => {
    this.setState({
      showFinalModal: true,
    });
  };

  checkBoxPdf = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  checkBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  handleToggle = (modalName, appointmentSlotTime, appointmentDay) => {
    this.setState({
      [modalName]: !this.state[modalName],
      appointmentSlotTime: appointmentSlotTime ?? "",
      appointmentDay: appointmentDay ?? "",
    });
    let bookingTag = document.querySelector(".booking-tag");

    document.body.classList.add("body-fixed");
    bookingTag.addEventListener("click", function () {});
  };
  closeModal = (modalName) => {
    document.body.classList.remove("body-fixed");
    this.setState(
      {
        [modalName]: false,
        appointmentSlotTime: null,
        appointmentDay: null,
        selectedAppointmentTime: "",
        selectedAppointmentId: null,
        confirmEdit: false,
      },
      () => {
        if (
          modalName === "isOpenAppointmentSuccessModal" &&
          !this.state.isOpenAppointmentListModal
        ) {
          window.close();
        }
      }
    );
  };
  handleConfirmDetailsModal = (
    selectedAppointmentTime,
    selectedAppointmentId,
    formattedFromDate
  ) => {
    const { isOpenConfirmDetailsModal } = this.state;
    this.setState({
      isOpenConfirmDetailsModal: !isOpenConfirmDetailsModal,
      isOpenAppointmentListModal: false,
      selectedAppointmentTime: selectedAppointmentTime,
      selectedAppointmentDate: formattedFromDate,
      selectedAppointmentId,
    });
    let uuid = this.props.router.location.state.uuid;

    const url = configuration.bookAppointment + uuid;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            userData: res.data.data,
            firstName: res.data.data.first_name,
            lastName: res.data.data.last_name,
            email: res.data.data.email_id,
            mobileNumber: res.data.data.contact_no,
            street: res.data.data.street,
            houseNumber: res.data.data.house_no,
            country: res.data.data.country,
            state: res.data.data.state,
            city: res.data.data.city,
            postalCode: res.data.data.pincode,
            km_stand: res.data.data.km_stand,
            country_code: res.data.data.country_code,
            insurance_client_id: res.data.data.insurance_client_id,
            salutation: res.data.data.salutation
              ? res.data.data.salutation
              : this.state.salutation,
            profession: res.data.data.profession
              ? res.data.data.profession
              : this.state.profession,
            is_booking: res.data.data.is_booking,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
        toastAlert(error.response.data.message, "error");
      });
  };
  handleConfirmSubmit = async () => {
    const isValid = await this.validateInputs();
    console.log(isValid);
    this.setState({ buttonClicked: true });

    console.log(this.state.userData.reg_no, "reg");
    if (isValid) {
      let uuid = this.props?.router?.location?.state?.uuid;
      const reg_no = this.state.userData.reg_no;

      const order_id = this.props?.router?.location?.state?.order_id;
      const claim_number = this.state.userData.claim_number;

      const url = configuration.updateData;
      const {
        salutation,
        profession,
        firstName,
        lastName,
        email,
        country_code,
        mobileNumber,
        street,
        houseNumber,
        country,
        state,
        city,
        postalCode,
        km_stand,
        is_booking,
      } = this.state;
      console.log(reg_no, "reg_no");
      const payLoad = {
        reg_no: reg_no,
        order_id: order_id,
        claim_number: claim_number,
        uuid: uuid,

        first_name: firstName,
        last_name: lastName,
        email_id: email,
        contact_no: mobileNumber,
        street: street,
        house_no: houseNumber,
        country: country,
        state: state,
        city: city || CITY,
        pincode: postalCode || POSTAL_CODE,
        km_stand: km_stand,
        profession: profession,
        salutation: salutation,
      };
      axios
        .post(url, payLoad) //  <- Payload
        .then((response) => {
          if (response.data.success) {
            const { isOpenConfirmAppointmentModal, isOpenConfirmDetailsModal } =
              this.state;
            this.setState({
              isOpenConfirmDetailsModal: false,
              isOpenConfirmAppointmentModal: !isOpenConfirmAppointmentModal,
            });
          } else {
            toastAlert(
              "Something went to wrong.Please try again later.",
              "error"
            );
          }
        })
        .catch((error) => {
          toastAlert(
            error?.response?.data?.message
              ? error.response.data.message
              : "Something went to wrong. Please try again later.",
            "error"
          );
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
          }
        });
    }
  };
  handleDayView = () => {
    this.setState(
      {
        monthView: false,
        weekView: false,
        isDayView: true,
      },
      () => this.onDayViewClick()
    );
  };

  onDayViewClick = () => {
    this.setState({
      loading: true,
      viewMode: "Day",
    });
    // const drivein_id = ;
    const date = moment(this.state.currentDate).format("YYYY-MM-DD");
    console.log(date, "clickDate");
    this.setState({
      dayviewDate: date,
      daytoWeek: this.state.fromDate,
    });
    console.log(
      moment(this.state.currentDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      "pp"
    );
    console.log(this.state.driveinId, "this.state.driveinId");
    let driveinId = this.state.driveinId;
    const url = `${configuration.getDaySlots}drivein_id=${
      driveinId || Cookies.get("DrivinId")
    }&date=${this.state.fromDate || date}`;

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "es.dat");
        if (res.data.success) {
          this.setState({ loading: false });
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    is_disable: "",
                    slots: [],
                  },
                },
              }));
            }
          });
          this.setState({ timeData });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
      });
  };

  handleWeekView = () => {
    this.setState({
      monthView: false,
      weekView: true,
      isDayView: false,
    });
  };
  handleRemoveDate = () => {
    this.setState({ loading: true });

    const newDate = moment(this.state.fromDate)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    // console.log(newdate, newDate, "his.state.fromDate");
    console.log(newDate, "newDatenewDate");
    // let drivein_id = this.state.monthData[newDate][0].drivein_id || this.state.driveinId;
    let drivein_id = this.state.driveinId;

    if (this.state.monthData[newDate] && this.state.monthData[newDate][0]) {
      drivein_id = this.state.monthData[newDate][0].drivein_id || drivein_id;
    }
    console.log(drivein_id, "drivein_iddrivein_id");
    this.setState({ fromDate: newDate });
    const url = `${configuration.getDaySlots}drivein_id=${
      drivein_id || Cookies.get("DrivinId")
    }&date=${newDate || ""}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ loading: false });
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    is_disable: "",
                    slots: [],
                  },
                },
              }));
            }
          });

          this.setState({ timeData });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
      });
  };
  handleAddDate = () => {
    this.setState({ loading: true });

    const newDate = moment(this.state.fromDate)
      .add(1, "day")
      .format("YYYY-MM-DD");
    // console.log(this.state.monthData[newDate][0].drivein_id,"handleAddDate");
    // console.log(newDate, "newDate");
    // const drivein_id =
    //   this.state.monthData[newDate][0].drivein_id;
    let drivein_id = this.state.driveinId;

    if (this.state.monthData[newDate] && this.state.monthData[newDate][0]) {
      drivein_id = this.state.monthData[newDate][0].drivein_id || drivein_id;
    }

    this.setState({ fromDate: newDate });
    const url = `${configuration.getDaySlots}drivein_id=${
      drivein_id || Cookies.get("DrivinId")
    }&date=${newDate || ""}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    slots: [],
                  },
                },
              }));
            }
          });

          this.setState({ timeData, loading: false }, () => {
            if (Object.keys(timeData).length === 0) {
              this.setState({ noSlotsAvailable: true });
            } else {
              this.setState({ noSlotsAvailable: false });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
      });
  };

  render() {
    console.log(this.state.startDate, "this.state.startDate");
    const { firstDate, lastDate, rows } = this.state;
    const { language, lang } = this.context;
    const {
      timeData,
      allSlot,
      days,
      headerTotalSlot,
      sortedTimeArray,
      slots,
      startOfWeek,
      firstName,
      lastName,
      email,
      mobileNumber,
      street,
      houseNumber,
      country,
      state,
      city,
      postalCode,
      km_stand,
      userData,
      confirmEdit,
      // modal states
      isOpenAppointmentListModal,
      appointmentDay,
      appointmentSlotTime,
      isOpenConfirmDetailsModal,
      selectedAppointmentTime,
      isOpenConfirmAppointmentModal,
      isOpenAppointmentSuccessModal,
      loading,
      buttonClicked,
      isNextDisable,
      is_email,
      is_sms,
      is_print_pdf,
      countOnClick,
      isPreviousDisable,
      cityError,
      stateError,
      countryError,
      houseNumberError,
      streetError,
      mobileNumberError,
      emailError,
      lastNameError,
      firstNameError,
      postalCodeError,
      mileageError,
      viewPdf,
      salutation,
      profession,
      fromDate,
      isButtonClicked,
      message,
      driveinId,
    } = this.state;

    const oldFrom_date = this?.props?.router?.location?.state?.from_date;
    const dateFormat = Cookies.get("dateFormat");
    const from_date = moment(oldFrom_date).format(dateFormat);
    const oldto_date = this.props?.router?.location?.state?.to_date;
    const to_date = moment(oldto_date).format(dateFormat);
    console.log(from_date, "from_date");
    console.log(to_date, "to_date");
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    const insurance_name = this.props?.router?.location?.state?.insurance_name;
    const claim_number = this.props?.router?.location?.state?.claim_number;
    const first_name = this.props?.router?.location?.state?.first_name;
    const last_name = this.props?.router?.location?.state?.last_name;
    const reg_no = this.props?.router?.location?.state?.reg_no;
    const country_code = this.props?.router?.location?.state?.country_code;
    console.log(country_code, "country_code");
    const locationName = this.props?.router?.location?.state?.locationName;
    const locationKm = this.props?.router?.location?.state?.locationKm;
    const { viewMode } = this.state;
    const isDayView = viewMode === "Day";
    const monthName = moment(lastDate, "DD/MM/YYYY").format("MMMM");
    return (
      <div>
        <main class="main-content calender-content calendar-content-main">
          {/* <!-- Admin-calendar::Start --> */}
          {this.state.loading && (
            <div class="loader-main">
              <Spinner color="light">
                <span></span>
              </Spinner>
            </div>
          )}
          <section class="calendar-wrapper">
            <div class="container-fluid">
              {/* <!-- Calendar-header-main::Start --> */}
              <div class="calendar-header">
                <div class="row align-items-center">
                  {/* <div class="col-lg-4 col-md-12 col-sm-12 calendar-25-col">
                    <div class="header-calendar-and-search">
                      <div class="calendar-filter-btn">
                        <button class="btn black-btn filter-btn" onclick={(e)=> console.log("first",e) (this.setState({isToggle:true}))}>
                        <button
                          class="btn black-btn filter-btn"
                          onClick={this.handleCheck}
                        >
                          <span>
                            <i class="fi fi-sliders"></i>
                          </span>
                          <span data-key="Filter">Filter</span>
                        </button>
                      </div>
                      <div class="calendar-search-btn">
                        <button class="search-btn btn icon-btn">
                          <i class="fa fa-search"></i>
                        </button>
                        <div class="calendar-search-input hide-element">
                          <input
                            class="form-control"
                            type="search"
                            placeholder="Search here.."
                            data-key="SearchHere"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-25-col"></div>
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-25-col">
                    <div class="calendar-date-and-back-btn">
                      <div class="calendar-date">
                        <div class="next-prev-calendar">
                          <button
                            class="calendar-prev cal-btn btn icon-btn"
                            onClick={
                              this.state.monthView == true
                                ? this.handlePrevClick
                                : this.getCountOnPrevious
                            }
                            disabled={
                              this.state.monthView !== true && isPreviousDisable
                            }
                          >
                            <i class="fa fa-long-arrow-left"></i>
                          </button>
                          <div class="calendar-date-text">
                            <h4 class="small-title-18">
                              {lang(monthName.toLowerCase())}-
                              {moment(lastDate, "DD/MM/YYYY").format("YYYY")}
                            </h4>
                          </div>
                          <button
                            class="calendar-next cal-btn icon-btn btn"
                            onClick={
                              this.state.monthView == true
                                ? this.handleNextClick
                                : this.getCountOnNext
                            }
                            disabled={
                              this.state.monthView !== true && isNextDisable
                            }
                            // onClick={this.handleNextClick}
                          >
                            <i class="fa fa-long-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-50-col">
                    <div class="calendar-ul-top">
                      <ul
                        class="nav nav-pills calendar-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            // class="nav-link active"
                            class={
                              this.state.viewMode == "Month"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="pills-month-tab"
                            data-toggle="pill"
                            data-target="#pills-month"
                            type="button"
                            role="tab"
                            aria-controls="pills-month"
                            aria-selected="true"
                            data-key="Month"
                            onClick={this.handleMonthclick}
                          >
                            {lang("month")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class={
                              this.state.viewMode == "Week"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="pills-week-tab"
                            data-toggle="pill"
                            data-target="#pills-week"
                            type="button"
                            role="tab"
                            aria-controls="pills-week"
                            aria-selected="false"
                            data-key="Week"
                            onClick={this.handleLocationNameclick}
                          >
                            {lang("week")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class={
                              this.state.viewMode == "Day"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="pills-day-tab"
                            data-toggle="pill"
                            data-target="#pills-day"
                            type="button"
                            role="tab"
                            aria-controls="pills-day"
                            aria-selected="false"
                            data-key="Day"
                            onClick={this.handleDayView}
                          >
                            {lang("day")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class={`${
                    !this.state.isToggle
                      ? "calendar-filter-drawer"
                      : "calendar-filter-drawer reveal-filter-drawer"
                  }`}
                >
                  <div class="filter-close-btn">
                    <div class="filter-title">
                      <span class="filter-img">
                        <img src="assets/images/filter.svg" alt="Filter" />
                      </span>
                      <span>
                        <h4 class="title-20" data-key="ApplyFilter">
                          Apply Filter
                        </h4>
                      </span>
                    </div>

                    <button
                      class="btn closeCalendarDrawer"
                      onClick={this.handleFilter}
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                  <div class="filter-dropdowns-main">
                    <div class="row">
                      <div class="col-12">
                        <div class="custom-select-to">
                          <label class="form-label" for="" data-key="Country">
                            Country{" "}
                          </label>
                          <select class="select-country" name="country">
                            <option disabled data-key="SelectCountry">
                              Select Country
                            </option>
                            <option value="AL">Switzerland</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="custom-select-to">
                          <label class="form-label" for="" data-key="State">
                            State{" "}
                          </label>
                          <select class="select-state" name="State">
                            <option disabled data-key="SelectState">
                              Select State
                            </option>
                            <option value="AL">Alabama</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <label class="form-label" for="" data-key="City">
                          City{" "}
                        </label>
                        <div class="custom-select-to">
                          <select class="select-city" name="City">
                            <option disabled data-key="SelectCity">
                              Select City
                            </option>
                            <option value="AL">Bern</option>
                            <option value="WY">Zürich</option>
                            <option value="WY">Thun</option>
                            <option value="WY">Studen</option>
                            <option value="WY">Basel</option>
                            <option value="WY">Grünen </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="filter-apply-btn">
                    <a href="admin-calendar.html" class="btn red-btn">
                      <span>
                        <i class="fi fi-check-mark-circle"></i>
                      </span>
                      <span data-key="Apply">Apply</span>
                    </a>
                  </div>
                </div>
                <div
                  class={`${
                    !this.state.isToggle
                      ? "filter-overlay d-none"
                      : "filter-overlay d-block"
                  }`}
                ></div>
                {/* <div class="filter-overlay"></div> */}
              </div>
              {/* <!-- Calendar-header-main::End --> */}

              {/* <!-- Calendar-view::Start --> */}
              <div class="calendar-view">
                <div class="tab-content" id="pills-tabContent">
                  {/* <!-- Month-view::Start --> */}
                  <div
                    // class="tab-pane fade month-calendar-top show active"
                    class={`${
                      this.state.monthView == true
                        ? "tab-pane fade month-calendar-top show active"
                        : "tab-pane fade month-calendar-top"
                    }`}
                    id="pills-month"
                    role="tabpanel"
                    aria-labelledby="pills-month-tab"
                  >
                    {/* <!-- Month-view-table::Start --> */}
                    <div class="calender-table-main month-view-table ">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              {this.state.daysOfWeek.map((day) => (
                                <th
                                  key={day}
                                  scope="col"
                                  class="active-table-th"
                                >
                                  <div class="day-name" data-key={day}>
                                    {lang(day?.toLowerCase())}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row, rowIndex) => (
                              <>
                                <tr class="empty-space-tr"></tr>
                                <tr key={rowIndex}>
                                  {row.map((day, index) => {
                                    return (
                                      <td key={index}>
                                        {day && (
                                          <div class="month-title">
                                            {" "}
                                            <div>
                                              <p lass="month-date small-text-14">
                                                {moment(day).format("DD")}
                                              </p>
                                            </div>
                                            <div class="month-date">
                                              <span
                                                class="month-booked-span small-text-16"
                                                data-key="Booked"
                                              >
                                                {lang("free")}
                                              </span>
                                              <span
                                                class="month-total-span small-text-black-16"
                                                data-key="Total"
                                              >
                                                {lang("total")}
                                              </span>
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          class="month-tags"
                                          id="weekTabOpen"
                                        >
                                          {this.state.monthData[day] &&
                                            this.state.monthData[day].map(
                                              (finalData, i) => {
                                                console.log(
                                                  finalData,
                                                  "finalData"
                                                );
                                                return (
                                                  <div className="month-tag-all-wrapper">
                                                    <div
                                                      class="month-city-and-booked-slots"
                                                      onClick={(e) =>
                                                        this.handleLocationNameclick(
                                                          e,
                                                          finalData,
                                                          day
                                                        )
                                                      }
                                                    >
                                                      <div class="month-city-name">
                                                        <p class="small-text-black-16">
                                                          {
                                                            finalData.location_name
                                                          }
                                                          <span></span>
                                                        </p>
                                                      </div>
                                                      <div class="month-slot-number">
                                                        <span class="all-booked-text small-text-16">
                                                          {finalData.total_slot -
                                                            finalData.booked_slot}
                                                        </span>
                                                        <span class="small-text-black-16">
                                                          |
                                                        </span>
                                                        <span class="small-text-black-16">
                                                          {finalData.total_slot}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <!-- Month-view-table::End --> */}
                  </div>
                  {/* <!-- Month-view::End --> */}

                  {/* <!-- Week-view::Start --> */}
                  {/* tab-pane fade week-calendar-top active show */}
                  <div
                    class={`${
                      this.state.weekView == true
                        ? "tab-pane fade  week-calendar-top active show"
                        : "tab-pane fade  week-calendar-top"
                    }`}
                    // class="tab-pane fade  week-calendar-top"
                    id="pills-week"
                    role="tabpanel"
                    aria-labelledby="pills-week-tab"
                  >
                    <AdminWeek
                      message={message}
                      countOnClick={countOnClick}
                      getCountOnPrevious={this.getCountOnPrevious}
                      getCountOnNext={this.getCountOnNext}
                      allSlot={allSlot}
                      isButtonClicked={isButtonClicked}
                      selectedAppointmentDate={
                        this.state.selectedAppointmentDate
                      }
                      salutation={salutation}
                      profession={profession}
                      // postalCodeError={postalCodeError}
                      mileageError={mileageError}
                      // cityError={cityError}
                      firstNameError={firstNameError}
                      lastNameError={lastNameError}
                      stateError={stateError}
                      emailError={emailError}
                      countryError={countryError}
                      houseNumberError={houseNumberError}
                      mobileNumberError={mobileNumberError}
                      streetError={streetError}
                      days={this.state.daysOfWeek}
                      country_code={country_code}
                      headerTotalSlot={headerTotalSlot}
                      sortedTimeArray={sortedTimeArray}
                      slots={slots}
                      startOfWeek={this.state.startDate}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      mobileNumber={mobileNumber}
                      street={street}
                      houseNumber={houseNumber}
                      country={country}
                      state={state}
                      city={city}
                      postalCode={postalCode}
                      km_stand={km_stand}
                      userData={userData}
                      confirmEdit={confirmEdit}
                      isOpenAppointmentListModal={isOpenAppointmentListModal}
                      appointmentDay={appointmentDay}
                      appointmentSlotTime={appointmentSlotTime}
                      isOpenConfirmDetailsModal={isOpenConfirmDetailsModal}
                      selectedAppointmentTime={selectedAppointmentTime}
                      isOpenConfirmAppointmentModal={
                        isOpenConfirmAppointmentModal
                      }
                      isOpenAppointmentSuccessModal={
                        isOpenAppointmentSuccessModal
                      }
                      loading={loading}
                      buttonClicked={buttonClicked}
                      isNextDisable={isNextDisable}
                      reg_no={reg_no}
                      last_name={last_name}
                      first_name={first_name}
                      claim_number={claim_number}
                      insurance_name={insurance_name}
                      currentDayIndex={currentDayIndex}
                      to_date={to_date}
                      from_date={from_date}
                      lang={lang}
                      is_email={is_email}
                      is_sms={is_sms}
                      is_print_pdf={is_print_pdf}
                      handleToggle={this.handleToggle}
                      closeModal={this.closeModal}
                      handleConfirmDetailsModal={this.handleConfirmDetailsModal}
                      handleConfirmSubmit={this.handleConfirmSubmit}
                      checkBox={this.checkBox}
                      clickPrevious={this.clickPrevious}
                      clickNext={this.clickNext}
                      handleEdit={this.handleEdit}
                      handleChange={(e, name) => this.handleChange(e, name)}
                      bookAppointment={this.bookAppointment}
                      PhoneInput={PhoneInput}
                      viewPdf={() => this.viewPdf()}
                      checkBoxPdf={this.checkBoxPdf}
                      weekstart={this.state.startDate}
                      weekend={this.state.endDate}
                      location_name={this.state.location_name}
                    />
                  </div>
                  {/* <!-- Week-view::End --> */}

                  {/* <!-- Day-view::Start --> */}
                  <div
                    class={`${
                      this.state.isDayView == true
                        ? "tab-pane fade  week-calendar-top active show"
                        : "tab-pane fade  week-calendar-top"
                    }`}
                    id="pills-day"
                    role="tabpanel"
                    aria-labelledby="pills-contact-day"
                  >
                    {/* <!-- Day-view-table::Start --> */}
                    <div class="calender-table-main day-view-table help-day-view-table">
                      <div class="table-responsive">
                        <AdminDay
                          oldFrom_date={this.state.currentDate}
                          onDayViewClick={this.onDayViewClick}
                          oldto_date={oldto_date}
                          from_date={moment(firstDate, "DD/MM/YYYY").format(
                            Cookies.get("dateFormat")
                          )}
                          to_date={moment(lastDate, "DD/MM/YYYY").format(
                            Cookies.get("dateFormat")
                          )}
                          driveinId={driveinId}
                          timeData={timeData}
                          handleConfirmDetailsModal={
                            this.handleConfirmDetailsModal
                          }
                          handleAddDate={this.handleAddDate}
                          handleRemoveDate={this.handleRemoveDate}
                          fromDate={fromDate}
                          loading={loading}
                          locationName={locationName}
                          locationKm={locationKm}
                          first_name={first_name}
                          last_name={last_name}
                          salutation={salutation}
                          location_name={this.state.location_name}
                        />
                      </div>
                    </div>
                    {/* <!-- Day-view-table::End --> */}
                  </div>
                  {/* <!-- Day-view::End --> */}
                </div>
              </div>
              {/* <!-- Calendar-view::End --> */}
            </div>
          </section>

          {/* <!-- Admin-calendar::End --> */}
        </main>
      </div>
    );
  }
}
export default HelpDeskAdminView;
