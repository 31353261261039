import React, { Component } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/LanguageProvider";
import Cookies from "js-cookie";

export class HelpdeskOptions extends Component {
  static contextType = LanguageContext;
  render() {
    const val  = Cookies.get('roleId');
    console.log(val,val == 1 || val == 2,"1234567890")
    const { lang } = this.context;
    return (
      <main class="main-content">
        <section class="options-wrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8 options-row">
                <div class="options-card-main">
                  <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-6 col-12  d-flex">
                      <div class="card option-card  d-flex">
                        <div class="card-body">
                          <div class="option-img">
                            <img
                              src="../assets/images/helpdesk.svg"
                              alt="Reschedule Booking"
                            />
                          </div>
                          <div class="option-title">
                            <h4 class="title-20">
                              {lang("reschedule_booking")}
                            </h4>
                          </div>
                          <Link to="/helpdesk/reschedule">
                            <div class="option-text-btn">
                              <a class="btn red-btn role-btn">
                                <span class="small-text-14">
                                  {lang("next")}{" "}
                                </span>
                                <i class="fa fa-long-arrow-right"></i>
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 col-12  d-flex">
                      <div class="card option-card d-flex">
                        <div class="card-body">
                          <div class="option-img">
                            <img
                              src="../assets/images/create-new-booking.svg"
                              alt="Create new booking"
                            />
                          </div>
                          <div class="option-title">
                            <h4 class="title-20">
                              {lang("create_new_booking")}
                            </h4>
                          </div>
                          <Link to="/helpdesk/create/appointment">
                            <div class="option-text-btn">
                              <a class="btn red-btn role-btn">
                                <span class="small-text-14">
                                  {lang("next")}{" "}
                                </span>
                                <i class="fa fa-long-arrow-right"></i>
                              </a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-6 col-12  d-flex">
                      <div class="card option-card d-flex">
                        <div class="card-body">
                          <div class="option-img">
                            <img
                              src="../assets/images/userlist-icon.svg"
                              alt="Create new booking"
                            />
                          </div>
                          <div class="option-title">
                            <h4 class="title-20">{lang("user-list")}</h4>
                          </div>
                          <Link to="/helpdesk/userlist">
                            <a class="btn red-btn role-btn">
                              <span class="small-text-14">{lang("next")} </span>
                              <i class="fa fa-long-arrow-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {val == 1 || val == 2 ? <div class="col-lg-5 col-md-6 col-sm-6 col-12  d-flex">
                      <div class="card option-card d-flex">
                        <div class="card-body">
                          <div class="option-img">
                            <img
                              src="../assets/images/admin.svg"
                              alt="Create new booking"
                            />
                          </div>
                          <div class="option-title">
                            <h4 class="title-20">{lang("admin_view")}</h4>
                          </div>
                          <Link to="/helpdesk/admin-view">
                            {/* <Link to="https://planner2.drivenscan.app/new-theme/admin-calendar.html" target='_blank'> */}
                            <a class="btn red-btn role-btn">
                              <span class="small-text-14">{lang("next")} </span>
                              <i class="fa fa-long-arrow-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>:<div></div>}
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default HelpdeskOptions;
