import React, { Component } from "react";
import "../helpDeskCalendar/HelpDeskCalendar.css";
import axios from "axios";
import { configuration, url } from "../../appConfig";
import LanguageContext from "../../context/LanguageProvider";
import CalendarComponent from "../../components/calendar/CalendarComponent";
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import withRouter from "../../withRouter";
import CalendarDayView from "../../components/calendarDayView/CalendarDayView";
import Cookies from "js-cookie";
import { CookiesRemove } from "../../CookiesRemove";
import Swal from "sweetalert2";

const POSTAL_CODE = 2000;
const CITY = "Maribor";
export class HelpDeskCalendar extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      allSlot: [],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      detail: [],
      timeSlot: [],
      slots: {},
      headerTotalSlot: {},
      sortedTimeArray: [],
      startOfWeek: "",
      is_booking: "",
      is_email: "",
      is_sms: "",
      is_print_pdf: "",
      // is_backoffice_mail: true,
      driveinId: "",
      confirmEdit: false,
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      mobileNumber: "",
      mobileNumberError: "",
      street: "",
      streetError: "",
      houseNumber: "",
      houseNumberError: "",
      country: "",
      countryError: "",
      state: "",
      stateError: "",
      city: CITY,
      cityError: "",
      postalCode: POSTAL_CODE,
      postalCodeError: "",
      km_stand: "",
      mileageError: "",
      showModal: false,
      showAppointmentList: false,
      showFinalModal: false,
      // modal states
      isOpenAppointmentListModal: false,
      appointmentSlotTime: null,
      appointmentDay: null,
      isOpenConfirmDetailsModal: false,
      selectedAppointmentTime: "",
      isOpenConfirmAppointmentModal: false,
      selectedAppointmentId: null,
      isOpenAppointmentSuccessModal: false,
      loading: false,
      buttonClicked: false,
      isNextDisable: false,
      country_code: "",
      profession: "not specified",
      salutation: "Mr.",
      slots: "",
      timeData: [],
      fromDate: this?.props?.router?.location?.state?.from_date,
      toDate: this?.props?.router?.location?.state?.to_date,
      viewMode: "Week",
      selectedAppointmentDate: false,
      isButtonClicked: false,
      message: "",
      countOnClick: 0,
    };
  }

  componentDidMount = async () => {
    await this.getCalenderData();
    const is_send_email = Cookies.get("is_send_email");
    const is_send_sms = Cookies.get("is_send_sms");
    const is_booking_pdf_view = Cookies.get("is_booking_pdf_view");
    this.setState({
      is_email: is_send_email === "1" ? true : false,
      is_sms: is_send_sms === "1" ? true : false,
      is_print_pdf: is_booking_pdf_view === "1" ? true : false,
    });

    const organizationCountry = Cookies.get("organizationCountry");
    if (organizationCountry === "Slovenia") {
      const message = "e.g_audatex,_eurotax,_dat_etc...";
      this.setState({
        message: message,
      });
    }
  };

  // getCalenderData = () => {
  //     this.setState({ loading: true });
  //     const clientId = this.props?.router?.location?.state?.insurance_client_id || '';
  //     const driveinId = this.props?.router?.location?.state?.driveIn || '';

  //     // const { driveIn, insurance_client_id } = this.props?.location?.state;
  //     let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`
  //     axios.get(url).then(
  //         res => {

  //             if (res.data.success) {
  //                 this.setState({
  //                     allSlot: res.data.data.allSlot,
  //                     driveinId: driveinId,
  //                     loading: false

  //                 }, () => {
  //                     const { allSlot, days } = this.state
  //                     const currentDate = new Date();
  //                     const currentDayIndex = currentDate.getDay();
  //                     let sortedTimeArray = [];
  //                     let headerTotalSlot = {};

  //                     const moment = require('moment');

  //                     // const from_date = '23-03-2023';
  //                     const from_date = this.props.router.location.state.from_date;  // from_date from props
  //                     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
  //                     const to_date = this.props.router.location.state.to_date;
  //                     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
  //                     for (let i = 0; i < 7; i++) {
  //                         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
  //                         if (date == to_date) {
  //                             this.setState({
  //                                 isNextDisable: true
  //                             })
  //                         }

  //                     }

  //                     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');
  //                     const index = days.indexOf(dayOfWeek);

  //                     const slots = {};
  //                     // const from_date = '01/04/2023'
  //                     const total_slot_empty = {}
  //                     allSlot.forEach(slot => {
  //                         slot.details.forEach(detail => {
  //                             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));

  //                             if (index <= dayIndex) {
  //                                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
  //                                     "total_slot": slot.total_slot,
  //                                     "available_slot": slot.available_slot,
  //                                     "Date": slot.date
  //                                 }
  //                             }

  //                             this.setState({
  //                                 headerTotalSlot: headerTotalSlot
  //                             })
  //                             const time = detail.start_time;
  //                             const total_slot = detail.total_slot

  //                             if (!slots[`${time}`]) {
  //                                 slots[`${time}`] = {
  //                                     Monday: [],
  //                                     Tuesday: [],
  //                                     Wednesday: [],
  //                                     Thursday: [],
  //                                     Friday: [],
  //                                     Saturday: [],
  //                                     Sunday: [],
  //                                 };
  //                             }

  //                             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
  //                                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
  //                             }

  //                             if (index <= dayIndex) {
  //                                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
  //                                     Date: slot.date,
  //                                     Total_booked: slot.total_slot,
  //                                     available: slot.available_slot,
  //                                     sub_slots: detail
  //                                 });
  //                             }
  //                             this.setState({
  //                                 slots: slots
  //                             })

  //                             sortedTimeArray = Object.keys(slots).sort((a, b) => {
  //                                 let timeA = new Date("1970/01/01 " + a);
  //                                 let timeB = new Date("1970/01/01 " + b);
  //                                 return timeA - timeB;
  //                             });
  //                             this.setState({
  //                                 sortedTimeArray: sortedTimeArray,
  //                                 startOfWeek: startOfWeek
  //                             })
  //                         });
  //                     });
  //                 });
  //             }
  //         }
  //     ).catch(err => {
  //         if (err.response.data.code === 401) {
  //             CookiesRemove();
  //             toastAlert(err.response.data.message, 'error');
  //             this.props.router.navigate('/login');
  //             // Cookies.remove('id');
  //             // Cookies.remove('organization_country');
  //             // Cookies.remove('globalUserId');
  //             // Cookies.remove('isAuthenticated');
  //             // Cookies.remove('globalOrganizationId');
  //             // Cookies.remove('name');
  //             // Cookies.remove('organizationName');
  //             // Cookies.remove('organizationCountry');
  //             // Cookies.remove('regionName');
  //             // Cookies.remove('roleId');
  //             // Cookies.remove('isLoggedIn');
  //             // Cookies.remove('authToken');
  //             // Cookies.remove('uuid');
  //             // Cookies.remove('userData');
  //             // Cookies.remove('organizationCountry');
  //             // Cookies.remove('role_name');
  //             // Cookies.remove('clientTypeSetting');
  //             // localStorage.removeItem('access_token');
  //             // var element = document.getElementsByTagName("html")[0];
  //             // element.classList.remove("otherclass");
  //         }
  //         toastAlert(err.response.data.message, 'error');
  //     });
  // }

  // clickNext = () => {
  //     const { allSlot, days } = this.state
  //     const currentDate = new Date();
  //     const currentDayIndex = currentDate.getDay();
  //     let sortedTimeArray = [];
  //     let headerTotalSlot = {};
  //     const moment = require('moment');
  //     const from_date = this.props.router.location.state.from_date;  // from_date from props
  //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(1, 'weeks');
  //     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');
  //     const to_date = this.props.router.location.state.to_date;
  //     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
  //     const index = days.indexOf(dayOfWeek);
  //     const to_date_index = days.indexOf(to_datedayOfWeek);
  //     const slots = {};
  //     // const from_date = '01/04/2023'
  //     const total_slot_empty = {}

  //     for (let i = 0; i < 7; i++) {
  //         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
  //     }

  //     allSlot.forEach(slot => {
  //         slot.details.forEach(detail => {
  //             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));
  //             if (to_date_index >= dayIndex) {
  //                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
  //                     "total_slot": slot.total_slot,
  //                     "available_slot": slot.available_slot,
  //                     "Date": slot.date
  //                 }
  //             }
  //             this.setState({
  //                 headerTotalSlot: headerTotalSlot
  //             })
  //             const time = detail.start_time;
  //             const total_slot = detail.total_slot
  //             if (!slots[`${time}`]) {
  //                 slots[`${time}`] = {
  //                     Monday: [],
  //                     Tuesday: [],
  //                     Wednesday: [],
  //                     Thursday: [],
  //                     Friday: [],
  //                     Saturday: [],
  //                     Sunday: [],
  //                 };
  //             }

  //             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
  //                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
  //             }

  //             if (to_date_index >= dayIndex) {
  //                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
  //                     Date: slot.date,
  //                     Total_booked: slot.total_slot,
  //                     available: slot.available_slot,
  //                     sub_slots: detail
  //                 });
  //             }
  //             this.setState({
  //                 slots: slots
  //             })

  //             sortedTimeArray = Object.keys(slots).sort((a, b) => {
  //                 let timeA = new Date("1970/01/01 " + a);
  //                 let timeB = new Date("1970/01/01 " + b);
  //                 return timeA - timeB;
  //             });
  //             this.setState({
  //                 sortedTimeArray: sortedTimeArray,
  //                 startOfWeek: startOfWeek
  //             })
  //         });
  //     });

  // }

  // clickPrevious = () => {
  //     const { allSlot, days } = this.state
  //     const currentDate = new Date();
  //     const currentDayIndex = currentDate.getDay();
  //     let sortedTimeArray = [];
  //     let headerTotalSlot = {};

  //     const moment = require('moment');

  //     // const from_date = this.props.router.location.state.from_date;  // from_date from props
  //     const from_date = this.props.router.location.state.from_date;  // from_date from props
  //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
  //     // const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).subtract(1, 'weeks');

  //     for (let i = 0; i < 7; i++) {
  //         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');

  //     }
  //     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');

  //     const index = days.indexOf(dayOfWeek);

  //     const slots = {};

  //     const total_slot_empty = {}
  //     allSlot.forEach(slot => {

  //         slot.details.forEach(detail => {
  //             ;
  //             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));

  //             if (index <= dayIndex) {
  //                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
  //                     "total_slot": slot.total_slot,
  //                     "available_slot": slot.available_slot,
  //                     "Date": slot.date
  //                 }
  //             }
  //             this.setState({
  //                 headerTotalSlot: headerTotalSlot
  //             })
  //             const time = detail.start_time;
  //             const total_slot = detail.total_slot

  //             if (!slots[`${time}`]) {
  //                 slots[`${time}`] = {
  //                     Monday: [],
  //                     Tuesday: [],
  //                     Wednesday: [],
  //                     Thursday: [],
  //                     Friday: [],
  //                     Saturday: [],
  //                     Sunday: [],
  //                 };
  //             }

  //             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
  //                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
  //             }

  //             if (index <= dayIndex) {
  //                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
  //                     Date: slot.date,
  //                     Total_booked: slot.total_slot,
  //                     available: slot.available_slot,
  //                     sub_slots: detail
  //                 });
  //             }
  //             this.setState({
  //                 slots: slots
  //             })

  //             sortedTimeArray = Object.keys(slots).sort((a, b) => {
  //                 let timeA = new Date("1970/01/01 " + a);
  //                 let timeB = new Date("1970/01/01 " + b);
  //                 return timeA - timeB;
  //             });
  //             this.setState({
  //                 sortedTimeArray: sortedTimeArray,
  //                 startOfWeek
  //             })
  //         });
  //     });

  // }

  // before smit device change
  // getCalenderData = () => {
  //     this.setState({ loading: true });
  //     const driveinId = this.props?.router?.location?.state?.driveIn || '';
  //     const clientId = this.props?.router?.location?.state?.insurance_client_id || '';
  //     // const { driveIn, insurance_client_id } = this.props?.location?.state;
  //     let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`
  //     axios.get(url).then(
  //         res => {
  //             if (res.data.success) {
  //                 this.setState({
  //                     allSlot: res.data.data.allSlot,
  //                     driveinId: driveinId,
  //                     loading: false

  //                 }, () => {
  //                     const { allSlot, days } = this.state
  //                     const currentDate = new Date();
  //                     const currentDayIndex = currentDate.getDay();
  //                     let sortedTimeArray = [];
  //                     let headerTotalSlot = {};

  //                     const moment = require('moment');

  //                     // const from_date = '23-03-2023';
  //                     const from_date = this.props.router.location.state.from_date;  // from_date from props
  //                     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
  //                     const to_date = this.props.router.location.state.to_date;
  //                     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
  //                     for (let i = 0; i < 7; i++) {
  //                         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
  //                         console.log(date,to_date,'date');
  //                         if (date == to_date) {
  //                             this.setState({
  //                                 isNextDisable: true
  //                             })
  //                         }
  //                     }
  //                     if(this.state.countOnClick == 0){
  //                         this.setState({
  //                             isPreviousDisable : true
  //                         })
  //                     }
  //                     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');

  //                     const index = days.indexOf(dayOfWeek);

  //                     const slots = {};
  //                     // const from_date = '01/04/2023'
  //                     const total_slot_empty = {}
  //                     allSlot.forEach(slot => {

  //                         slot.details.forEach(detail => {

  //                             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));

  //                             if (index <= dayIndex) {
  //                                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
  //                                     "total_slot": slot.total_slot,
  //                                     "available_slot": slot.available_slot,
  //                                     "Date": slot.date
  //                                 }
  //                             }

  //                             this.setState({
  //                                 headerTotalSlot: headerTotalSlot
  //                             });

  //                             const time = detail.start_time;
  //                             const total_slot = detail.total_slot

  //                             if (!slots[`${time}`]) {
  //                                 slots[`${time}`] = {
  //                                     Monday: [],
  //                                     Tuesday: [],
  //                                     Wednesday: [],
  //                                     Thursday: [],
  //                                     Friday: [],
  //                                     Saturday: [],
  //                                     Sunday: [],
  //                                 };
  //                             }

  //                             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
  //                                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
  //                             }

  //                             if (index <= dayIndex) {
  //                                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
  //                                     Date: slot.date,
  //                                     Total_booked: slot.total_slot,
  //                                     available: slot.available_slot,
  //                                     sub_slots: detail
  //                                 });
  //                             }

  //                             this.setState({
  //                                 slots: slots
  //                             });
  //                             sortedTimeArray = Object.keys(slots).sort((a, b) => {
  //                                 let timeA = new Date("1970/01/01 " + a);
  //                                 let timeB = new Date("1970/01/01 " + b);
  //                                 return timeA - timeB;
  //                             });

  //                             this.setState({
  //                                 sortedTimeArray: sortedTimeArray,
  //                                 startOfWeek: startOfWeek
  //                             })
  //                         });
  //                     });
  //                 });
  //             }
  //         }
  //     ).catch(error => {
  //         this.setState({ loading: false });
  //         toastAlert(error.response.data.message, 'error');
  //     });
  // }

  getCalenderData = () => {
    this.setState({ loading: true });
    const driveinId = this.props?.router?.location?.state?.driveIn || "";
    const clientId =
      this.props?.router?.location?.state?.insurance_client_id || "";
    // const { driveIn, insurance_client_id } = this.props?.location?.state;
    let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              allSlot: res.data.data.allSlot,
              driveinId: driveinId,
              loading: false,
            },
            () => {
              const { allSlot, days } = this.state;
              const currentDate = new Date();
              // console.log(currentDate,"currentDate")
              const currentDayIndex = currentDate.getDay();
              let sortedTimeArray = [];
              let headerTotalSlot = {};

              const moment = require("moment");

              // const from_date = '23-03-2023';
              const from_date = this.props.router.location.state.from_date; // from_date from props
              // console.log(from_date,"from_date")
              const startOfWeek = moment(from_date, "YYYY-MM-DD").isoWeekday(1);
              // console.log(startOfWeek,"startOfWeek")
              const to_date = this.props.router.location.state.to_date;
              // console.log(to_date,"to_date")
              const to_datedayOfWeek = moment(to_date, "YYYY-MM-DD").format(
                "dddd"
              );
              // console.log(to_datedayOfWeek,"to_datedayOfWeek")
              for (let i = 0; i < 7; i++) {
                const date = moment(startOfWeek)
                  .add(i, "days")
                  .format("YYYY-MM-DD");
                console.log(date, to_date, "date");
                if (date == to_date) {
                  this.setState({
                    isNextDisable: true,
                  });
                }
              }
              if (this.state.countOnClick == 0) {
                this.setState({
                  isPreviousDisable: true,
                });
              }
              const dayOfWeek = moment(from_date, "YYYY-MM-DD").format("dddd");

              const index = days.indexOf(dayOfWeek);
              console.log(index, "index", dayOfWeek, "dayOfWeek");
              const slots = {};
              // const from_date = '01/04/2023'

              // Define the start and end dates
              var startDate = moment(from_date).startOf("week");
              var endDate = moment(from_date).endOf("week");
              // console.log(startDate,"startDate")
              // console.log(endDate,"endDate")

              // Check if the date is between the start and end dates

              const total_slot_empty = {};
              allSlot.forEach((slot) => {
                slot.details.forEach((detail) => {
                  const dayIndex = days.indexOf(
                    moment(slot.date, "YYYY-MM-DD").format("dddd")
                  );
                  var isBetween = moment(slot.date).isBetween(
                    startDate,
                    endDate
                  );
                  // console.log(index <= dayIndex && isBetween,"index <= dayIndex && isBetween")
                  if (index <= dayIndex && isBetween) {
                    headerTotalSlot[
                      moment(slot.date, "YYYY-MM-DD").format("dddd")
                    ] = {
                      total_slot: slot.total_slot,
                      available_slot: slot.available_slot,
                      Date: slot.date,
                    };
                  }
                  // console.log(headerTotalSlot,"headerTotalSlot")

                  this.setState({
                    headerTotalSlot: headerTotalSlot,
                  });

                  const time = detail.start_time;
                  const total_slot = detail.total_slot;

                  if (!slots[`${time}`]) {
                    slots[`${time}`] = {
                      Monday: [],
                      Tuesday: [],
                      Wednesday: [],
                      Thursday: [],
                      Friday: [],
                      Saturday: [],
                      Sunday: [],
                    };
                  }

                  if (
                    !total_slot_empty[
                      moment(slot.date, "YYYY-MM-DD").format("dddd")
                    ]
                  ) {
                    total_slot_empty[
                      moment(slot.date, "YYYY-MM-DD").format("dddd")
                    ] = [];
                  }

                  if (index <= dayIndex) {
                    slots[`${time}`][
                      moment(slot.date, "YYYY-MM-DD").format("dddd")
                    ].push({
                      Date: slot.date,
                      Total_booked: slot.total_slot,
                      available: slot.available_slot,
                      sub_slots: detail,
                    });
                    // console.log(slots, slot.date, "line505")
                  }

                  this.setState({
                    slots: slots,
                  });
                  sortedTimeArray = Object.keys(slots).sort((a, b) => {
                    let timeA = new Date("1970/01/01 " + a);
                    let timeB = new Date("1970/01/01 " + b);
                    return timeA - timeB;
                  });

                  this.setState({
                    sortedTimeArray: sortedTimeArray,
                    startOfWeek: startOfWeek,
                  });
                });
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
      });
  };

  getCountOnNext = () => {
    console.log("getCountOnNext");
    this.setState(
      {
        countOnClick: this.state.countOnClick + 1,
      },
      () => this.clickNext()
    );
  };

  getCountOnPrevious = () => {
    this.setState(
      {
        countOnClick: this.state.countOnClick - 1,
      },
      () => {
        if (this.state.countOnClick == 0) {
          this.clickPrevious();
        } else {
          this.clickPreviousByDate();
        }
      }
    );
  };

  clickNext = () => {
    console.log("Calledd");
    const { allSlot, days } = this.state;
    const currentDate = new Date();
    const { language } = this.context;
    const currentDayIndex = currentDate.getDay();
    let sortedTimeArray = [];
    let headerTotalSlot = {};

    const moment = require("moment");
    const from_date = this.props.router.location.state.from_date; // from_date from props
    const to_date = this.props.router.location.state.to_date;

    const startOfWeek = moment(from_date, "YYYY-MM-DD")
      .isoWeekday(1)
      .add(this.state.countOnClick, "weeks");
    const endOfWeek = moment(to_date, "YYYY-MM-DD").isoWeekday(7);
    console.log(
      from_date,
      to_date,
      startOfWeek,
      endOfWeek,
      "endOfWeekendOfWeek"
    );
    if (startOfWeek.isAfter(endOfWeek)) {
      return;
    }

    const slots = {};

    const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    const dateByDayArr = [];

    days.forEach((day, i) => {
      const dateByDay = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      dateByDayArr.push(dateByDay);
    });
    for (let i = 0; i < 7; i++) {
      const date = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      console.log(date, to_date, "date");
      if (date == to_date) {
        this.setState({
          isNextDisable: true,
        });
      }
    }

    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const slotDate = moment(slot.date).format("YYYY-MM-DD");
        console.log(
          slotDate,
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]")
        );
        if (
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]")
        ) {
          const time = detail.start_time;
          if (!slots[time]) {
            slots[time] = {};
          }

          // Check if the date exists in the array
          if (!slots[time][slotDate]) {
            slots[time][slotDate] = [];
          }

          // Push the slot information into the array
          slots[time][slotDate].push({
            Date: slot.date,
            Total_booked: slot.total_slot,
            available: slot.available_slot,
            sub_slots: detail,
          });

          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
      });
    });

    sortedTimeArray = Object.keys(slots).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA - timeB;
    });

    // Create empty arrays if they don't have any values
    dateByDayArr.forEach((date) => {
      sortedTimeArray.forEach((time) => {
        if (!slots[time][date]) {
          slots[time][date] = [];
          console.log((slots[time][date] = []), "slots[time][date] = [];");
        }
        console.log(sortedTimeArray, "sortedTimeArray");
      });
    });
    console.log(dateByDayArr, "dateByDayArr");
    this.setState({
      headerTotalSlot,
      slots,
      sortedTimeArray,
      startOfWeek,
      isPreviousDisable: false,
    });
  };

  clickPrevious = () => {
    console.log("clickPrevious");
    const { allSlot, days } = this.state;
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    console.log(currentDayIndex, currentDate, "currentDayIndex");
    let sortedTimeArray = [];
    let headerTotalSlot = {};

    const moment = require("moment");

    // const from_date = this.props.router.location.state.from_date;  // from_date from props
    const from_date = this.props.router.location.state.from_date; // from_date from props

    const startOfWeek = moment(from_date, "YYYY-MM-DD").isoWeekday(1);
    console.log(startOfWeek, from_date, "startOfWeek");
    // const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).subtract(1, 'weeks');

    for (let i = 0; i < 7; i++) {
      const date = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      console.log(date, "datttt");
    }

    const dayOfWeek = moment(from_date, "YYYY-MM-DD").format("dddd");

    const index = days.indexOf(dayOfWeek);

    const slots = {};

    const total_slot_empty = {};
    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const dayIndex = days.indexOf(
          moment(slot.date, "YYYY-MM-DD").format("dddd")
        );

        var startDate = moment(from_date).startOf("week");
        var endDate = moment(from_date).endOf("week");

        var isBetween = moment(slot.date).isBetween(startDate, endDate);

        if (index <= dayIndex && isBetween) {
          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
        this.setState({
          headerTotalSlot: headerTotalSlot,
        });
        const time = detail.start_time;
        const total_slot = detail.total_slot;

        if (!slots[`${time}`]) {
          slots[`${time}`] = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          };
        }

        if (!total_slot_empty[moment(slot.date, "YYYY-MM-DD").format("dddd")]) {
          total_slot_empty[moment(slot.date, "YYYY-MM-DD").format("dddd")] = [];
        }

        if (index <= dayIndex) {
          slots[`${time}`][moment(slot.date, "YYYY-MM-DD").format("dddd")].push(
            {
              Date: slot.date,
              Total_booked: slot.total_slot,
              available: slot.available_slot,
              sub_slots: detail,
            }
          );
        }
        this.setState({
          slots: slots,
        });

        sortedTimeArray = Object.keys(slots).sort((a, b) => {
          let timeA = new Date("1970/01/01 " + a);
          let timeB = new Date("1970/01/01 " + b);
          return timeA - timeB;
        });
        this.setState({
          sortedTimeArray: sortedTimeArray,
          startOfWeek,
          isPreviousDisable: true,
          isNextDisable: false,
        });
      });
    });
  };

  clickPreviousByDate = () => {
    console.log("clickPreviousByDate");
    const { allSlot, days } = this.state;
    const currentDate = new Date();
    const { language } = this.context;
    const currentDayIndex = currentDate.getDay();
    let sortedTimeArray = [];
    let headerTotalSlot = {};

    const moment = require("moment");
    const from_date = this.props.router.location.state.from_date; // from_date from props
    const to_date = this.props.router.location.state.to_date;
    console.log(from_date, to_date, "fffffff");
    const startOfWeek = moment(from_date, "YYYY-MM-DD")
      .isoWeekday(1)
      .add(this.state.countOnClick, "weeks");
    const endOfWeek = moment(to_date, "YYYY-MM-DD").isoWeekday(7);
    console.log(startOfWeek, endOfWeek, "ddddddd");
    if (startOfWeek.isAfter(endOfWeek)) {
      return;
    }

    const slots = {};

    const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    console.log(endOfCurrentWeek, "endOfCurrentWeek");
    const dateByDayArr = [];

    days.forEach((day, i) => {
      const dateByDay = moment(startOfWeek).add(i, "days").format("YYYY-MM-DD");
      console.log(dateByDay, "dateByDay");
      dateByDayArr.push(dateByDay);
    });

    console.log(dateByDayArr, startOfWeek, endOfCurrentWeek, "popopop");

    allSlot.forEach((slot) => {
      slot.details.forEach((detail) => {
        const slotDate = moment(slot.date).format("YYYY-MM-DD");
        console.log(
          slotDate,
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]"),
          "slotDate"
        );
        if (
          moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, "[]")
        ) {
          const time = detail.start_time;
          if (!slots[time]) {
            slots[time] = {};
          }

          // Check if the date exists in the array
          if (!slots[time][slotDate]) {
            slots[time][slotDate] = [];
          }

          // Push the slot information into the array
          slots[time][slotDate].push({
            Date: slot.date,
            Total_booked: slot.total_slot,
            available: slot.available_slot,
            sub_slots: detail,
          });

          headerTotalSlot[moment(slot.date, "YYYY-MM-DD").format("dddd")] = {
            total_slot: slot.total_slot,
            available_slot: slot.available_slot,
            Date: slot.date,
          };
        }
      });
    });

    sortedTimeArray = Object.keys(slots).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA - timeB;
    });

    // Create empty arrays if they don't have any values
    dateByDayArr.forEach((date) => {
      sortedTimeArray.forEach((time) => {
        if (!slots[time][date]) {
          slots[time][date] = [];
        }
      });
    });

    this.setState({
      headerTotalSlot,
      slots,
      sortedTimeArray,
      startOfWeek,
      isNextDisable: false,
    });
  };

  OpenModal = (id) => {
    this.setState({ showModal: true, activeModalId: id });
  };

  openFinalModal = () => {
    this.setState({
      showFinalModal: true,
    });
  };

  checkBoxPdf = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  checkBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  // checkBox = (e) => {
  //     const { name, checked } = e.target;

  //     if (checked) {
  //       // Display SweetAlert
  //       Swal.fire({
  //         title: 'Alert',
  //         text: 'Please select either Email or SMS, not both',
  //         icon: 'warning',
  //       });

  //       // Uncheck both checkboxes
  //       this.setState({
  //         email: false,
  //         sms: false,
  //       });
  //     } else {
  //       this.setState({
  //         [name]: checked,
  //       });
  //     }
  //   }

  // checkBox = (e) => {
  //     const { name } = e.target;
  //     if (name === 'is_sms' || name === 'is_email') {
  //         const isSendSMS = Cookies.get('is_send_sms');
  //         const isSendEmail = Cookies.get('is_send_email');

  //         if (isSendSMS === '0' || isSendEmail === '0') {
  //             Swal.fire({
  //                 title: 'Alert',
  //                 text: 'Please select either Email or SMS, not both',
  //                 icon: 'warning',
  //             });
  //             e.preventDefault();
  //         }
  //     }
  // }

  bookAppointment = () => {
    this.setState({ isButtonClicked: true });
    const uuid = this.props.router.location.state.uuid;
    const drive_through_id = this.state.driveinId;
    const customer_id = this.props.router.location.state.customer_id;
    const { is_email, is_sms, is_print_pdf, selectedAppointmentId } =
      this.state;
    const is_booking = this.state.is_booking;
    const pathname = this.props?.router?.location?.pathname;
    const helpDeskPage = pathname && pathname.startsWith("/helpdesk");

    if (is_booking == "0") {
      // const url = configuration.appointBook;
      const url = localStorage.getItem("access_token")
        ? configuration.helpdeskAppointBook
        : configuration.appointBook;

      const accessTokenHeader = localStorage.getItem("access_token")
        ? { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        : {};
      const payLoad = {
        uuid,
        appointment_id: selectedAppointmentId,
        drive_through_id,
        customer_id,
        is_email: is_email == true ? 1 : 0,
        is_sms: is_sms == true ? 1 : 0,
        is_print_pdf: is_print_pdf == true ? 1 : 0,
      };

      axios
        .post(url, payLoad, {
          headers: accessTokenHeader,
        })
        .then((response) => {
          if (response.data.success) {
            toastAlert(response.data.message, "success");
            Cookies.remove("uuid");
            Cookies.remove("clientTypeSetting");
            this.setState({
              isOpenConfirmAppointmentModal: false,
              isOpenAppointmentSuccessModal:
                !this.state.isOpenAppointmentSuccessModal,
            });
            // after 3 seconds redirect to next page
            // setTimeout(() => {
            //     const nextPage = helpDeskPage ? '/helpdesk/options' : '/thankyou';
            //     const state = {
            //         uuid: this.props.router.location.state.uuid,
            //     };
            //     this.props.router.navigate(nextPage, state);
            // }, 3000);
            this.getCalenderData();
          }
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
          }
          this.setState({ isButtonClicked: false });
          toastAlert(error.response.data.message, "error");
        });
    } else {
      const url = localStorage.getItem("access_token")
        ? configuration.helpDeskReschedule
        : configuration.reschedule;

      const accessTokenHeader = localStorage.getItem("access_token")
        ? { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        : {};

      axios
        .post(
          url,
          {
            appointment_id: selectedAppointmentId,
            drive_through_id,
            customer_id,
            is_email: is_email == true ? 1 : 0,
            is_sms: is_sms == true ? 1 : 0,
            is_print_pdf: is_print_pdf == true ? 1 : 0,
          },
          {
            headers: accessTokenHeader,
          }
        )
        .then((response) => {
          if (response.data.success) {
            toastAlert(response.data.message, "success");
            Cookies.remove("uuid");
            this.setState({
              isOpenConfirmAppointmentModal: false,
              isOpenAppointmentSuccessModal:
                !this.state.isOpenAppointmentSuccessModal,
            });
            // after 3 seconds redirect to next page
            // setTimeout(() => {
            //     const nextPage = helpDeskPage ? '/helpdesk/options' : '/thankyou';
            //     const state = {
            //         uuid: this.props.router.location.state.uuid,
            //     };
            //     this.props.router.navigate(nextPage, state);
            // }, 3000);
            this.getCalenderData();
          }
        })
        .catch((error) => {
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
            // Cookies.remove('id');
            // Cookies.remove('organization_country');
            // Cookies.remove('globalUserId');
            // Cookies.remove('isAuthenticated');
            // Cookies.remove('globalOrganizationId');
            // Cookies.remove('name');
            // Cookies.remove('organizationName');
            // Cookies.remove('organizationCountry');
            // Cookies.remove('regionName');
            // Cookies.remove('roleId');
            // Cookies.remove('isLoggedIn');
            // Cookies.remove('authToken');
            // Cookies.remove('uuid');
            // Cookies.remove('userData');
            // Cookies.remove('organizationCountry');
            // Cookies.remove('role_name');
            // Cookies.remove('clientTypeSetting');
            // localStorage.removeItem('access_token');
            // var element = document.getElementsByTagName("html")[0];
            // element.classList.remove("otherclass");
          }
          this.setState({ isButtonClicked: false });
          toastAlert(error.response.data.message, "error");
        });
    }
  };

  handleEdit = () => {
    // if (!this.state.confirmEdit) {
    this.setState({
      confirmEdit: !this.state.confirmEdit,
    });
    // }
  };

  validateInputs = () => {
    const { lang } = this.context;
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    // console.log(this.state.mobileNumber,'this.state.mobileNumber')
    let mobileNumberError = "";
    // let streetError = '';
    // let houseNumberError = '';
    // let countryError = '';
    // let stateError = '';
    // let cityError = '';
    // let postalCodeError = '';
    // let mileageError = '';

    if (!this.state.firstName.trim()) {
      firstNameError = `${lang("please_enter_your_first_name.")}`;
    }

    if (!this.state.lastName.trim()) {
      lastNameError = `${lang("please_enter_your_last_name.")}`;
    }

    if (this.state.email!='' && !this.state.email.includes("@")) {
      emailError = `${lang("please_enter_your_email_id.")}`;
    }else{
      emailError =''
    }

    
    // console.log(this.state.mobileNumber.length,'jjj');
    // if (this.state.mobileNumber.trim() && this.state.mobileNumber.length < 7) {
    //   mobileNumberError = 'Please enter a valid mobile number.';
    // }
    if (this.state.mobileNumber && this.state.mobileNumber.trim().length < 7) {
      mobileNumberError = 'Please enter a valid mobile number.';
    }

    // if (!this.state.street.trim()) {
    //     streetError = `${lang("please_enter_your_street_name.")}`;
    // }

    // if (!this.state.houseNumber.trim()) {
    //     houseNumberError = `${lang("please_enter_your_house_number.")}`;
    // }

    // if (!this.state.country.trim()) {
    //     countryError = `${lang("please_enter_your_country.")}`;
    // }

    // if (!this.state.state.trim()) {
    //     stateError = `${lang("please_enter_your_state.")}`;
    // }

    // if (!this.state.city.trim()) {
    //     cityError = `${lang("please_enter_your_city.")}`;
    // }

    // if (!this.state.postalCode.trim()) {
    //     postalCodeError = `${lang("please_enter_your_postal_code.")}`;
    // }

    // if (!this.state.km_stand.trim()) {
    //     mileageError = `${lang("please_enter_your_mileage.")}`;
    // }

    this.setState({
      firstNameError,
      lastNameError,
      emailError,
      mobileNumberError,
      // streetError,
      // houseNumberError,
      // countryError,
      // stateError,
      // cityError,
      // postalCodeError,
      // mileageError
    });
console.log(this.state.email != '' && emailError == '','poiuy')
    if(this.state.email != '' && emailError == ''){
      this.setState({
        is_email:1
      })
    }else{
      this.setState({
        is_email:0
      })
    }
    // console.log(this.state.mobileNumber,this.state.mobileNumber.length,this.state.mobileNumber.length < 7 , mobileNumberError == '',"plpl")
    if (this.state.mobileNumber && this.state.mobileNumber.length >= 7 && mobileNumberError === '') {
      this.setState({
        is_sms: 1
      });
    }else{
      this.setState({
        is_sms:0
      })
    }
    return !firstNameError && !lastNameError && !emailError;
  };

  handleChange = (e, name) => {
    if (name == "mobileNumber") {
      this.setState({
        [name]: e,
      });
    } else {
      if (e && e.target) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
      }
    }
  };

  handleConfirmSubmit = async () => {
    const isValid = await this.validateInputs();
    // console.log(isValid);

    // console.log(this.state.userData.reg_no,"reg");
    this.setState({ buttonClicked: true });
    if (isValid) {
      let uuid = this.props?.router?.location?.state?.uuid;
      const reg_no = this.state.userData.reg_no;
      // const reg_no = this.props?.router?.location?.state?.reg_no
      const order_id = this.props?.router?.location?.state?.order_id;
      const claim_number = this.state.userData.claim_number;
      // const claim_number = this.props?.router?.location?.state?.claim_number
      // const country_code = this.props.router.location.state.country_code

      // let country_code = "123"
      const url = configuration.updateData;
      const {
        salutation,
        profession,
        firstName,
        lastName,
        email,
        country_code,
        mobileNumber,
        street,
        houseNumber,
        country,
        state,
        city,
        postalCode,
        km_stand,
        is_booking,
      } = this.state;
      // console.log(reg_no,"reg_no");
      const payLoad = {
        reg_no: reg_no,
        order_id: order_id,
        claim_number: claim_number,
        uuid: uuid,
        // 'country_code': country_code,
        first_name: firstName,
        last_name: lastName,
        email_id: email,
        contact_no: mobileNumber,
        street: street,
        house_no: houseNumber,
        country: country,
        state: state,
        city: city || CITY,
        pincode: postalCode || POSTAL_CODE,
        km_stand: km_stand,
        profession: profession,
        salutation: salutation,
      };
      axios
        .post(url, payLoad) //  <- Payload
        .then((response) => {
          if (response.data.success) {
            const { isOpenConfirmAppointmentModal, isOpenConfirmDetailsModal } =
              this.state;
            this.setState({
              isOpenConfirmDetailsModal: false,
              isOpenConfirmAppointmentModal: !isOpenConfirmAppointmentModal,
            });
          } else {
            toastAlert(
              "Something went to wrong.Please try again later.",
              "error"
            );
          }
        })
        .catch((error) => {
          toastAlert(
            error?.response?.data?.message
              ? error.response.data.message
              : "Something went to wrong. Please try again later.",
            "error"
          );
          if (error.response.data.code === 401) {
            CookiesRemove();
            toastAlert(error.response.data.message, "error");
            this.props.router.navigate("/login");
            // Cookies.remove('id');
            // Cookies.remove('organization_country');
            // Cookies.remove('globalUserId');
            // Cookies.remove('isAuthenticated');
            // Cookies.remove('globalOrganizationId');
            // Cookies.remove('name');
            // Cookies.remove('organizationName');
            // Cookies.remove('organizationCountry');
            // Cookies.remove('regionName');
            // Cookies.remove('roleId');
            // Cookies.remove('isLoggedIn');
            // Cookies.remove('authToken');
            // Cookies.remove('uuid');
            // Cookies.remove('userData');
            // Cookies.remove('organizationCountry');
            // Cookies.remove('role_name');
            // Cookies.remove('clientTypeSetting');
            // localStorage.removeItem('access_token');
            // var element = document.getElementsByTagName("html")[0];
            // element.classList.remove("otherclass");
          }
        });
    }
  };

  handleToggle = (modalName, appointmentSlotTime, appointmentDay) => {
    this.setState({
      [modalName]: !this.state[modalName],
      appointmentSlotTime: appointmentSlotTime ?? "",
      appointmentDay: appointmentDay ?? "",
    });
    let bookingTag = document.querySelector(".booking-tag");

    document.body.classList.add("body-fixed");
    bookingTag.addEventListener("click", function () {});
  };

  handleConfirmDetailsModal = (
    selectedAppointmentTime,
    selectedAppointmentId,
    formattedFromDate
  ) => {
    const { isOpenConfirmDetailsModal } = this.state;
    this.setState({
      isOpenConfirmDetailsModal: !isOpenConfirmDetailsModal,
      isOpenAppointmentListModal: false,
      selectedAppointmentTime: selectedAppointmentTime,
      selectedAppointmentDate: formattedFromDate,
      selectedAppointmentId,
    });
    let uuid = this.props.router.location.state.uuid;

    const url = configuration.bookAppointment + uuid;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            userData: res.data.data,
            firstName: res.data.data.first_name,
            lastName: res.data.data.last_name,
            email: res.data.data.email_id,
            mobileNumber: res.data.data.contact_no,
            street: res.data.data.street,
            houseNumber: res.data.data.house_no,
            country: res.data.data.country,
            state: res.data.data.state,
            city: res.data.data.city,
            postalCode: res.data.data.pincode,
            km_stand: res.data.data.km_stand,
            country_code: res.data.data.country_code,
            insurance_client_id: res.data.data.insurance_client_id,
            salutation: res.data.data.salutation
              ? res.data.data.salutation
              : this.state.salutation,
            profession: res.data.data.profession
              ? res.data.data.profession
              : this.state.profession,
            is_booking: res.data.data.is_booking,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
          // Cookies.remove('id');
          // Cookies.remove('organization_country');
          // Cookies.remove('globalUserId');
          // Cookies.remove('isAuthenticated');
          // Cookies.remove('globalOrganizationId');
          // Cookies.remove('name');
          // Cookies.remove('organizationName');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('regionName');
          // Cookies.remove('roleId');
          // Cookies.remove('isLoggedIn');
          // Cookies.remove('authToken');
          // Cookies.remove('uuid');
          // Cookies.remove('userData');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('role_name');
          // Cookies.remove('clientTypeSetting');
          // localStorage.removeItem('access_token');
          // var element = document.getElementsByTagName("html")[0];
          // element.classList.remove("otherclass");
        }
        toastAlert(error.response.data.message, "error");
      });
  };

  closeModal = (modalName) => {
    document.body.classList.remove("body-fixed");
    this.setState(
      {
        [modalName]: false,
        appointmentSlotTime: null,
        appointmentDay: null,
        selectedAppointmentTime: "",
        selectedAppointmentId: null,
        confirmEdit: false,
      },
      () => {
        if (
          modalName === "isOpenAppointmentSuccessModal" &&
          !this.state.isOpenAppointmentListModal
        ) {
          window.close();
        }
      }
    );
  };

  viewPdf = () => {
    const customer_id = this.props.router.location.state.customer_id;
    const driveIn = this.props.router.location.state.driveIn;
    const appointment_id = this.state.selectedAppointmentId;

    const url = `${configuration.viewPdf}appointment_id=${
      appointment_id || ""
    }&customer_id=${customer_id}&drivin_id=${driveIn}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          var windo = window.open("", "");
          var objbuilder = "";
          objbuilder +=
            "<embed width='100%' height='100%'  src=\"data:application/pdf;base64,";
          objbuilder += res.data.data;
          objbuilder += '" type="application/pdf" />';
          windo.document.write(objbuilder);
        } else {
          toastAlert("Data not Found", "error");
        }
      })
      .catch((error) => {
        // toastAlert('Failed to fetch PDF data', 'error');
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
          // Cookies.remove('id');
          // Cookies.remove('organization_country');
          // Cookies.remove('globalUserId');
          // Cookies.remove('isAuthenticated');
          // Cookies.remove('globalOrganizationId');
          // Cookies.remove('name');
          // Cookies.remove('organizationName');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('regionName');
          // Cookies.remove('roleId');
          // Cookies.remove('isLoggedIn');
          // Cookies.remove('authToken');
          // Cookies.remove('uuid');
          // Cookies.remove('userData');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('role_name');
          // Cookies.remove('clientTypeSetting');
          // localStorage.removeItem('access_token');
          // var element = document.getElementsByTagName("html")[0];
          // element.classList.remove("otherclass");
        }
      });
  };

  onDayViewClick = () => {
    this.setState({
      loading: true,
      viewMode: "Day",
    });
    const drivein_id = this.props?.router?.location?.state?.driveIn || "";
    console.log(drivein_id, "drivein_id");
    const date = this?.props?.router?.location?.state?.from_date;
    console.log(date, "date");
    const url = `${configuration.getDaySlots}drivein_id=${
      drivein_id || ""
    }&date=${date || ""}`;

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "es.dat");
        if (res.data.success) {
          this.setState({ loading: false });
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    is_disable: "",
                    slots: [],
                  },
                },
              }));
            }
          });
          this.setState({ timeData });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
        }
      });
  };

  onWeekViewClick = () => {
    this.setState({ viewMode: "Week" });
  };

  // handleAddDate = () => {
  //     this.setState({ loading: true });
  //     const drivein_id = this.props?.router?.location?.state?.driveIn || '';
  //     const newDate = moment(this.state.fromDate).add(1, "day").format("YYYY-MM-DD");

  //     this.setState({ fromDate: newDate });
  //     const url = `${configuration.getDaySlots}drivein_id=${drivein_id || ''}&date=${newDate || ''}`;

  //     axios.get(url).then(res => {
  //         if (res.data.success) {
  //             this.setState({ loading: false });
  //             const timeData = {};

  //             res.data.data.allSlot.details.forEach(detail => {
  //                 const time = detail.start_time;
  //                 const date = detail.date;
  //                 const breakName = detail.break_name;

  //                 if (!timeData[time]) {
  //                     timeData[time] = {
  //                         date,
  //                         breakName,
  //                         slots: detail.time_slot,
  //                     };
  //                 } else {
  //                     timeData[time].slots.push(...detail.time_slot);
  //                 }
  //             });

  //             // Add empty data for times that have no slots
  //             Object.keys(timeData).forEach(time => {
  //                 if (!this.state.timeData[time]) {
  //                     this.setState(prevState => ({
  //                         timeData: {
  //                             ...prevState.timeData,
  //                             [time]: {
  //                                 date: '',
  //                                 breakName: '',
  //                                 slots: [],
  //                             }
  //                         }
  //                     }));
  //                 }
  //             });

  //             this.setState({ timeData });
  //             console.log(timeData, 'timeData');
  //         }
  //     }).catch(error => {
  //         this.setState({ loading: false });
  //         toastAlert('Data not found', 'error');
  //     });
  // }

  handleAddDate = () => {
    this.setState({ loading: true });
    const drivein_id = this.props?.router?.location?.state?.driveIn || "";
    const newDate = moment(this.state.fromDate)
      .add(1, "day")
      .format("YYYY-MM-DD");

    this.setState({ fromDate: newDate });
    const url = `${configuration.getDaySlots}drivein_id=${
      drivein_id || ""
    }&date=${newDate || ""}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    slots: [],
                  },
                },
              }));
            }
          });

          this.setState({ timeData, loading: false }, () => {
            if (Object.keys(timeData).length === 0) {
              this.setState({ noSlotsAvailable: true });
            } else {
              this.setState({ noSlotsAvailable: false });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
          // Cookies.remove('id');
          // Cookies.remove('organization_country');
          // Cookies.remove('globalUserId');
          // Cookies.remove('isAuthenticated');
          // Cookies.remove('globalOrganizationId');
          // Cookies.remove('name');
          // Cookies.remove('organizationName');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('regionName');
          // Cookies.remove('roleId');
          // Cookies.remove('isLoggedIn');
          // Cookies.remove('authToken');
          // Cookies.remove('uuid');
          // Cookies.remove('userData');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('role_name');
          // Cookies.remove('clientTypeSetting');
          // localStorage.removeItem('access_token');
          // var element = document.getElementsByTagName("html")[0];
          // element.classList.remove("otherclass");
        }
      });
  };

  handleRemoveDate = () => {
    console.log("handleRemoveDate");
    this.setState({ loading: true });
    const drivein_id = this.props?.router?.location?.state?.driveIn || "";
    const newDate = moment(this.state.fromDate)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    console.log(this.state.fromDate, "this.state.fromDate");

    this.setState({ fromDate: newDate });
    const url = `${configuration.getDaySlots}drivein_id=${
      drivein_id || ""
    }&date=${newDate || ""}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ loading: false });
          const timeData = {};

          res.data.data.allSlot.details.forEach((detail) => {
            const time = detail.start_time;
            const date = detail.date;
            const breakName = detail.break_name;
            const is_disable = detail.is_disable;

            if (!timeData[time]) {
              timeData[time] = {
                date,
                breakName,
                is_disable,
                slots: detail.time_slot,
              };
            } else {
              timeData[time].slots.push(...detail.time_slot);
            }
          });

          // Add empty data for times when no slots Available
          Object.keys(timeData).forEach((time) => {
            if (!this.state.timeData[time]) {
              this.setState((prevState) => ({
                timeData: {
                  ...prevState.timeData,
                  [time]: {
                    date: "",
                    breakName: "",
                    is_disable: "",
                    slots: [],
                  },
                },
              }));
            }
          });

          this.setState({ timeData });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toastAlert(error.response.data.message, "error");
        if (error.response.data.code === 401) {
          CookiesRemove();
          toastAlert(error.response.data.message, "error");
          this.props.router.navigate("/login");
          // Cookies.remove('id');
          // Cookies.remove('organization_country');
          // Cookies.remove('globalUserId');
          // Cookies.remove('isAuthenticated');
          // Cookies.remove('globalOrganizationId');
          // Cookies.remove('name');
          // Cookies.remove('organizationName');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('regionName');
          // Cookies.remove('roleId');
          // Cookies.remove('isLoggedIn');
          // Cookies.remove('authToken');
          // Cookies.remove('uuid');
          // Cookies.remove('userData');
          // Cookies.remove('organizationCountry');
          // Cookies.remove('role_name');
          // Cookies.remove('clientTypeSetting');
          // localStorage.removeItem('access_token');
          // var element = document.getElementsByTagName("html")[0];
          // element.classList.remove("otherclass");
        }
      });
  };

  render() {
    const { language, lang } = this.context;
    const {
      timeData,
      allSlot,
      days,
      headerTotalSlot,
      sortedTimeArray,
      slots,
      startOfWeek,
      firstName,
      lastName,
      email,
      mobileNumber,
      street,
      houseNumber,
      country,
      state,
      city,
      postalCode,
      km_stand,
      userData,
      confirmEdit,
      // modal states
      isOpenAppointmentListModal,
      appointmentDay,
      appointmentSlotTime,
      isOpenConfirmDetailsModal,
      selectedAppointmentTime,
      isOpenConfirmAppointmentModal,
      isOpenAppointmentSuccessModal,
      loading,
      buttonClicked,
      isNextDisable,
      is_email,
      is_sms,
      is_print_pdf,
      countOnClick,
      isPreviousDisable,
      cityError,
      stateError,
      countryError,
      houseNumberError,
      streetError,
      mobileNumberError,
      emailError,
      lastNameError,
      firstNameError,
      postalCodeError,
      mileageError,
      viewPdf,
      salutation,
      profession,
      fromDate,
      isButtonClicked,
      message,
    } = this.state;

    const driveinId = this.props?.router?.location?.state?.driveIn || "";
    const oldFrom_date = this?.props?.router?.location?.state?.from_date;
    console.log(oldFrom_date, "old");
    const dateFormat = Cookies.get("dateFormat");
    // const from_date = moment(oldFrom_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY');
    const from_date = moment(oldFrom_date).format(dateFormat);
    const oldto_date = this.props?.router?.location?.state?.to_date;
    // const to_date = moment(oldto_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY');
    const to_date = moment(oldto_date).format(dateFormat);
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    const insurance_name = this.props?.router?.location?.state?.insurance_name;
    const claim_number = this.props?.router?.location?.state?.claim_number;
    const first_name = this.props?.router?.location?.state?.first_name;
    const last_name = this.props?.router?.location?.state?.last_name;
    const reg_no = this.props?.router?.location?.state?.reg_no;
    const country_code = this.props?.router?.location?.state?.country_code;
    console.log(country_code, "country_code");
    const locationName = this.props?.router?.location?.state?.locationName;
    const locationKm = this.props?.router?.location?.state?.locationKm;
    const { viewMode } = this.state;
    const isDayView = viewMode === "Day";
    return (
      <>
        <main class="main-content calender-content">
          <section class="calendar-wrapper">
            <div class="container-fluid">
              <div class="calendar-header">
                <div class="row align-items-center justify-content-end">
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-25-col">
                    <div class="calendar-date-and-back-btn">
                      <div class="calendar-date">
                        <div class="next-prev-calendar">
                          <a
                            class={`calendar-prev cal-btn btn icon-btn ${
                              isDayView ? "d-none" : ""
                            }`}
                            onClick={() => {
                              if (!isPreviousDisable) {
                                this.getCountOnPrevious();
                              }
                            }}
                          >
                            <i class="fa fa-long-arrow-left"></i>
                          </a>
                          <div class="calendar-date-text">
                            <h4 class="small-title-18">
                              {from_date} - {to_date}
                            </h4>
                          </div>
                          <a
                            class={`calendar-next cal-btn icon-btn btn ${
                              isDayView ? "d-none" : ""
                            }`}
                            onClick={() => {
                              if (!isNextDisable) {
                                this.getCountOnNext();
                              }
                            }}
                          >
                            <i class="fa fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-50-col">
                    <div class="calendar-ul-top">
                      <ul
                        class="nav nav-pills calendar-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-week-tab"
                            data-toggle="pill"
                            onClick={() => this.onWeekViewClick()}
                            data-target="#pills-week"
                            type="button"
                            role="tab"
                            aria-controls="pills-week"
                            aria-selected="false"
                            data-key="Week"
                          >
                            {lang("week")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-day-tab"
                            data-toggle="pill"
                            onClick={() => this.onDayViewClick()}
                            data-target="#pills-day"
                            type="button"
                            role="tab"
                            aria-controls="pills-day"
                            aria-selected="false"
                            data-key="Day"
                          >
                            {lang("day")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="calendar-view">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active week-calendar-top"
                    id="pills-week"
                    role="tabpanel"
                    aria-labelledby="pills-week-tab"
                  >
                    <CalendarComponent
                      message={message}
                      countOnClick={countOnClick}
                      getCountOnPrevious={this.getCountOnPrevious}
                      getCountOnNext={this.getCountOnNext}
                      allSlot={allSlot}
                      isButtonClicked={isButtonClicked}
                      selectedAppointmentDate={
                        this.state.selectedAppointmentDate
                      }
                      salutation={salutation}
                      profession={profession}
                      // postalCodeError={postalCodeError}
                      mileageError={mileageError}
                      // cityError={cityError}
                      firstNameError={firstNameError}
                      lastNameError={lastNameError}
                      stateError={stateError}
                      emailError={emailError}
                      countryError={countryError}
                      houseNumberError={houseNumberError}
                      mobileNumberError={mobileNumberError}
                      streetError={streetError}
                      days={days}
                      country_code={country_code}
                      headerTotalSlot={headerTotalSlot}
                      sortedTimeArray={sortedTimeArray}
                      slots={slots}
                      startOfWeek={startOfWeek}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      mobileNumber={mobileNumber}
                      street={street}
                      houseNumber={houseNumber}
                      country={country}
                      state={state}
                      city={city}
                      postalCode={postalCode}
                      km_stand={km_stand}
                      userData={userData}
                      confirmEdit={confirmEdit}
                      isOpenAppointmentListModal={isOpenAppointmentListModal}
                      appointmentDay={appointmentDay}
                      appointmentSlotTime={appointmentSlotTime}
                      isOpenConfirmDetailsModal={isOpenConfirmDetailsModal}
                      selectedAppointmentTime={selectedAppointmentTime}
                      isOpenConfirmAppointmentModal={
                        isOpenConfirmAppointmentModal
                      }
                      isOpenAppointmentSuccessModal={
                        isOpenAppointmentSuccessModal
                      }
                      loading={loading}
                      buttonClicked={buttonClicked}
                      isNextDisable={isNextDisable}
                      reg_no={reg_no}
                      last_name={last_name}
                      first_name={first_name}
                      claim_number={claim_number}
                      insurance_name={insurance_name}
                      currentDayIndex={currentDayIndex}
                      to_date={to_date}
                      from_date={from_date}
                      lang={lang}
                      is_email={is_email}
                      is_sms={is_sms}
                      is_print_pdf={is_print_pdf}
                      handleToggle={this.handleToggle}
                      closeModal={this.closeModal}
                      handleConfirmDetailsModal={this.handleConfirmDetailsModal}
                      handleConfirmSubmit={this.handleConfirmSubmit}
                      checkBox={this.checkBox}
                      clickPrevious={this.clickPrevious}
                      clickNext={this.clickNext}
                      handleEdit={this.handleEdit}
                      handleChange={(e, name) => this.handleChange(e, name)}
                      bookAppointment={this.bookAppointment}
                      PhoneInput={PhoneInput}
                      viewPdf={() => this.viewPdf()}
                      checkBoxPdf={this.checkBoxPdf}
                    />
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-day"
                    role="tabpanel"
                    aria-labelledby="pills-contact-day"
                  >
                    <CalendarDayView
                      oldFrom_date={oldFrom_date}
                      onDayViewClick={this.onDayViewClick}
                      oldto_date={oldto_date}
                      from_date={from_date}
                      to_date={to_date}
                      driveinId={driveinId}
                      timeData={timeData}
                      handleConfirmDetailsModal={this.handleConfirmDetailsModal}
                      handleAddDate={this.handleAddDate}
                      handleRemoveDate={this.handleRemoveDate}
                      fromDate={fromDate}
                      loading={loading}
                      locationName={locationName}
                      locationKm={locationKm}
                      first_name={first_name}
                      last_name={last_name}
                      salutation={salutation}
                    ></CalendarDayView>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(HelpDeskCalendar);
