import React, { Component } from 'react';
import LanguageContext from '../../context/LanguageProvider';
import moment from "moment/moment";
import axios from 'axios';
import { configuration } from '../../appConfig';
import withRouter from '../../withRouter';
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Spinner } from 'reactstrap';
export class ThankyouPage extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            drivin_location: '',
            loading : false,
        }
    }

    componentDidMount = async () => {
        await this.getAppointmentData();
    }

    getAppointmentData = () => {
        this.setState({ loading : true })
        let uuid = this.props.router.location.state.uuid;
        const bookingInfoUrl = configuration.customerBookingInfo + uuid;
        axios.get(bookingInfoUrl).then(
            response => {
                if (response.data.success) {
                    this.setState({
                        date: response.data.data.date,
                        time: response.data.data.from_time,
                        drivin_location: response.data.data.drivin_location,
                        loading : false,
                    })
                }

            }
        ).catch(error => {
            this.setState({ loading : false })
            toastAlert(error.response.data.message, 'error');
        });
    }

    render() {
        const { language, lang } = this.context
        const { date, time, drivin_location,loading } = this.state
        return (
            <>
                <main class="main-content thankYou-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="thankyou-wrapper">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="thank-you-content-main">
                                        <div class="thankyou-title">
                                            {/* <p class="small-text-black-16">Dear <span class="small-text-black-16">,</span>
                                            <span class="small-text-red-16">Mr. Jhone Doe</span>
                                        </p> */}
                                            <h2 class="black-thankyou-title thankyou-title">{lang("thank_you!")} <span
                                                class="red-thankyou-title thankyou-title"></span> </h2>
                                        </div>
                                        <div class="booked-customer-details">
                                            <p class="small-text-red-16 mt-2">{lang("we_confirm_your_appointment_at_the_hagel_drive-in.")}</p>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="thankyou-date-time">
                                                        <span class="small-text-black-16">{lang("date")}<span class="small-text-black-16">: </span></span>
                                                        <span class="small-text">{moment(date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="thankyou-date-time">
                                                        <span class="small-text-black-16">{lang("time")}<span class="small-text-black-16">: </span></span>
                                                        <span class="small-text"> {time}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="thankyou-date-time">
                                                <span class="small-text-black-16">{lang("location")}<span
                                                    class="small-text-black-16">: </span></span>
                                                <span class="small-text"> {drivin_location}</span>
                                            </div>
                                            {/* <div class="thankyou-date-time">
                                            <span class="small-text-black-16">Expertise No.<span
                                                class="small-text-black-16">:</span></span>
                                            <span class="small-text"> 14155130</span>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 cl-md-12 col-sm-12">
                                    <div class="thank-you-image">
                                        <img src="../../assets/images/dns-thankyou.png" alt="Thankyou" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default withRouter(ThankyouPage);
