import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Component } from 'react'
import { createContext } from "react";
import { configuration } from '../appConfig';
import toastAlert from '../helper/ToastAlert/ToastAlert';
const LanguageContext = createContext({});

class LanguageProvider extends Component {
    constructor() {
        super()
        this.state = {
            lang: null,
            words: {}
        }
    }

    componentDidMount = () => {
        this.setState({
            lang: Cookies.get('lang') || 'DE',
        })
        this.getLang();
    }

    getLang = async () => {
        let url = configuration.lang
        axios.get(url)
            .then((res) => {
                if (res?.data?.data) {
                    this.setState({
                        clientType:res.data.data.client_type
                    })
                    Cookies.set('clientType',res?.data?.data?.client_type);
                    if((res.data.data.client_type === "sava")){
                        this.setState({
                            lang:'SL-SL',
                            
                        })
                    }
                    this.setState({
                        words: res.data.data,
                    });
                };
            })
            .catch((err) => {
                toastAlert(err?.response?.data?.message ? err.response.data.message : 'Something went wrong!', 'error');
            });
    };

    lang = (word) => {
        const { lang, words } = this.state;
        return words[word]?.translations[lang] ? words[word]?.translations[lang] : (words[word]?.label || word);
    };

    userLanguageChange = (lang) => {
        this.setState({
            lang: lang
        });
        Cookies.set('lang', lang)
    };

    render() {
        const { lang,clientType } = this.state;
        return (
            <LanguageContext.Provider value={{ userLanguageChange: this.userLanguageChange, lang: this.lang, language: lang ,clientType:clientType}}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}


export default LanguageContext;
export { LanguageProvider }