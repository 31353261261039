import React, { Component } from 'react';
import withRouter from '../../withRouter';
import user from "../../assets/images/user.svg";
import LanguageContext from '../../context/LanguageProvider';
import Cookies from 'js-cookie';
import { configuration, url } from '../../appConfig';
import axios from 'axios';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
export class RescheduleHeplDesk extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            reg_noError: '',
            insurance_numberError: '',
            order_idError: '',
            reg_no: '',
            insurance_number: '',
            order_id: '',
            country_code: '',
            isButtonClicked: false,
            isModalOpen: false,
            loading: false,
            date: '',
            time: '',
            formValidate: '',
            isDisableNextButton: 0,
            isBookingModal: false,
            message : '',
            userData: {
                reg_no: '',
                claim_number: '',
                order_id: '',
            },
        }
    }

    handleChange = (e) => {
        const {name,value} = e.target;
        // console.log(e.t,"evnt");

        // this.setState({
        //     [name]: value
        // });
        this.setState(prevState => ({
            userData: {
                ...prevState.userData,
                [name]: value
            },

        }),)
    }

    componentDidMount = () => {
        const organizationCountry = Cookies.get('organizationCountry');
        if (organizationCountry === 'Slovenia') {
            const message = 'e.g_audatex,_eurotax,_dat_etc...';
            this.setState({
                message: message
            })
        }

        this.setState({
            checkOrderLoading: false,
            loading: false,
            userData: {
                reg_no: this.props?.router?.location?.state?.userData?.reg_no ?this.props?.router?.location?.state?.userData?.reg_no:this.props?.router?.location?.state?.stateItem?.reg_no ||'',
                claim_number: this.props?.router?.location?.state?.userData?.claim_number?this.props?.router?.location?.state?.userData?.claim_number:this.props?.router?.location?.state?.stateItem?.insurance_no ||'',
                order_id: this.props?.router?.location?.state?.userData?.order_id ?this.props?.router?.location?.state?.userData?.order_id :this.props?.router?.location?.state?.stateItem?.order_number ||'' ,
                data:this.props?.router?.location?.state?.userData?.order_id ?this.props?.router?.location?.state?.userData?.data :this.props?.router?.location?.state?.value||''
            },
        })

       
        
    }


    validateFields = () => {
        const { lang } = this.context;
        const { reg_no, claim_number, order_id } = this.state.userData;
        console.log(typeof (claim_number));
        console.log(typeof (order_idError));
        console.log(typeof (reg_noError));
        let isError = false;

        if (reg_no.trim() === '') {
            this.setState({ reg_noError: lang('registration_plate_is_required.') });
            isError = true;
        }

        if (claim_number.trim() === '') {
            this.setState({ insurance_numberError: lang('insurance_number_is_required.') });
            isError = true;
        }
        if (order_id.trim() === '') {
            this.setState({ order_idError: lang('order_id_is_required.') });
            isError = true;
        }

        return !isError;
    }


    handleNext = (event) => {
        this.setState({
            // checkOrderLoading: true,
            loading: true,
        })
      
        event.preventDefault();
        const isValid = this.validateFields();
        const { lang } = this.context;
        const { claim_number, order_id, reg_no } = this.state.userData;
        // if (!insurance_number && !order_id && !reg_no) {
        //     this.setState({
        //         formValidate: { message: lang('please_enter_at_least_one_field.') },
        //     });
        //     return;
        // } 
        if(isValid) {


            console.log(this.state?.userData?.data?.first_name,"first_name");
            const url = configuration.updateData;
            // console.log(this.state.userData.data.data,"erhteituhr","url");
            // let country_code = this.props.router.location.state.country_code
            const { first_name, last_name, email_id, contact_no, street, house_no, country, state, city, pincode, km_stand, salutation, profession,uuid } = this.state?.userData?.data;
    console.log(this.state.userData.data,"this.state.data");
            const states = {
                ...this.state.userData.data,
                reg_no: reg_no,
                claim_number: claim_number,
                // claim_number: claim_number,
                order_id: order_id,
                // uuid: this.props.router.params.id,
            }
            const payLoad =
            {
                'reg_no': reg_no,
                'order_id': order_id,
                'claim_number': claim_number,
                'first_name': first_name,
                'last_name': last_name,
                'email_id': email_id,
                'contact_no': contact_no,
                'street': street,
                'house_no': house_no,
                'country': country,
                'state': state,
                'city': city,
                'uuid':uuid,
                'pincode': pincode,
                'km_stand': km_stand,
                'salutation': salutation,
                'profession': profession,
            }
            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            loading:false
                        })
                        console.log(response.data,"res.data");
                        this.props.router.navigate('/helpdesk/reschedule/details', {
                                    state: states
                                });
                        
                    }
                })
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });


            // console.log(this.state.data,"this.state.data");
            // this.setState({
            //     formValidate: ''
            // });
        //     const state = {
        //         reg_no: reg_no,
        //         insurance_number: claim_number,
        //         // claim_number: claim_number,
        //         order_id: order_id,
        //         data:this.state.data
        //         // uuid: this.props.router.params.id,
        //     }
        //     this.props.router.navigate('/helpdesk/reschedule/details', {
        //         state: state
        //     });
        }
    }


    handleBlur = async () => {
      
        const reg_no = this.state.userData.reg_no
        const claim_number = this.state.userData.claim_number
        const order_id = this.state.userData.order_id
        if((reg_no.trim() && claim_number.trim() && order_id.trim()) || (!reg_no.trim() && !claim_number.trim() && !order_id.trim())) {
            return;
        }

        this.setState({
            checkOrderLoading: true,
            loading: true,
        })
        const { lang } = this.context;
        let url = `${configuration.sendDataSearch}insurance_no=${claim_number || ''}&&reg_no=${reg_no || ''}&&order_id=${order_id || ''}`

        try {
            // const isValid = this.validateFields();
            // if (isValid) {
            const res = await axios.get(url);
            if (res.data.success) {
                let organizationCountry = Cookies.get('organizationCountry')
                this.setState({
                    checkOrderLoading: false,
                    loading: false,
                    userData: {
                        reg_no: res.data.data.reg_no,
                        claim_number: res.data.data.claim_number,
                        order_id: res.data.data.order_id,
                        data:res.data.data
                    },
                })


                if (res.data.data.is_booking == 1) {
                    this.setState({
                        loading: false,
                        isBookingModal: false,
                        isDisableButton: true,
                    })
                } else {
                    const cookieName = "userData";
                    const cookieValue = JSON.stringify(res.data.data);
                    document.cookie = `${cookieName}=${cookieValue}; path=/`;

                    this.setState({
                        isDisableButton: false,
                        isBookingModal: true,
                        checkOrderLoading: false,
                        loading: false,
                        userData: {
                            reg_no: res.data.data.reg_no,
                            claim_number: res.data.data.claim_number,
                            order_id: res.data.data.order_id,
                        },
                        enterCount: true,
                        isFromResponse: true,
                    })
                }
            }

        } catch (err) {
            this.setState({ loading: false });
            if (err.response.status == 404) {
                // this.props.router.navigate('/helpdesk/create/appointment')
                // toastAlert('Order Id not Found', 'err');
                this.setState({ isBookingModal: true })
            } else if (err.response.data.code === 401) {
                CookiesRemove();
                toastAlert(err.response.data.message, 'err');
                this.props.router.navigate('/login');
        } else {
            toastAlert(error.response.data.message, 'error');
            this.setState({ isBookingModal: false })
        }}
    }

    render() {
        const { lang } = this.context;
        // const { formValidate, message } = this.state
        const { reg_noError, insurance_numberError, order_idError, isDisableButton, formValidate, message } = this.state;
        const { reg_no, claim_number, order_id } = this.state.userData;
        return (
            <>
                <main class="main-content">
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-4">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src={user} alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18">{lang("reschedule_appointment")}</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={this.handleNext}>
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('order_id')} {lang(`${message}`)}<span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" class="form-control"  value={order_id} name="order_id" onBlur={this.handleBlur} placeholder={lang("order_id")} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
                                                                </div>
                                                                <span className="error-message">{order_idError}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('insurance_number')} <span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" class="form-control"
                                                                        placeholder={lang('insurance_number')}   onBlur={this.handleBlur} value={claim_number} name="claim_number" onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
                                                                </div>
                                                                <span className="error-message">{insurance_numberError}</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('registration_plate')} <span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        name="reg_no"
                                                                        onChange={this.handleChange}
                                                                        placeholder={lang('registration_plate')}
                                                                        onKeyDown={this.handleKeyDown}
                                                                        value={reg_no}
                                                                        onBlur={this.handleBlur}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{reg_noError}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer single-next-btn">
                                                <div class="card-btn next-btn-main">
                                                    <button
                                                        className="btn next-btn red-btn"
                                                        type="submit"
                                                    // onClick={this.handleNext}
                                                    disabled={!claim_number.trim() || !order_id.trim() || !reg_no.trim()?true:false}
                                                    >
                                                        {lang("next")}
                                                        <span><i className="fa fa-long-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {formValidate && (<span class="error-message one-error">{formValidate.message}</span>)}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Modal isOpen={this.state.isBookingModal} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    {/* <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader> */}
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang('an_appointment_has_not_been_created_for_this_order_id_:')} {order_id}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")} </span> <span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")} </span><span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16">{lang("kindly_click_on_the_create_new_appointment_button_to_create_a_new_appointment")}</p>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            <Link to='/helpdesk/create/appointment'>
                                <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("create_new_appointment")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(RescheduleHeplDesk);
