import moment from 'moment';
import React, { Component } from 'react';
import LanguageContext from '../../context/LanguageProvider';
import adiLogo from '../../assets/images/adi-logo.png';
import pdrLogo from '../../assets/images/pdr.png';
import './DefaultFooter.css'
import Cookies from 'js-cookie';
export class DefaultFooter extends Component {
    static contextType = LanguageContext;
    render() {
        const { lang } = this.context;
        const organizationCountry = Cookies.get('organization_country');
        const footer_logo_1 = Cookies.get('footer_logo_1');
        const footer_logo_2 = Cookies.get('footer_logo_2');
        const footer_text = Cookies.get('footer_text');
        return (
            <div>
                {/* <footer class="footer-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="copy-right-text">
                                    <img class="footerLogoAdi" src={adiLogo} alt='adiLogo' />
                                    <p className="small-text-black-16 d-inline-flex copyright_text">
                                        {lang('copyright')} &#169;  {moment().format('YYYY')} ADI <span><pre className='text-black'>&#174;</pre></span> {lang('all_rights_reserved._copy_and_distribution_are_prohibited.')}
                                    </p>
                                    <img class="footerLogoPdr" src={pdrLogo} alt='adiLogo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
                <footer class="footer-wrapper">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="footer-logo-text">
                                    {/* {organizationCountry == 'Slovenia'
                                        ?
                                        <div class="footer-left-side-logo footer-logo">
                                            <img src={footer_logo_1} alt='adiLogo' />
                                        </div>
                                        :
                                        ""
                                    } */}
                                    <div class="footer-left-side-logo footer-logo">
                                        {footer_logo_1 == "null" ? '' : <img src={footer_logo_1} />}
                                    </div>

                                    <div class="copy-right-text">
                                        <p class="small-text-black-16">
                                            {lang('copyright')} © 2023 Demo® {lang(`${footer_text}`)}
                                        </p>
                                    </div>
                                    {/* {organizationCountry == 'Slovenia'
                                        ?
                                        <div class="footer-right-side-logo footer-logo">
                                            <img src={footer_logo_2} />
                                        </div>
                                        :
                                        ""
                                    } */}
                                    <div class="footer-right-side-logo footer-logo">
                                        {footer_logo_2 == "null" ? '' : <img src={footer_logo_2} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default DefaultFooter;
