import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import user from "../../assets/images/user.svg";
import withRouter from '../../withRouter';

export class CustomerBasicComponent extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { isButtonDisabled,userData, lang, formValidate, loading, isButtonClicked, handleNext, handleKeyDown, handleChange, isDisableNextButton } = this.props
    const currentPath = window.location.pathname;
    const isReschedule = currentPath.includes('reschedule');
    return (
      <>
        <main class="main-content">
          {loading && <div class="loader-main">
            <Spinner color="light">
              <span></span>
            </Spinner>
          </div>}
          <section class="customer-basic-details-wrapper all-roles-cards-wrapper">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-4">
                  <div class="card card-primary">
                    <div class="card-header">
                      <div class="card-title">
                        <div class="card-title-img-main">
                          <span class="card-title-img">
                            <img src={user} alt="Customer basic details" />
                          </span>
                          <span class="card-title-text">
                            <h4 class="small-title-18">{isReschedule ? lang("reschedule_appointment") : lang("customer_information")}</h4>
                          </span>
                        </div>
                      </div>
                    </div>
                    <form action="">
                      <div class="card-body">
                        <div class="card-form-main">
                          <div class="row">
                            <div class="col-12">
                              <div class="input-group custom-input-group">
                                <label for="" class="form-label">{lang('order_id')}<span
                                  class="important-label">*</span></label>
                                <div class="form-control-top">
                                  <input type="text" class="form-control" name="order_id" value={userData.order_id} placeholder={lang("order_id")} onChange={handleChange} onKeyDown={handleKeyDown} disabled />
                                </div>
                                <span class="error-message"></span>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-group custom-input-group">
                                <label for="" class="form-label">{lang('insurance_number')} <span
                                  class="important-label">*</span></label>
                                <div class="form-control-top">
                                  <input type="text" class="form-control"
                                    placeholder={lang('insurance_number')} name="claim_number" value={userData?.claim_number} onChange={handleChange} onKeyDown={handleKeyDown} disabled />
                                </div>
                                <span class="error-message"></span>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="input-group custom-input-group">
                                <label for="" class="form-label">{lang('registration_plate')} <span
                                  class="important-label">*</span></label>
                                <div class="form-control-top">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="reg_no"
                                    value={userData?.reg_no}
                                    onChange={handleChange}
                                    placeholder={lang('registration_plate')}
                                    onKeyDown={handleKeyDown}
                                  // disabled={isReschedule}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer single-next-btn">
                        <div class="card-btn next-btn-main">
                          {/* <Link
                            to={isReschedule ? '/customer/reschedule/details' : '/customer/details'}
                            className="btn next-btn red-btn"
                            type="btn"
                            onClick={handleNext}
                            disabled={isDisableNextButton === 1}
                          >
                            {lang("next")}
                            <span><i class="fa fa-long-arrow-right"></i></span>
                          </Link> */}
                          {(isDisableNextButton === 1 || isButtonDisabled) ? (
                            <button
                              className="btn next-btn red-btn"
                              type="button"
                              disabled={true}
                            >
                              {lang("next")}
                              <span><i className="fa fa-long-arrow-right"></i></span>
                            </button>
                          ) : (
                            <Link
                              to={isReschedule ? '/customer/reschedule/details' : '/customer/details'}
                              className="btn next-btn red-btn"
                              type="btn"
                              onClick={handleNext}
                              disabled={isButtonDisabled}
                            >
                              {lang("next")}
                              <span><i className="fa fa-long-arrow-right"></i></span>
                            </Link>
                          )}


                        </div>
                      </div>
                    </form>
                  </div>
                  {formValidate && (<span class="error-message one-error">{formValidate.message}</span>)}
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(CustomerBasicComponent);
