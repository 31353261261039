import axios from 'axios';
import { configuration } from '../../appConfig';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';


export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export function loginSuccess(token) {
  return { type: LOGIN_SUCCESS, payload: token };
}

export function loginFailure() {
  return { type: LOGIN_FAILURE };
}

export function logout() {
  return { type: LOGOUT };
}

export const setAccessToken = (token) => {
  console.log('token');
  return {
    type: SET_ACCESS_TOKEN,
    payload: token,
  };
};

export function authenticateUser(payload) {
  return async function (dispatch) {
    try {
      const url = configuration.heplDeskLogin;
      const fpPromise = Promise.resolve(FingerprintJS.load());
      const fp = await fpPromise;
      const result = await fp.get();
      payload.device_id = result.visitorId;
      // const isLoggedIn = localStorage.getItem('access_token') !== null;
      // const isLoggedIn = Cookies.get('authToken') !== undefined;


      const response = await axios.post(url, payload);
      const { id, access_token, global_organization_id, global_user_id, name, organization_name, organization_country, region_name, role_name, role_id } = response.data.data
      const isLoggedIn = access_token !== null;
      Cookies.set('authToken', access_token)
      Cookies.set('id', id)
      Cookies.set('globalUserId', global_user_id)
      Cookies.set('isAuthenticated', true)
      Cookies.set('globalOrganizationId', global_organization_id)
      Cookies.set('name', name)
      Cookies.set('organizationName', organization_name)
      Cookies.set('organizationCountry', organization_country)
      Cookies.set('regionName', region_name)
      Cookies.set('roleId', role_id)
      Cookies.set('role_name', role_name)
      Cookies.set('isLoggedIn', isLoggedIn)
      var element = document.getElementsByTagName("html")[0];
      element.classList.add("otherclass");
      dispatch(loginSuccess(access_token));

      return access_token;
    } catch (error) {
      dispatch(loginFailure());
      if (error.response && error.response.status === 401) {
        throw new Error(error);
      } else {
        throw new Error('Something went wrong');
      }
    }
  };
}
