import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { configuration } from "../../appConfig";
import LanguageContext from "../../context/LanguageProvider";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import withRouter from "../../withRouter";
import { CookiesRemove } from "../../CookiesRemove";

export class HelpdeskUserManagement extends React.Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      admin: [],
      superadmin: [],
      manager: [],
      helpdesk: [],
      loading: false,
      showModal: false,
      showModal1: false,
      showEditPassModel: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userRoles: [],
      userRolesId: "",
      userRolesIdError: "",
      emailError: "",
      passwordError: "",
      firstNameError: "",
      lastNameError: "",
      isDeleteModel: false,
      updateUserId: "",
      tabcontent: 1,
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
      newpassword:'',
      conformpassword:'',
      editpassid:'',
      confromPassErr:'',
      newpassErr:''
    };
  }

  componentDidMount() {
    this.fetchUsers();
    this.usersRoles();
  }

  fetchUsers = async () => {
    try {
      this.setState({ loading: true });
      const accessToken = Cookies.get("authToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
      };
      const url = configuration.userManagementListUser;

      axios.get(url, { headers }).then((res) => {
        const superadminUsers = res.data.data.filter(
          (user) => user.role_id === 1
        );

        this.setState({
          users: res.data.data,
          loading: false,
        });
      });
    } catch (error) {
      {
        toastAlert(error.response.data.message, "error");
        this.setState({ loading: false });
      }
    }
  };
  usersRoles = () => {
    this.setState({ loading: true });
    const url = configuration.userManagementUserRole;
    const accessToken = Cookies.get("authToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
    };
    axios
      .get(url, { headers })
      .then((res) => {
        // console.log(res.data.data, "56");
        this.setState({
          userRoles: res.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        toastAlert(error.response.data.message, "error");
      });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleInputChange1 = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleInputChange2 = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validateFields = () => {
    const { lastName, firstName, password, email, userRolesId } = this.state;
    let isError = false;
    const { lang } = this.context;

    if (lastName.trim() === "") {
      this.setState({ lastNameError: lang("please_enter_your_last_name.") });
      isError = true;
    }
    if (firstName.trim() === "") {
      this.setState({ firstNameError: lang("please_enter_your_first_name.") });
      isError = true;
    }

    if (password.trim() === "") {
      this.setState({ passwordError: lang("password_is_required.") });
      isError = true;
    }
    if (email.trim() === "") {
      this.setState({ emailError: lang("email_is_required.") });
      isError = true;
    }
    if (userRolesId.trim() === "") {
      this.setState({ userRolesIdError: lang("user_roll_is_required") });
      isError = true;
    }

    return !isError;
  };
  validateFields1 = () => {
    const { lastName, firstName, password, email, userRolesId } = this.state;
    let isError = false;
    const { lang } = this.context;

    if (lastName.trim() === "") {
      this.setState({ lastNameError: lang("please_enter_your_last_name.") });
      isError = true;
    }
    if (firstName.trim() === "") {
      this.setState({ firstNameError: lang("please_enter_your_first_name.") });
      isError = true;
    }

    if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(email.trim())) {
      this.setState({ emailError: lang("invalid_email_format.") });
      isError = true;
    }

    return !isError;
  };
  validateFields2 = ()=>{
    const {newpassword,conformpassword}=this.state
    let isError = false;
    const { lang } = this.context;
    if (newpassword.trim() === "") {
      this.setState({ newpassErr: ("please_enter_your_new_password") });
      isError = true;
    }
   
    if(conformpassword.trim() === "" || newpassword != conformpassword){
      this.setState({ confromPassErr: ("please_enter_your_confirmpassword") });
      isError = true;
    }
    return !isError;
  }

  handleCreateUser = async () => {
    const isValid = this.validateFields();
    const { lang } = this.context;
    const url = configuration.userManagementCreateUserRole;
    const accessToken = Cookies.get("authToken");
    const userData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email_id: this.state.email,
      password: this.state.password,
      role_id: this.state.userRolesId,
    };
    this.setState({ loading: true });
    const headers = {
      Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
    };
    if (isValid) {
      await axios
        .post(url, userData, { headers: headers })
        .then((response) => {
          if (response.data.success) {
            // console.log(response.data.message)
            const message = response.data.message
              .toLowerCase()
              .replace(/ /g, "_")
              .replace(/\./g, "");
            toastAlert(lang(message), "success");
            this.setState(
              {
                showModal: false,
                loading: false,
              },
              () => this.fetchUsers()
            );
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          toastAlert(error.response.data.message, "error");
        });
    }
  };
  handleNameChange = async (event) => {
    // console.log(event.target.value, "p00");
    this.setState({
      userRolesId: event.target.value,
    });

    // if (this.state.date != "" && event.target.value) {

    //   await this.getCardPdf(this.state.date, event.target.value);
    // }
  };
  handleToggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleToggleShowPassword1 = () => {
    this.setState({ showPassword1: !this.state.showPassword1 });
  };
  handleToggleShowPassword2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 });
  };
  UpdatePassword = async()=>{
    const isValid = this.validateFields2();
    this.setState({loading:true})
    const url = configuration.updatePassword;
    const accessToken = Cookies.get('authToken')
    const headers = {
      'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
    }
    const payload = {
      user_id: this.state.editpassid,
      new_password:this.state.newpassword,
      confirm_password:this.state.conformpassword
    }
    if(isValid){
        await axios.post(url, payload, { headers })
        .then(res=>{
          if (res.data.success) {
            const message = res.data.message
              .toLowerCase()
              .replace(/ /g, "_")
              .replace(/\./g, "");
            toastAlert(message, "success");
            this.setState({ 
              loading: false,
              showEditPassModel:false
             });
          }
        }
        ) .catch((error) => {
          this.setState({
            loading: false,
          });

          toastAlert(error.response.data.message, "error");
        });
    }
    else{
      
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      firstName: "",
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      emailError: "",
      userRolesIdError: "",
      lastName: "",
      email: "",
      password: "",
      userRolesId: "",
      loading: false,
    }));
  };
  toggleModal1 = () => {
    this.setState((prevState) => ({
      showModal1: !prevState.showModal1,
      loading: false,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
    }));
  };
  toggleModal2 = () => {
    this.setState((prevState) => (console.log(prevState,!prevState.showEditPassModel,'prevState'),{
      showEditPassModel: !prevState.showEditPassModel,
      loading: false, 
      confromPassErr:'',
      confromPassErr:''
    }));
  };

  handleDeleteButton = (id) => {
    this.setState({
      isDeleteModel: true,
      deleteclickid: id,
    });
  };
  isDeletecloseModal = () => {
    this.setState({
      isDeleteModel: false,
    });
  };
  handleDeleteButtonClick = () => {
    this.setState({
      loading: true,
    });
    const { lang } = this.context;
    const accessToken = Cookies.get("authToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
    };
    const url = configuration.userManagementdeleteUserRole;
    axios
      .get(`${url}?user_id=${this.state.deleteclickid}`, { headers })
      .then((resp) => {
        // console.log(resp.data.message,resp.data.message.toLowerCase().replace(/ /g, "_"),'message')
        const message = resp.data.message
          .toLowerCase()
          .replace(/ /g, "_")
          .replace(/\./g, "");
        toastAlert(lang(message), "success");

        if (Cookies.get("id") == this.state.deleteclickid) {
          CookiesRemove();
          this.props.router.navigate("/login");
        } else {
          this.setState(
            {
              isDeleteModel: false,
              loading: false,
            },
            () => this.fetchUsers()
          );
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        toastAlert(error.response.data.message, "error");
      });
  };

  handleEditClick1 = (user) => {
    console.log(user, "");
    this.setState({
      showModal1: true,
      firstName: user.first_name == "" ? "-" : user.first_name,
      lastName: user.last_name == "" ? "-" : user.last_name,
      email: user.email,
      updateUserId: user.id,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
    });
  };

  handleEditPassClick = (user)=>{
    console.log(user.id, 'handleEditPassClick');
    this.setState({
      showEditPassModel: true,
      editpassid:user.id,
      newpassword:'',
      conformpassword:'',
      confromPassErr:'',
      newpassErr:''
    })
  }

  helpdeskUpdateUser = async () => {
    const { lang } = this.context;
    const isValid = this.validateFields1();
    const url = configuration.userManagementUpdateUserRole;
    const accessToken = Cookies.get("authToken");
    const userData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email_id: this.state.email,
    };
    this.setState({ loading: true });
    const headers = {
      Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
    };
    if (isValid) {
      await axios
        .post(`${url}?user_id=${this.state.updateUserId}`, userData, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.success) {
            const message = response.data.message
              .toLowerCase()
              .replace(/ /g, "_")
              .replace(/\./g, "");
            toastAlert(lang(message), "success");
            this.setState(
              {
                loading: false,
                showModal1: false,
              },
              () => this.fetchUsers()
            );
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          toastAlert(error.response.data.message, "error");
        });
    }
  };
  tabContent = (id) => {
    this.setState({
      tabcontent: id,
    });
  };

  render() {
    const {
      loading,
      users,
      showModal,
      showModal1,
      showModal2,
      showEditPassModel,
      firstName,
      lastName,
      newpassword,
      conformpassword,
      email,
      password,
      userRoles,
      helpdesk,
      manager,
      superadmin,
      admin,
      reg_noError,
      userRolesIdError,
      emailError,
      passwordError,
      firstNameError,
      lastNameError,
      tabcontent,
      showPassword,
      showPassword1,
      showPassword2,
    } = this.state;
    const { lang, language } = this.context;
    const data = users.filter((user) => user.role_id === tabcontent);
    return (
      <div>
        {loading && (
          <div class="loader-main">
            <Spinner color="light">
              <span></span>
            </Spinner>
          </div>
        )}

        <div className="container-fluid">
          <div className="tab-table-view-wrapeer">
            <div class="row align-items-center mb-3">
              <div class="col-lg-6 col-md-6 col-sm-12"></div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                <button
                  type="button"
                  class="theme_button_color btn red-btn"
                  data-toggle="modal"
                  data-target="#addtrailermodal"
                  onClick={this.toggleModal}
                >
                  {lang("create_user")}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2">
                <div
                  class="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {userRoles.map(
                    (item, index) => (
                      console.log(
                        item,
                        item.name === "superadmin",
                        item.name === "superadmin" ? "super_admin" : item.name,
                        "item1"
                      ),
                      (
                        <a
                          class={`nav-link ${
                            item.id == tabcontent ? "active" : ""
                          }`}
                          id={`v-pills-${item.id}-tab`}
                          data-toggle="pill"
                          href={`#v-pills-${item.id}`}
                          role="tab"
                          aria-controls={`v-pills-${item.id}`}
                          aria-selected="true"
                          onClick={() => this.tabContent(item.id)}
                        >
                          {lang(
                            item.name === "superadmin"
                              ? "super_admin"
                              : item.name && item.name === "Help Desk"
                              ? "help_desk"
                              : item.name
                          )}
                        </a>
                      )
                    )
                  )}
                </div>
              </div>
              <div className="col-lg-10">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade  show active"
                    id={`v-pills-${tabcontent}`}
                    role="tabpanel"
                    aria-labelledby={`v-pills-${tabcontent}-tab`}
                  >
                    <div className="tab-one-content">
                      <div className="tab-table-main">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>{lang("sr._no.")}</th>
                                <th>{lang("roll_name")}</th>
                                <th>{lang("first_name")}</th>
                                <th>{lang("last_name")}</th>
                                <th>{lang("email")}</th>
                                <th>{lang("action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.length != 0 ? (
                                data &&
                                data.map((user, index) => (
                                  <tr key={user.user_id}>
                                    <th>{index + 1}</th>
                                    <th>
                                      {lang(
                                        user.role_name === "superadmin"
                                          ? "super_admin"
                                          : user.role_name &&
                                            user.role_name === "Help Desk"
                                          ? "help_desk"
                                          : user.role_name
                                      )}
                                    </th>
                                    {/* <th>{user.role_name}</th> */}
                                    <td>
                                      {user.first_name != ""
                                        ? user.first_name
                                        : "-"}
                                    </td>
                                    <td>
                                      {" "}
                                      {user.last_name != ""
                                        ? user.last_name
                                        : "-"}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>
                                      <div className="table-actions">
                                        <button
                                          className="btn edit-btn"
                                          onClick={() =>
                                            this.handleEditClick1(user)
                                          }
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                        <button
                                          className="btn setting-btn"
                                          onClick={() =>
                                            this.handleEditPassClick(user)
                                          }
                                        >
                                          <i className="fa fa-wrench"></i>
                                        </button>
                                        <button
                                          className="btn delete-btn"
                                          onClick={() =>
                                            this.handleDeleteButton(user.id)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} style={{textAlign:"center"}}>
                                  <h6>{lang("no_data_found")}</h6>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          isOpen={showModal}
          toggle={this.toggleModal}
          className="appointment-list-modal"
          centered
          backdrop="static"
        >
          <ModalHeader>{lang("create_user")}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <div className="custom-input-group">
                <Label className="form-label">{lang("list_roll")}</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={(event) => this.handleNameChange(event)}
                >
                  <option value={""}>{lang("select_role")} </option>

                  {userRoles.map((item, index) => (
                    // console.log(item, "item1"),
                    <option key={index} value={item.id}>
                      {lang(item.name)}
                    </option>
                  ))}
                </Input>
                <span className="error-message">{userRolesIdError}</span>
              </div>
              <div className="custom-input-group">
                <Label className="form-label">{lang("first_name")}</Label>
                <Input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleInputChange}
                  placeholder={lang("first_name")}
                />
                <span className="error-message">{firstNameError}</span>
              </div>
              <div className="custom-input-group">
                <Label className="form-label">{lang("last_name")}</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleInputChange}
                  placeholder={lang("last_name")}
                />
                <span className="error-message">{lastNameError}</span>
              </div>
              <div className="custom-input-group">
                <Label className="form-label">{lang("email")}</Label>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                  placeholder={lang("email")}
                />
                <span className="error-message">{emailError}</span>
              </div>
              <div className="custom-input-group password-input-main">
                <Label className="form-label">{lang("password")}</Label>
                <div className="password-input-top">
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                    placeholder={lang("password")}
                  />
                  <div className="password-icon" onClick={this.handleToggleShowPassword}>
                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </div>
                </div>
                {/* <div class="eye-icon" onClick={this.handleToggleShowPassword}>
                  <i class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                </div> */}
                <span className="error-message ">{passwordError}</span>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button  className="btn gray-btn user-modal-btn" onClick={this.toggleModal}>
              {lang("cancel")}
            </Button>
            <Button
              color="theme_button_color btn red-btn user-modal-btn"
              onClick={this.handleCreateUser}
            >
              {lang("save")}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isDeleteModel}
          className="appointment-list-modal custom-modal modal-dialog-centered"
          backdrop="static"
        >
          <ModalBody>
            <div className="table-delete-modal">
              <h6 className="booked-appointment-content">
                {lang("are_you_sure_cancel_this_user")}
              </h6>
            </div>
          </ModalBody>
          <ModalFooter className="delete-table-modal-footer">
            <button
              className="btn gray-btn user-modal-btn"
      
              onClick={this.isDeletecloseModal}
            >
              {lang("no")}
            </button>
            <button
              className="btn red-btn user-modal-btn"
    
              onClick={this.handleDeleteButtonClick}
            >
              {lang("yes")}
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          size="md"
          isOpen={showModal1}
          toggle={this.toggleModal1}
          className="appointment-list-modal"
          centered
          backdrop="static"
        >
          <ModalHeader>{lang("update_user")}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <div>
                <Label className="form-label">{lang("first_name")}</Label>
                <Input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleInputChange1}
                  placeholder={lang("first_name")}
                />
                <span className="error-message">{firstNameError}</span>
              </div>
              <div>
                <Label className="form-label">{lang("last_name")}</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleInputChange1}
                  placeholder={lang("last_name")}
                />
                <span className="error-message">{lastNameError}</span>
              </div>
              <div>
                <Label className="form-label">{lang("email")}</Label>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange1}
                  placeholder={lang("email")}
                />
                <span className="error-message">{emailError}</span>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button className="btn gray-btn user-modal-btn" onClick={this.toggleModal1}>
              {lang("cancel")}
            </Button>
            <Button
              color="btn red-btn user-modal-btn"
              onClick={this.helpdeskUpdateUser}
            >
              {lang("update_user")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={showEditPassModel}
          toggle={this.toggleModal2}
          className="appointment-list-modal"
          centered
          backdrop="static"
        >
          <ModalHeader>{lang("reset_password")}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <div>
                <Label className="form-label">{lang("new_password")}</Label>
                <div className="password-input-top">
                <Input
                  type={showPassword1 ? "text" : "password"}
                  name="newpassword"
                  value={newpassword}
                  onChange={this.handleInputChange2}
                  placeholder={lang("new_password")}
                />
                <div className="password-icon" onClick={this.handleToggleShowPassword1}>
                    <i className={showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </div>
                  </div>
                <span className="error-message">{this.state.newpassErr}</span>
              </div>
              <div>
                <Label className="form-label">{lang("confirm_password")}</Label>
                <div className="password-input-top">
                <Input
                  type={showPassword2 ? "text" : "password"}
                  name="conformpassword"
                  value={conformpassword}
                  onChange={this.handleInputChange2}
                  placeholder={lang("confirm_password")}
                />
                <div className="password-icon" onClick={this.handleToggleShowPassword2}>
                    <i className={showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </div>
                  </div>
                <span className="error-message">{this.state.confromPassErr}</span>
              </div>
             
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button className="btn gray-btn user-modal-btn" onClick={this.toggleModal2}>
              {lang("cancel")}
            </Button>
            <Button
              color="btn red-btn user-modal-btn"
              onClick={this.UpdatePassword}
            >
              {lang("update_password")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(HelpdeskUserManagement);
