import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../withRouter';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import Swal from 'sweetalert2';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import user from "../../assets/images/user.svg";
import LanguageContext from '../../context/LanguageProvider';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import CustomerDetailComponent from '../../components/customerDetails/CustomerDetailComponent';
import moment from "moment/moment";
import Cookies from 'js-cookie';
export class CustomerRescheduleDetails extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            email: '',
            emailError: '',
            mobileNumber: '',
            mobileNumberError: '',
            street: '',
            streetError: '',
            houseNumber: '',
            houseNumberError: '',
            country: '',
            countryError: '',
            state: '',
            stateError: '',
            city: '',
            cityError: '',
            postalCode: '',
            postalCodeError: '',
            km_stand: '',
            mileageError: '',
            userData: {},
            uuid: "",
            insurance_client_id: '',
            loading: false,
            isButtonClicked: false,
            isModalOpen: false,
            date: '',
            time: '',
            country_code: '',
            salutation: 'Mr.',
            salutationError: '',
            profession: 'not specified',
            professionError: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }

    handlePhone = (val) => {

        this.setState({
            mobileNumber: val
        })
    }

    validateInputs = () => {
        const { lang } = this.context;
        let firstNameError = '';
        let lastNameError = '';
        let emailError = '';
        let mobileNumberError = '';
        let streetError = '';
        let houseNumberError = '';
        let countryError = '';
        let stateError = '';
        let cityError = '';
        let postalCodeError = '';
        let mileageError = '';

        if (!this.state.firstName.trim()) {
            firstNameError = `${lang("please_enter_your_first_name.")}`;
        }

        if (!this.state.lastName.trim()) {
            lastNameError = `${lang("please_enter_your_last_name.")}`;
        }

        if (!this.state.email.includes('@')) {
            emailError = `${lang("please_enter_your_email_id.")}`;
        }

        if (!this.state.mobileNumber.trim()) {
            mobileNumberError = `${lang("please_enter_your_mobile_number.")}`;
        }

        if (!this.state.street.trim()) {
            streetError = `${lang("please_enter_your_street_name.")}`;
        }

        if (!this.state.houseNumber.trim()) {
            houseNumberError = `${lang("please_enter_your_house_number.")}`;
        }

        if (!this.state.country.trim()) {
            countryError = `${lang("please_enter_your_country.")}`;
        }

        if (!this.state.state.trim()) {
            stateError = `${lang("please_enter_your_state.")}`;
        }

        if (!this.state.city.trim()) {
            cityError = `${lang("please_enter_your_city.")}`;
        }

        if (!this.state.postalCode.trim()) {
            postalCodeError = `${lang("please_enter_your_postal_code.")}`;
        }

        if (!this.state.km_stand.trim()) {
            mileageError = `${lang("please_enter_your_mileage.")}`;
        }

        this.setState({
            firstNameError,
            lastNameError,
            emailError,
            mobileNumberError,
            streetError,
            houseNumberError,
            countryError,
            stateError,
            cityError,
            postalCodeError,
            mileageError
        });

        return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !countryError && !stateError && !cityError && !postalCodeError && !mileageError;
    }

    componentDidMount = async () => {
        await this.getAppointmentData();
    }

    getAppointmentData = () => {
        this.setState({ loading: true });
        let uuid = this.props?.router?.location?.state?.uuid
        // let uuid = false

        if (uuid) {

            const url = configuration.bookAppointment + uuid;
            axios.get(url).then(
                res => {
                    if (res.data.success) {
                        this.setState({
                            userData: res.data.data,
                            firstName: res.data.data.first_name,
                            lastName: res.data.data.last_name,
                            email: res.data.data.email_id,
                            mobileNumber: res.data.data.contact_no,
                            street: res.data.data.street,
                            houseNumber: res.data.data.house_no,
                            country: res.data.data.country,
                            state: res.data.data.state,
                            city: res.data.data.city,
                            postalCode: res.data.data.pincode,
                            km_stand: res.data.data.km_stand,
                            country_code: res.data.data.country_code,
                            insurance_client_id: res.data.data.insurance_client_id,
                            salutation: res.data.data.salutation ? res.data.data.salutation : this.state.salutation,
                            profession: res.data.data.profession ? res.data.data.profession : this.state.profession,
                            loading: false
                        });
                    }
                }
            )
                .catch(error => {
                    this.setState({ loading: false });
                    toastAlert(error.response.data.message, 'error');
                });
        }
    }

    handleNext = async (e) => {
        e.preventDefault();
        const isValid = this.validateInputs();
        this.setState({ isButtonClicked: true });
        if (isValid) {
            this.setState({ isModalOpen: true });
            let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
            // const reg_no = this.props.router.location.state.reg_no
            // const order_id = this.props.router.location.state.order_id
            // const claim_number = this.props.router.location.state.claim_number
            const currentPath = window.location.pathname;
            const isReschedule = currentPath.includes('reschedule');

            // let country_code = "123"
            // let country_code = this.props.router.location.state.country_code

            const is_booking = this.state.userData.is_booking

            if (is_booking) {
                const url = configuration.customerBookingInfo + uuid;
                try {
                    const response = await axios.get(url); //  <- Payload 
                    if (response.data.success) {
                        this.setState({
                            date: response.data.data.date,
                            time: response.data.data.from_time
                        })
                    }
                } catch (error) {
                    toastAlert(error.response.data.message, 'error');
                }
            }
        }
    }


    handleUpdate = (e) => {
        e.preventDefault();
        const isValid = this.validateInputs();
        this.setState({ isButtonClicked: true });
        if (isValid) {
            let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
            const reg_no = this.props.router.location.state.reg_no
            const order_id = this.props.router.location.state.order_id
            const claim_number = this.props.router.location.state.claim_number

            // let country_code = "123"
            let country_code = this.props.router.location.state.country_code

            const url = configuration.updateData;
            const { salutation, profession, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand } = this.state;
            const payLoad =
            {
                'reg_no': reg_no,
                'order_id': order_id,
                'claim_number': claim_number,
                'uuid': uuid,
                'country_code': country_code,
                'first_name': firstName,
                'last_name': lastName,
                'email_id': email,
                'contact_no': mobileNumber,
                'street': street,
                'house_no': houseNumber,
                'country': country,
                'state': state,
                'city': city,
                'pincode': postalCode,
                'km_stand': km_stand,
                'salutation': salutation,
                'profession': profession,
            }
            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        this.props.router.navigate('/customer/location-list', {
                            state: {
                                uuid: uuid,
                                insurance_client_id: this.state.insurance_client_id,
                                insurance_name: this.state.userData.insurance_name,
                                customer_id: this.state.userData.id,
                                first_name: this.state.userData.first_name,
                                last_name: this.state.userData.last_name,
                                reg_no: this.props.router.location.state.reg_no,
                                order_id: this.props.router.location.state.order_id,
                                claim_number: this.state.userData.claim_number,
                                is_booking: this.state.userData.is_booking,
                                country_code: this.state.userData.country_code,
                                // claim_number : this.props.router.location.state.claim_number,
                            }
                        });
                    }
                })
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });

            // this.props.router.navigate('/CustomerLocationList');

        }
    }

    handleToggle = () => {
        const { isModalOpen } = this.state;
        this.setState({
            isModalOpen: !isModalOpen
        });
    }

    render() {
        const { profession, salutation, mobileNumberError, firstNameError, lastNameError, emailError, streetError, houseNumberError, countryError, stateError, cityError, postalCodeError, mileageError, handleUpdate, handleNext, isButtonClicked, userData, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, loading, time, date, country_code } = this.state;
        const uuid = this.props?.router?.location?.state?.uuid;
        const { lang, language } = this.context;
        const dateFormat = Cookies.get('dateFormat')
        return (
            <>
                <CustomerDetailComponent
                    salutation={salutation}
                    profession={profession}
                    mileageError={mileageError}
                    postalCodeError={postalCodeError}
                    stateError={stateError}
                    countryError={countryError}
                    houseNumberError={houseNumberError}
                    streetError={streetError}
                    emailError={emailError}
                    lastNameError={lastNameError}
                    firstNameError={firstNameError}
                    mobileNumberError={mobileNumberError}
                    cityError={cityError}
                    isButtonClicked={isButtonClicked}
                    userData={userData}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    country_code={country_code}
                    mobileNumber={mobileNumber}
                    street={street}
                    houseNumber={houseNumber}
                    country={country}
                    state={state}
                    city={city}
                    postalCode={postalCode}
                    km_stand={km_stand}
                    loading={loading}
                    uuid={uuid}
                    lang={lang}
                    handleUpdate={handleUpdate}
                    handleNext={this.handleNext}
                    handleChange={this.handleChange}
                    handlePhone={this.handlePhone}
                    validateInputs={this.validateInputs}
                />


                <Modal isOpen={this.state.isModalOpen} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    {/* <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader> */}
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang("your_drive-in_appointment_is_booked.")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")}</span><span
                                                    class="small-text-black-16">: </span>
                                                {/* <span class="appointment-date-value small-text-16">{moment(date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span> */}
                                                <span class="appointment-date-value small-text-16">{moment(date).format(dateFormat)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")}</span><span
                                                    class="small-text-black-16">: </span>
                                                <span class="appointment-date-value small-text-16">{time}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16" data-key="Areyousureyouwanttorescheduleyourappointment?">{lang("are_you_sure_you_want_to_reschedule_your_appointment?")}</p>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">

                            <Link to='/customer/location-list' state={{
                                uuid: this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid,
                                insurance_client_id: this.state.insurance_client_id,
                                insurance_name: this.state.userData.insurance_name,
                                customer_id: this.state.userData.id,
                                first_name: this.state.userData.first_name,
                                last_name: this.state.userData.last_name,
                                reg_no: this.props.router.location.state.reg_no,
                                order_id: this.props.router.location.state.order_id,
                                claim_number: this.state.userData.claim_number,
                                // claim_number : this.props.router.location.state.claim_number,
                            }}>
                                <button class="btn red-btn" onClick={this.handleUpdate}>
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("reschedule")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>


            </>

        );
    }
}

export default withRouter(CustomerRescheduleDetails);
