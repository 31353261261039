import React, { Component } from 'react';
import '../customerBasicDetails/customerBasicDetails.css';
import { Link } from 'react-router-dom';
import withRouter from '../../withRouter';
import axios from 'axios';
import { configuration } from '../../appConfig';
import user from "../../assets/images/user.svg";
import LanguageContext from '../../context/LanguageProvider';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import CustomerBasicComponent from '../../components/customerBasicDetails/CustomerBasicComponent';
import toastAlert from '../../helper/ToastAlert/ToastAlert'
import moment from "moment/moment";
import Cookies from 'js-cookie';
export class CustomerBasicDetails extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            reg_no: '',
            insurance_number: '',
            formValidate: '',
            order_id: '',
            userData: {},
            loading: false,
            country_code: '',
            isButtonClicked: false,
            isModalOpen: false,
            date: '',
            time: '',
            isDisableNextButton: 0,
            isTimeReached: false,
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            userData: {
                ...prevState.userData,
                [name]: value
            }
        }));
    }


    handleNext = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        this.setState({ isButtonClicked: true });
        const { reg_no, claim_number, order_id, country_code } = this.state.userData;
        const uuid = this.props.router.params.id
        const { lang } = this.context;
        if (uuid) {
            if (!reg_no) {
                this.setState({ loading: false });
                this.setState({
                    formValidate: {message: lang("please_enter_reg_no.")},
                });
                return;
            }
            else {
                const state = {
                    reg_no: reg_no,
                    claim_number: claim_number,
                    order_id: order_id,
                    country_code: country_code,
                    uuid: this.props.router.params.id,
                }
                this.props.router.navigate('/customer/details', {
                    state: state
                });
            }
            // else{
            //     const url = configuration.updateData;
            //     const userData = this.state.userData

            //     const payLoad = {
            //         'uuid': uuid,
            //         'country_code': userData.country_code,
            //         'first_name': userData.first_name,
            //         'last_name': userData.last_name,
            //         'email_id': userData.email_id,
            //         'contact_no': userData.contact_no,
            //         'street': userData.street,
            //         'house_no': userData.house_no,
            //         'country': userData.country,
            //         'state': userData.state,
            //         'city': userData.city,
            //         'pincode': userData.pincode,
            //         'km_stand': userData.km_stand,
            //         'reg_no': userData.reg_no,
            //         'order_id': userData.order_id,
            //         'claim_number': userData.claim_number,
            //         'salutation': userData.salutation,
            //         'profession': userData.profession,
            //     };
            //     axios.post(url, payLoad)  //  <- Payload 
            //         .then(response => {
            //             if (response.data.success) {
            //                 
            //             }
            //         })
            //         .catch(error => {
            //             this.setState({ loading: false });
            //             toastAlert(error.response.data.message, 'error');
            //         });

            // }
        }
        else {
            if (!reg_no && !claim_number && !order_id) {
                this.setState({ loading: false });
                this.setState({
                    formValidate: {message: lang("please_enter_at_least_one_field.")},
                });
                return;
            } else {
                this.setState({
                    formValidate: ''
                });
                const state = {
                    reg_no: reg_no,
                    claim_number: claim_number,
                    order_id: order_id,
                    country_code: country_code,
                    uuid: this.props.router.params.id,
                }

                this.props.router.navigate('/customer/details', {
                    state: state
                });
            }
        }
    }


    componentDidMount = async () => {
        await this.getAppointmentData();
    }


    getAppointmentData = () => {
        let uuid = this.props.router.params.id;
        // if (uuid !== '') {
        if (uuid) {
            const url = configuration.bookAppointment + uuid;
            axios.get(url).then(
                res => {
                    const is_booking = res.data.data.is_booking;
                    if (res.data.success) {
                        if(res.data.data.is_canceled == 1)
                        {
                            this.props.router.navigate('/404')
                        }
                        else{
                            if (res.data.data.is_over_time_resudule == true) {
                                this.setState({
                                    isTimeReached: true,
                                })
                            }
                            else {
                                this.setState({
                                    userData: res.data.data,
                                    loading: false,
                                    isDisableNextButton: res.data.data.is_booking
                                });
                                if (is_booking === 1) {
                                    this.setState({ isModalOpen: true });
                                    const bookingInfoUrl = configuration.customerBookingInfo + uuid;
                                    axios.get(bookingInfoUrl).then(
                                        response => {
    
                                            this.setState({
                                                date: response.data.data.date,
                                                time: response.data.data.from_time,
                                                isTimeReached: false,
                                            })
                                        }
                                    ).catch(error => {
                                        toastAlert(error.response.data.message, 'error');
                                    });
                                }
                            }
                        }
                    }
                }
            ).catch(error => {
                toastAlert(error.response.data.message, 'error');
            });
        }
    }


    handleKeyDown = (e) => {
        if (e && e.key === 'Enter') {
            this.handleNext(e);
        }
    };

    handleToggle = () => {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen
        }));
    }

    render() {
        const { formValidate, loading, isButtonClicked, date, time, isDisableNextButton } = this.state;
        const { userData, country_code } = this.state;
        const { lang , language } = this.context;
        let uuid = this.props.router.params.id;
        const dateFormat = Cookies.get('dateFormat') 

        return (
            <>
                <CustomerBasicComponent country_code={country_code} userData={userData} lang={lang} formValidate={formValidate} loading={loading} isButtonClicked={isButtonClicked} handleNext={this.handleNext} handleKeyDown={this.handleKeyDown} handleChange={this.handleChange} isDisableNextButton={isDisableNextButton} />
                <Modal size="md" isOpen={this.state.isModalOpen} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader>
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang("your_drive-in_appointment_is_booked.")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")}</span><span
                                                    class="small-text-black-16">: </span>
                                                {/* <span class="appointment-date-value small-text-16">{moment(date).format(['DE', 'CH-DE','SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span> */}
                                                <span class="appointment-date-value small-text-16">{moment(date).format(dateFormat)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")}</span><span
                                                    class="small-text-black-16">: </span>
                                                <span class="appointment-date-value small-text-16">{time}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16">{lang("are_you_sure_you_want_to_reschedule_your_appointment?")}</p>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            {/* <button class="btn gray-btn" data-dismiss="modal">
                                <span><i class="fa fa-trash"></i></span>
                                <span data-key="Delete">Delete</span>
                            </button> */}
                            <Link to={`/appointment/reschedule/${uuid}`} state={{
                                // uuid: this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid,
                                // insurance_client_id: this.state.insurance_client_id,
                                // insurance_name: this.state.userData.insurance_name,
                                // customer_id: this.state.userData.id,
                                // first_name: this.state.userData.first_name,
                                // last_name: this.state.userData.last_name,
                                // reg_no: this.props.router.location.state.reg_no,
                                // order_id: this.props.router.location.state.order_id,
                                // claim_number: this.state.userData.claim_number,
                                // claim_number : this.props.router.location.state.claim_number,
                            }}>
                                <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("reschedule")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.isTimeReached} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    {/* <ModalHeader
                        // toggle={() => this.handleToggle()}
                        // close={
                            // <button type="button" className="close" onClick={() => this.handleToggle()}>
                            //     <span aria-hidden="true">&times;</span>
                            // </button>
                        // }
                    >
                    </ModalHeader> */}
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang("time_limit_reached_can_not_reschedule_before_24_hours.")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")} </span> <span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16">{date}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")} </span><span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16">{time}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    {/* <p class="small-text-16" data-key="Areyousureyouwanttorescheduleyourappointment?">{lang("you_already_reschedule_your_appointment_please_contact_help_desk")}</p> */}
                                    <p class="small-text-16">{lang("please_contact_help_desk.")}</p>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            {/* <button class="btn gray-btn" data-dismiss="modal">
                                <span><i class="fa fa-trash"></i></span>
                                <span data-key="Delete">Delete</span>
                            </button> */}

                            {/* <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">Help Desk</span>
                                </button> */}
                            <div class="modal-bottom-text">
                                <h6 class="small-text-red-16">{lang("contact")}: 031 389 8960</h6>
                            </div>
                            {/* </Link> */}
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(CustomerBasicDetails);
