import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LanguageContext from '../../context/LanguageProvider';
import logo from "../../assets/images/adi-logo.png";
import savalogo from "../../assets/images/sava-logo.jpg";
import menu from "../../assets/images/menu.png";
import profile from "../../assets/images/profile-img.png.svg";
import './HelpDeskHeader.css';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import Cookies from 'js-cookie';
import withRouter from '../../withRouter';
import de from "../../assets/images/de.png"
import en from "../../assets/images/en.png"
import sl from "../../assets/images/sl.png"
import { CookiesRemove } from '../../CookiesRemove';

let langArr = [
    {
        icon: de,
        lang: "DE"
    },
    {
        icon: en,
        lang: "US"
    },
    {
        icon: sl,
        lang: "SL-SL"
    }
]
export class HelpDeskHeader extends Component {
    static contextType = LanguageContext;

    handleClick = (lang) => {
        const { userLanguageChange } = this.context;
        userLanguageChange(lang);
    }

    handleLogout = () => {
        let url = configuration.helpDeskLogout
        const accessToken = localStorage.getItem('access_token');
        // const accessToken = Cookies.get('authToken');
        /*  console.log("accessTokenC + ",accessTokenC); */
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
            }
        })

            .then(
                res => {
                    if (res.data.success) {
                        CookiesRemove();
                        toastAlert('Logout Successfully', 'success');
                        this.props.router.navigate('/login');
                        // Cookies.remove('id');
                        // Cookies.remove('globalUserId');
                        // Cookies.remove('isAuthenticated');
                        // Cookies.remove('globalOrganizationId');
                        // Cookies.remove('name');
                        // Cookies.remove('organizationName');
                        // Cookies.remove('organizationCountry');
                        // Cookies.remove('regionName');
                        // Cookies.remove('roleId');
                        // Cookies.remove('isLoggedIn');
                        // Cookies.remove('authToken');
                        // Cookies.remove('uuid');
                        // Cookies.remove('userData');
                        // Cookies.remove('organizationCountry');
                        // Cookies.remove('organization_country');
                        // Cookies.remove('role_name');
                        // // Cookies.remove('clientLogo');
                        // Cookies.remove('clientTypeSetting');
                        // localStorage.removeItem('access_token');
                        // var element = document.getElementsByTagName("html")[0];
                        // element.classList.remove("otherclass");
                    }
                }
            )
            .catch(error => {
                CookiesRemove();
                this.props.router.navigate('/login');
                toastAlert(error.response.data.message, 'error');
                // Cookies.remove('id');
                // Cookies.remove('globalUserId');
                // Cookies.remove('isAuthenticated');
                // Cookies.remove('globalOrganizationId');
                // Cookies.remove('name');
                // Cookies.remove('organizationName');
                // Cookies.remove('organizationCountry');
                // Cookies.remove('regionName');
                // Cookies.remove('roleId');
                // Cookies.remove('isLoggedIn');
                // Cookies.remove('organization_country');
                // Cookies.remove('authToken');
                // Cookies.remove('uuid');
                // Cookies.remove('userData');
                // Cookies.remove('organizationCountry');
                // Cookies.remove('role_name');
                // // Cookies.remove('clientLogo');
                // Cookies.remove('clientTypeSetting');
                // localStorage.removeItem('access_token');
                // var element = document.getElementsByTagName("html")[0];
                // element.classList.remove("otherclass");
            });
    }

    // handleSetting = () => {
    //     let url = configuration.setting
    //     // const accessToken = Cookies.get('authToken');
    //     /*  console.log("accessTokenC + ",accessTokenC); */
    //     axios.get(url)

    //         .then(
    //             res => {
    //                 if (res.data.success) {

    //                 }
    //             }
    //         )
    //         .catch(error => {
    //             toastAlert(error.response.data.message, 'error');
    //         });
    // }

    render() {
        const { lang, language } = this.context;
        const name = Cookies.get('name');
        const role = Cookies.get('role_name');
        return (
            <header class="header-main">
                <div class="container-fluid">
                    <nav class="navbar">
                        <div class="header-logo-menu-main space">
                            <div class="header-logo">
                                <Link to="/helpdesk/options">
                                    <a class="navbar-brand">
                                        <img src={savalogo} alt="Sava Logo" />
                                    </a>
                                </Link>
                            </div>
                            <button class="navbar-toggler header-menu-button" type="button">
                                <img src={menu} alt="Menu" />
                            </button>
                        </div>
                        <div class="header-drawer-wrapper">
                            <div class="header-drawer-main">
                                <div class="drawer-close-btn">
                                    <button class="btn closeHeaderDrawer">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <div class="drawer-links">
                                    <ul>
                                        <li class="active-drawer-link">
                                            <Link to="/helpdesk/options">
                                                <span class="drawer-icon"><i class="fa fa-home"></i></span>
                                                <span class="drawer-link-name">{lang("home")}</span>
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <span class="drawer-icon"><i class="fa fa-comment"></i></span>
                                            <span class="drawer-link-name">{lang("message")}</span>
                                        </li> */}
                                        {/* <li onClick={this.handleSetting}>
                                            <span class="drawer-icon"><i class="fa fa-cog"></i></span>
                                            <span class="drawer-link-name">{lang("settings")}</span>
                                        </li> */}
                                        <li>
                                            <div class="dropdown drawer-language-select">
                                                <a class="btn  dropdown-toggle" role="button" data-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span class="drawer-icon"><i class="fa fa-globe"></i></span> <span
                                                        class="drawer-link-name">{language}</span>
                                                </a>
                                                <div class="dropdown-menu">
                                                    {langArr?.map((langObj) => {
                                                        console.log(langObj.icon, "langObj")
                                                        return <a class="dropdown-item" onClick={() => this.handleClick(langObj.lang)}>
                                                            <div class="language-icon-main">
                                                                <span class="language-img">
                                                                    {/* <img src={`../../assets/images/${langObj?.icon}.png`} alt={langObj?.lang} /> */}
                                                                    <img src={langObj.icon} alt={langObj?.lang} />
                                                                </span>
                                                                <span class="drawer-link-name">{langObj?.lang}</span>
                                                            </div>
                                                        </a>
                                                    })}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="drawer-logout-btn">
                                    <div class="drawer-profile-main">
                                        <div class="profile-img">
                                            <img src={profile} alt="Profile" />
                                        </div>
                                        <div class="profile-name">
                                            <h6 class="small-title-18">{name}</h6>
                                            <p class="small-text-16">{lang(`${role}`)}</p>

                                        </div>
                                    </div>
                                    <button class="btn red-btn" onClick={() => this.handleLogout()}>
                                        <span>
                                            {lang('logout')}
                                        </span>
                                        <span>
                                            <i class="fa fa-power-off"></i>
                                        </span>

                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="drawer-overlay"></div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default withRouter(HelpDeskHeader);
