import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { configuration, url } from '../../appConfig';
import withRouter from '../../withRouter';
import axios from 'axios';
import moment from "moment/moment";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import calendarSvg from "../../assets/images/calendar.svg";
import bookedCheck from "../../assets/images/booked-check.svg";
import LanguageContext from "../../context/LanguageProvider";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Spinner } from 'reactstrap';
import '../calendar/CalendarComponent.css';
import Cookies from 'js-cookie';
export class CalendarComponent extends Component {
    static contextType = LanguageContext;

    render() {
        const { language, lang } = this.context
        const {
            allSlot,
            days,
            headerTotalSlot,
            sortedTimeArray,
            slots,
            startOfWeek,
            firstName,
            lastName,
            email,
            mobileNumber,
            street,
            houseNumber,
            state,
            city,
            postalCode,
            km_stand,
            userData,
            confirmEdit,
            // modal states
            isOpenAppointmentListModal,
            appointmentDay,
            appointmentSlotTime,
            isOpenConfirmDetailsModal,
            selectedAppointmentTime,
            isOpenConfirmAppointmentModal,
            isOpenAppointmentSuccessModal,
            loading,
            buttonClicked,
            isPreviousDisable,
            isNextDisable,
            getCountOnNext,
            is_email,
            is_sms,
            is_print_pdf,
            handleToggle,
            closeModal,
            getCountOnPrevious,
            handleConfirmDetailsModal,
            handleConfirmSubmit,
            checkBox,
            clickPrevious,
            clickNext,
            handleEdit,
            handleChange,
            handleKeyDown,
            bookAppointment,
            phoneInput,
            country_code,
            viewPdf,
            profession,
            countOnClick,
            isButtonClicked,
            salutation,
            selectedAppointmentDate,
            checkBoxPdf,
            cityError, countryError, houseNumberError, streetError, mobileNumberError, emailError, lastNameError, firstNameError, postalCodeError, mileageError, message
        } = this.props
        console.log(mobileNumber,'mobileNumber')
        const country = this.props.country
        let organizationCountry = Cookies.get('organizationCountry')
        const countryCodeObj = {
            "Switzerland": "ch",
            "Germany": "de",
            "India": "in",
            'Slovenia': 'si'
        };
        const checkEmail = is_email == true;
        const checkSms = is_sms == true;
        const checkPdf = is_print_pdf == true;
        const dateFormat = Cookies.get('dateFormat')
        const defaultCountry = organizationCountry ? countryCodeObj[organizationCountry] : countryCodeObj[country];
        const oldFrom_date = this?.props?.router?.location?.state?.from_date;
        const from_date = moment(oldFrom_date).format(dateFormat);
        // const from_date = moment(oldFrom_date).format(['DE', 'CH-DE','SL-SL'].includes(language) ? 'DD/MM/YYYY' : 'DD.MM.YYYY');
        const oldto_date = this.props?.router?.location?.state?.to_date;
        const to_date = moment(oldto_date).format(dateFormat);
        // const to_date = moment(oldto_date).format(['DE', 'CH-DE','SL-SL'].includes(language) ? 'DD/MM/YYYY' : 'DD.MM.YYYY');
        const currentDate = new Date();
        const currentDayIndex = currentDate.getDay();
        const insurance_name = this.props?.router?.location?.state?.insurance_name;
        const claim_number = this.props?.router?.location?.state?.claim_number;
        const first_name = this.props?.router?.location?.state?.first_name;
        const last_name = this.props?.router?.location?.state?.last_name;
        const reg_no = this.props?.router?.location?.state?.reg_no;
        // const url = "/customer/calendar";
        const pathname = this.props?.router?.location?.pathname;
        const helpDeskPage = pathname && pathname.startsWith('/helpdesk')
        const url = pathname && pathname.startsWith('/customer/calendar')
        const authToken = localStorage.getItem('authToken');
        const isLoggedIn = Cookies.get('isLoggedIn');

     
        
        return (
            <>
                <main class="main-content calender-content ">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="calendar-wrapper">
                        <div class="container-fluid">
                            {/* <div class="calendar-header">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <div class="calendar-date-and-back-btn">
                                            <div class="calendar-date">
                                                <div class="next-prev-calendar">
                                                    <a class="calendar-prev cal-btn btn icon-btn" onClick={() => this.clickPrevious()}>
                                                        <i class="fa fa-long-arrow-left"></i>
                                                    </a>
                                                    <div class="calendar-date-text">
                                                        <h4 class="small-title-18">{from_date} - {to_date}</h4>
                                                    </div>
                                                    <a class="calendar-next cal-btn icon-btn btn" onClick={() => this.clickNext()}>
                                                        <i class="fa fa-long-arrow-right"></i>
                                                    </a>
                                                </div>
                                                <div class="calender-insurence-client">
                                                    <span class="small-text-black-16 " data-key="InsuranceClient">
                                                        {lang("insurance_client")}
                                                    </span>
                                                    <span class="small-text-black-16-bold">
                                                        :
                                                    </span>&nbsp;
                                                    <span class="small-text-red-16">
                                                        {insurance_name}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="calendar-header">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 calendar-25-col">
                                        <div class="calendar-date-and-back-btn">
                                            <div class="calendar-date">
                                                {url ? (
                                                    <div class="next-prev-calendar">
                                                        <a class="calendar-prev cal-btn btn icon-btn" onClick={() => { if (!isPreviousDisable) { getCountOnPrevious() } }}>
                                                            <i class="fa fa-long-arrow-left"></i>
                                                        </a>
                                                        <div class="calendar-date-text">
                                                            <h4 class="small-title-18">{from_date} - {to_date}</h4>
                                                        </div>
                                                        <a class="calendar-next cal-btn icon-btn btn" onClick={() => { if (!isNextDisable) { getCountOnNext() } }}>
                                                            <i class="fa fa-long-arrow-right"></i>
                                                        </a>
                                                    </div>
                                                ) : null}
                                                {/* <div class="calender-insurence-client">
                                                    <span class="small-text-black-16 " data-key="InsuranceClient">
                                                        {lang("insurance_client")}
                                                    </span>
                                                    <span class="small-text-black-16-bold">
                                                        :
                                                    </span>&nbsp;
                                                    <span class="small-text-red-16">
                                                        {insurance_name}
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {url ? (
                                        <div className="col-12 mt-3">
                                            <div className="row calendar-details-row">
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <div className="calender-insurence-client">
                                                        <span className="small-text-black-14" data-key="CustomerName">
                                                            {lang("customer_name")}
                                                        </span>
                                                        <span className="small-text-black-14-bold">:</span>
                                                        &nbsp;
                                                        <span className="small-text-red-14">
                                                            {first_name} {last_name}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <div className="calender-insurence-client">
                                                        <span className="small-text-black-14" data-key="InsuranceClient">
                                                            {lang("insurance_client")}
                                                        </span>
                                                        <span className="small-text-black-14-bold">:</span>
                                                        &nbsp;
                                                        <span className="small-text-red-14">{insurance_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <div className="calender-insurence-client">
                                                        <span className="small-text-black-14" data-key="Insurancenumber">
                                                            {lang("insurance_number")}
                                                        </span>
                                                        <span className="small-text-black-14-bold">:</span>
                                                        &nbsp;
                                                        <span className="small-text-red-14">{claim_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                                    <div className="calender-insurence-client">
                                                        <span className="small-text-black-14" data-key="RegistrationPlate">
                                                            {lang("registration_plate")}
                                                        </span>
                                                        <span className="small-text-black-14-bold">:</span>
                                                        &nbsp;
                                                        <span className="small-text-red-14">{reg_no}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div class="calendar-view">
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active week-calendar-top" id="pills-week" role="tabpanel" aria-labelledby="pills-week-tab">
                                        <div class="calender-table-main week-view-calendar">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            {days.map((day, i) => {
                                                                // console.log(startOfWeek,"startOfWeek")
                                                                const date = startOfWeek && moment(startOfWeek).add(i, 'days').format(dateFormat);
                                                               
                                                                {/* const date = startOfWeek && moment(startOfWeek).add(i, 'days').format(['DE', 'CH-DE','SL-SL'].includes(language) ? 'DD/MM/YYYY' : 'DD.MM.YYYY'); */ }
                                                                return (
                                                                    <th scope="col" className={`${headerTotalSlot[day]?.Date ? 'active-table-th' : 'table-th'}`}>
                                                                        <span class="small-text-red-16">
                                                                            {date}
                                                                        </span>
                                                                        <div className="day-name">{lang(day?.toLowerCase())}</div>
                                                                        <div className="day-slot">
                                                                            <span>({headerTotalSlot[day]?.available_slot || '00'}</span>
                                                                            <span> / </span>
                                                                            <span>{headerTotalSlot[day]?.total_slot || '00'} )</span>
                                                                        </div>
                                                                    </th>
                                                                );
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sortedTimeArray?.map((key, i) => {
                                                          
                                                            const sortOrNot = countOnClick != 0 ? Object.keys(slots[key]).sort() : Object.keys(slots[key])
                                                           
                                                            return <>
                                                                <tr class="empty-space-tr"></tr>
                                                                <tr>
                                                                    <th scope="row">{key}</th>
                                                                    {sortOrNot.map((slotKey, idx) => {
                                                                       
                                                                       const convertedValue = slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.break_name;
                                                                        const split = convertedValue ? convertedValue.split(" ") : '';
                                                                        const data = split[0] + '_' + split[1];
                                                                        // console.log(split[0],split[1],'PPPP')
                                                                        const breakName = data.toLocaleLowerCase();
                                                                        const date = new Date();
                                                                        // const currentDate= date.toLocaleDateString('en-GB').split('/').reverse().join('-')
                                                                        const slotDate = slots[key][slotKey][0]?.sub_slots?.date
                                                                        const SlotTime = slots[key][slotKey][0]?.sub_slots?.start_time
                                                                    //    console.log(slotDate,SlotTime,'SlotTime')
                                                                        const newSlotDate = `${slotDate}`+' '+`${SlotTime}`;
                                                                        // console.log(newSlotDate,'newSlotDate')
                                                                        const currentTime = moment().format('YYYY-MM-DD H:mm');
                                                                       console.log(currentTime,"currentTime")
                                                                        console.log(newSlotDate,"newSlotDate") 
                                                                        let isTimeDisable = false;
                                                                        // newSlotDate && console.log(newSlotDate , currentTime,moment(currentTime,'YYYY-MM-DD HH:mm').isAfter(moment(newSlotDate, 'YYYY-MM-DD HH:mm')), newSlotDate < currentTime, 'line 300')
                                                                        // if(newSlotDate < currentTime){
                                                                        if(moment(currentTime,'YYYY-MM-DD HH:mm').isAfter(moment(newSlotDate, 'YYYY-MM-DD HH:mm'))){
                                                                            isTimeDisable = true;
                                                                        }
                                                                        {/* console.log('isTimeDisable ::'+isTimeDisable)
                                                                        console.log(SlotTime,"SlotTime")
                                                                        console.log((slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.is_disable &&isTimeDisable) ,"slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.is_disable)?'booking-tag booking-tag-disable':'booking-tag'") */}
                                                                        
                                                                            {/* console.log(currentDate==slotDate,"truefalse") */}
                                                                        return (
                                                                            <>
                                                                                <td class={`${slots[key][slotKey][0] ? '' : 'disabled-td'}`}>
                                                                                    {slots[key][slotKey][0] ? 
                                                                                    (
                                                                                        slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.is_break == true ? (
                                                                                            lang(breakName)
                                                                                        ) : (
                                                                                            (isTimeDisable == true)?(<div className={'booking-tag booking-tag-disable'} 
                                                                                            >

                                                                                            
                                                                                                <div class="booking-type">
                                                                                                    <p class="small-text-black-16">{lang("free")} -</p>
                                                                                                    
                                                                                                </div>
                                                                                               
                                                                                                <div class="booking-value">
                                                                                                    <span>{slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.available_slot}</span> <span> / </span>
                                                                                                    <span>{slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.total_slot}</span>
                                                                                                </div>
                                                                                            </div> ):(<div className={'booking-tag'}  onClick={() => {
                                                                                               handleToggle('isOpenAppointmentListModal', key, slotKey)
                                                                                            }}>
                                                                                                <div class="booking-type">
                                                                                                    <p class="small-text-black-16">{lang("free")} -</p>
                                                                                                    
                                                                                                </div>
                                                                                               
                                                                                                <div class="booking-value">
                                                                                                    <span>{slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.available_slot}</span> <span> / </span>
                                                                                                    <span>{slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.total_slot}</span>
                                                                                                </div>
                                                                                            </div>)
                                                                                        )
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </td>
                                                                            </>
                                                                        );
                                                                    })}

                                                                </tr>
                                                            </>

                                                        })}
                                                    </tbody>
                                                </table>

                                                {/* Appointment List modal */}
                                                <Modal size="lg" isOpen={isOpenAppointmentListModal} className="appointment-list-modal" centered >
                                                    <ModalHeader
                                                        toggle={() => closeModal('isOpenAppointmentListModal')}
                                                        close={
                                                            <button type="button" className="close" onClick={() => closeModal('isOpenAppointmentListModal')}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        }
                                                    >
                                                        <h5>
                                                            <span class="modal-title-img">
                                                                <img src={calendarSvg} alt="Calendar" />
                                                            </span>
                                                            <span class="modal-title-20">{lang('appointment_list')}</span>
                                                        </h5>
                                                    </ModalHeader>
                                                    <ModalBody className="appointment-list-content">
                                                        <div class="row">
                                                            {appointmentDay && appointmentSlotTime && slots[appointmentSlotTime][appointmentDay] && slots[appointmentSlotTime][appointmentDay][0]?.sub_slots?.time_slot.map((obj, index) => {
                                                                return <>
                                                                    <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                                                                        <div class="appointment-content-top">
                                                                            <div class="appointment-span">
                                                                                <p>{obj.from_time}</p>
                                                                            </div>
                                                                            <div class="appointment-type-main">
                                                                                <p className={`${!obj.is_disable?`appointment-type ${obj.is_booked ? "booked-appointmentList" : "free-appointment"}`:'appointment-type booking-tag-disable'}`}
                                                                                    onClick={() => !obj.is_booked &&!obj.is_disable&& handleConfirmDetailsModal(obj.from_time, obj.appointment_id)}
                                                                                    disabled={obj.is_booked ? true : false}
                                                                                >
                                                                                    {obj.is_booked ? lang("booked") : lang("free")}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            })}
                                                        </div>
                                                    </ModalBody>
                                                </Modal>

                                                {/* Confirm Customer Details modal */}
                                                <Modal size="lg" isOpen={isOpenConfirmDetailsModal} modalClassName="custom-modal customer-details-modal" centered scrollable>
                                                    <ModalHeader
                                                        toggle={() => closeModal('isOpenConfirmDetailsModal')}
                                                        close={
                                                            <div>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal('isOpenConfirmDetailsModal')}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                                <button type="button" className="close" onClick={handleEdit}>
                                                                    <i class="fa fa-edit modal-edit"></i>
                                                                </button>
                                                            </div>
                                                        }
                                                    >
                                                        <h5 class="modal-title" id="customerDetailsLabel">
                                                            <span class="modal-title-img">
                                                                <img src={calendarSvg} alt="Calendar" />
                                                            </span>
                                                            <span class="modal-title-20">{lang("confirm_your_details")} </span>
                                                        </h5>
                                                    </ModalHeader>
                                                    <ModalBody >
                                                        <div class="confirm-details-content">
                                                            <form action="">
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="input-group custom-input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text" id="basic-addon1">
                                                                                    <i class="fi fi-calendar"></i>
                                                                                </span>
                                                                            </div>
                                                                            {/* <input type="text" class="form-control" placeholder="DD.MM.YY" value={selectedAppointmentDate ? selectedAppointmentDate : appointmentDay && appointmentSlotTime && moment(slots[appointmentSlotTime][appointmentDay][0]?.Date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} id="datepicker-modal" data-key="Date" disabled /> */}
                                                                            <input type="text" class="form-control" placeholder="DD.MM.YY" value={selectedAppointmentDate ? selectedAppointmentDate : appointmentDay && appointmentSlotTime &&  moment(slots?.[appointmentSlotTime]?.[appointmentDay]?.[0]?.Date).format(dateFormat)} id="datepicker-modal" data-key="Date" disabled />
                                                                            <span class="error-message"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="input-group custom-input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text" id="basic-addon1">
                                                                                    <i class="fi fi-clock"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input type="text" id="start_time" class="form-control" name="start_time" value={selectedAppointmentTime} placeholder="Time" autoComplete="off" data-key="Time" disabled />
                                                                            <span class="error-message"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="customer-other-details">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("insurance_number")} <span class="important-label">*</span>
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_insurance_number")} value={userData?.claim_number} disabled />
                                                                                </div>
                                                                                <span class="error-message"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("order_id")} {lang(`${message}`)}<span class="important-label">*</span>
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_order_id")} value={userData?.order_id} disabled />
                                                                                </div>
                                                                                <span class="error-message"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("registration_plate")} <span class="important-label">*</span>
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_registration_plate")} name="reg_no" value={userData?.reg_no} onChange={handleChange} disabled />
                                                                                </div>
                                                                                <span class="error-message"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang('mileage')}
                                                                                     {/* <span class="important-label">*</span> */}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="number" class="form-control" placeholder={lang("enter_mileage")} onKeyDown={this.handleKeyDown} name="km_stand" value={km_stand} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {/* {mileageError && (<span class="error-message">{mileageError}</span>)} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group custom-select-top">
                                                                                <label class="form-label">{lang("salutation")}</label>
                                                                                <div class="form-control-top">
                                                                                    <select class="form-control" id="exampleFormControlSelect1" name='salutation' value={salutation} onChange={handleChange} disabled={!confirmEdit}>
                                                                                        <option value="Please select">{lang("please_select")}</option>
                                                                                        <option value="Ms.">{lang("ms.")}</option>
                                                                                        <option value="Mr.">{lang("mr.")}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group custom-select-top">
                                                                                <label class="form-label">{lang("title")}</label>
                                                                                <div class="form-control-top">
                                                                                    <select class="form-control" id="exampleFormControlSelect1" name='profession' value={profession} onChange={handleChange} disabled={!confirmEdit}>
                                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                                        <option value="Dr.">{lang("dr.")}</option>
                                                                                        <option value="Prof.">{lang("prof.")}</option>
                                                                                        <option value="Prof. Dr.">{lang("prof._dr.")}</option>
                                                                                    </select>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("first_name")} <span class="important-label">*</span>
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_first_name")} name="firstName" value={firstName} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {firstNameError && (<span class="error-message">{firstNameError}</span>)}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("last_name")} <span class="important-label">*</span>
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_last_name")} name="lastName" value={lastName} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {lastNameError && (<span class="error-message">{lastNameError}</span>)}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("email")}{" "}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="email" class="form-control" placeholder={lang('enter_email_id')} name="email" value={email} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {emailError && (<span class="error-message">{emailError}</span>)}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group mobile-input-group">
                                                                                <label for="" class="form-label">{lang("mobile_number")}</label>
                                                                                <div class="form-control-top mobile-input">
                                                                                    {/* <PhoneInput
                                                                                        inputClass="form-control"
                                                                                        containerClass="phone-input-container"
                                                                                        buttonClass="btn phone-input-btn"
                                                                                        country={'us'}
                                                                                        placeholder={lang("mobile_number")}
                                                                                        onChange={(e) => handleChange(e, 'mobileNumber')}
                                                                                        value={`${mobileNumber}`}
                                                                                        name="mobileNumber"
                                                                                        autoFormat={false}
                                                                                        // ref={ref => {
                                                                                        //     phoneInput = ref;
                                                                                        // }}
                                                                                        disabled={!confirmEdit}
                                                                                    /> */}
                                                                                    {isLoggedIn == 'true'
                                                                                        ?
                                                                                        <PhoneInput
                                                                                            disabled={!confirmEdit}
                                                                                            inputClass="form-control"
                                                                                            placeholder={lang("mobile_number")}
                                                                                            name="mobileNumber"
                                                                                            // containerClass="phone-input-container"
                                                                                            buttonClass="btn phone-input-btn"
                                                                                            // country={!mobileNumber ? defaultCountry : ""}
                                                                                            country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                                            // value={`${mobileNumber}`}
                                                                                            value={mobileNumber?`${mobileNumber}`:''}
                                                                                            onChange={(e) => handleChange(e, 'mobileNumber')}
                                                                                            // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                                            addInternationalOption={false}
                                                                                            // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                                            defaultErrorMessage='Please enter valid contact number'
                                                                                        />
                                                                                        :
                                                                                        <PhoneInput
                                                                                            disabled={!confirmEdit}
                                                                                            inputClass="form-control"
                                                                                            placeholder={lang("mobile_number")}
                                                                                            name="mobileNumber"
                                                                                            // containerClass="phone-input-container"
                                                                                            buttonClass="btn phone-input-btn"
                                                                                            country={!mobileNumber ? defaultCountry : ""}
                                                                                            // country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                                            value={mobileNumber?`${mobileNumber}`:''}
                                                                                            onChange={(e) => handleChange(e, 'mobileNumber')}
                                                                                            // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                                            addInternationalOption={false}
                                                                                            // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                                            defaultErrorMessage='Please enter valid contact number'
                                                                                        />
                                                                                    }

                                                                                    {/* <PhoneInput
                                                                                        disabled={!confirmEdit}
                                                                                        inputClass="form-control"
                                                                                        placeholder={lang("mobile_number")}
                                                                                        name="mobileNumber"
                                                                                        // containerClass="phone-input-container"
                                                                                        buttonClass="btn phone-input-btn"
                                                                                        country={!mobileNumber ? defaultCountry : ""}
                                                                                        // country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                                        value={`${mobileNumber}`}
                                                                                        onChange={(e) => handleChange(e, 'mobileNumber')}
                                                                                        // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                                        addInternationalOption={false}
                                                                                        // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                                        defaultErrorMessage='Please enter valid contact number'
                                                                                    /> */}
                                                                                    {/* {mobileNumberError && (
                                                                                        <span class="error-message">{mobileNumberError}</span>
                                                                                    )} */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("street")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_your_street_name")} name="street" value={street} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {/* {streetError && (<span class="error-message">{streetError}</span>)} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("house_number")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_your_house_number")} name="houseNumber" value={houseNumber} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {/* {houseNumberError && (<span class="error-message">{houseNumberError}</span>)} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("postal_code")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="number" class="form-control" placeholder={lang("enter_postal_code")} name="postalCode" value={postalCode} onKeyDown={this.handleKeyDown}  onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {/* {postalCodeError && (<span class="error-message">{postalCodeError}</span>)} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("city")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang('enter_city')} name="city" value={city} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div>
                                                                                {/* {cityError && (<span class="error-message">{cityError}</span>)} */}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("state")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_state")} name="state" value={state} onChange={handleChange} disabled={!confirmEdit} />
                                                                                </div> */}
                                                                                {/* {stateError && (<span class="error-message">{stateError}</span>)} */}
                                                                            {/* </div>
                                                                        </div> */}
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="input-group custom-input-group">
                                                                                <label for="" class="form-label">
                                                                                    {lang("country")}
                                                                                </label>
                                                                                <div class="form-control-top">
                                                                                    <input type="text" class="form-control" placeholder={lang("enter_country")} name="country" value={country} onChange={handleChange} readOnly />
                                                                                </div>
                                                                                {countryError && (<span class="error-message">{countryError}</span>)}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <div className="modal-button">
                                                            <button
                                                                className="btn red-btn"
                                                                data-toggle="modal"
                                                                onClick={handleConfirmSubmit}
                                                            >
                                                                <span>
                                                                    <i className="fi fi-check-mark-circle"></i>
                                                                </span>
                                                                <span>{lang(confirmEdit ? 'confirm_&_submit' : 'confirm')}</span>
                                                            </button>
                                                        </div>
                                                    </ModalFooter>
                                                </Modal>

                                                {/* Confirm Appointment modal */}
                                                <Modal size="lg" isOpen={isOpenConfirmAppointmentModal} className="appointment-list-modal confirm-appointment" centered >
                                                    <ModalHeader
                                                        toggle={() => closeModal('isOpenConfirmAppointmentModal')}
                                                        close={
                                                            <button type="button" className="close" onClick={() => closeModal('isOpenConfirmAppointmentModal')}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        }
                                                    >
                                                    </ModalHeader>
                                                    <ModalBody className="appointment-list-content">
                                                        <div class="confirm-appointment-content">
                                                            <div class="confirm-modal-date-time-main">
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="data-time-icon">
                                                                            <button class="search-btn btn icon-btn">
                                                                                <i class="fi fi-calendar"></i>
                                                                            </button>
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("date")}<span class="small-text-black-16">:</span>&nbsp;
                                                                                </span>
                                                                                {/* <span class="appointment-date-value small-text-16">{selectedAppointmentDate ? selectedAppointmentDate : appointmentSlotTime && appointmentDay && moment(slots[appointmentSlotTime][appointmentDay][0]?.Date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span> */}
                                                                                <span class="appointment-date-value small-text-16">{selectedAppointmentDate ? selectedAppointmentDate : appointmentSlotTime && appointmentDay && moment(slots?.[appointmentSlotTime]?.[appointmentDay]?.[0]?.Date).format(dateFormat)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="data-time-icon time-text">
                                                                            <button class="search-btn btn icon-btn">
                                                                                <i class="fi fi-clock"></i>
                                                                            </button>
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("time")}<span class="small-text-black-16">:</span>&nbsp;
                                                                                </span>
                                                                                <span class="appointment-date-value small-text-16">{selectedAppointmentTime}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="confirm-modal-date-time-main mt-3 confirm-modal-email-content">
                                                                <div class="row checkbox-row">
                                                                    <div class="col-9">
                                                                        <div class="data-time-icon">
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("email")} <span class="small-text-black-16"> - </span>&nbsp;
                                                                                </span>
                                                                                <span class="appointment-date-value small-text-16">{email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 custom-check-box-col">
                                                                        <div class="custom-checkbox">
                                                                            <label class="container">
                                                                                <input type="checkbox" name="is_email"checked={false} onClick={(e) => checkBox(e)} disabled />
                                                                                {/* <input type="checkbox" name="is_email"checked={is_email} onClick={(e) => checkBox(e)} disabled={!checkEmail}/> */}
                                                                                <span className='checkmark' ></span>
                                                                                {/* <span className={(is_email == 1)?'checkmark':'checkmark chkbox_disable'} ></span> */}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row checkbox-row">
                                                                    <div class="col-9">
                                                                        <div class="data-time-icon">
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("SMS")} <span class="small-text-black-16"> - </span>&nbsp;
                                                                                </span>
                                                                                <span class="appointment-date-value small-text-16">{mobileNumber !=null ?`+${mobileNumber}`:''}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 custom-check-box-col">
                                                                        <div class="custom-checkbox">
                                                                            <label class="container">
                                                                                <input type="checkbox" name="is_sms" checked={false} onClick={(e) =>( checkBox(e))} disabled />
                                                                                {/* <input type="checkbox" name="is_sms" checked={is_sms} onClick={(e) =>( checkBox(e))} disabled={!checkSms}/> */}
                                                                                {/* <span className={(is_sms == 1)?'checkmark':'checkmark chkbox_disable'} ></span> */}
                                                                                <span className='checkmark' ></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                 <div class="row checkbox-row">
                                                                    <div class="col-9">
                                                                        <div class="data-time-icon">
                                                                            <div class="date-time-text view-pdf-btn">
                                                                                <span class="appointment-date-title small-text-black-16">{lang("print_pdf")}</span>
                                                                                 {/* <i  className='fa fa-eye' aria-hidden="true" onClick={() => checkPdf && viewPdf()}></i>  */}
                                                                                <i  className={(is_print_pdf == 1)?'fa fa-eye':'fa fa-eye chkbox_disable'} aria-hidden="true" onClick={() => checkPdf && viewPdf()} ></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 custom-check-box-col">
                                                                        <div class="custom-checkbox">
                                                                            <label class="container">
                                                                                <input type="checkbox" name="is_print_pdf" checked={true} onClick={(e) => checkBoxPdf(e)}  /> 
                                                                                 {/* <input type="checkbox" name="is_print_pdf" checked={is_print_pdf} onClick={(e) => checkBoxPdf(e)} disabled={!checkPdf}/> */}
                                                                                 {/* <span className={(is_print_pdf == 1)?'checkmark':'checkmark chkbox_disable'} ></span>  */}
                                                                                 <span className='checkmark' ></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div> 

{/* <div class="row checkbox-row">
                                                                    <div class="col-9">
                                                                        <div class="data-time-icon">
                                                                            <div class="date-time-text view-pdf-btn">
                                                                                <span class="appointment-date-title small-text-black-16">{lang("print_pdf")}</span>
                                                                                
                                                                                <i  className={'fa fa-eye chkbox_disable'} aria-hidden="true" onClick={() => checkPdf && viewPdf()}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3 custom-check-box-col">
                                                                        <div class="custom-checkbox">
                                                                            <label class="container">
                                                                                <input type="checkbox" name="is_print_pdf" checked={is_print_pdf} disabled />
                                                                             
                                                                                <span className='checkmark' ></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <div class="modal-button" onClick={() => bookAppointment()}>
                                                            <button class="btn red-btn" data-target="#appointmentBooked" data-toggle="modal" disabled={isButtonClicked}>
                                                                <span>
                                                                    <i class="fi fi-check-mark-circle"></i>
                                                                </span>
                                                                <span>{lang("confirm")}</span>
                                                            </button>
                                                        </div>
                                                    </ModalFooter>
                                                </Modal>

                                                {/* Appointment success modal */}
                                                <Modal size="lg" isOpen={isOpenAppointmentSuccessModal} className="appointment-list-modal" centered >
                                                    {/* <ModalHeader
                                                        toggle={() => closeModal('isOpenAppointmentSuccessModal')}
                                                        close={
                                                            <button type="button" className="close" onClick={() => closeModal('isOpenAppointmentSuccessModal')}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        }
                                                    >
                                                    </ModalHeader> */}
                                                    <ModalBody className="appointment-list-content">
                                                        <div class="booked-appointment-content">
                                                            <div class="your-appointment-booked-image-content">
                                                                <div class="row justify-content-center">
                                                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                                                        <div class="booked-appointment-image">
                                                                            <img src={bookedCheck} alt="Booked appointment" />
                                                                        </div>
                                                                        <div class="booked-appointment-text">
                                                                            <h4 class="modal-title-20">{lang('your_drive-in_appointment_is_booked.')}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="confirm-modal-date-time-main">
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="data-time-icon">
                                                                            <button class="search-btn btn icon-btn">
                                                                                <i class="fi fi-calendar"></i>
                                                                            </button>
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("date")}<span class="small-text-black-16">:&nbsp;</span>
                                                                                </span>
                                                                            

                                                                                {/* <span class="appointment-date-value small-text-16">{selectedAppointmentDate ? selectedAppointmentDate : appointmentDay && appointmentSlotTime && moment(slots[appointmentSlotTime][appointmentDay][0]?.Date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span> */}
                                                                                <span class="appointment-date-value small-text-16">{selectedAppointmentDate ? selectedAppointmentDate : appointmentSlotTime && appointmentDay && moment(slots?.[appointmentSlotTime]?.[appointmentDay]?.[0]?.Date).format(dateFormat)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="data-time-icon time-text">
                                                                            <button class="search-btn btn icon-btn">
                                                                                <i class="fi fi-clock"></i>
                                                                            </button>
                                                                            <div class="date-time-text">
                                                                                <span class="appointment-date-title small-text-black-16">
                                                                                    {lang("time")}<span class="small-text-black-16">:&nbsp;</span>
                                                                                </span>
                                                                                <span class="appointment-date-value small-text-16">{selectedAppointmentTime}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Link to={helpDeskPage ? '/helpdesk/options' : '/thankyou'}
                                                            state={{
                                                                uuid:helpDeskPage?"": this.props.router.location.state.uuid,
                                                            }}>
                                                            <div class="modal-button">
                                                                <button class="btn red-btn" data-dismiss="modal" onClick={() => {
                                                                    closeModal('isOpenAppointmentSuccessModal')
                                                                }}>
                                                                    <span>
                                                                        <i class="fa fa-times"></i>
                                                                    </span>
                                                                    <span>{lang("close")}</span>
                                                                </button>
                                                            </div>
                                                        </Link>
                                                    </ModalFooter>
                                                </Modal>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default withRouter(CalendarComponent);
