import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import "./UpdateLang.css";
import { configuration } from "../../appConfig";
import toastAlert from '../../helper/ToastAlert/ToastAlert'

export default class UpdateLang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: [],
      numRows: 140,
      modal: false,
      isRequired: false,
      modalData: {},
      loading: false,
      tableData: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.apiInputChange = this.apiInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  apiInputChange = (e, key, i) => {
    const { name, value } = e.target;
    const { tableData } = this.state;
    if (tableData[key]) {
      tableData[key] = {
        ...tableData[key],
        [name]: value,
      };
    } else {
      tableData[key] = {
        [name]: value,
      };
    }
    // tableData[key][name] = value
    this.setState({
      tableData,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let { modalData } = this.state;
    modalData = {
      ...modalData,
      [name]: value,
    };

    this.setState({
      modalData,
    });
  };

  async handleSubmit(e, type) {
    e.preventDefault();
    const { modalData, tableData } = this.state;
    this.setState({ modal: false, loader: true });
    let url = configuration.updateLang;
    let key = modalData?.label?.replaceAll(" ", "_")?.toLowerCase();
    let language_details;
    if (type === "table") {
      language_details = { ...tableData };
    } else {
      language_details = { [key] : {...modalData} };
    }

    await axios
      .post(url, { language_details, lang_type: 2 })
      .then((res) => {
        toastAlert(res?.data?.message ? res?.data?.message : "Data saved successfully","success");
        this.setState({
          loader: false,
        });
        this.getLang();
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong.Please try again later.","error");
        this.setState({
          loader: false,
        });
      });
  }

  async tableDataSubmit(e) {
    let url = configuration.lang;
    let language_details = { ...this.state.value };
    await axios.post(url, { language_details, lang_type: 2 }).then((res) => {
      this.setState({
        value: res.data.data,
      });
    });
  }

  componentDidMount() {
    this.getLang();
  }

  getLang = () => {
    let url = configuration.lang;
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        this.setState({
          data: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message ?? "Something went wrong. Please try again later", "error");
        this.setState({
          loading: false,
        });
      });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const { data, modal, loader, loading } = this.state;
    return (
      <>
        <div className="main_card_body main_lang_wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="lang_table_wrapper">
                  <Table striped responsive bordered>
                    <thead>
                      <tr>
                        <th colSpan={2} className="text-center">
                          Description
                        </th>
                        <th>DE</th>
                        <th>CH-DE</th>
                        <th>CH-FR</th>
                        <th>CH-IT</th>
                        <th>CZ</th>
                        <th>FR-DE</th>
                        <th>FR-FR</th>
                        <th>NL</th>
                        <th>RO-DE</th>
                        <th>RO-RO</th>
                        <th>SL-DE</th>
                        <th>SL-SL</th>
                        <th>US</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan={15} className="text-center">
                            loading...
                          </td>
                        </tr>
                      )}
                      {Object.keys(this.state.data).length
                        ? Object.keys(this.state.data).map((key, i) => {
                            return (
                              <tr key={i}>
                                <td width={"10%"} component="th" scope="row">
                                  {this.state.data[key].id}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={this.state.data[key].label}
                                    title={this.state.data[key].label}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="DE"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["DE"]
                                    }
                                    title={
                                      this.state.data[key].translations["DE"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="CH-DE"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["CH-DE"]
                                    }
                                    title={
                                      this.state.data[key].translations["CH-DE"]
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="CH-FR"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["CH-FR"]
                                    }
                                    title={
                                      this.state.data[key].translations["CH-FR"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="CH-IT"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["CH-IT"]
                                    }
                                    title={
                                      this.state.data[key].translations["CH-IT"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="CZ"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["CZ"]
                                    }
                                    title={
                                      this.state.data[key].translations["CZ"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="FR-DE"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["FR-DE"]
                                    }
                                    title={
                                      this.state.data[key].translations["FR-DE"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="FR-FR"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["FR-FR"]
                                    }
                                    title={
                                      this.state.data[key].translations["FR-FR"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="NL"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["NL"]
                                    }
                                    title={
                                      this.state.data[key].translations["NL"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="RO-DE"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["RO-DE"]
                                    }
                                    title={
                                      this.state.data[key].translations["RO-DE"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="RO-RO"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["RO-RO"]
                                    }
                                    title={
                                      this.state.data[key].translations["RO-RO"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="SL-DE"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["SL-DE"]
                                    }
                                    title={
                                      this.state.data[key].translations["SL-DE"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="SL-SL"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["SL-SL"]
                                    }
                                    title={
                                      this.state.data[key].translations["SL-SL"]
                                    }
                                  ></input>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id=""
                                    name="US"
                                    onChange={(e) =>
                                      this.apiInputChange(e, key, i)
                                    }
                                    defaultValue={
                                      this.state.data[key].translations["US"]
                                    }
                                    title={
                                      this.state.data[key].translations["US"]
                                    }
                                  ></input>
                                </td>
                              </tr>
                            );
                          })
                        : !loading && (
                            <tr>
                              <td colSpan={15} className="text-center">
                                No data found
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-lg-12 m-1 text-right">
                <Button
                  className="mx-2 black-btn "
                  onClick={() => this.toggle()}
                >
                  <i className="fa fa-plus text-white"></i>&nbsp; Add New
                </Button>
                <Button
                  type="button"
                  className="red-btn border-0"
                  onClick={(e) => this.handleSubmit(e, "table")}
                  disabled={loader}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal}
          toggle={() => this.toggle()}
          size="xl"
          className="lang_modal"
        >
          <ModalHeader
            toggle={() => this.toggle()}
            close={
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.toggle()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          >
            Add New Language
          </ModalHeader>
          <ModalBody>
            <div>
              {/* <form onSubmit={this.handleSubmit}> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Enter Keyword</label>
                    <input
                      type="label"
                      required={this.state.isRequired}
                      onChange={(e) => this.handleChange(e)}
                      name="label"
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>SL-SL</label>
                        <input
                          type="text"
                          name="SL-SL"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>CH-DE</label>
                        <input
                          type="text"
                          name="CH-DE"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>CH-FR</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="CH-FR"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>CH-IT</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="CH-IT"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>CZ </label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="CZ"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>DE </label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="DE"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>FR-DE</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="FR-DE"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>FR-FR</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="FR-FR"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>NL </label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="NL"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>RO-DE</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="RO-DE"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>RO-RO</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="RO-RO"
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <label>SL-DE</label>
                        <input
                          type="label"
                          onChange={(e) => this.handleChange(e)}
                          name="SL-DE"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn_blue_border_small"
              onClick={() => this.toggle()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="red-btn"
              onClick={this.handleSubmit}
              disabled={loader}
            >
              {loader ? (
                <Spinner color="light" size="sm">
                  <span className="visually-hidden d-none">loading...</span>
                </Spinner>
              ) : ("Submit")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
