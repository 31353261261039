import React, { Component } from "react";
import {
  Spinner,
  Input,
  Label,
  FormGroup,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import LanguageContext from "../../context/LanguageProvider";
import axios from "axios";
import { configuration } from "../../appConfig";
import moment from "moment";
import Cookies from "js-cookie";
// import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
// import "react-data-table-component-extensions/dist/index.css";

export default class EmailSmsLog extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      locationData: [],
      locationSelectName: "",
      emailSmsSelect: "1",
      reportData: [],
      emailData: [],
      smsData: [],
      allData: [],
      setActiveTab: "",
      isModelOpen: false,
      pdfurl: "",
      from_date: moment().format("YYYY-MM-DD"),
      to_date: moment().format("YYYY-MM-DD"),
    };
  }

  componentDidMount = async () => {
    await this.getAllLocation();
    // await this.getTablePdf()
  };
  getAllLocation = () => {
    this.setState({
      loading: true,
    });
    const url = configuration.allLocationGet;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        this.setState(
          {
            loading: false,
            locationData: res.data.data,
          },
          () => this.emailSmsReport()
        );
      });
  };

  handleNameChange = async (event) => {
    console.log(event.target.value, "p00");
    this.setState(
      {
        locationSelectName: event.target.value,
      },
      () => this.emailSmsReport()
    );
  };
  handleNameChange1 = (event) => {
    console.log(event.target.value, "event.target.value");
    this.setState(
      {
        emailSmsSelect: event.target.value,
      },
      () => this.emailSmsReport()
    );
  };

  emailSmsReport = async (to_date, from_date) => {
    this.setState({
      loading: true,
    });
    const url = `${configuration.emailSmsReport}?location_id=${this.state?.locationSelectName}&report_type=${this.state?.emailSmsSelect}&lang_code=${this.context.language}&to_date=${this.state.to_date}&from_date=${this.state.from_date}`;
    const accessToken = localStorage.getItem("access_token");
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        const emaillogData = res.data.data.email_log;
        const smslogData = res.data.data.sms_log;
        const allLogData = [...emaillogData, ...smslogData];

        this.setState({
          loading: false,
          reportData: res.data.data,
          emailData: res.data.data.email_log,
          smsData: res.data.data.sms_log,
          allData: allLogData,
        });
      });
  };

  pdfHandleClick = async () => {
    this.setState({
      loading: true,
    });
    const url = `${configuration.emailSmsReportPdf}?location_id=${this.state?.locationSelectName}&report_type=${this.state?.emailSmsSelect}&lang_code=${this.context.language}&to_date=${this.state.to_date}&from_date=${this.state.from_date}`;
    const accessToken = localStorage.getItem("access_token");
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
          isModelOpen: !this.state.isModelOpen,
          pdfurl: res.data.data,
        });
      });
  };

  handleFilterClick = () => {
    document.body.classList.toggle('no-scroll', !this.state.modalOpen);
    this.emailSmsReport(this.state.to_date, this.state.from_date);
  };

  closeModal = (modalName) => {
    document.body.classList.remove("body-fixed");
    this.setState({
      [modalName]: false,
    });
  };

  render() {
    const { lang } = this.context;
    const { loading, locationData, from_date, to_date } = this.state;
    const dateFormat = Cookies.get("dateFormat");
    const emailDataKeys = this.state.emailData.map((item) => Object.keys(item));
    const columns = emailDataKeys[0]?.map((item) => ({
      
      name: lang(item),
      selector: item,
    }));
   
    const CustomNoDataMessage = () => (
      <div className="no-data-message">{lang('')}</div>
    );
    
    
    const smsDataKeys = this.state.smsData.map((item) => Object.keys(item));
    const smscolumns = smsDataKeys[0]?.map((item) => ({
      name: lang(item),
      selector: item,
    }));
    console.log(emailDataKeys[0], columns, "columns");
    return (
      <div>
        {loading && (
          <div class="loader-main">
            <Spinner color="light">
              <span></span>
            </Spinner>
          </div>
        )}
        <div className="pdf-filters-wrapper">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="pdf-inner-filter">
                  <div className="row filter-row">
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                      <div className="pdf-filters">
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className="form-label">
                              {lang("select_location")}
                            </Label>
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              onChange={(event) => this.handleNameChange(event)}
                            >
                              <option value={""}>
                                {lang("all")}
                              </option>
                              {locationData.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                      <div className="pdf-filters">
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className="form-label">
                              {lang("select_report")}
                            </Label>
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              onChange={(event) =>
                                this.handleNameChange1(event)
                              }
                            >
                              <option value="1">{lang("all")}</option>
                              <option value="2">{lang("email")}</option>
                              <option value="3">{lang("sms")}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-sm-11">
                      <div className="user-list-date-range-main custom-input-group">
                        <div class="custom-form-control">
                          <div class="form-group mb-0">
                            <label class="form-label ">
                              {" "}
                              {lang("filter_by_a_date_range")}{" "}
                            </label>

                            {/* <input class="form-control" type="text" name="daterange"
                                                                        value="01/01/2018 - 01/15/2018" /> */}
                            {/* {console.log(typeof (dateFormat), 'dateFormat')} */}
                            <div className="apply-date-range-and-btn">
                              <DateRangePicker
                                initialSettings={{
                                  locale: {
                                    // format: dateFormat !== 'undefined' ? 'DD.MM.YYYY' :  dateFormat,
                                    format:
                                      dateFormat !== "undefined"
                                        ? dateFormat
                                        : "DD.MM.YYYY",
                                    startDate: moment(from_date, "YYYY-MM-DD"),
                                    endDate: moment(to_date, "YYYY-MM-DD"),
                                  },
                                }}
                                onApply={(e, picker) => (
                                  console.log(
                                    picker.startDate.format("YYYY-MM-DD"),
                                    "from_date"
                                  ),
                                  this.setState({
                                    from_date:
                                      picker.startDate.format("YYYY-MM-DD"),
                                    to_date:
                                      picker.endDate.format("YYYY-MM-DD"),
                                  })
                                )}
                              >
                                <input type="text" className="form-control" />
                              </DateRangePicker>
                              <div class="apply-date-range-btn">
                                <button
                                  class="btn red-btn"
                                  onClick={() => this.handleFilterClick()}
                                >
                                  {lang("apply_date_range")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                      <div class="download-pdf-top-button">
                        <div class="apply-date-range-btn">
                          <Button
                            onClick={this.pdfHandleClick}
                            class="btn red-btn"
                          >
                            PDF Herunterladen
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="pdf-content-tabs">
                        {
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            {this.state.emailSmsSelect == "1" ||
                            this.state.emailSmsSelect == "2" ? (
                              <li class="nav-item" role="presentation">
                                <a
                                  class="nav-link active"
                                  id="table-view-tab"
                                  data-toggle="tab"
                                  href="#table-view"
                                  role="tab"
                                  aria-controls="table-view"
                                  aria-selected="true"
                                >
                                  {lang("e-mail_report")}
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {this.state.emailSmsSelect == "1" ||
                            this.state.emailSmsSelect == "3" ? (
                              <li class="nav-item" role="presentation">
                                <a
                                  class={`nav-link ${
                                    this.state.emailSmsSelect == "3"
                                      ? "active"
                                      : ""
                                  }`}
                                  id="card-view-tab"
                                  data-toggle="tab"
                                  href="#card-view"
                                  role="tab"
                                  aria-controls="card-view"
                                  aria-selected="false"
                                >
                                  {lang("sms_report")}
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        }

                        <div class="tab-content" id="myTabContent">
                          <div
                            class={`tab-pane fade${
                              this.state.emailSmsSelect == "1" ||
                              this.state.emailSmsSelect == "2"
                                ? "show active"
                                : ""
                            } `}
                            id="table-view"
                            role="tabpanel"
                            aria-labelledby="table-view-tab"
                          >
                            <div className="table-view-wrapper data-table-wrapper">
                              <div class="card-body">
                                <div class="custom-table user-list-table-wrapper">
                                  <div class="table-responsive">
                                    <DataTable
                                      columns={columns}
                                      data={this.state.emailData}
                                      pagination
                                      noDataComponent={lang('no_data_found')}
                                    />

                                    {/* <table  class="table table-main">
                          
                              <thead>
                                <tr>
                                  <th scope="col">{lang("sr._no.")}</th>
                                  <th scope="col">{lang("date")}</th>
                                  <th scope="col">{lang("customer_name")}</th>
                                  <th scope="col">{lang("email")}</th>
                                  <th scope="col">{lang("e-mail_count")}</th>
                                  <th scope="col">{lang("email_status")}</th>
                                  <th scope="col">{lang("e-mail_purpose")}</th>
                                
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.emailData?.length > 0 ? (
                                  this.state.emailData.map((item, i) => (
                                    <tr>
                                      <td scope="row">{item.customer_name}</td>
                                      <td>
                                        {(item.date)}
                                      </td>
                                      
                                      <td>
                                        {item.email_count != ""
                                          ? item.email_count
                                          : "-"}
                                      </td>
                                      <td>
                                        {item.email_id != ""
                                          ? item.email_id
                                          : "-"}
                                      </td>

                                      <td>{item.notification_type}</td>
                                      
                                     
                                     
                                      <td>
                                        {item.status != "" ? item.status : "-"}
                                      </td>
                                    
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={10}>
                                      {" "}
                                      {lang("no_data_found")}{" "}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class={`tab-pane fade${
                              this.state.emailSmsSelect == "3"
                                ? "show active"
                                : ""
                            } `}
                            id="card-view"
                            role="tabpanel"
                            aria-labelledby="profcard-view"
                          >
                            <div className="card-view-wrapper data-table-wrapper">
                              <div class="card-body">
                                <div class="custom-table user-list-table-wrapper">
                                  <div class="table-responsive">
                                    <DataTable
                                      columns={smscolumns}
                                      data={this.state.smsData}
                                      pagination
                                      noDataComponent={lang('no_data_found')}
                                     
                                    />
                                    {/* <table class="table table-main">
                              <thead>
                                <tr>
                                  <th scope="col">{lang("sr._no.")}</th>
                                  <th scope="col">{lang("date")}</th>
                                  <th scope="col">{lang("customer_name")}</th>
                                  <th scope="col">
                                    {lang("mobile_number")}
                                  </th>
                                  <th scope="col">{lang("sms_count")}</th>
                                  <th scope="col">{lang("sms_status")}</th>
                                  <th scope="col">{lang("sms_length")}</th>
                                  <th scope="col">{lang("sms_purpose")}</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.smsData?.length > 0 ? (
                                  this.state.smsData.map((item, i) => (
                                    <tr>
                                      <td scope="row">{item.customer_name}</td>
                                      <td>
                                        {(item.date)}
                                      </td>
                                      
                                      
                                      <td>
                                        {item.message_body != ""
                                          ? item.message_body
                                          : "-"}
                                      </td>

                                      <td>{item.mobile_no}</td>
                                      
                                      <td>
                                        {item.notification_type != ""
                                          ? item.notification_type
                                          : "-"}
                                      </td>
                                     
                                      <td>
                                        { item.sms_count}
                                      </td>
                                      <td>
                                        {item.status != "" ? item.status : "-"}
                                      </td>
                                    
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={10}>
                                      {" "}
                                      {lang("no_data_found")}{" "}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          isOpen={this.state.isModelOpen}
          className="appointment-list-modal confirm-appointment"
          centered
        >
          <ModalHeader
            toggle={() => closeModal("isModelOpen")}
            close={
              <button
                type="button"
                className="close"
                onClick={() => this.closeModal("isModelOpen")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          ></ModalHeader>
          <ModalBody className="appointment-list-content">
            {this.state.pdfurl ? (
              <iframe
                src={`data:application/pdf;base64,${this.state.pdfurl}`}
                title="Base64 Image"
                width="100%"
                height="800px"
                frameBorder="0"
              ></iframe>
            ) : (
              ""
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
