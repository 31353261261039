import React, { Component } from 'react';
import withRouter from '../../withRouter';
import '../pageNotFound/PageNotFound.css';
import PageNotFoundImage from '../../assets/images/404-error.svg'

export class PageNotFound extends Component {
    render() {
        return (
            <>
                <section class="error-wrapper">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                            </div>
                            <div class="col-12">
                                <div class="row justify-content-center  ">
                                    <div class="col-lg-5">
                                        <div class="thank-you-image">
                                            <img src={PageNotFoundImage} alt="404 Error" />
                                        </div>
                                        <div class="thank-you-content-main">
                                            <div class="thankyou-title text-center">
                                                <h2 class="black-thankyou-title thankyou-title">Page not <span
                                                    class="red-thankyou-title thankyou-title">found</span> </h2>
                                                <p class="small-text-black-16">We are not able to find what you were looking for.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default withRouter(PageNotFound);
