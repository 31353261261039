import React from 'react';
import Cookies from 'js-cookie';

export function CookiesRemove() {
    // this.props.router.navigate('/login');
    Cookies.remove('id');
    Cookies.remove('globalUserId');
    Cookies.remove('isAuthenticated');
    Cookies.remove('globalOrganizationId');
    Cookies.remove('name');
    Cookies.remove('organizationName');
    Cookies.remove('organizationCountry');
    Cookies.remove('regionName');
    Cookies.remove('roleId');
    Cookies.remove('isLoggedIn');
    Cookies.remove('authToken');
    Cookies.remove('uuid');
    Cookies.remove('userData');
    Cookies.remove('organizationCountry');
    Cookies.remove('role_name');
    Cookies.remove('clientTypeSetting');
    Cookies.remove('is_existing');
    // Cookies.remove('organization_country');
    // Cookies.remove('clientLogo');
    localStorage.removeItem('access_token');
    var element = document.getElementsByTagName("html")[0];
    element.classList.remove("otherclass");
    console.log('Working');
}
