import React, { Component } from 'react';
import moment from 'moment';
import LanguageContext from "../../context/LanguageProvider";
import { Spinner } from 'reactstrap';
import noDataImg from "../../assets/images/no-data.svg";
import { computeHeadingLevel } from '@testing-library/react';

export class AdminDay extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            slots: {},
        };
    }

    getBreakName = (break_name) => {
        const { language, lang } = this.context
        const split = break_name ? break_name.split(" ") : '';
        const data = split[0] + '_' + split[1];
        const breakName = data.toLocaleLowerCase();
        return (lang(breakName));
    }

    render() {
        console.log(this.props.fromDate,"fromDate")
        const { language, lang } = this.context
        const { timeData, handleConfirmDetailsModal, handleAddDate, handleRemoveDate, loading, locationName, locationKm, first_name, last_name, salutation } = this.props
        const formattedFromDate = moment(this.props.fromDate).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY');
        const day = moment(this.props.fromDate).format('dddd');
        const lowercaseDay = day.toLowerCase();
        const dayInLang = lang(lowercaseDay);
        const { to_date, from_date } = this.props;
        console.log(to_date, from_date,day,"to_date, from_date")
        const lowerSalution = salutation.toLowerCase();
        localStorage.setItem('formattedFromDate', formattedFromDate);
        console.log(formattedFromDate == to_date ,formattedFromDate,to_date,"line37")
        return (
            <>
                <div class="calender-table-main day-view-table help-day-view-table">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" class="active-table-th">
                                        <div class="day-name">
                                            <div class="day-view-name-date-main">
                                                <div class="day-view-day">
                                                    <div class="day-view-name">
                                                        <span class="small-text-black-16-bold">{dayInLang}</span>
                                                    </div>
                                                    <div class="help-desk-day-next-prev-main">
                                                        <div class="row helpdesk-day-view-row">
                                                            {formattedFromDate == from_date ? (
                                                                <div class="col-lg-6 help-desk-col help-desk-center-date-col">
                                                                    <div class="help-desk-year-month help-desk-day-date small-text-14">
                                                                        <p class="small-text-14"></p>{formattedFromDate}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div class="col-lg-3 help-desk-col">
                                                                        <div class="help-desk-left-arrow help-desk-arrow" onClick={() => handleRemoveDate()}>
                                                                            <i class="fa fa-long-arrow-left"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 help-desk-col help-desk-center-date-col">
                                                                        <div class="help-desk-year-month help-desk-day-date small-text-14">
                                                                            <p class="small-text-14"></p>{formattedFromDate}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div class="col-lg-3 help-desk-col">
                                                                {formattedFromDate == to_date ? (
                                                                    ''
                                                                ) : (
                                                                    <div class="help-desk-left-arrow help-desk-arrow" onClick={() => handleAddDate()}>
                                                                        <i class="fa fa-long-arrow-right"></i>
                                                                    </div>

                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div class="city-name">
                                            
                                            <span class="small-text-black-16"> {lang("your_drive_in_location_is")} <span class="small-text-red-16 day-location-name"><i class="fa fa-map-marker"></i> {this.props.location_name}</span> 
                                                </span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {timeData && Object.keys(timeData).map((key, i) => {
                                    return (
                                        <>
                                            <tr className="empty-space-tr"></tr>
                                            <tr>
                                                <th>
                                                    {key}
                                                </th>
                                                <td>
                                                    <div class="row help-desk-day-view-row">
                                                        {timeData[key].breakName ?
                                                            <div className='centerBreak'>{this.getBreakName(timeData[key]?.breakName)}</div>
                                                            :
                                                            timeData[key].slots && timeData[key].slots.map((slots) => {
                                                                return <div class="free-booked-wrapper">
                                                                    <div class="col-lg-2">
                                                                        <div class="day-view-free-booked-tag">
                                                                            <div class={`appointment-type ${slots.is_booked ? "booked-appointmentList" : "free-appointment"}`}
                                                                                onClick={() => !slots.is_booked && handleConfirmDetailsModal(slots.from_time, slots.appointment_id)} data-toggle="modal">
                                                                                <span class="small-text-black-16-bold">{slots.from_time}</span>
                                                                                <span class=" small-text-16 day-booking-type" disabled={slots.is_booked ? true : false}>{slots.is_booked ? `${lang("booked")}` : `${lang("free")}`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })} */}

                                {Object.keys(timeData).length === 0 ?
                                    <>
                                        <tr class="empty-space-tr"></tr>
                                        <tr>
                                            <td colSpan="2" style={{ textAlign: "center" }} class="nodata-td">
                                                <div class="row justify-content-center align-items-center" style={{ height: "50vh" }}>
                                                    <div class="col-lg-4">
                                                        <img class="no-data-img" src={noDataImg} alt="noDataImg" />
                                                        <p>
                                                            {lang("no_slots_available")}
                                                        </p>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                    :
                                    Object.keys(timeData).map((key, i) => {
                                        const date = new Date();
                                        const currentDate = date.toLocaleDateString('en-GB').split('/').reverse().join('-')
                                        const slotDate = timeData[key].date
                                     
                                        {/* if(currentDate<=slotDate){

                                        console.log('true')
                                        }else{
                                            console.log("false")
                                        };
                                        console.log(slotDate,'slotDate')
                                        console.log(currentDate,'currentDate') */}
                                        {/* const SlotTime = timeData.slots */}
                                        {/* const newSlotDate = `${slotDate}` + ' ' + `${SlotTime}`; */}
                                        {/* const currentTime = moment().format('YYYY-MM-DD H:mm'); */}
                                        {/* console.log(newSlotDate, "newSlotDate") */}
                                        {/* let isTimeDisable = false; */}
                                        {/* if (newSlotDate < currentTime) { isTimeDisable = true; } */}
                                        {/* console.log('isTimeDisable ::' + isTimeDisable) */}
                                        {/* console.log(SlotTime, "SlotTime") */}
                                        {/* console.log((slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.is_disable && isTimeDisable), "slots[key][slotKey][0] && slots[key][slotKey][0].sub_slots.is_disable)?'booking-tag booking-tag-disable':'booking-tag'") */}
                                        {/* console.log(currentDate == slotDate, "truefalse") */}
                                        return (
                                            <>
                                                <tr className="empty-space-tr"></tr>
                                                <tr>
                                                    <th>
                                                        {key}
                                                    </th>
                                                    <td>

                                                        {
                                                            (timeData[key].is_disable ) ? (<div class="row help-desk-day-view-row">
                                                                {timeData[key].breakName ?
                                                                    <div className='centerBreak'>{this.getBreakName(timeData[key]?.breakName)}</div>
                                                                    :
                                                                    timeData[key].slots && timeData[key].slots.map((slots) => {
                                                                        {/* console.log(slots, "slots") */}
                                                                        return <div class="free-booked-wrapper">
                                                                            <div class="col-lg-2">
                                                                                <div class="day-view-free-booked-tag">
                                                                                    <div class={"appointment-type  disable-appointmentList"}>
                                                                                        <span class="small-text-black-14-bold">{slots.from_time}</span>
                                                                                        <span class=" small-text-14 disabled-day-booking-type ">{slots.is_booked ? `${lang("booked")}` : `${lang("free")}`}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                            </div>) : (
                                                               ( <div class="row help-desk-day-view-row">
                                                                    {timeData[key].breakName ?
                                                                        <div className='centerBreak'>{this.getBreakName(timeData[key]?.breakName)}</div>
                                                                        :
                                                                        timeData[key].slots && timeData[key].slots.map((slots) => {
                                                                           const currentTime = moment().format('H:mm');
                                                                           const slotsTime = slots.from_time
                                                                           {/* console.log(currentTime,"currentTime")
                                                                           console.log(currentTime<slotsTime,"slotsTime") */}
                                                                           {/* console.log((currentDate>=slotDate  && currentTime<slotsTime) ==1,"currentDate==slotDate  && currentTime<slotsTime") */}
                                                                            return <div class="free-booked-wrapper">
                                                                            {(currentDate==slotDate  ) ?
                                                                            (currentTime<slotsTime)?
                                                                            <div class="col-lg-2">
                                                                                    <div class="day-view-free-booked-tag">
                                                                                        <div class={`${!slots.is_disable?`appointment-type ${slots.is_booked ? "booked-appointmentList" : "free-appointment"}`:'appointment-type disable-appointmentList'}`}
                                                                                            // onClick={() => !slots.is_booked && handleConfirmDetailsModal(slots.from_time, slots.appointment_id, formattedFromDate)}
                                                                                             data-toggle="modal">
                                                                                            <span class="small-text-black-14-bold">{slots.from_time}</span>
                                                                                            <span class={`${!slots.is_disable?'small-text-14 day-booking-type':'small-text-14 disabled-day-booking-type'}`} disabled={slots.is_booked ? true : false}>{slots.is_booked ? `${lang("booked")}` : `${lang("free")}`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>: <div class="col-lg-2">
                                                                                    <div class="day-view-free-booked-tag">
                                                                                        <div class='appointment-type disable-appointmentList'
                                                                                            >
                                                                                            <span class="small-text-black-14-bold">{slots.from_time}</span>
                                                                                            <span class=" small-text-14 day-booking-type disabled-day-booking-type " disabled={slots.is_booked ? true : false}>{slots.is_booked ? `${lang("booked")}` : `${lang("free")}`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div class="col-lg-2">
                                                                                    <div class="day-view-free-booked-tag">
                                                                                        <div class={`${!slots.is_disable?`appointment-type ${slots.is_booked ? "booked-appointmentList" : "free-appointment"}`:'appointment-type disable-appointmentList'}`}
                                                                                            // onClick={() => !slots.is_booked && handleConfirmDetailsModal(slots.from_time, slots.appointment_id, formattedFromDate)}
                                                                                             data-toggle="modal">
                                                                                            <span class="small-text-black-14-bold">{slots.from_time}</span>
                                                                                            <span class={`${!slots.is_disable?'small-text-14 day-booking-type':'small-text-14 disabled-day-booking-type'}`} disabled={slots.is_booked ? true : false}>{slots.is_booked ? `${lang("booked")}` : `${lang("free")}`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                }
                                                                               
                                                                            </div>
                                                                        })}
                                                                </div>)
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default AdminDay;
