import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import withRouter from '../../withRouter';
import Location from "../../assets/images/select-location.svg"
import greenCircle from "../../assets/images/green-semi-circle.svg"
import redCircle from "../../assets/images/red-semi-circle.svg"
import orangeCircle from "../../assets/images/orange-semi-circle.svg"
import locationIcon from "../../assets/images/location.svg"
import LanguageContext from '../../context/LanguageProvider';
import moment from 'moment';
import toastAlert from '../../helper/ToastAlert/ToastAlert'
import { Spinner } from 'reactstrap';
import Cookies from 'js-cookie';
export class LocationComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        // const locationOrigin = this.props?.locationOrigin.length && this.props?.locationOrigin[1]?.location_name
        // const locationOrigin = this.props?.locationOrigin?.hasOwnProperty('1') &&  this.props?.locationOrigin?.[1]?.location_name

        // const locationOrigin = ""
        const { locationOrigin, handelSlotsButton, lang, handleToggle, handelClick, handleMoreLocation, handleMoreLocations, getLocationData, componentWillReceiveProps, isMoreLocationVisible, moreLocation, toggle, locationDestination, locationPagination, clickedCardLocation, buttonOpen, driveIn, to_date, from_date, loading, toggleMoreLocation } = this.props;
        const { language } = this.context
        const dateFormat = Cookies.get('dateFormat')
        return (
            <>
                <main class="main-content location-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-6 col-sm-12 location-title-col">
                                    <div class="location-title">
                                        <span class="select-location-img">
                                            <img src={Location} alt="Location" />
                                        </span>
                                        <span class="select-location-title">
                                            <h4 class="small-title-18">{lang('select_your_suitable_location/time')}</h4>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="location-main-content">
                        <section class="customer-location-wrapper">
                            <div class="container">
                                <div class="row all-locations-row">
                                    <div class="col-12">
                                        <div class="locations-top">
                                            <div class="green-location-top">
                                                <div class="green-location-row">
                                                    <div class="customer-location-title">
                                                        <div class="customer-location-image">
                                                            <img src={locationIcon} alt="Your Location" />
                                                        </div>
                                                        <h4 class="small-title-18">{locationOrigin}</h4>
                                                    </div>
                                                    <div class="green-location-img">
                                                        <img src={greenCircle} alt="Curve" />
                                                    </div>
                                                    <div class="green-location-name">
                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(0, 2).map((location, i) => {
                                                            return (
                                                                <div
                                                                    class={`green-location-${i} ${location.category === 'city' ? 'city-location' : 'other-location'}`}
                                                                    onClick={() => handelClick(location)}
                                                                >
                                                                    <div class="city-name-km-main">
                                                                        <div class="city-name-main left-side-location">
                                                                            <p class="small-text-black-16">{location.location_name} <span class="small-text-14">({location.km}km)</span></p>
                                                                        </div>
                                                                        <div class="location-circle-main left-side-dots">
                                                                            <div class="location-circle-out">
                                                                                <div class="location-circle-in"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(2, 3).map((location, i) => (
                                                            <div
                                                                class={`green-location-2 ${location.category === 'city' ? 'city-location' : 'other-location'}`}
                                                                onClick={() => handelClick(location)}
                                                            >
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(3, 5).map((location, i) => (
                                                            <div
                                                                class={`green-location-${i + 3} ${location.category === 'city' ? 'city-location' : 'other-location'}`}
                                                                onClick={() => handelClick(location)}
                                                            >
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {locationDestination[1] && locationDestination[1]?.length > 5 && (
                                                            <div class="green-location-4">
                                                                <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[1], 1)}>
                                                                    <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                        <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="orange-location-top">
                                                <div class="orange-location-row">
                                                    <div class="orange-location-img">
                                                        <img src={orangeCircle} alt="Curve" />
                                                    </div>
                                                    <div class="orange-location-name">
                                                        {locationDestination[2] && locationDestination[2].length && locationDestination[2].slice(0, 2).map((location, i) => (
                                                            <div class={`orange-location-${i}`} onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main left-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main left-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {locationDestination[2] && locationDestination[2].length && locationDestination[2].slice(2, 3).map((location, i) => (
                                                            <div class="orange-location-2" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {locationDestination[2] && locationDestination[2].length && locationDestination[2].slice(3, 5).map((location, i) => (
                                                            <div class={`orange-location-${i + 3}`} onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {locationDestination[2] && locationDestination[2]?.length > 5 && (
                                                            <div class="orange-location-4">
                                                                <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[2], 2)}>
                                                                    <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                        <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="red-location-top">
                                                <div class="red-location-row">
                                                    <div class="red-location-img">
                                                        <img src={redCircle} alt="Curve" />
                                                    </div>
                                                    <div class="red-location-name">
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(0, 2).map((location, i) => {
                                                            return <div class={`red-location-${i}`} onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main left-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main left-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(2, 3).map((location, i) => (
                                                            <div class="red-location-2" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(3, 5).map((location, i) => (
                                                            <div class={`red-location-${i + 3}`} onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name} <span
                                                                            class="small-text-14">({location.km}km)</span></p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {locationDestination[3] && locationDestination[3]?.length > 5 && (
                                                            <div class="red-location-4">
                                                                <div class="city-name-km-main" onClick={() => handleMoreLocations(locationDestination[3], 3)}>
                                                                    <div class="city-name-main right-side-location more-locations more-location-btn">
                                                                        <p class="small-text-black-16">{lang("more_locations")}</p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="location-bottom-line"></div>
                        </section>

                        {toggle &&
                            <div class="location-drawer-wrapper">
                                <div class={`location-drawer-main ${toggle ? 'multi-location-drawer reveal-location-drawer reveal-location-drawer' : ''}`} id="single-location">
                                    <div class="drawer-close-btn">
                                        <button class="btn closeLocationDrawer" onClick={() => handleToggle()}>
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="location-cards-wrapper">
                                        {loading && <div class="loader-main-half">
                                            <Spinner color="light">
                                                <span></span>
                                            </Spinner>
                                        </div>}
                                        {driveIn?.map((driveInData, i) => {
                                            const weekNumber = moment(driveInData.from_date).week();
                                            const fromDateDay = moment(driveInData.from_date).format('dd');
                                            const toDateDay = moment(driveInData.to_date).format('dd');
                                            return <div class="row">
                                                <div class="col-12">
                                                    <div class="accordion" id="onlyOneLocation">
                                                        <div class="card location-card">
                                                            <div class="card-header" id="singleLocation">
                                                                <div>
                                                                    <div class="row align-items-center">
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="location-name">
                                                                                <span class="small-text-black-16">{lang("location")}</span>
                                                                                <span class="small-text-black-16">: </span>
                                                                                <span class="small-text-red-16">{clickedCardLocation.location_name}</span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div class="col-lg-6">
                                                                            <div class="location-view-slot-main">
                                                                                <div class="location-slot">
                                                                                    <button class="btn red-btn" data-toggle="collapse"
                                                                                        data-target={`#singleLocationOne_${i}`}
                                                                                        aria-expanded="true"
                                                                                        aria-controls={`singleLocationOne_${i}`}
                                                                                    // onClick={() => handelSlotsButton()}
                                                                                    >{driveInData.total_slot}&nbsp;

                                                                                        {lang("slots")}</button>
                                                                                </div>

                                                                            </div>
                                                                        </div> */}
                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div class="location-view-slot-main">
                                                                                <div class="location-slot">
                                                                                    <button class="btn red-btn" data-toggle="collapse"
                                                                                        data-target={`#singleLocationOne_${i}`}
                                                                                        aria-expanded="true"
                                                                                        aria-controls={`singleLocationOne_${i}`}
                                                                                        disabled={driveInData.total_slot === 0}
                                                                                    // onClick={() => handelSlotsButton()}
                                                                                    >
                                                                                        {driveInData.total_slot}&nbsp;{lang("slots")}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* {buttonOpen && */}
                                                            <div id={`singleLocationOne_${i}`} class="collapse location-collapse"
                                                                aria-labelledby="singleLocation" data-parent="#onlyOneLocation">
                                                                <div class="card-body">
                                                                    <div class="address-zip-wrapper">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                                <div class="location-address">
                                                                                    <div class="location-icon-with-address">
                                                                                        <div class="location-icon">
                                                                                            <i class="fi fi-contact-book"></i>
                                                                                        </div>
                                                                                        <div class="location-zipCode">
                                                                                            <span class="small-text-black-16">{lang("address")}</span>
                                                                                            <span class="small-text-black-16">: </span>
                                                                                            <span class="small-text-16">{driveInData.location}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                                <div class="location-address">
                                                                                    <div
                                                                                        class="location-icon-with-address location-zipCode-main">
                                                                                        <div class="location-icon">
                                                                                            <i class="fa fa-map-pin"></i>
                                                                                        </div>
                                                                                        <div class="location-zipCode">
                                                                                            <span class="small-text-black-16">{lang("zip_code_")} </span>
                                                                                            <span class="small-text-black-16">: </span>
                                                                                            <span class="small-text-16">{driveInData.postal_code}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="location-line"></div>
                                                                    <div class="location-more-details-table">
                                                                        <div class="table-responsive">
                                                                            <table class="table location-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {lang("date,_cw")}
                                                                                        </th>
                                                                                        <th>
                                                                                            {lang("free/total")}
                                                                                        </th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {/* {lang("from")} {moment(driveInData.from_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber}) */}
                                                                                            {lang("from")} {moment(driveInData.from_date).format(dateFormat)} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(dateFormat)} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber})
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="free-total-tag">
                                                                                                {driveInData.available_slot == 0 ? (
                                                                                                    <div className="inner-tag red-tag" style={{ backgroundColor: 'red' }}>
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : driveInData.available_slot / driveInData.total_slot == 1 ? (
                                                                                                    <div className="inner-tag green-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : driveInData.available_slot / driveInData.total_slot >= 0.5 ? (
                                                                                                    <div className="inner-tag orange-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="inner-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div className="inner-tag">
                                                                                                    <span>{driveInData.total_slot}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>
                                                                                            {driveInData.total_slot === 0 ? (
                                                                                                <button type="button" className="btn red-btn reserve-btn" disabled>
                                                                                                    {lang("reserve")}
                                                                                                </button>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to="/customer/calendar"
                                                                                                    state={{
                                                                                                        insurance_client_id: this.props.router.location.state?.insurance_client_id,
                                                                                                        insurance_name: this.props.router.location.state?.insurance_name,
                                                                                                        driveIn: driveInData?.drive_through_id,
                                                                                                        from_date: driveInData.from_date,
                                                                                                        to_date: driveInData.to_date,
                                                                                                        uuid: this.props.router.location.state.uuid,
                                                                                                        customer_id: this.props.router.location.state.customer_id,
                                                                                                        reg_no: this.props.router.location.state.reg_no,
                                                                                                        order_id: this.props.router.location.state.order_id,
                                                                                                        claim_number: this.props.router.location.state.claim_number,
                                                                                                        first_name: this.props.router.location.state.first_name,
                                                                                                        last_name: this.props.router.location.state.last_name,
                                                                                                        is_booking: this.props.router.location.state.is_booking,
                                                                                                    }}
                                                                                                >
                                                                                                    <button type="button" className="btn red-btn reserve-btn">
                                                                                                        {lang("reserve")}
                                                                                                    </button>
                                                                                                </Link>
                                                                                            )}
                                                                                        </td>

                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div class="location-overlay"></div>
                            </div>
                        }

                        <div class="more-location-drawer-wrapper">
                            <div class={`location-drawer-main ${toggleMoreLocation ? 'multi-location-drawer reveal-location-drawer reveal-location-drawer' : ''}`} id="all-locations">
                                <div class="drawer-close-btn">
                                    <button class="btn closeLocationDrawer" onClick={() => handleToggle()}>
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <div class="location-cards-wrapper">
                                    <div class="row" >
                                        <div class="col-12 111">
                                            <div class="accordion" id="multiLocation" >
                                                {moreLocation.length && moreLocation?.map((multiLocation, i) => {


                                                    return <div class="card location-card" key={i}>
                                                        <div class="card-header" id="multiLocationOne" >
                                                            <div>
                                                                <div class="row align-items-center">
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="location-name">
                                                                            <span class="small-text-black-16">{lang("address")}</span>
                                                                            <span class="small-text-black-16">: </span>
                                                                            <span class="small-text-red-16">{multiLocation.location_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div class="col-lg-6">
                                                                    <div class="location-view-slot-main">
                                                                        <div class="location-slot">
                                                                            <button class="btn red-btn" data-toggle="collapse" data-target={`#ml-${i}`}
                                                                                aria-expanded="true" aria-controls={`ml-${i}`} onClick={() => handelClick(multiLocation)}>{multiLocation.total_slot} Slots</button>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                        <div class="location-view-slot-main">
                                                                            <div class="location-slot">
                                                                                <button class="btn red-btn" data-toggle="collapse" data-target={`#ml-${i}`}
                                                                                    aria-expanded="true" aria-controls={`ml-${i}`} onClick={() => multiLocation.total_slot > 0 && handelClick(multiLocation)} disabled={multiLocation.total_slot === 0}>
                                                                                    {multiLocation.total_slot} {lang("slots")}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id={`ml-${i}`} class="collapse location-collapse"
                                                            aria-labelledby="multiLocationOne" data-parent="#multiLocation" key={i}>
                                                            {driveIn?.map((driveInData, i) => {
                                                                const weekNumber = moment(driveInData.from_date).week();
                                                                const fromDateDay = moment(driveInData.from_date).format('dd');
                                                                const toDateDay = moment(driveInData.to_date).format('dd');
                                                                return <div class="card-body">
                                                                    <div class="address-zip-wrapper">
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                                                <div class="location-address">
                                                                                    <div class="location-icon-with-address">
                                                                                        <div class="location-icon">
                                                                                            <i class="fi fi-contact-book"></i>
                                                                                        </div>
                                                                                        <div class="location-zipCode">
                                                                                            <span class="small-text-black-16">{lang("address")}</span>
                                                                                            <span class="small-text-black-16">: </span>
                                                                                            <span class="small-text-16">{driveInData.location}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                                                <div class="location-address">
                                                                                    <div
                                                                                        class="location-icon-with-address location-zipCode-main">
                                                                                        <div class="location-icon">
                                                                                            <i class="fa fa-map-pin"></i>
                                                                                        </div>
                                                                                        <div class="location-zipCode">
                                                                                            <span class="small-text-black-16">{lang("zip_code_")}</span>
                                                                                            <span class="small-text-black-16">: </span>
                                                                                            <span class="small-text-16">{driveInData.postal_code}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="location-line"></div>
                                                                    <div class="location-more-details-table">
                                                                        <div class="table-responsive">
                                                                            <table class="table location-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {lang("date,_cw")}
                                                                                        </th>
                                                                                        <th>
                                                                                            {lang("free/total")}
                                                                                        </th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {/* {lang("from")} {moment(driveInData.from_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber}) */}
                                                                                            {lang("from")} {moment(driveInData.from_date).format(dateFormat)} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(dateFormat)} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber})
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="free-total-tag">
                                                                                                {driveInData.available_slot == 0 ? (
                                                                                                    <div className="inner-tag red-tag" style={{ backgroundColor: 'red' }}>
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : driveInData.available_slot / driveInData.total_slot == 1 ? (
                                                                                                    <div className="inner-tag green-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : driveInData.available_slot / driveInData.total_slot >= 0.5 ? (
                                                                                                    <div className="inner-tag orange-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="inner-tag">
                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div className="inner-tag">
                                                                                                    <span>{driveInData.total_slot}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {driveInData.total_slot === 0 ? (
                                                                                                <button type="button" className="btn red-btn reserve-btn" disabled>
                                                                                                    {lang("reserve")}
                                                                                                </button>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to="/customer/calendar"
                                                                                                    state={{
                                                                                                        insurance_client_id: this.props.router.location.state?.insurance_client_id,
                                                                                                        insurance_name: this.props.router.location.state?.insurance_name,
                                                                                                        driveIn: driveInData?.drive_through_id,
                                                                                                        from_date: driveInData.from_date,
                                                                                                        to_date: driveInData.to_date,
                                                                                                        uuid: this.props.router.location.state.uuid,
                                                                                                        customer_id: this.props.router.location.state.customer_id,
                                                                                                        reg_no: this.props.router.location.state.reg_no,
                                                                                                        order_id: this.props.router.location.state.order_id,
                                                                                                        claim_number: this.props.router.location.state.claim_number,
                                                                                                        first_name: this.props.router.location.state.first_name,
                                                                                                        last_name: this.props.router.location.state.last_name,
                                                                                                        is_booking: this.props.router.location.state.is_booking,
                                                                                                    }}
                                                                                                >
                                                                                                    <button type="button" className="btn red-btn reserve-btn">
                                                                                                        {lang("reserve")}
                                                                                                    </button>
                                                                                                </Link>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="location-overlay"></div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(LocationComponent);
