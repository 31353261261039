import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import user from "../../assets/images/user.svg";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import withRouter from '../../withRouter';
import LanguageContext from '../../context/LanguageProvider';
import Cookies from 'js-cookie';

export class CustomerDetailComponent extends Component {
    static contextType = LanguageContext;
    // constructor(props) {
    //     super(props);   
    // }
    render() {
        const { salutationError, professionError, cityError, stateError, countryError, houseNumberError, streetError, mobileNumberError, emailError, lastNameError, firstNameError, postalCodeError, mileageError, country_code, handleChange, handlePhone, isButtonClicked, handleNext, userData, salutation, profession, firstName, lastName, email, mobileNumber, street, houseNumber, state, city, postalCode, km_stand, loading } = this.props;
        // const { uuid } = this.props.router?.location?.state;
        const { lang } = this.context
        const country = this.props.country
        let organizationCountry = Cookies.get('organizationCountry')
        const countryCodeObj = {
            "Switzerland": "ch",
            "Germany": "de",
            "India": "in",
            "Slovenia": "si",
        };
        const defaultCountry = organizationCountry ? countryCodeObj[organizationCountry] : countryCodeObj[country];
        const currentPath = window.location.pathname;
        const isReschedule = currentPath.includes('reschedule');
        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper add-customer-details-card-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src={user} alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18">{lang("add_your_details")}</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <form method='post'>
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("salutation")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='salutation' value={salutation} onChange={handleChange}>
                                                                        <option value="Please select" disabled>{lang("please_select")}</option>
                                                                        <option value="Ms.">{lang("ms.")}</option>
                                                                        <option value="Mr.">{lang("mr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                    {salutationError && (<span class="error-message">{salutationError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("title")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='profession' value={profession} onChange={handleChange}>
                                                                        <option value="Please select" disabled>{lang("please_select")}</option>
                                                                        <option value="Dr.">{lang("dr.")}</option>
                                                                        <option value="Prof.">{lang("prof.")}</option>
                                                                        <option value="Prof. Dr.">{lang("prof._dr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                    {professionError && (<span class="error-message">{professionError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("first_name")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="firstName" value={firstName} onChange={handleChange} class="form-control"
                                                                        placeholder={lang('enter_first_name')} />
                                                                    {firstNameError && (<span class="error-message">{firstNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("last_name")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="lastName" value={lastName} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_last_name")} />
                                                                    {lastNameError && (<span class="error-message">{lastNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('street')}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="street" value={street} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_street_name")} />
                                                                    {streetError && (<span class="error-message">{streetError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("house_number")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="houseNumber" value={houseNumber} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_house_number")} />
                                                                    {houseNumberError && (<span class="error-message">{houseNumberError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("postal_code")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="postalCode" value={postalCode} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_postal_code")} />
                                                                    {postalCodeError && (<span class="error-message">{postalCodeError}</span>)}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("city")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="city" value={city} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_city")} />
                                                                    {cityError && (<span class="error-message">{cityError}</span>)}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("state")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="state" value={state} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_state")} />
                                                                    {stateError && (<span class="error-message">{stateError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("country")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="country" value={country} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_country")} readOnly />
                                                                    {countryError && (<span class="error-message">{countryError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group mobile-input-group">
                                                                <label for="" class="form-label">{lang("mobile_number")}</label>
                                                                <div class="form-control-top mobile-input">
                                                                    {/* <PhoneInput
                                                                        inputClass="form-control"
                                                                        containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        country={'us'}
                                                                        value={`${mobileNumber}`}
                                                                        onChange={(val) => {
                                                                            handlePhone(val)
                                                                        }}
                                                                        addInternationalOption={false}
                                                                    // ref={ref => {
                                                                    //     phoneInput = ref;
                                                                    // }}
                                                                    /> */}
                                                                    <PhoneInput
                                                                        inputClass="form-control"
                                                                        // containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        country={!mobileNumber ? defaultCountry : ""}
                                                                        // country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                        value={mobileNumber}
                                                                        onChange={(val) => {
                                                                            handlePhone(val)
                                                                        }}
                                                                        // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                        addInternationalOption={false}
                                                                        // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                        defaultErrorMessage='Please enter valid contact number'
                                                                    />
                                                                    {mobileNumberError && (
                                                                        <span class="error-message">{mobileNumberError}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("email")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="email" name="email" value={email} onChange={handleChange} class="form-control"
                                                                        placeholder={lang("enter_email_id")} />
                                                                    {emailError && (<span class="error-message">{emailError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("mileage")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="km_stand" value={km_stand} onChange={(e) => handleChange(e)} class="form-control"
                                                                        placeholder={lang("enter_mileage")} />
                                                                    {mileageError && (<span class="error-message">{mileageError}</span>)}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <div class="card-btn prev-btn">

                                                    <Link to={this.props.router?.location?.state?.uuid ? `/appointment/book/${this.props.router?.location?.state?.uuid || ''}` : "/appointment/book"} class="btn prev-btn black-btn">
                                                        <span><i class="fa fa-long-arrow-left"></i></span>
                                                        {lang("prev")}</Link>

                                                </div>
                                                <div class="card-btn next-btn-main">
                                                    {/* disabled={isButtonClicked}  For disable */}
                                                    {isReschedule ?
                                                        <button class="btn next-btn red-btn" type="btn" onClick={handleNext} data-target="#rescheduleAppointment"
                                                            data-toggle="modal">{lang("next")}
                                                            <span><i class="fa fa-long-arrow-right"></i></span>
                                                        </button>
                                                        : <Link to="/customer/location-list" class="btn next-btn red-btn" type="btn" onClick={handleNext} >{lang("next")}
                                                            <span><i class="fa fa-long-arrow-right"></i></span>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                </main>
            </>
        );
    }
}

export default withRouter(CustomerDetailComponent);
