import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import LanguageContext from "../../context/LanguageProvider";

export default class UserlistChart extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    // console.log(this.props.series,"")
    this.state = {
      series: [],
      labels: [],
      // series: [
      //   {
      //     name: "Booked by Me",
      //     data: this.props.book_by_me
      //   },
      //   {
      //     name: "Total slot of all users",
      //     data: this.props.total_slot_book_by_users
      //   },
      //   {
      //     name: "Remaining day slot",
      //     data:this.props.total_day_slot
      //   },
      // ],
    };
  }

  // componentDidUpdate = async (props) => {
  //   console.log(props,"propsprops")
  //   if(!this.state.series.length) {
  //     this.setState({
  //       series: props.series
  //     });
  //   }
  // }

  // static getDerivedStateFromProps(props, state) {
  //   if(!state.series.length) {
  //     return {
  //       series: props.series
  //     }
  //   }
  //  }

  getOption = () => {
    return {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: [ "#FC553C", "#147969","#D8B755"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      // dataLabels: {
      //     enabled: false
      //   },
      xaxis: {
        type: "name",
        categories: this.props.date,
      },
      legend: {
        position: "top",
        offsetY: 0,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      fill: {
        opacity: 1,
      },
    };
  };

  render() {
    const { language, lang } = this.context;
    return (
      <div id="chart">
        <div className="row">
          <div className="col-12">
            <div className="next-prev-userLisgt-wrapper">
            <div className="next-prev-btn prev-btn">
              <button
                className="btn red-btn"
                onClick={this.props.handlePrevchart}
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </div>
            <div className="week-name-div">
              <p>
                {lang("cw")}-{this.props.chart_week}
              </p>
            </div>
            <div className="next-prev-btn next-btn">
              <button
                className="btn red-btn"
                onClick={this.props.handleNextchart}
              >
                <i className="fa fa-arrow-right"></i>
              </button>
            </div>
            </div>
          </div>
        </div>
        <ReactApexChart
          options={this.getOption()}
          series={this.props.series != undefined ? this.props.series : ""}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}
