import React, { Component } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

class PrivateRoute extends Component {
  render() {
    const { children, userData, ...rest } = this.props;
    return userData.isLoggedIn ?  children  : <Navigate to="/login" />;
  }
}

const mapStateToProps = state => ({
  userData: state,
});

export default connect(mapStateToProps)(PrivateRoute);
