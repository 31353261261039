import React, { Component } from 'react';
import withRouter from '../../withRouter';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import LanguageContext from '../../context/LanguageProvider';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import user from "../../assets/images/user.svg";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Cookies from 'js-cookie';
import moment from "moment/moment";
import { CookiesRemove } from '../../CookiesRemove';

const POSTAL_CODE = 2000;
const CITY='Maribor';
export class HelpDeskRescheduleDetails extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            email: '',
            // emailError: '',
            mobileNumber: '',
            // mobileNumberError: '',
            street: '',
            // streetError: '',
            houseNumber: '',
            // houseNumberError: '',
            country: '',
            countryError: '',
            state: '',
            // stateError: '',
            city:CITY,
            cityError: '',
            postalCode: POSTAL_CODE,
            postalCodeError: '',
            km_stand: '',
            // mileageError: '',
            userData: {},
            uuid: "",
            insurance_client_id: '',
            loading: false,
            isButtonClicked: false,
            country_code: '',
            salutation: 'Mr.',
            salutationError: '',
            profession: 'Please select',
            professionError: '',
            claim_number: '',
            note: '',
            isModalOpen: false,
            isBookingModal: false,
            uuidFromUrl: '',
            isTimeReached: false,
            isDeleteModel:false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }

    handlePhone = (val) => {
        // console.log(this.state.mobileNumber,"mobileNumber");
        this.setState({
            mobileNumber: val
        })
    }

    validateInputs = () => {
        const { lang } = this.context;
        let firstNameError = '';
        let lastNameError = '';
        // let emailError = '';
        // let mobileNumberError = '';
        // let streetError = '';
        // let houseNumberError = '';
        // let countryError = '';
        // let stateError = '';
        // let cityError = '';
        // let postalCodeError = '';
        // let mileageError = '';
        // let professionError = '';
        // let salutationError = '';

        if (!this.state.firstName?.trim()) {
            firstNameError = `${lang("please_enter_your_first_name.")}`;
        }

        if (!this.state.lastName?.trim()) {
            lastNameError = `${lang("please_enter_your_last_name.")}`;
        }

        // if (!this.state.email?.includes('@')) {
        //     emailError = `${lang("please_enter_your_email_id.")}`;
        // }

        // if (!this.state.mobileNumber?.trim()) {
        //     mobileNumberError = `${lang("please_enter_your_mobile_number.")}`;
        // }

        // if (!this.state.street?.trim()) {
        //     streetError = `${lang("please_enter_your_street_name.")}`;
        // }

        // if (!this.state.houseNumber.trim()) {
        //     houseNumberError = `${lang("please_enter_your_house_number.")}`;
        // }

        // if (!this.state.country) {
        //     countryError = `${lang("please_enter_your_country.")}`;
        // }

        // if (!this.state.state.trim()) {
        //     stateError = `${lang("please_enter_your_state.")}`;
        // }

        // if (!this.state.city?.trim()) {
        //     cityError = `${lang("please_enter_your_city.")}`;
        // }

        // if (!this.state.postalCode?.trim()) {
        //     postalCodeError = `${lang("please_enter_your_postal_code.")}`;
        // }

        // if (!this.state.km_stand.trim()) {
        //     mileageError = `${lang("please_enter_your_mileage.")}`;
        // }

        // if (!this.state.salutation.trim()) {
        //     salutationError = `${lang("please_enter_your_salutation.")}`;
        // }

        // if (!this.state.profession.trim()) {
        //     professionError = `${lang("please_enter_your_profession.")}`;
        // }

        this.setState({
            firstNameError,
            lastNameError,
            // emailError,
            // mobileNumberError,
            // streetError,
            // houseNumberError,
            // countryError,
            // stateError,
            // cityError,
            // postalCodeError,
            // mileageError,
            // salutationError,
            // professionError,
        });

        return !firstNameError && !lastNameError  ;
        // return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !stateError && !cityError && !postalCodeError && !mileageError;
        // return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !stateError && !cityError && !postalCodeError && !mileageError && !professionError && !salutationError;
    }

    componentDidMount = async () => {
        console.log(this.props.router.location.state.data,"reop");
        console.log( this.props?.router?.location.state?.data?.reg_no,"reop");
        console.log( this.props?.router?.location,"172");
        // await this.getAppointmentData();
        this.setState({
        userData: this.props?.router?.location.state,
        firstName: this.props?.router?.location.state?.first_name,
        lastName: this.props?.router?.location.state?.last_name,
        email: this.props?.router?.location.state?.email_id,
        mobileNumber: this.props?.router?.location.state?.contact_no,
        street: this.props?.router?.location.state?.street,
        houseNumber: this.props?.router?.location.state?.house_no,
        country: this.props?.router?.location.state?.organizationCountry,
        state: this.props?.router?.location.state?.state,
        city: this.props?.router?.location.state?.city,
        postalCode: this.props?.router?.location.state?.pincode,
        km_stand: this.props?.router?.location.state?.km_stand,
        uuid: this.props?.router?.location.state?.uuid,
        insurance_client_id: this.props?.router?.location.state?.insurance_client_id,
        salutation: this.props?.router?.location.state?.salutation,
        profession: this.props?.router?.location.state?.profession,
        claim_number: this.props?.router?.location.state?.claim_number,
        order_id: this.props?.router?.location.state?.order_id,
        reg_no: this.props?.router?.location.state?.reg_no,
        note: this.props?.router?.location.state?.note,
    })
    }

    getAppointmentData = () => {
        this.setState({ loading: true });
        const { insurance_number, order_id, reg_no } = this.props.router.location.state
        // console.log(insurance_no,this.props.router.location.state);
        let url = `${configuration.sendDataSearch}insurance_no=${insurance_number || ''}&order_id=${order_id || ''}&reg_no=${reg_no || ''}`

        axios.get(url)
            .then(
                res => {
                    if (res.data.success) {
                        let organizationCountry = Cookies.get('organizationCountry')
                        if (res.data.data.is_over_time_resudule == true) {
                            this.setState({
                                isTimeReached: true,
                            })
                        }
                        else {
                            if (res.data.data.is_booking == 1) {
                                this.setState({
                                    userData: res.data.data,
                                    firstName: res.data.data.first_name,
                                    lastName: res.data.data.last_name,
                                    email: res.data.data.email_id,
                                    mobileNumber: res.data.data.contact_no,
                                    street: res.data.data.street,
                                    houseNumber: res.data.data.house_no,
                                    country: organizationCountry,
                                    state: res.data.data.state,
                                    city: res.data.data.city,
                                    postalCode: res.data.data.pincode,
                                    km_stand: res.data.data.km_stand,
                                    uuid: res.data.data.uuid,
                                    insurance_client_id: res.data.data.insurance_client_id,
                                    salutation: res.data.data.salutation,
                                    profession: res.data.data.profession,
                                    claim_number: res.data.data.claim_number,
                                    order_id: res.data.data.order_id,
                                    reg_no: res.data.data.reg_no,
                                    note: res.data.data.note,
                                    loading: false,
                                    isTimeReached: false,
                                });
                            }
                            else {
                                this.setState({
                                    isBookingModal: true,
                                    order_id: res.data.data.order_id,
                                    isTimeReached: false,
                                })
                            }
                        }
                    }
                }
            )
            .catch(error => {
                this.setState({ loading: false });
                if (error.response.status == 404) {
                    // this.props.router.navigate('/helpdesk/create/appointment')
                    // toastAlert('Order Id not Found', 'error');
                    this.setState({ isBookingModal: true })
                } else if (error.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(error.response.data.message, 'error');
                    this.props.router.navigate('/login');
                    // Cookies.remove('id');
                    // Cookies.remove('organization_country');
                    // Cookies.remove('globalUserId');
                    // Cookies.remove('isAuthenticated');
                    // Cookies.remove('globalOrganizationId');
                    // Cookies.remove('name');
                    // Cookies.remove('organizationName');
                    // Cookies.remove('organizationCountry');
                    // Cookies.remove('regionName');
                    // Cookies.remove('roleId');
                    // Cookies.remove('isLoggedIn');
                    // Cookies.remove('authToken');
                    // Cookies.remove('uuid');
                    // Cookies.remove('userData');
                    // Cookies.remove('organizationCountry');
                    // Cookies.remove('role_name');
                    // Cookies.remove('clientTypeSetting');
                    // localStorage.removeItem('access_token');
                    // var element = document.getElementsByTagName("html")[0];
                    // element.classList.remove("otherclass");
                }
                else {
                    toastAlert(error.response.data.message, 'error');
                    this.setState({ isBookingModal: false })
                }
            });

    }
    deleteopenModel  = (item) => {
        console.log(item, "202line");
        this.setState({
          deleteuuid: item,
        });
        this.setState({isDeleteModel: true });
      };
      isDeletecloseModal = () => {
        this.setState({ isDeleteModel: false,loading:false });
      };  
      handleDeleteButtonClick = () => {
        console.log(this.state.deleteuuid, "line123");
        let uuid = this.state.deleteuuid;
        const url = configuration.deleteAppointment + uuid;
        axios
          .get(url) //  <- Payload
          .then((response) => {
            if (response.data.success) {
                this.props.router.navigate('/helpdesk/options');
              Swal.fire(
                `${lang("deleted!")}!`,
                `${lang("your_appointment_has_been_deleted.")}`,
                "success"
              );
            }
          })
          .catch((error) => {
            Swal.fire(
              `${lang("deleted!")}!`,
              `${lang("you_already_deleted_your_appointment.")}`,
              "success"
            );
            this.setState({ loading: false });
          });
        this.isDeletecloseModal();
      }; 
       

    handleNext = (e) => {
        e.preventDefault();
        const isValid = this.validateInputs();
        if (isValid) {
            this.setState({ loading: true });
            this.setState({ isModalOpen: true });
            this.setState({ isButtonClicked: true });
            let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
            // let uuid = this.state.uuidFromUrl

            const is_booking = this.state.userData.is_booking

            if (is_booking) {
                const url = configuration.customerBookingInfo + uuid;
                axios.get(url)  //  <- Payload 
                    .then(response => {
                        if (response.data.success) {
                            this.setState({
                                date: response.data.data.date,
                                time: response.data.data.from_time
                            })
                        }

                    })
                    .catch(error => {
                        toastAlert(error.response.data.message, 'error');
                        if (error.response.data.code === 401) {
                            CookiesRemove();
                            toastAlert(error.response.data.message, 'error');
                            this.props.router.navigate('/login');
                            // Cookies.remove('id');
                            // Cookies.remove('organization_country');
                            // Cookies.remove('globalUserId');
                            // Cookies.remove('isAuthenticated');
                            // Cookies.remove('globalOrganizationId');
                            // Cookies.remove('name');
                            // Cookies.remove('organizationName');
                            // Cookies.remove('organizationCountry');
                            // Cookies.remove('regionName');
                            // Cookies.remove('roleId');
                            // Cookies.remove('isLoggedIn');
                            // Cookies.remove('authToken');
                            // Cookies.remove('uuid');
                            // Cookies.remove('userData');
                            // Cookies.remove('organizationCountry');
                            // Cookies.remove('role_name');
                            // Cookies.remove('clientTypeSetting');
                            // localStorage.removeItem('access_token');
                            // var element = document.getElementsByTagName("html")[0];
                            // element.classList.remove("otherclass");
                        }
                    });
            }
        }

    }


    handleModalNext = () => {
        let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
        // const reg_no = this.props.router.location.state.reg_no
        // const order_id = this.props.router.location.state.order_id
        // const claim_number = this.props.router.location.state.claim_number

        // let country_code = "123"
        let country_code = this.props.router.location.state.country_code
        const url = configuration.updateData;
        const { firstName, reg_no, claim_number, order_id, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, salutation, profession, note } = this.state;
        console.log(reg_no,"reg_no");
        console.log(claim_number,"claim_number");
        let organizationCountry = Cookies.get('organizationCountry')
        const payLoad =
        {
            'reg_no': reg_no,
            'order_id': order_id,
            'claim_number': claim_number,
            'uuid': uuid,
            'country_code': country_code,
            'first_name': firstName,
            'last_name': lastName,
            'email_id': email,
            'contact_no': mobileNumber,
            'street': street,
            'house_no': houseNumber,
            'country': organizationCountry,
            'state': state,
            'city': city || CITY,
            'pincode': postalCode || POSTAL_CODE,
            'km_stand': km_stand,
            'salutation': salutation,
            'profession': profession,
            'note': note,
        }
        axios.post(url, payLoad)  //  <- Payload 
            .then(response => {
                if (response.data.success) {
                    this.setState({ loading: false });
                    this.setState({
                        date: response.data.data.date,
                        time: response.data.data.from_time
                    })
                }

            })
            .catch(error => {
                this.setState({ loading: false });
                toastAlert(error.response.data.message, 'error');
                if (error.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(error.response.data.message, 'error');
                    this.props.router.navigate('/login');
                    // Cookies.remove('id');
                    // Cookies.remove('organization_country');
                    // Cookies.remove('globalUserId');
                    // Cookies.remove('isAuthenticated');
                    // Cookies.remove('globalOrganizationId');
                    // Cookies.remove('name');
                    // Cookies.remove('organizationName');
                    // Cookies.remove('organizationCountry');
                    // Cookies.remove('regionName');
                    // Cookies.remove('roleId');
                    // Cookies.remove('isLoggedIn');
                    // Cookies.remove('authToken');
                    // Cookies.remove('uuid');
                    // Cookies.remove('userData');
                    // Cookies.remove('organizationCountry');
                    // Cookies.remove('role_name');
                    // Cookies.remove('clientTypeSetting');
                    // localStorage.removeItem('access_token');
                    // var element = document.getElementsByTagName("html")[0];
                    // element.classList.remove("otherclass");
                }
            });
    }

    handleToggle = () => {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen,
            loading: false,
        }));
    }

    handleKeyDown=(e)=>{
        ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
    }


    render() {
        console.log(this.state.firstName,"firstName");
        const { profession, salutationError, professionError, salutation, mobileNumberError, firstNameError, lastNameError, emailError, streetError, houseNumberError, countryError, stateError, cityError, postalCodeError, mileageError, isButtonClicked, userData, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, loading, time, date, country_code, note, order_id } = this.state;
        const uuid = this.props?.router?.location?.state?.uuid || '';
        const { lang, language } = this.context;
        let organizationCountry = Cookies.get('organizationCountry')
        const countryCodeObj = {
            "Switzerland": "ch",
            "Germany": "de",
            "India": "in",
            "Slovenia": "si",
        };
        const defaultCountry = countryCodeObj[organizationCountry];
        const dateFormat = Cookies.get('dateFormat')
        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper add-customer-details-card-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src={user} alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18">{lang("add_your_details")}</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <form method='post' onSubmit={this.handleNext}>
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("salutation")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='salutation' value={salutation} onChange={this.handleChange}>
                                                                        <option value="Please select">{lang("please_select")}</option>
                                                                        <option value="Ms.">{lang("ms.")}</option>
                                                                        <option value="Mr.">{lang("mr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                    {salutationError && (<span class="error-message">{salutationError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("title")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='profession' value={profession} onChange={this.handleChange}>
                                                                        <option value="Please select">{lang("please_select")}</option>
                                                                        <option value="Dr.">{lang("dr.")}</option>
                                                                        <option value="Prof.">{lang("prof.")}</option>
                                                                        <option value="Prof. Dr.">{lang("prof._dr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                    {professionError && (<span class="error-message">{professionError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("first_name")}  <span class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="firstName" value={firstName} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang('enter_first_name')} />
                                                                    {firstNameError && (<span class="error-message">{firstNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("last_name")} <span class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="lastName" value={lastName} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_last_name")} />
                                                                    {lastNameError && (<span class="error-message">{lastNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('street')}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="street" value={street} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_street_name")} />
                                                                    {/* {streetError && (<span class="error-message">{streetError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("house_number")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="houseNumber" value={houseNumber} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_house_number")} />
                                                                    {/* {houseNumberError && (<span class="error-message">{houseNumberError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("postal_code")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="postalCode" value={postalCode} onKeyDown={this.handleKeyDown} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_postal_code")} />
                                                                    {/* {postalCodeError && (<span class="error-message">{postalCodeError}</span>)} */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("city")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="city" value={city} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_city")} />
                                                                    {/* {cityError && (<span class="error-message">{cityError}</span>)} */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        {/* <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("state")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="state" value={state} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_state")} /> */}
                                                                    {/* {stateError && (<span class="error-message">{stateError}</span>)} */}
                                                                {/* </div>
                                                            </div>
                                                        </div> */}
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("country")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="country" value={organizationCountry} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_country")} readOnly />
                                                                    {countryError && (<span class="error-message">{countryError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group mobile-input-group">
                                                                <label for="" class="form-label">{lang("mobile_number")}</label>
                                                                <div class="form-control-top mobile-input">
                                                                    {/* <PhoneInput
                                                                        inputClass="form-control"
                                                                        containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        country={'us'}
                                                                        value={`${mobileNumber}`}
                                                                        onChange={(val) => {
                                                                            this.handlePhone(val)
                                                                        }}
                                                                        addInternationalOption={false}
                                                                    // ref={ref => {
                                                                    //     phoneInput = ref;
                                                                    // }}
                                                                    /> */}
                                                                    <PhoneInput
                                                                        inputClass="form-control"
                                                                        // containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        // country={!mobileNumber ? defaultCountry : ""}
                                                                        country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                        value={mobileNumber ? `${mobileNumber}` : ''}
                                                                        onChange={(val) => {
                                                                            this.handlePhone(val)
                                                                        }}
                                                                        // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                        addInternationalOption={false}
                                                                        // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                        defaultErrorMessage='Please enter valid contact number'
                                                                    />
                                                                    {/* {mobileNumberError && (
                                                                        <span class="error-message">{mobileNumberError}</span>
                                                                    )} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("email")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="email" name="email" value={email} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_email_id")} />
                                                                    {/* {emailError && (<span class="error-message">{emailError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("mileage")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="km_stand" value={km_stand} onKeyDown={this.handleKeyDown} onChange={(e) => this.handleChange(e)} class="form-control"
                                                                        placeholder={lang("enter_mileage")} />
                                                                    {/* {mileageError && (<span class="error-message">{mileageError}</span>)} */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="note" class="form-label">{lang("note")}</label>
                                                                <div class="form-control-top">
                                                                    <textarea name="note" value={note} onChange={(e) => this.handleChange(e)} class="form-control"
                                                                        placeholder={lang("enter_note")} />
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <div class="card-btn prev-btn">

                                                    <Link to="/helpdesk/reschedule" class="btn prev-btn black-btn">
                                                        <span><i class="fa fa-long-arrow-left"></i></span>
                                                        {lang("prev")}</Link>

                                                </div>
                                                <div class="card-btn next-btn-main">
                                                    {/* disabled={isButtonClicked}  For disable */}

                                                    <button class="btn next-btn red-btn" type="submit" data-target="#rescheduleAppointment"
                                                        data-toggle="modal">{lang("next")}
                                                        <span><i class="fa fa-long-arrow-right"></i></span>
                                                    </button>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                </main>
                <Modal isOpen={this.state.isModalOpen} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader>
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20" data-key="Yourdriveinappointmentisbooked">{lang("your_drive-in_appointment_is_booked.")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")}</span><span
                                                    class="small-text-black-16">: </span>
                                                {/* <span class="appointment-date-value small-text-16">{moment(date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')}</span> */}
                                                <span class="appointment-date-value small-text-16">{moment(date).format(dateFormat)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")}</span><span
                                                    class="small-text-black-16">: </span>
                                                <span class="appointment-date-value small-text-16">{time}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16" data-key="Areyousureyouwanttorescheduleyourappointment?">{lang("are_you_sure_you_want_to_reschedule_your_appointment?")}</p>

                                </div>
                            </div> */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom1 your-appointment-is-booked-modal">
                        <button class="btn gray-btn delete-btn-modal" onClick={() =>
                                            this.deleteopenModel(this.state.uuid)
                                          }>
                                <span><i class="fa fa-trash"></i></span>
                                <span data-key="Delete">{lang("cancel")}</span>
                        </button>
                            <Link to='/helpdesk/location-list' state={{
                                uuid: this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid,
                                insurance_client_id: this.state?.insurance_client_id,
                                insurance_name: this.state?.userData?.insurance_name,
                                customer_id: this.state?.userData?.id,
                                first_name: this.state?.userData?.first_name,
                                last_name: this.state?.userData?.last_name,
                                reg_no: this.state?.userData?.reg_no,
                                order_id: this.props.router.location.state.order_id ? this.props.router.location.state.order_id : this.state.order_id,
                                claim_number: this.state?.userData?.claim_number,
                                // claim_number : this.props.router.location.state.claim_number,
                            }}>
                                <button class="btn red-btn reschedule-btn" onClick={this.handleModalNext}>
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("reschedule")}</span>
                                    
                                </button>
                            </Link>
                            
                              
                                
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal
                isOpen={this.state.isDeleteModel}
                toggle={this.isDeletecloseModal}
                className="appointment-list-modal modal-dialog-centered custom-modal"
              >
                {/* <ModalHeader>
                  {lang('cancel_appointment')}
                </ModalHeader> */}
                <ModalBody>
                    <div className='table-delete-modal'>
                        <h6 className="booked-appointment-content">
                            {lang('do_you_want_to_cancel_your_appointment')}
                        </h6>
                    </div>
                </ModalBody>
                <ModalFooter className='delete-table-modal-footer'>
                  <button className="btn gray-btn user-modal-btn" color="secondary" onClick={this.isDeletecloseModal}>
                    {lang('no')}
                  </button>
                  <button  className="btn red-btn user-modal-btn" color="danger" onClick={this.handleDeleteButtonClick}>
                    {lang('yes')}
                  </button>
                </ModalFooter>
              </Modal>
              
                <Modal isOpen={this.state.isTimeReached} className="appointment-list-modal" toggle={() => this.handleToggle} centered >
                    {/* <ModalHeader
                        toggle={() => this.handleToggle()}
                        close={
                            <button type="button" className="close" onClick={() => this.handleToggle()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                    </ModalHeader> */}
                    <ModalBody>
                        <div class="booked-appointment-content">
                            <div class="your-appointment-booked-image-content">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="booked-appointment-image">
                                            <img src="../../assets/images/booked-check.svg" alt="Booked appointment" />
                                        </div>
                                        <div class="booked-appointment-text">
                                            <h4 class="modal-title-20">{lang('time_limit_reached_can_not_reschedule_before_24_hours.')}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="confirm-modal-date-time-main">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-calendar"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Date">{lang("date")} </span> <span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="data-time-icon time-text">
                                            <button class="search-btn btn icon-btn">
                                                <i class="fi fi-clock"></i>
                                            </button>
                                            <div class="date-time-text">
                                                <span class="appointment-date-title small-text-black-16" data-key="Time">{lang("time")} </span><span
                                                    class="small-text-black-16">:</span>
                                                <span class="appointment-date-value small-text-16"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="reschedule-text">
                                <div class="are-you-sure-text">
                                    <p class="small-text-16">{lang("kindly_click_on_the_create_new_appointment_button_to_create_a_new_appointment")}</p>

                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div class="modal-button two-modal-btn-custom">
                            <Link to='/helpdesk/create/appointment'>
                                <button class="btn red-btn">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <span data-key="Reschedule">{lang("create_new_appointment")}</span>
                                </button>
                            </Link>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default withRouter(HelpDeskRescheduleDetails);
