import Cookies from 'js-cookie';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, SET_ACCESS_TOKEN } from '../actions/actions';
const userData = JSON.parse(Cookies.get('userData') || '{}');
// const initialState = {
//   isLoggedIn: false,
//   accessToken: Cookies.get('authToken'),
//   id: Cookies.get('id'),
//   globalUserId: Cookies.get('globalUserId'),
//   isAuthenticated: Cookies.get('isAuthenticated'),
//   globalOrganizationId: Cookies.get('globalOrganizationId'),
//   name: Cookies.get('name'),
//   organizationName: Cookies.get('organizationName'),
//   organizationCountry: Cookies.get('organizationCountry'),
//   regionName: Cookies.get('regionName'),
//   roleId: Cookies.get('roleId'),
// };

const reducer = (state , action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.payload,
        // userData: action.payload.userData, 
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        accessToken: null,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
