import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { configuration } from '../../appConfig';
import '../login/Login.css';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import LanguageContext from "../../context/LanguageProvider";
import withRouter from '../../withRouter';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { loginSuccess, loginFailure, setAccessToken, authenticateUser } from '../../redux/actions/actions';
import Cookies from 'js-cookie';
import { CookiesRemove } from '../../CookiesRemove';
export class Login extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            email_id: '',
            password: '',
            emailError: '',
            passwordError: '',
            device_id: '',
            showPassword: false,
            loading: false,
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleToggleShowPassword = this.handleToggleShowPassword.bind(this);
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handleToggleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    mapStateToProps = state => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
        };
    };

    mapDispatchToProps = dispatch => {
        return {
            loginSuccess: () => dispatch(loginSuccess()),
            loginFailure: () => dispatch(loginFailure()),
        };
    };

    handleEmailChange = (event) => {
        this.setState({ email_id: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }



    handleLogin = (e) => {
        e.preventDefault();
        this.setState({ emailError: '', passwordError: '' });

        let isValid = true;
        const { lang, language } = this.context;

        if (this.state.email_id.trim() === '') {
            this.setState({ emailError: { message: lang('email_is_required.') } });
            isValid = false;
        } else if (!this.validateEmail(this.state.email_id)) {
            this.setState({ emailError: { message: lang('please_enter_a_valid_email.') } });
            isValid = false;
        } else {
            this.setState({ emailError: '' });
        }

        if (this.state.password.trim() === '') {
            this.setState({ passwordError: { message: lang('password_is_required.') } });
            isValid = false;
        } else {
            this.setState({ passwordError: '' });
        }

        if (isValid) {
            this.setState({ loading: true });
            const url = configuration.heplDeskLogin;
            const payload = {
                email_id: this.state.email_id,
                password: this.state.password,
            };
            this.props.authenticateUser(payload)
                .then(access_token => {
                    this.setState({ loading: false });
                    toastAlert('Login Successfully', 'success');
                    localStorage.setItem('access_token', access_token);
                    this.props.setAccessToken(access_token); // Set Access Token In redux
                    Cookies.set('email_id', this.state.email_id);
                    this.props.router.navigate('/helpdesk/options', {});
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error.code === 'Unauthorized User') {
                        CookiesRemove();
                        toastAlert('Unauthorized User', 'error');
                        // Cookies.remove('id');
                        // Cookies.remove('organization_country');
                        // Cookies.remove('globalUserId');
                        // Cookies.remove('isAuthenticated');
                        // Cookies.remove('globalOrganizationId');
                        // Cookies.remove('name');
                        // Cookies.remove('organizationName');
                        // Cookies.remove('organizationCountry');
                        // Cookies.remove('regionName');
                        // Cookies.remove('roleId');
                        // Cookies.remove('isLoggedIn');
                        // Cookies.remove('authToken');
                        // Cookies.remove('uuid');
                        // Cookies.remove('role_name');
                        // // Cookies.remove('clientLogo');
                        // Cookies.remove('clientTypeSetting');
                        // localStorage.removeItem('access_token');
                        // var element = document.getElementsByTagName("html")[0];
                        // element.classList.remove("otherclass");
                        this.props.router.navigate('/login');
                    } else {
                        toastAlert('Unauthorized User', 'error');
                        CookiesRemove();
                        // Cookies.remove('id');
                        // Cookies.remove('organization_country');
                        // Cookies.remove('globalUserId');
                        // Cookies.remove('isAuthenticated');
                        // Cookies.remove('globalOrganizationId');
                        // Cookies.remove('name');
                        // Cookies.remove('organizationName');
                        // Cookies.remove('organizationCountry');
                        // Cookies.remove('regionName');
                        // Cookies.remove('roleId');
                        // Cookies.remove('isLoggedIn');
                        // Cookies.remove('authToken');
                        // Cookies.remove('uuid');
                        // Cookies.remove('role_name');
                        // // Cookies.remove('clientLogo');
                        // Cookies.remove('clientTypeSetting');
                        // localStorage.removeItem('access_token');
                        // var element = document.getElementsByTagName("html")[0];
                        // element.classList.remove("otherclass");
                        this.props.router.navigate('/login');
                    }
                });
        }
    }


    render() {
        const { lang, language } = this.context;
        const { showPassword, loading } = this.state
        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-4">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src="assets/images/user.svg" alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18" data-key="CustomerBasicDetails">{lang("sign_up")}</h4>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <form onSubmit={this.handleLogin}>
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">
                                                                    <span data-key="Insurancenumber">{lang("email")}</span>
                                                                    <span class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={lang('enter_email')}
                                                                        autoComplete="on"
                                                                        value={this.state.email_id}
                                                                        onChange={this.handleEmailChange}
                                                                    />
                                                                </div>
                                                                {this.state.emailError && (<span className="error-message">{this.state.emailError.message}</span>)}
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="input-group custom-input-group">

                                                                <label for="" class="form-label">
                                                                    <span data-key="OrderId">{lang("password")}</span>
                                                                    <span class="important-label">*</span>
                                                                </label>
                                                                <div class="form-control-top">
                                                                    <div class="icon-input-container">
                                                                        <input
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            className="form-control"
                                                                            placeholder={lang('enter_password')}
                                                                            autoComplete="on"
                                                                            value={this.state.password}
                                                                            onChange={this.handlePasswordChange}
                                                                        />
                                                                        <div class="eye-icon" onClick={this.handleToggleShowPassword}>
                                                                            <i class={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.passwordError && (<span className="error-message">{this.state.passwordError.message}</span>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer centerButton">
                                                <div class="card-btn next-btn-main">
                                                    <button className="btn next-btn red-btn" type="submit">
                                                        <span data-key="Next">{lang("login")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                </main>
            </>
        );
    }
}
export default withRouter(connect(null, { loginSuccess, loginFailure, setAccessToken, authenticateUser })(Login));



