import React, { Component } from 'react';
import LocationComponent from '../../components/location/LocationComponent';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import withRouter from '../../withRouter';
import LanguageContext from '../../context/LanguageProvider';
import Location from "../../assets/images/select-location.svg"
import greenCircle from "../../assets/images/green-semi-circle.svg"
import redCircle from "../../assets/images/red-semi-circle.svg"
import orangeCircle from "../../assets/images/orange-semi-circle.svg"
import locationIcon from "../../assets/images/location.svg"
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CookiesRemove } from '../../CookiesRemove';
import { BrowserView, MobileView, TabletView, isMobile, isTablet } from 'react-device-detect';
import BrowserViewComponent from '../../components/location/BrowserViewComponent';
import TabletViewComponent from '../../components/location/TabletViewComponent';
import MobileViewComponent from '../../components/location/MobileViewComponent';

export class HelpDeskLocation extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            locationDestination: [],
            locationPagination: [],
            locationOrigin: [],
            clickedCardLocation: {},
            buttonOpen: false,
            driveIn: [],
            insurance_client_id: '',
            current_page: '',
            to_date: '',
            from_date: '',
            km_distance: '',
            moreLocation: [],
            showMoreLocationButton: true,
            loading: false,
            showMoreLocations: false,
            numLocations: 5,
            isMoreLocationVisible: false,
            toggleMoreLocation: false,
            locationKm: '',
            locationName: '',
            listViewData: [],
            currentPage: 1,
            totalPages: 0,
        }
    }

    handleToggle = () => {
        this.setState({
            toggle: !this.state.toggle,
        })

        const collapsibleElements = document.querySelectorAll('.collapse.show');
        collapsibleElements.forEach((el) => {
            el.classList.remove('show');
        });
    }

    componentDidMount = () => {
        this.getLocationData();
    }

    componentWillReceiveProps = (props) => {
        if (props?.router?.location?.state?.insurance_client_id)
            return {
                insurance_client_id: props?.router?.location?.state?.insurance_client_id
            }
    }

    getLocationData = () => {
        this.setState({ loading: true });
        let uuid = this.props?.router?.location?.state?.uuid;
        let insurance_client_id = this.props.router.location?.state?.insurance_client_id;
        let page_no = 1;
        const distances = [1, 2, 3];
        var moreLocation = [];

        distances.map(distance => {
            const url = `${configuration.locationData}${uuid}?insurance_client_id=${insurance_client_id || ''}&&page_no=${page_no || ''}&&km_distance=${distance}`;
            axios.get(url).then(
                res => {
                    if (res.data.success) {
                        // moreLocation = [...moreLocation,...res.data.data.destination.slice(6)]
                        this.setState(prevState => ({
                            locationDestination: {
                                ...prevState.locationDestination,
                                [distance]: res.data.data.destination
                            },
                            locationOrigin: {
                                ...prevState.locationOrigin,
                                [distance]: res.data.data.origin
                            },
                            insurance_client_id: insurance_client_id,
                            uuid: uuid,
                            from_date: res.data?.data?.destination[0]?.from_date,
                            to_date: res.data?.data?.destination[0]?.to_date,
                            km_distance: distance,
                            loading: false

                        }));
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }
            ).catch(err => {
                this.setState({ loading: false })
                if (err.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(err.response.data.message, 'error');
                    this.props.router.navigate('/login');
                }
                toastAlert(err.response.data.message, 'error');
            });
        });


    }

    handleMoreLocations = (MoreLocation, key) => {
        const clickMoreLocation = MoreLocation.slice(4)
        this.setState(prevState => ({
            showMoreLocations: !prevState.showMoreLocations,
            moreLocation: clickMoreLocation,
            isMoreLocationVisible: !prevState.isMoreLocationVisible
        }));
        const moreLocationDrawer = document.getElementsByClassName("more-location-drawer-wrapper")[key];
        if (moreLocationDrawer) {
            moreLocationDrawer.classList.toggle("show");
        }
    }

    handleMoreLocation = () => {
        var moreLocationHandle = []
        this.setState({
            toggle: !this.state.toggle,
        })
        Object.keys(this.state.locationDestination).map((destination) => {
            moreLocationHandle = [...moreLocationHandle, ...this.state.locationDestination[destination].slice(5)]
        })
        this.setState({
            moreLocation: moreLocationHandle,
        })

        let uuid = this.props.router.location.state.uuid
        let insurance_client_id = this.props.router.location.state.insurance_client_id
        let page_no = 2


        const url = `${configuration.locationData}${uuid}?insurance_client_id=${insurance_client_id || ''}&&page_no=${page_no || ''}`;

        axios.get(url).then(
            res => {
                if (res.data.success) {
                    this.setState({
                        locationPagination: res.data.data.destination,
                        locationOrigin: res.data.data.origin,
                        uuid: uuid
                    });

                }
            }
        ).catch(err => {
            if (err.response.data.code === 401) {
                CookiesRemove();
                toastAlert(err.response.data.message, 'error');
                this.props.router.navigate('/login');
            }
            toastAlert(err.response.data.message, 'error');
        });
    }

    handelClick = (location) => {
        this.setState({
            clickedCardLocation: location,
            toggle: !this.state.toggle,
            loading: true
        })
        let url = `${configuration.driveInLocation}?location=${location.location_name || ''}&country=${location.country_name || ''}&to_date=${location.to_date || ''}&from_date=${location.from_date || ''}&insurance_client_id=${this.props.router.location.state.insurance_client_id || ''}&cust_id=${this.props.router.location.state.uuid || ''}`
        axios.get(url)
            .then(
                res => {
                    if (res.data.success) {
                        console.log(res.data.data.drivin, 'res.data.data.drivin');
                        this.setState({
                            driveIn: res.data.data.drivin,
                            loading: false,
                            locationKm: location.km,
                            locationName: location.location_name,
                        });
                    }
                }
            )
            .catch(error => {
                this.setState({ loading: false })
                toastAlert(error.response.data.message, 'error');
                if (error.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(error.response.data.message, 'error');
                    this.props.router.navigate('/login');
                }
            });

    }

    handelListClick = async (listData) => {
        this.setState({
            clickedCardLocation: listData,
            toggle: !this.state.toggle,
            loading: true
        });
        let url = `${configuration.driveInLocation}?location=${listData.location_name || ''}&country=${listData.country_name || ''}&to_date=${listData.to_date || ''}&from_date=${listData.from_date || ''}&insurance_client_id=${this.props.router.location.state.insurance_client_id || ''}&cust_id=${this.props.router.location.state.uuid || ''}`;

        try {
            const response = await axios.get(url);
            if (response.data.success) {
                console.log(response.data, 'response.data.data.drivin');
                this.setState({
                    driveIn: response.data.data.drivin,
                    loading: false,
                    locationKm: listData.km,
                    locationName: listData.location_name,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            toastAlert(error.response.data.message, 'error');
            if (error.response.data.code === 401) {
                CookiesRemove();
                toastAlert(error.response.data.message, 'error');
                this.props.router.navigate('/login');
            }
        }
    }

    handelSlotsButton = () => {
        this.setState({
            buttonOpen: !this.state.buttonOpen,
        })
    }

    handleListView = async () => {
        this.setState({
            loading: true,
        });
        let uuid = this.props?.router?.location?.state?.uuid;
        let page_no = this.state.currentPage;
        const locationListView = `${configuration.locationListView}${uuid}?page_no=${page_no || ''}`;
        if (uuid) {
            try {
                const response = await axios.get(locationListView);
                if (response.data.success) {
                    this.setState({
                        listViewData: response.data.data.destination,
                        loading: false,
                        totalPages: response.data.data.total_page,
                    });
                }
            } catch (err) {
                this.setState({ loading: false });

                if (err.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(err.response.data.message, 'error');
                    this.props.router.navigate('/login');
                }

                toastAlert(err.response.data.message, 'error');
            }
        }
    }

    handlePageChange(page) {
        this.setState({ 
            currentPage: page
         },() => {
            this.handleListView();
        });
    }

    handlePreviousPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ 
                currentPage: currentPage - 1
            },() => {
                this.handleListView();
            });
        }
    }

    handleNextPage = () => {
        const { currentPage, totalPages } = this.state;
        if (currentPage < totalPages) {
            this.setState({ 
                currentPage: currentPage + 1
            },() => {
                this.handleListView();
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({ 
            currentPage: page 
        },() => {
            this.handleListView();
        });
    };

    // disable
    // renderPagination() {
    //     const { currentPage, totalPages } = this.state;
    //     const pageLinks = [];

    //     for (let page = 1; page <= totalPages; page++) {
    //         pageLinks.push(
    //             <li key={page} className={`page-item${page === currentPage ? ' active' : ''}`}>
    //                 <a className="page-link" href="#" onClick={() => this.handlePageChange(page)}>
    //                     {page}
    //                 </a>
    //             </li>
    //         );
    //     }

    //     return (
    //         <nav aria-label="Page navigation example">
    //             <ul className="pagination">
    //                 <li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
    //                     <a className="page-link" href="#" onClick={this.handlePreviousPage} aria-label="Previous">
    //                         <span aria-hidden="true">&laquo;</span>
    //                     </a>
    //                 </li>
    //                 {pageLinks}
    //                 <li className={`page-item${currentPage === totalPages ? ' disabled' : ''}`}>
    //                     <a className="page-link" href="#" onClick={this.handleNextPage} aria-label="Next">
    //                         <span aria-hidden="true">&raquo;</span>
    //                     </a>
    //                 </li>
    //             </ul>
    //         </nav>
    //     );
    // }

    // all page show
    // renderPagination() {
    //     const { currentPage, totalPages } = this.state;
    //     const pageLinks = [];

    //     for (let page = 1; page <= totalPages; page++) {
    //         pageLinks.push(
    //             <li key={page} className={`page-item${page === currentPage ? ' active' : ''}`}>
    //                 <a className="page-link" onClick={() => this.handlePageChange(page)}>
    //                     {page}
    //                 </a>
    //             </li>
    //         );
    //     }

    //     return (
    //         <nav aria-label="Page navigation example">
    //             <ul className="pagination">
    //                 {currentPage > 1 && (
    //                     <li className="page-item">
    //                         <a className="page-link" onClick={this.handlePreviousPage} aria-label="Previous">
    //                             <span aria-hidden="true">&laquo;</span>
    //                         </a>
    //                     </li>
    //                 )}
    //                 {pageLinks}
    //                 {currentPage < totalPages && (
    //                     <li className="page-item">
    //                         <a className="page-link" onClick={this.handleNextPage} aria-label="Next">
    //                             <span aria-hidden="true">&raquo;</span>
    //                         </a>
    //                     </li>
    //                 )}
    //             </ul>
    //         </nav>
    //     );
    // }

    // hide buttons
    renderPagination() {
        const { currentPage, totalPages } = this.state;
        const pageLinks = [];
        const pageSize = 3; // Number of page links to display at a time

        // Calculate the start and end page indices based on the current page
        let startPage = (Math.floor((currentPage - 1) / pageSize) * pageSize) + 1;
        let endPage = Math.min(startPage + pageSize - 1, totalPages);

        for (let page = startPage; page <= endPage; page++) {
            pageLinks.push(
                <li key={page} className={`page-item${page === currentPage ? ' active' : ''}`}>
                    <a className="page-link" onClick={() =>{totalPages==1?"": this.handlePageChange(page)}}>
                        {page}
                       
                    </a>
                </li>
            );
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    {currentPage > 1 && (
                        <li className="page-item">
                            <a className="page-link" onClick={this.handlePreviousPage} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                    )}
                    {pageLinks}
                    {currentPage < totalPages && (
                        <li className="page-item">
                            <a className="page-link" onClick={this.handleNextPage} aria-label="Next">
                          
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
        );
    }
//disable button only
    // renderPagination() {
    //     const { currentPage, totalPages } = this.state;
    //     const pageLinks = [];
    //     const pageSize = 3; // Number of page links to display at a time

    //     // Calculate the start and end page indices based on the current page
    //     let startPage = (Math.floor((currentPage - 1) / pageSize) * pageSize) + 1;
    //     let endPage = Math.min(startPage + pageSize - 1, totalPages);

    //     for (let page = startPage; page <= endPage; page++) {
    //         pageLinks.push(
    //             <li key={page} className={`page-item${page === currentPage ? ' active' : ''}`}>
    //                 <a className="page-link" onClick={() => this.handlePageChange(page)}>
    //                     {page}
    //                 </a>
    //             </li>
    //         );
    //     }

    //     return (
    //         <nav aria-label="Page navigation example">
    //             <ul className="pagination">
    //                 {currentPage > 1 ? (
    //                     <li className="page-item">
    //                         <a className="page-link" onClick={this.handlePreviousPage} aria-label="Previous">
    //                             <span aria-hidden="true">&laquo;</span>
    //                         </a>
    //                     </li>
    //                 ) : (
    //                     <li className="page-item">
    //                         <span className="page-link" aria-label="Previous">
    //                             <span aria-hidden="true">&laquo;</span>
    //                         </span>
    //                     </li>
    //                 )}
    //                 {pageLinks}
    //                 {currentPage < totalPages ? (
    //                     <li className="page-item">
    //                         <a className="page-link" onClick={this.handleNextPage} aria-label="Next">
    //                             <span aria-hidden="true">&raquo;</span>
    //                         </a>
    //                     </li>
    //                 ) : (
    //                     <li className="page-item">
    //                         <span className="page-link" aria-label="Next">
    //                             <span aria-hidden="true">&raquo;</span>
    //                         </span>
    //                     </li>
    //                 )}
    //             </ul>
    //         </nav>
    //     );
    // }

    mapview=()=>{
        this.setState({
            toggle:false
        })
    }

    render() {
        const locationOrigin = this.state.locationOrigin[1]?.location_name
        const isMobileView = isMobile && !isTablet;
        const { isMoreLocationVisible, moreLocation, toggle, locationDestination, locationPagination, clickedCardLocation, buttonOpen, driveIn, to_date, from_date, loading, toggleMoreLocation, listViewData } = this.state;
        const { lang, language } = this.context
        const dateFormat = Cookies.get('dateFormat')

        return (
            <main class="main-content location-content ">
                {loading && <div class="loader-main">
                    <Spinner color="light">
                        <span></span>
                    </Spinner>
                </div>}
                <section>
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center">
                           <div class="col-lg-4 col-md-12 col-sm-12">
                            
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6 col-12 location-title-col">
                                <div class="location-title">
                                    <span class="select-location-img">
                                        <img src={Location} alt="Location" />
                                    </span>
                                    <span class="select-location-title">
                                        <h4 class="small-title-18">{lang('select_your_suitable_location/time')}</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 map-location-container">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation" onClick={this.handleListView}>
                                        <a class="nav-link" id="listView-tab" data-toggle="tab" href="#listView"
                                            role="tab" aria-controls="listView" aria-selected="true">{lang('list_view')}</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="mapView-tab" data-toggle="tab" href="#mapView" role="tab"
                                            aria-controls="mapView" aria-selected="false" onClick={this.mapview}>{lang('map_view')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div class="container-fluid map-location-container p-0" id="map-location-wrapper">
                        <div class="row">
                            <div class="col-lg-12">
                               
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade" id="listView" role="tabpanel"
                                        aria-labelledby="listView-tab">
                                        {/* List-view::Start */}
                                        <div class="list-view-wrapper">
                                            <div class="row">
                                                <div class="col-lg-12 location-wrapper-col">
                                                    <div class="list-view-top">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="accordion accordion-card" id="accordionone">
                                                                    <div class="row">
                                                                        {listViewData.map((listData, i) => {
                                                                            const weekNumber = moment(listData.from_date).week();
                                                                            const weekEndNumber = moment(listData.to_date).week();
                                                                            console.log(weekNumber===weekEndNumber,"weekEndNumber")
                                                                            const fromDateDay = moment(listData.from_date).format('dd');
                                                                            const toDateDay = moment(listData.to_date).format('dd');
                                                                            return(listData.total_slot != 0 ?
                                                                             (<div class="col-lg-4 col-md-6 col-sm-12 d-flex">
                                                                                <div class="list-view-card">
                                                                                    <div class="card">
                                                                                        <div class="card-header" id={`locationOne_${i}`}>
                                                                                            <button
                                                                                                onClick={() => this.handelListClick(listData)}
                                                                                                class="btn btn-link btn-block text-left"
                                                                                                type="button" data-toggle="collapse"
                                                                                                data-target={`#locationOneInner_${i}`}
                                                                                                aria-expanded="true"
                                                                                                aria-controls={`locationOneInner_${i}`}>
                                                                                                <div class="row collapse-row">
                                                                                                    <div class="col-11">
                                                                                                        <div class="location-datails">
                                                                                                            <p class="list-card-location">
                                                                                                                <i class="fa fa-map-marker"></i>
                                                                                                                {listData.location_name},
                                                                                                                <span class="slot-span">{listData.total_slot} {lang("slots")}</span>
                                                                                                            </p>
                                                                                                            <div class="location-address-and-zip">
                                                                                                                <p>{listData.address_1} {listData.address_2},
                                                                                                                    <span>{listData.postal_code}</span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-1">
                                                                                                        <div class="location-collapse-icon">
                                                                                                            <i class="fa fa-chevron-down"></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div id={`locationOneInner_${i}`} class="collapse"
                                                                                            aria-labelledby={`locationOne_${i}`}
                                                                                            data-parent="#accordionone">
                                                                                            <div class="card-body">
                                                                                                <div class="slot-date-time-reserve">
                                                                                                    <div class="location-date">
                                                                                                        <ul>
                                                                                                            <li>
                                                                                                                <div class="row align-items-center">
                                                                                                                    <div
                                                                                                                        class="col-12">
                                                                                                                        <p class="small-text-black-14">
                                                                                                                            {lang("from")}
                                                                                                                            <span class="small-text-14"> {moment(listData.from_date).format(dateFormat)} ({lang(`${fromDateDay}`)}) </span>
                                                                                                                            {lang("to")}
                                                                                                                            <span
                                                                                                                                class="small-text-14"> {moment(listData.to_date).format(dateFormat)} ({lang(`${toDateDay}`)}),</span>
                                                                                                                            <span
                                                                                                                                class="small-text-14">
                                                                                                                                {/* ({lang("cw")}{weekNumber} - {lang("cw")}{weekEndNumber}) */}
                                                                                                                                {weekNumber==weekEndNumber
                                                                                                                                 ? 
                                                                                                                                 (`(${lang("cw")}${weekNumber})`)
                                                                                                                                 :
                                                                                                                                 (`(${lang("cw")}${weekNumber} - ${lang("cw")}${weekEndNumber})`)
                                                                                                                                 }
                                                                                                                                </span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div class="col-6">
                                                                                                                        <div class="available-slots">
                                                                                                                            {listData.available_slot == 0 ? (
                                                                                                                                <span class="red-location-span">{listData.available_slot}</span>
                                                                                                                            ) : listData.available_slot / listData.total_slot == 1 ? (
                                                                                                                                <span class="green-location-span">{listData.available_slot}</span>
                                                                                                                            ) : listData.available_slot / listData.total_slot >= 0.5 ? (
                                                                                                                                <span class="orange-location-span">{listData.available_slot}</span>
                                                                                                                            ) : (
                                                                                                                                <span class="">{listData.available_slot}</span>
                                                                                                                            )}
                                                                                                                            <span>/</span>
                                                                                                                            <span>{listData.total_slot}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* <div class="col-6">
                                                                                                                        <div class="list-view-link">
                                                                                                                            <a href="customer-calendar.html">Reserve</a>
                                                                                                                        </div>
                                                                                                                    </div> */}
                                                                                                                    {/* <button type="button" className="btn red-btn reserve-btn" disabled>
                                                                                                                            {lang("reserve")}
                                                                                                                        </button> */}
                                                                                                                    {listData.total_slot === 0 ? (
                                                                                                                        <>
                                                                                                                            <div class="col-6">
                                                                                                                                <div class="list-view-link">
                                                                                                                                    <a class="btn" type='button' className='reserve-location-list disabled' disabled>{lang("reserve")}</a>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    ) : ( <div class="col-6">
                                                                                                                        <Link
                                                                                                                            to="/helpdesk/calendar"
                                                                                                                            state={{
                                                                                                                                insurance_client_id: this.props.router.location.state?.insurance_client_id,
                                                                                                                                insurance_name: this.props.router.location.state?.insurance_name,
                                                                                                                                driveIn: listData?.drivein_id,
                                                                                                                                from_date: listData.from_date,
                                                                                                                                to_date: listData.to_date,
                                                                                                                                uuid: this.props.router.location.state.uuid,
                                                                                                                                customer_id: this.props.router.location.state.customer_id,
                                                                                                                                reg_no: this.props.router.location.state.reg_no,
                                                                                                                                order_id: this.props.router.location.state.order_id,
                                                                                                                                claim_number: this.props.router.location.state.claim_number,
                                                                                                                                first_name: this.props.router.location.state.first_name,
                                                                                                                                last_name: this.props.router.location.state.last_name,
                                                                                                                                is_booking: this.props.router.location.state.is_booking,
                                                                                                                                salutation: this.props.router.location.state.salutation,
                                                                                                                                locationName: this.state.locationName,
                                                                                                                                locationKm: this.state.locationKm,
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {/* <button type="button" className="btn red-btn reserve-btn">
                                                                                                                                {lang("reserve")}
                                                                                                                            </button> */}
                                                                                                                           
                                                                                                                                <div class="list-view-link">
                                                                                                                                    <a class="btn" type='button'>{lang("reserve")}</a>
                                                                                                                                </div>
                                                                                                                        </Link>
                                                                                                                            </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                            :<div></div>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="custom-pagination">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                {this.renderPagination()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* List-view::End */}
                                    </div>
                                    <div class="tab-pane fade show active" id="mapView" role="tabpanel" aria-labelledby="mapView-tab">

                                        {/* Customer-location-list::Start */}
                                        <BrowserView>
                                            <BrowserViewComponent
                                                locationOrigin={locationOrigin}
                                                locationDestination={locationDestination}
                                                lang={lang}
                                                handelClick={this.handelClick}
                                                handleMoreLocations={this.handleMoreLocations}
                                            />
                                        </BrowserView>

                                        <TabletView>
                                            <TabletViewComponent
                                                locationOrigin={locationOrigin}
                                                locationDestination={locationDestination}
                                                lang={lang}
                                                handelClick={this.handelClick}
                                                handleMoreLocations={this.handleMoreLocations}
                                            />
                                        </TabletView>

                                        {isMobileView &&
                                            <MobileViewComponent
                                                locationOrigin={locationOrigin}
                                                locationDestination={locationDestination}
                                                lang={lang}
                                                handelClick={this.handelClick}
                                                handleMoreLocations={this.handleMoreLocations}
                                            />
                                        }

                                        <section class="customer-map-wrapper">
                                            <div id="map"></div>
                                        </section>

                                        {toggle &&
                                            <div class="location-drawer-wrapper">

                                                <div class={`location-drawer-main ${toggle ? 'multi-location-drawer reveal-location-drawer reveal-location-drawer' : ''}`} id="single-location">
                                                    <div class="drawer-close-btn">
                                                        <button class="btn closeLocationDrawer" onClick={() => this.handleToggle()}>
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </div>
                                                    <div class="location-cards-wrapper">
                                                        {loading && <div class="loader-main-half">
                                                            <Spinner color="light">
                                                                <span></span>
                                                            </Spinner>
                                                        </div>}
                                                        {driveIn?.map((driveInData, i) => {
                                                            const weekNumber = moment(driveInData.from_date).week();
                                                            const toNumber = moment(driveInData.to_date).week();
                                                            console.log(toNumber,"toNumber")
                                                            const fromDateDay = moment(driveInData.from_date).format('dd');
                                                            const toDateDay = moment(driveInData.to_date).format('dd');
                                                            return <div class="row">
                                                                <div class="col-12">
                                                                    <div class="accordion" id="onlyOneLocation">
                                                                        <div class="card location-card">
                                                                            <div class="card-header" id="singleLocation">
                                                                                <div>
                                                                                    <div class="row align-items-center">
                                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                            <div class="location-name">
                                                                                                <span class="small-text-black-16">{lang("location")}</span>
                                                                                                <span class="small-text-black-16">: </span>&nbsp;
                                                                                                <span class="small-text-red-16">{clickedCardLocation.location_name}</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                            <div class="location-view-slot-main">
                                                                                                <div class="location-slot">
                                                                                                <p className='slots_count_text'> {driveInData.total_slot}&nbsp;{lang("slots")}</p>
                                                                                                    <button class="btn red-btn" data-toggle="collapse"
                                                                                                        data-target={`#singleLocationOne_${i}`}
                                                                                                        aria-expanded="true"
                                                                                                        aria-controls={`singleLocationOne_${i}`}
                                                                                                        disabled={driveInData.total_slot === 0}
                                                                                                    // onClick={() => handelSlotsButton()}
                                                                                                    >
                                                                                                        {/* {driveInData.total_slot}&nbsp;{lang("slots")} */}
                                                                                                        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* {buttonOpen && */}
                                                                            <div id={`singleLocationOne_${i}`} class="show collapse location-collapse"
                                                                                aria-labelledby="singleLocation" data-parent="#onlyOneLocation">
                                                                                <div class="card-body">
                                                                                    <div class="address-zip-wrapper">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                                                <div class="location-address">
                                                                                                    <div class="location-icon-with-address">
                                                                                                        <div class="location-icon">
                                                                                                            <i class="fi fi-contact-book"></i>
                                                                                                        </div>
                                                                                                        <div class="location-zipCode">
                                                                                                            <span class="small-text-black-16">{lang("address")}</span>
                                                                                                            <span class="small-text-black-16">: </span>&nbsp;
                                                                                                            <span class="small-text-16">{driveInData.location}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                                                                <div class="location-address">
                                                                                                    <div
                                                                                                        class="location-icon-with-address location-zipCode-main">
                                                                                                        <div class="location-icon">
                                                                                                            <i class="fa fa-map-pin"></i>
                                                                                                        </div>
                                                                                                        <div class="location-zipCode">
                                                                                                            <span class="small-text-black-16">{lang("zip_code_")} </span>
                                                                                                            <span class="small-text-black-16">: </span>&nbsp;
                                                                                                            <span class="small-text-16">{driveInData.postal_code}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="location-line"></div>
                                                                                    <div class="location-more-details-table">
                                                                                        <div class="table-responsive">
                                                                                            <table class="table location-table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>
                                                                                                            {lang("date,_cw")}
                                                                                                        </th>
                                                                                                        <th>
                                                                                                            {lang("free/total")}
                                                                                                        </th>
                                                                                                        <th></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {/* {lang("from")} {moment(driveInData.from_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({(`${toDateDay}`)}), ({lang("cw")}{weekNumber}) */}
                                                                                                            {lang("from")} {moment(driveInData.from_date).format(dateFormat)} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(dateFormat)} ({lang(`${toDateDay}`)}),
                                                                                                             {/* ({lang("cw")}{weekNumber}-{lang("cw")}{toNumber}) */}
                                                                                                             {weekNumber==toNumber
                                                                                                                                 ? 
                                                                                                                                 (`(${lang("cw")}${weekNumber})`)
                                                                                                                                 :
                                                                                                                                 (`(${lang("cw")}${weekNumber} - ${lang("cw")}${toNumber})`)
                                                                                                                                 }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="free-total-tag">
                                                                                                                {driveInData.available_slot == 0 ? (
                                                                                                                    <div className="inner-tag red-tag" style={{ backgroundColor: 'red' }}>
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : driveInData.available_slot / driveInData.total_slot == 1 ? (
                                                                                                                    <div className="inner-tag green-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : driveInData.available_slot / driveInData.total_slot >= 0.5 ? (
                                                                                                                    <div className="inner-tag orange-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="inner-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div className="inner-tag">
                                                                                                                    <span>{driveInData.total_slot}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            {driveInData.total_slot === 0 ? (
                                                                                                                <button type="button" className="btn red-btn reserve-btn" disabled>
                                                                                                                    {lang("reserve")}
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <Link
                                                                                                                    to="/helpdesk/calendar"
                                                                                                                    state={{
                                                                                                                        insurance_client_id: this.props.router.location.state?.insurance_client_id,
                                                                                                                        insurance_name: this.props.router.location.state?.insurance_name,
                                                                                                                        driveIn: driveInData?.drive_through_id,
                                                                                                                        from_date: driveInData.from_date,
                                                                                                                        to_date: driveInData.to_date,
                                                                                                                        uuid: this.props.router.location.state.uuid,
                                                                                                                        customer_id: this.props.router.location.state.customer_id,
                                                                                                                        reg_no: this.props.router.location.state.reg_no,
                                                                                                                        order_id: this.props.router.location.state.order_id,
                                                                                                                        claim_number: this.props.router.location.state.claim_number,
                                                                                                                        first_name: this.props.router.location.state.first_name,
                                                                                                                        last_name: this.props.router.location.state.last_name,
                                                                                                                        is_booking: this.props.router.location.state.is_booking,
                                                                                                                        salutation: this.props.router.location.state.salutation,
                                                                                                                        locationName: this.state.locationName,
                                                                                                                        locationKm: this.state.locationKm,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <button type="button" className="btn red-btn reserve-btn">
                                                                                                                        {lang("reserve")}
                                                                                                                    </button>
                                                                                                                </Link>
                                                                                                            )}
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* } */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                                <div class="location-overlay"></div>
                                            </div>
                                        }

                                        <div class="more-location-drawer-wrapper">
                                            <div class={`location-drawer-main ${toggleMoreLocation ? 'multi-location-drawer reveal-location-drawer reveal-location-drawer' : ''}`} id="all-locations">
                                                <div class="drawer-close-btn">
                                                    <button class="btn closeLocationDrawer" onClick={() => this.handleToggle()}>
                                                        <i class="fa fa-times"></i>
                                                    </button>
                                                </div>
                                                <div class="location-cards-wrapper">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="accordion" id="multiLocation" >
                                                                {moreLocation.length && moreLocation?.map((multiLocation, i) => {
                                                                    return <div class="card location-card" key={i}>
                                                                        <div class="card-header" id={`multiLocationOne_${i}`} >
                                                                            <div>
                                                                                <div class="row align-items-center">
                                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                        <div class="location-name">
                                                                                            <span class="small-text-black-16">{lang("location")}</span>
                                                                                            <span class="small-text-black-16">: </span>
                                                                                            <span class="small-text-red-16">{multiLocation.location_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                        <div class="location-view-slot-main">
                                                                                            <div class="location-slot">
                                                                                                <button class="btn red-btn" data-toggle="collapse" data-target={`#ml-${i}`}
                                                                                                    aria-expanded="true" aria-controls={`ml-${i}`} onClick={() => multiLocation.total_slot > 0 && this.handelClick(multiLocation)} disabled={multiLocation.total_slot === 0}>
                                                                                                    {multiLocation.total_slot} {lang("slots")}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id={`ml-${i}`} class="collapse location-collapse"
                                                                            aria-labelledby={`multiLocationOne_${i}`} data-parent="#multiLocation" key={i}>
                                                                            {driveIn?.map((driveInData, i) => {
                                                                                const weekNumber = moment(driveInData.from_date).week();
                                                                                const fromDateDay = moment(driveInData.from_date).format('dd');
                                                                                const toDateDay = moment(driveInData.to_date).format('dd');
                                                                                return <div class="card-body">
                                                                                    <div class="address-zip-wrapper">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                                                                <div class="location-address">
                                                                                                    <div class="location-icon-with-address">
                                                                                                        <div class="location-icon">
                                                                                                            <i class="fi fi-contact-book"></i>
                                                                                                        </div>
                                                                                                        <div class="location-zipCode">
                                                                                                            <span class="small-text-black-16">{lang("address")}</span>
                                                                                                            <span class="small-text-black-16">: </span>
                                                                                                            <span class="small-text-16">{driveInData.location}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                                                                <div class="location-address">
                                                                                                    <div
                                                                                                        class="location-icon-with-address location-zipCode-main">
                                                                                                        <div class="location-icon">
                                                                                                            <i class="fa fa-map-pin"></i>
                                                                                                        </div>
                                                                                                        <div class="location-zipCode">
                                                                                                            <span class="small-text-black-16">{lang("zip_code_")} </span>
                                                                                                            <span class="small-text-black-16">: </span>
                                                                                                            <span class="small-text-16">{driveInData.postal_code}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="location-line"></div>
                                                                                    <div class="location-more-details-table">
                                                                                        <div class="table-responsive">
                                                                                            <table class="table location-table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>
                                                                                                            {lang("date,_cw")}
                                                                                                        </th>
                                                                                                        <th>
                                                                                                            {lang("free/total")}
                                                                                                        </th>
                                                                                                        <th></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {/* {lang("from")} {moment(driveInData.from_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY')} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber}) */}
                                                                                                            {lang("from")} {moment(driveInData.from_date).format(dateFormat)} ({lang(`${fromDateDay}`)}) {lang("to")} {moment(driveInData.to_date).format(dateFormat)} ({lang(`${toDateDay}`)}), ({lang("cw")}{weekNumber})
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div class="free-total-tag">
                                                                                                                {driveInData.available_slot == 0 ? (
                                                                                                                    <div className="inner-tag red-tag" style={{ backgroundColor: 'red' }}>
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : driveInData.available_slot / driveInData.total_slot == 1 ? (
                                                                                                                    <div className="inner-tag green-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : driveInData.available_slot / driveInData.total_slot >= 0.5 ? (
                                                                                                                    <div className="inner-tag orange-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="inner-tag">
                                                                                                                        <span>{driveInData.available_slot}</span>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div className="inner-tag">
                                                                                                                    <span>{driveInData.total_slot}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>

                                                                                                            {driveInData.total_slot === 0 ? (
                                                                                                                <button type="button" className="btn red-btn reserve-btn" disabled>
                                                                                                                    {lang("reserve")}
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <Link
                                                                                                                    to="/helpdesk/calendar"
                                                                                                                    state={{
                                                                                                                        insurance_client_id: this.props.router.location.state?.insurance_client_id,
                                                                                                                        insurance_name: this.props.router.location.state?.insurance_name,
                                                                                                                        driveIn: driveInData?.drive_through_id,
                                                                                                                        from_date: driveInData.from_date,
                                                                                                                        to_date: driveInData.to_date,
                                                                                                                        uuid: this.props.router.location.state.uuid,
                                                                                                                        customer_id: this.props.router.location.state.customer_id,
                                                                                                                        reg_no: this.props.router.location.state.reg_no,
                                                                                                                        order_id: this.props.router.location.state.order_id,
                                                                                                                        claim_number: this.props.router.location.state.claim_number,
                                                                                                                        first_name: this.props.router.location.state.first_name,
                                                                                                                        last_name: this.props.router.location.state.last_name,
                                                                                                                        is_booking: this.props.router.location.state.is_booking,
                                                                                                                        salutation: this.props.router.location.state.salutation,
                                                                                                                        locationName: this.state.locationName,
                                                                                                                        locationKm: this.state.locationKm,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <button type="button" className="btn red-btn reserve-btn">
                                                                                                                        {lang("reserve")}
                                                                                                                    </button>
                                                                                                                </Link>
                                                                                                            )}
                                                                                                        </td>

                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="location-overlay"></div>
                                        </div>
                                        {/* Customer-location-list::End */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        );
    }
}

export default withRouter(HelpDeskLocation);
