import React, { Component } from 'react';
import withRouter from '../../withRouter';
import { Spinner } from 'reactstrap';
import user from "../../assets/images/user.svg";
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import LanguageContext from '../../context/LanguageProvider';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import Cookies from 'js-cookie';
import { CookiesRemove } from '../../CookiesRemove';

const POSTAL_CODE = 2000;
const CITY='Maribor';
export class CreateAppointmentDetails extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            loading: false,
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            email: '',
            // emailError: '',
            mobileNumber: '',
            // mobileNumberError: '',
            street: '',
            streetError: '',
            houseNumber: '',
            // houseNumberError: '',
            country: '',
            //countryError: '',
            state: '',
            //stateError: '',
            city: CITY,
            cityError: '',
            postalCode: POSTAL_CODE,
            postalCodeError: '',
            km_stand: '',
            // mileageError: '',
            userData: {},
            uuid: "",
            insurance_client_id: '',
            country_code: '',
            salutation: 'Mr.',
            salutationError: '',
            profession: 'not specified',
            professionError: '',
            note: "",
            id: '',
            uuid: '',
            is_booking: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        let uuid = Cookies.get('uuid')
        uuid && this.getAppointmentDetails(uuid)
        let userData = Cookies.get('userData')
        let organizationCountry = Cookies.get('organizationCountry')
        console.log(userData,'userData');
        if (userData) {
            let userDataObj = JSON.parse(userData);
            this.setState({
                "firstName": userDataObj.first_name,
                "lastName": userDataObj.last_name,
                "email": userDataObj.email_id,
                "mobileNumber": userDataObj.contact_no,
                "street": userDataObj.street,
                "houseNumber": userDataObj.house_no,
                "country": organizationCountry,
                "state": userDataObj.state,
                "city": userDataObj.city,
                "postalCode": userDataObj.pincode,
                "km_stand": userDataObj.km_stand,
                "salutation": userDataObj.salutation,
                "profession": userDataObj.profession,
                "note": userDataObj.note,
                is_exiting: userDataObj.is_exiting,
                id: userDataObj.id,
                uuid: userDataObj.uuid,
                is_booking: userDataObj.is_booking,
                country_code: userDataObj.country_code,
            })
        }
    }

    getAppointmentDetails = (uuid) => {
        this.setState({ loading: true });
        const url = configuration.bookAppointment + uuid;
        let organizationCountry = Cookies.get('organizationCountry')
        const accessToken = Cookies.get('authToken')
        const headers = {
            'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
        }
        axios.get(url, { headers })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ loading: false });
                    const { km_stand, last_name, first_name, email_id, contact_no, house_no, street, city, country, profession, salutation, state, pincode, note } = res.data.data;
                    this.setState({
                        "firstName": first_name,
                        "lastName": last_name,
                        "email": email_id,
                        "mobileNumber": contact_no,
                        "street": street,
                        "houseNumber": house_no,
                        "country": organizationCountry,
                        "state": state,
                        "city": city,
                        "postalCode": pincode,
                        "km_stand": km_stand,
                        "salutation": salutation,
                        "profession": profession,
                        "note": note,
                    })
                }
            })
            .catch((err) => {
                if (err.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(err.response.data.message, 'error');
                    this.props.router.navigate('/login');
                }
                this.setState({ loading: false });
                toastAlert(err.response.data.message, 'error');
            })
    }

    handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }

    handlePhone = (val) => {
        this.setState({
            mobileNumber: val
        })
    }

    validateInputs = () => {
        console.log(this.state,"pp");
        const { lang } = this.context;
        let firstNameError = '';
        let lastNameError = '';
        // let emailError = '';
        // let mobileNumberError = '';
        // let streetError = '';
        // let houseNumberError = '';
        // let countryError = '';
        // let stateError = '';
        // let cityError = '';
        // let postalCodeError = '';
        // let mileageError = '';
        // let salutationError = '';
        // let professionError = '';

        if (!this.state.firstName?.trim()) {
            firstNameError = `${lang("please_enter_your_first_name.")}`;
        }

        if (!this.state.lastName?.trim()) {
            lastNameError = `${lang("please_enter_your_last_name.")}`;
        }

        // if (!this.state.email.includes('@')) {
        //     emailError = `${lang("please_enter_your_email_id.")}`;
        // }

        // if (!this.state.mobileNumber.trim()) {
        //     mobileNumberError = `${lang("please_enter_your_mobile_number.")}`;
        // }

        // if (!this.state.street.trim()) {
        //     streetError = `${lang("please_enter_your_street_name.")}`;
        // }

        // if (!this.state.houseNumber.trim()) {
        //     houseNumberError = `${lang("please_enter_your_house_number.")}`;
        // }

        // if (!this.state.country) {
        //     countryError = `${lang("please_enter_your_country.")}`;
        // }

        // if (!this.state.state.trim()) {
        //     stateError = `${lang("please_enter_your_state.")}`;
        // }

        // if (!this.state.city.trim()) {
        //     cityError = `${lang("please_enter_your_city.")}`;
        // }

        // if (!this.state.postalCode.trim()) {
        //     postalCodeError = `${lang("please_enter_your_postal_code.")}`;
        // }

        // if (!this.state.km_stand.trim()) {
        //     mileageError = `${lang("please_enter_your_mileage.")}`;
        // }

        // if (!this.state.salutation.trim()) {
        //     salutationError = `${lang("please_enter_your_salutation.")}`;
        // }

        // if (!this.state.profession.trim()) {
        //     professionError = `${lang("please_enter_your_profession.")}`;
        // }

        this.setState({
            firstNameError,
            lastNameError,
            // emailError,
            // mobileNumberError,
            // streetError,
            // houseNumberError,
            // countryError,
            // stateError,
            // cityError,
            // postalCodeError,
            // mileageError,
            // salutationError,
            // professionError,
        });

        // return !professionError && !salutationError && !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !countryError && !stateError && !cityError && !postalCodeError && !mileageError;
        // return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !stateError && !cityError && !postalCodeError && !mileageError;
        return !firstNameError && !lastNameError;
        // return !professionError && !salutationError && !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !stateError && !cityError && !postalCodeError && !mileageError;
    }

    handleNext = (e) => {
        e.preventDefault();
        console.log(process.env);
        const isValid = this.validateInputs();
        // const is_existing = Cookies.get('is_existing')
        if (isValid) {
            this.setState({ loading: true });
            // if (is_existing == 'true') {
                console.log(this.state.is_exiting,'this.state.is_exiting');
            if (this.state.is_exiting == true) {
                let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
                const reg_no = this.props?.router?.location?.state?.reg_no
                const order_id = this.props?.router?.location?.state?.order_id
                const claim_number = this.props?.router?.location?.state?.claim_number

                // let country_code = "123"
                let country_code = this.props.router.location.state.country_code

                const url = configuration.updateData;
                const { firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, salutation, profession } = this.state;
                const payLoad =
                {
                    'reg_no': reg_no,
                    'order_id': order_id,
                    'claim_number': claim_number,
                    'uuid': uuid,
                    'country_code': country_code,
                    'first_name': firstName,
                    'last_name': lastName,
                    'email_id': email,
                    'contact_no': mobileNumber,
                    'street': street,
                    'house_no': houseNumber,
                    'country': country,
                    'state': state,
                    // 'city': city,
                    // 'pincode': postalCode ,
                    'city': city || CITY,
                    'pincode': postalCode || POSTAL_CODE,
                    'km_stand': km_stand,
                    'salutation': salutation,
                    'profession': profession,
                }
                axios.post(url, payLoad)  //  <- Payload 
                    .then(response => {
                        if (response.data.success) {
                            this.props.router.navigate('/helpdesk/location-list', {
                                state: {
                                    uuid: uuid,
                                    insurance_client_id: this.state.insurance_client_id,
                                    insurance_name: this.state.insurance_name,
                                    customer_id: this.state.id,
                                    first_name: this.state.firstName,
                                    last_name: this.state.lastName,
                                    reg_no: this.props.router.location.state.reg_no,
                                    order_id: this.props.router.location.state.order_id,
                                    claim_number: this.state.claim_number,
                                    is_booking: this.state.is_booking,
                                    country_code: this.state.country_code,
                                    // claim_number : this.props.router.location.state.claim_number,
                                }
                            });
                        }
                    })
                    .catch(error => {
                        toastAlert(error.response.data.message, 'error');
                        if (error.response.data.code === 401) {
                            CookiesRemove();
                            toastAlert(error.response.data.message, 'error');
                            this.props.router.navigate('/login');
                        }
                    });
            }
            else {
                const reg_no = this.props.router.location.state.reg_no
                const order_id = this.props.router.location.state.order_id
                const claim_number = this.props.router.location.state.claim_number
                let organizationCountry = Cookies.get('organizationCountry')
                const url = configuration.createAppointment;
                const { note, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, salutation, profession } = this.state;
                const payLoad =
                {
                    'reg_no': reg_no,
                    'order_id': order_id,
                    'claim_number': claim_number,
                    'first_name': firstName,
                    'last_name': lastName,
                    'email_id': email,
                    'contact_no': mobileNumber,
                    'street': street,
                    'house_no': houseNumber,
                    'country': organizationCountry,
                    'state': state,
                    'city': city,
                    'pincode': postalCode,
                    'km_stand': km_stand,
                    'salutation': salutation,
                    'profession': profession,
                    'note': note,
                }

                const accessToken = localStorage.getItem('access_token');
                axios.post(url, payLoad, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}` // access token added in headers from local storage
                    }
                })  //  <- Payload 
                    .then(response => {
                        if (response.data.success) {
                            this.setState({ loading: false });
                            Cookies.set('uuid', response.data.data.uuid);
                            this.props.router.navigate('/helpdesk/location-list', {
                                state: {
                                    // insurance_client_id: this.state.insurance_client_id,
                                    // insurance_name: this.state.userData.insurance_name,
                                    uuid: response.data.data.uuid,
                                    customer_id: response.data.data.id,
                                    first_name: response.data.data.firstName,
                                    last_name: response.data.data.lastName,
                                    reg_no: this.props.router.location.state.reg_no,
                                    order_id: this.props.router.location.state.order_id,
                                    claim_number: response.data.data.claim_number,
                                    salutation: response.data.data.salutation,
                                    is_booking: this.state.userData.is_booking,
                                }
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                        if (error.response.data.code === 401) {
                            CookiesRemove();
                            toastAlert(error.response.data.message, 'error');
                            this.props.router.navigate('/login');
                            // Cookies.remove('id');
                            // Cookies.remove('globalUserId');
                            // Cookies.remove('isAuthenticated');
                            // Cookies.remove('globalOrganizationId');
                            // Cookies.remove('name');
                            // Cookies.remove('organizationName');
                            // Cookies.remove('organizationCountry');
                            // Cookies.remove('regionName');
                            // Cookies.remove('roleId');
                            // Cookies.remove('isLoggedIn');
                            // Cookies.remove('authToken');
                            // Cookies.remove('uuid');
                            // Cookies.remove('userData');
                            // Cookies.remove('organizationCountry');
                            // Cookies.remove('role_name');
                            // Cookies.remove('organization_country');
                            // Cookies.remove('clientTypeSetting');
                            // localStorage.removeItem('access_token');
                            // var element = document.getElementsByTagName("html")[0];
                            // element.classList.remove("otherclass");
                        }
                        toastAlert(error.response.data.message, 'error');
                    });
            }
        }
    }

    handleKeyDown=(e)=>{
        ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
    }

    render() {
        const { lang } = this.context;
        let organizationCountry = Cookies.get('organizationCountry')
        const countryCodeObj = {
            "Switzerland": "ch",
            "Germany": "de",
            "India": "in",
            "Slovenia": "si",
        };
        const defaultCountry = countryCodeObj[organizationCountry]
        console.log(defaultCountry, 'defaultCountry');
        console.log(!defaultCountry ? mobileNumber : defaultCountry, 'flag show');
        const { firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, salutation, profession, note, loading } = this.state;
        const { salutationError, professionError, firstNameError, lastNameError, streetError, houseNumberError, postalCodeError, cityError, stateError, countryError, mobileNumberError, emailError, mileageError } = this.state;
        return (
            <>
                <main class="main-content">
                    {loading && <div class="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <section class="customer-basic-details-wrapper all-roles-cards-wrapper add-customer-details-card-wrapper">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <div class="card-title-img-main">
                                                    <span class="card-title-img">
                                                        <img src={user} alt="Customer basic details" />
                                                    </span>
                                                    <span class="card-title-text">
                                                        <h4 class="small-title-18">{lang("add_your_details")}</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <form method='post'>
                                            <div class="card-body">
                                                <div class="card-form-main">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("salutation")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='salutation' value={salutation} onChange={this.handleChange}>
                                                                        <option value="Please select" disabled>{lang("please_select")}</option>
                                                                        <option value="Ms.">{lang("ms.")}</option>
                                                                        <option value="Mr.">{lang("mr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                </div>
                                                                {salutationError && (<span class="error-message">{salutationError}</span>)}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group custom-select-top">
                                                                <label class="form-label">{lang("title")}</label>
                                                                <div class="form-control-top">
                                                                    <select class="form-control" id="exampleFormControlSelect1" name='profession' value={profession} onChange={this.handleChange}>
                                                                        <option value="Please select" disabled>{lang("please_select")}</option>
                                                                        <option value="Dr.">{lang("dr.")}</option>
                                                                        <option value="Prof.">{lang("prof.")}</option>
                                                                        <option value="Prof. Dr.">{lang("prof._dr.")}</option>
                                                                        <option value="not specified">{lang("not_specified")}</option>
                                                                    </select>
                                                                </div>
                                                                {professionError && (<span class="error-message">{professionError}</span>)}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("first_name")}<span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="firstName" value={firstName} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang('enter_first_name')} />
                                                                    {firstNameError && (<span class="error-message">{firstNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("last_name")}<span
                                                                    class="important-label">*</span></label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="lastName" value={lastName} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_last_name")} />
                                                                    {lastNameError && (<span class="error-message">{lastNameError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang('street')}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="street" value={street} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_street_name")} />
                                                                    {/* {streetError && (<span class="error-message">{streetError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("house_number")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="houseNumber" value={houseNumber}  onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_your_house_number")} />
                                                                    {/* {houseNumberError && (<span class="error-message">{houseNumberError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("postal_code")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="postalCode" value={postalCode} onKeyDown={this.handleKeyDown} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_postal_code")} />
                                                                    {/* {postalCodeError && (<span class="error-message">{postalCodeError}</span>)} */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("city")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="city" value={city} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_city")} />
                                                                    {/* {cityError && (<span class="error-message">{cityError}</span>)}  */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        {/* <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("state")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="state" value={state} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_state")} /> */}
                                                                    {/* {stateError && (<span class="error-message">{stateError}</span>)} */}
                                                                {/* </div>
                                                            </div>
                                                        </div> */}
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("country")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="text" name="country" value={organizationCountry} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_country")} readOnly />
                                                                    {countryError && (<span class="error-message">{countryError}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group mobile-input-group">
                                                                <label for="" class="form-label">{lang("mobile_number")}</label>
                                                                <div class="form-control-top mobile-input">
                                                                    {/* <PhoneInput
                                                                        inputClass="form-control"
                                                                        containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        country={'us'}
                                                                        value={mobileNumber}
                                                                        onChange={(val) => {
                                                                            this.handlePhone(val)
                                                                        }}
                                                                        addInternationalOption={false}
                                                                    /> */}
                                                                    <PhoneInput
                                                                        inputClass="form-control"
                                                                        // containerClass="phone-input-container"
                                                                        buttonClass="btn phone-input-btn"
                                                                        // country={!mobileNumber ? defaultCountry : ""}
                                                                        country={!defaultCountry ? mobileNumber : defaultCountry}
                                                                        // country={!mobileNumber ? defaultCountry : mobileNumber}
                                                                        value={mobileNumber}
                                                                        onChange={(val) => {
                                                                            this.handlePhone(val)
                                                                        }}
                                                                        // onChange={(val, data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                                                                        addInternationalOption={false}
                                                                        // onlyCountries={['fr', 'at', 'de', 'us', 'cz', 'ro', 'it', 'nl', 'si', 'ch']} // Replace with the list of countries you want to allow
                                                                        defaultErrorMessage='Please enter valid contact number'
                                                                    />
                                                                    {/* {mobileNumberError && (
                                                                        <span class="error-message">{mobileNumberError}</span>
                                                                    )} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("email")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="email" name="email" value={email} onChange={this.handleChange} class="form-control"
                                                                        placeholder={lang("enter_email_id")} />
                                                                    {/* {emailError && (<span class="error-message">{emailError}</span>)} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="" class="form-label">{lang("mileage")}</label>
                                                                <div class="form-control-top">
                                                                    <input type="number" name="km_stand" value={km_stand} onKeyDown={this.handleKeyDown} onChange={(e) => this.handleChange(e)} class="form-control"
                                                                        placeholder={lang("enter_mileage")} />
                                                                    {/* {mileageError && (<span class="error-message">{mileageError}</span>)} */}
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div class="input-group custom-input-group">
                                                                <label for="note" class="form-label">{lang("note")}</label>
                                                                <div class="form-control-top">
                                                                    <textarea name="note" value={note} onChange={(e) => this.handleChange(e)} class="form-control"
                                                                        placeholder={lang("enter_note")} />
                                                                </div>
                                                                <span class="error-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <div class="card-btn prev-btn">

                                                    <Link to="/helpdesk/create/appointment" class="btn prev-btn black-btn">
                                                        <span><i class="fa fa-long-arrow-left"></i></span>
                                                        {lang("prev")}</Link>

                                                </div>
                                                <div class="card-btn next-btn-main">
                                                    <button class="btn next-btn red-btn" type="btn" onClick={this.handleNext} >{lang("next")}
                                                        <span><i class="fa fa-long-arrow-right"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                </main>
            </>
        );
    }
}

export default withRouter(CreateAppointmentDetails);
