import React, { Component } from "react";
import "../customerCalendar/customerCalendar.css";
import { Link } from "react-router-dom";
import { configuration, url } from '../../appConfig';
import withRouter from '../../withRouter';
import axios from 'axios';
import moment from "moment/moment";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import calendarSvg from "../../assets/images/calendar.svg";
import bookedCheck from "../../assets/images/booked-check.svg";
import LanguageContext from "../../context/LanguageProvider";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Spinner } from 'reactstrap';
import CalendarComponent from "../../components/calendar/CalendarComponent";
import { fromByteArray } from 'base64-js';
import { Base64 } from 'base64-js';
import Cookies from "js-cookie";

export class CustomerCalendar extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            allSlot: [],
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            detail: [],
            timeSlot: [],
            slots: {},
            headerTotalSlot: {},
            sortedTimeArray: [],
            startOfWeek: '',
            is_email: "",
            is_sms: "",
            is_print_pdf: true,
            // is_backoffice_mail: true,
            driveinId: '',
            confirmEdit: false,
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            email: '',
            emailError: '',
            mobileNumber: '',
            mobileNumberError: '',
            street: '',
            streetError: '',
            houseNumber: '',
            houseNumberError: '',
            country: '',
            countryError: '',
            state: '',
            stateError: '',
            city: '',
            cityError: '',
            postalCode: '',
            postalCodeError: '',
            km_stand: '',
            mileageError: '',
            showModal: false,
            showAppointmentList: false,
            showFinalModal: false,
            // modal states
            isOpenAppointmentListModal: false,
            appointmentSlotTime: null,
            appointmentDay: null,
            isOpenConfirmDetailsModal: false,
            selectedAppointmentTime: '',
            isOpenConfirmAppointmentModal: false,
            selectedAppointmentId: null,
            isOpenAppointmentSuccessModal: false,
            loading: false,
            buttonClicked: false,
            isNextDisable: false,
            isPreviousDisable: false,
            country_code: '',
            profession: 'not specified',
            salutation: 'Mr.',
            isButtonClicked: false,
            dateByDay: [],
            countOnClick: 0,
        }
    }

    componentDidMount = async () => {
        await this.getCalenderData();
        const is_send_email = Cookies.get('is_send_email');
        const is_send_sms = Cookies.get('is_send_sms');
        this.setState({
            is_email: is_send_email === '1' ? true : false,
            is_sms: is_send_sms === '1' ? true : false,
        })
    }

    // componentWillReceiveProps(props){
    //   
    //     if(props.location?.state?.driveIn || props?.location?.state?.insurance_client_id) {
    //         this.getCalenderData();
    //     }
    // }

    // before smit device change
    // getCalenderData = () => {
    //     this.setState({ loading: true });
    //     const driveinId = this.props?.router?.location?.state?.driveIn || '';
    //     const clientId = this.props?.router?.location?.state?.insurance_client_id || '';
    //     // const { driveIn, insurance_client_id } = this.props?.location?.state;
    //     let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`
    //     axios.get(url).then(
    //         res => {
    //             if (res.data.success) {
    //                 this.setState({
    //                     allSlot: res.data.data.allSlot,
    //                     driveinId: driveinId,
    //                     loading: false

    //                 }, () => {
    //                     const { allSlot, days } = this.state
    //                     const currentDate = new Date();
    //                     const currentDayIndex = currentDate.getDay();
    //                     let sortedTimeArray = [];
    //                     let headerTotalSlot = {};

    //                     const moment = require('moment');

    //                     // const from_date = '23-03-2023'; 
    //                     const from_date = this.props.router.location.state.from_date;  // from_date from props
    //                     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
    //                     const to_date = this.props.router.location.state.to_date;
    //                     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
    //                     for (let i = 0; i < 7; i++) {
    //                         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //                         if (date == to_date) {
    //                             this.setState({
    //                                 isNextDisable: true
    //                             })
    //                         }
    //                     }
    //                     if(this.state.countOnClick == 0){
    //                         this.setState({
    //                             isPreviousDisable : true
    //                         })
    //                     }
    //                     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');

    //                     const index = days.indexOf(dayOfWeek);

    //                     const slots = {};
    //                     // const from_date = '01/04/2023'
    //                     const total_slot_empty = {}
    //                     allSlot.forEach(slot => {

    //                         slot.details.forEach(detail => {

    //                             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));

    //                             if (index <= dayIndex) {
    //                                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //                                     "total_slot": slot.total_slot,
    //                                     "available_slot": slot.available_slot,
    //                                     "Date": slot.date
    //                                 }
    //                             }

    //                             this.setState({
    //                                 headerTotalSlot: headerTotalSlot
    //                             });

    //                             const time = detail.start_time;
    //                             const total_slot = detail.total_slot

    //                             if (!slots[`${time}`]) {
    //                                 slots[`${time}`] = {
    //                                     Monday: [],
    //                                     Tuesday: [],
    //                                     Wednesday: [],
    //                                     Thursday: [],
    //                                     Friday: [],
    //                                     Saturday: [],
    //                                     Sunday: [],
    //                                 };
    //                             }

    //                             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
    //                                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
    //                             }

    //                             if (index <= dayIndex) {
    //                                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
    //                                     Date: slot.date,
    //                                     Total_booked: slot.total_slot,
    //                                     available: slot.available_slot,
    //                                     sub_slots: detail
    //                                 });
    //                             }

    //                             this.setState({
    //                                 slots: slots
    //                             });
    //                             sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //                                 let timeA = new Date("1970/01/01 " + a);
    //                                 let timeB = new Date("1970/01/01 " + b);
    //                                 return timeA - timeB;
    //                             });

    //                             this.setState({
    //                                 sortedTimeArray: sortedTimeArray,
    //                                 startOfWeek: startOfWeek
    //                             })
    //                         });
    //                     });
    //                 });
    //             }
    //         }
    //     ).catch(error => {
    //         this.setState({ loading: false });
    //         toastAlert(error.response.data.message, 'error');
    //     });
    // }

    getCalenderData = () => {
        this.setState({ loading: true });
        const driveinId = this.props?.router?.location?.state?.driveIn || '';
        const clientId = this.props?.router?.location?.state?.insurance_client_id || '';
        // const { driveIn, insurance_client_id } = this.props?.location?.state;
        let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`
        axios.get(url).then(
            res => {
                if (res.data.success) {
                    this.setState({
                        allSlot: res.data.data.allSlot,
                        driveinId: driveinId,
                        loading: false

                    }, () => {
                        const { allSlot, days } = this.state
                        const currentDate = new Date();
                        const currentDayIndex = currentDate.getDay();
                        let sortedTimeArray = [];
                        let headerTotalSlot = {};

                        const moment = require('moment');

                        // const from_date = '23-03-2023'; 
                        const from_date = this.props.router.location.state.from_date;  // from_date from props
                        const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
                        const to_date = this.props.router.location.state.to_date;
                        const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
                        for (let i = 0; i < 7; i++) {
                            const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
                            if (date == to_date) {
                                this.setState({
                                    isNextDisable: true
                                })
                            }
                        }
                        if (this.state.countOnClick == 0) {
                            this.setState({
                                isPreviousDisable: true
                            })
                        }
                        const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');

                        const index = days.indexOf(dayOfWeek);
                        var startDate = moment(from_date).startOf('week');
                        var endDate = moment(from_date).endOf('week');

                        const slots = {};
                        // const from_date = '01/04/2023'
                        const total_slot_empty = {}
                        allSlot.forEach(slot => {

                            slot.details.forEach(detail => {

                                const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));
                                var isBetween = moment(slot.date).isBetween(startDate, endDate);

                                if (index <= dayIndex && isBetween) {
                                    headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
                                        "total_slot": slot.total_slot,
                                        "available_slot": slot.available_slot,
                                        "Date": slot.date
                                    }
                                }

                                this.setState({
                                    headerTotalSlot: headerTotalSlot
                                });

                                const time = detail.start_time;
                                const total_slot = detail.total_slot

                                if (!slots[`${time}`]) {
                                    slots[`${time}`] = {
                                        Monday: [],
                                        Tuesday: [],
                                        Wednesday: [],
                                        Thursday: [],
                                        Friday: [],
                                        Saturday: [],
                                        Sunday: [],
                                    };
                                }

                                if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
                                    total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
                                }

                                if (index <= dayIndex) {
                                    slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
                                        Date: slot.date,
                                        Total_booked: slot.total_slot,
                                        available: slot.available_slot,
                                        sub_slots: detail
                                    });
                                }

                                this.setState({
                                    slots: slots
                                });
                                sortedTimeArray = Object.keys(slots).sort((a, b) => {
                                    let timeA = new Date("1970/01/01 " + a);
                                    let timeB = new Date("1970/01/01 " + b);
                                    return timeA - timeB;
                                });

                                this.setState({
                                    sortedTimeArray: sortedTimeArray,
                                    startOfWeek: startOfWeek
                                })
                            });
                        });
                    });
                }
            }
        ).catch(error => {
            this.setState({ loading: false });
            toastAlert(error.response.data.message, 'error');
        });
    }

    // getCalenderData = () => {
    //     this.setState({ loading: true });
    //     const driveinId = this.props?.router?.location?.state?.driveIn || '';
    //     const clientId = this.props?.router?.location?.state?.insurance_client_id || '';
    //     // const { driveIn, insurance_client_id } = this.props?.location?.state;
    //     let url = `${configuration.calendarSlotGet}drivein_id=${driveinId}&insurance_client_id=${clientId}`
    //     axios.get(url).then(
    //         res => {
    //             if (res.data.success) {
    //                 this.setState({
    //                     allSlot: res.data.data.allSlot,
    //                     driveinId: driveinId,
    //                     loading: false

    //                 }, () => {
    //                     const { allSlot, days } = this.state
    //                     const currentDate = new Date();
    //                     const currentDayIndex = currentDate.getDay();
    //                     let sortedTimeArray = [];
    //                     let headerTotalSlot = {};

    //                     const moment = require('moment');

    //                     // const from_date = '23-03-2023'; 
    //                     const from_date = this.props.router.location.state.from_date;  // from_date from props
    //                     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
    //                     const to_date = this.props.router.location.state.to_date;
    //                     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
    //                     for (let i = 0; i < 7; i++) {
    //                         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //                         if (date == to_date) {
    //                             this.setState({
    //                                 isNextDisable: true
    //                             })
    //                         }

    //                     }

    //                     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');

    //                     const index = days.indexOf(dayOfWeek);

    //                     const slots = {};
    //                     const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    //                     const dateByDayArr = [];
    //                     days.forEach((day, i) => {
    //                         const dateByDay = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //                         console.log(dateByDay, 'dateByDay');
    //                         dateByDayArr.push(dateByDay);
    //                     });
    //                     // const from_date = '01/04/2023'
    //                     const total_slot_empty = {}
    //                     allSlot.forEach(slot => {
    //                         slot.details.forEach(detail => {
    //                             const slotDate = moment(slot.date).format('YYYY-MM-DD');
    //                             console.log(slotDate, 'slotDate');
    //                             if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
    //                                 const time = detail.start_time;
    //                                 console.log(time,'tttttttt');
    //                                 if (!slots[time]) {
    //                                     slots[time] = {};
    //                                 }

    //                                 // Check if the date exists in the array
    //                                 if (!slots[time][slotDate]) {
    //                                     slots[time][slotDate] = [];
    //                                 }

    //                                 // Push the slot information into the array
    //                                 slots[time][slotDate].push({
    //                                     Date: slot.date,
    //                                     Total_booked: slot.total_slot,
    //                                     available: slot.available_slot,
    //                                     sub_slots: detail,
    //                                 });

    //                                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //                                     "total_slot": slot.total_slot,
    //                                     "available_slot": slot.available_slot,
    //                                     "Date": slot.date
    //                                 };

    //                                 console.log(slots, 'slots');
    //                             }
    //                         });
    //                     });
    //                     sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //                         const timeA = new Date(`1970/01/01 ${a}`);
    //                         const timeB = new Date(`1970/01/01 ${b}`);
    //                         return timeA - timeB;
    //                     });

    //                     // Create empty arrays if they don't have any values
    //                     dateByDayArr.forEach(date => {
    //                         sortedTimeArray.forEach(time => {
    //                             if (!slots[time][date]) {
    //                                 slots[time][date] = [];
    //                             }
    //                         });
    //                     });

    //                     this.setState({
    //                         headerTotalSlot,
    //                         slots,
    //                         sortedTimeArray,
    //                         startOfWeek,
    //                     });
    //                 });
    //             }
    //         }
    //     ).catch(error => {
    //         this.setState({ loading: false });
    //         toastAlert(error.response.data.message, 'error');
    //     });
    // }


    // clickNext = () => {
    //     const { allSlot, days } = this.state
    //     const currentDate = new Date();
    //     const currentDayIndex = currentDate.getDay();
    //     let sortedTimeArray = [];
    //     let headerTotalSlot = {};
    //     const moment = require('moment');
    //     const from_date = this.props.router.location.state.from_date;  // from_date from props
    //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(1, 'weeks');
    //     for (let i = 0; i < 7; i++) {
    //         const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //     }
    //     const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');
    //     const to_date = this.props.router.location.state.to_date;
    //     const to_datedayOfWeek = moment(to_date, 'YYYY-MM-DD').format('dddd');
    //     const index = days.indexOf(dayOfWeek);
    //     const to_date_index = days.indexOf(to_datedayOfWeek);
    //     const slots = {};
    //     // const from_date = '01/04/2023'
    //     const total_slot_empty = {}
    //     allSlot.forEach(slot => {
    //         slot.details.forEach(detail => {
    //             console.log(slot,'slot');
    //             const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));
    //             console.log(to_date_index, 'to_date_index');
    //             console.log(dayIndex,'dayIndex');
    //             if (to_date_index >= dayIndex) {
    //                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //                     "total_slot": slot.total_slot,
    //                     "available_slot": slot.available_slot,
    //                     "Date": slot.date
    //                 }
    //             }
    //             this.setState({
    //                 headerTotalSlot: headerTotalSlot
    //             })
    //             console.log(headerTotalSlot,'headerTotalSlot');
    //             const time = detail.start_time;
    //             const total_slot = detail.total_slot

    //             if (!slots[`${time}`]) {
    //                 slots[`${time}`] = {
    //                     Monday: [],
    //                     Tuesday: [],
    //                     Wednesday: [],
    //                     Thursday: [],
    //                     Friday: [],
    //                     Saturday: [],
    //                     Sunday: [],
    //                 };
    //             }

    //             if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
    //                 total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
    //             }
    //             if (to_date_index >= dayIndex) {
    //                 slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
    //                     Date: slot.date,
    //                     Total_booked: slot.total_slot,
    //                     available: slot.available_slot,
    //                     sub_slots: detail
    //                 });
    //             }
    //             console.log(slots,'slots');
    //             this.setState({
    //                 slots: slots
    //             })

    //             sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //                 let timeA = new Date("1970/01/01 " + a);
    //                 let timeB = new Date("1970/01/01 " + b);
    //                 return timeA - timeB;
    //             });
    //             this.setState({
    //                 sortedTimeArray: sortedTimeArray,
    //                 startOfWeek: startOfWeek
    //             })
    //         });
    //     });
    // }




    // clickNext = () => {
    //     const { allSlot, days } = this.state;
    //     const currentDate = new Date();
    //     const { language } = this.context
    //     const currentDayIndex = currentDate.getDay();
    //     let sortedTimeArray = [];
    //     let headerTotalSlot = {};

    //     const moment = require('moment');
    //     const from_date = this.props.router.location.state.from_date; // from_date from props
    //     const to_date = this.props.router.location.state.to_date;

    //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(1, 'weeks');
    //     const endOfWeek = moment(to_date, 'YYYY-MM-DD').isoWeekday(7);
    //     console.log(endOfWeek, 'endOfWeek');
    //     if (startOfWeek.isAfter(endOfWeek)) {
    //         return;
    //     }

    //     const slots = {};
    //     const total_slot_empty = {};

    //     const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    //     const dateByDayArr = []
    //     {
    //         days.map((day, i) => {
    //             const dateByDay = startOfWeek && moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //             console.log(dateByDay, 'dateByDay');
    //             dateByDayArr.push(dateByDay)
    //         })
    //     }
    //     console.log(startOfWeek, 'startOfWeek');
    //     console.log(endOfCurrentWeek, 'endOfCurrentWeek');
    //     allSlot.forEach(slot => {
    //         console.log(slot,"line 334")
    //         slot.details.forEach(detail => {
    //             const slotDate = moment(slot.date).format('YYYY-MM-DD');
    //             if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
    //                 const time = detail.start_time;
    //                 console.log(slots[time],time,"slots[time]")
    //                 if (!slots[time]) {
    //                     slots[time] = {};
    //                 }
    //                 // console.log(slots[time], time, 'slots[time][slotDate]');
    //                 if (!slots[time][slotDate]) {
    //                     slots[time][slotDate] = [];
    //                     console.log(slots,time,slotDate,"in if")
    //                 }
    //                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //                     "total_slot": slot.total_slot,
    //                     "available_slot": slot.available_slot,
    //                     "Date": slot.date
    //                 }
    //                 // dateByDayArr[slotDate].push({
    //                 //     Date: slot.date,
    //                 //     Total_booked: slot.total_slot,
    //                 //     available: slot.available_slot,
    //                 //     sub_slots: detail,
    //                 // })
    //                 // dateByDayArr.map((dateFromArr, i) => {
    //                 //     if(dateFromArr == slotDate){
    //                 //         slots[time][dateFromArr].push({
    //                 //             Date: slot.date,
    //                 //             Total_booked: slot.total_slot,
    //                 //             available: slot.available_slot,
    //                 //             sub_slots: detail,
    //                 //         });
    //                 //         console.log(slots,'is slots');
    //                 //     }
    //                 //     else{
    //                 //         slots[time][dateFromArr] = []
    //                 //         console.log(slots,'else slots');
    //                 //     }
    //                 // })
    //                 slots[time][slotDate].push({
    //                     Date: slot.date,
    //                     Total_booked: slot.total_slot,
    //                     available: slot.available_slot,
    //                     sub_slots: detail,
    //                 });
    //                 // console.log(dateByDayArr, 'dateByDayArr');
    //                 console.log(slots, 'slots');
    //             }
    //         });
    //     });
    //     // this.setState({
    //     //     dateByDay: dateByDayArr,
    //     // })

    //     sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //         const timeA = new Date(`1970/01/01 ${a}`);
    //         const timeB = new Date(`1970/01/01 ${b}`);
    //         return timeA - timeB;
    //     });

    //     this.setState({
    //         headerTotalSlot,
    //         slots,
    //         sortedTimeArray,
    //         startOfWeek,
    //     });
    // };


    // clickNext = () => {
    //     const { allSlot, days } = this.state;
    //     const currentDate = new Date();
    //     const { language } = this.context;
    //     const currentDayIndex = currentDate.getDay();
    //     let sortedTimeArray = [];
    //     let headerTotalSlot = {};

    //     const moment = require('moment');
    //     const from_date = this.props.router.location.state.from_date; // from_date from props
    //     const to_date = this.props.router.location.state.to_date;

    //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(1, 'weeks');
    //     const endOfWeek = moment(to_date, 'YYYY-MM-DD').isoWeekday(7);
    //     console.log(endOfWeek, 'endOfWeek');
    //     if (startOfWeek.isAfter(endOfWeek)) {
    //         return;
    //     }

    //     const slots = {};
    //     const total_slot_empty = {};

    //     const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    //     const dateByDayArr = [];

    //     days.forEach((day, i) => {
    //         const dateByDay = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //         console.log(dateByDay, 'dateByDay');
    //         dateByDayArr.push(dateByDay);
    //     });

    //     console.log(startOfWeek, 'startOfWeek');
    //     console.log(endOfCurrentWeek, 'endOfCurrentWeek');

    //     allSlot.forEach(slot => {
    //         slot.details.forEach(detail => {
    //             const slotDate = moment(slot.date).format('YYYY-MM-DD');
    //             console.log(slotDate,'slotDate');
    //             if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
    //                 const time = detail.start_time;
    //                 if (!slots[time]) {
    //                     slots[time] = {};
    //                 }

    //                 // if (!slots[time][slotDate]) {
    //                 //     slots[time][slotDate] = [];
    //                 //     console.log('empty arr');
    //                 // }
    //                 console.log(dateByDayArr,slotDate,'vvv');
    //                 var isMatchDate = false;
    //                 var calenderDate = '';
    //                 dateByDayArr.forEach(dat => { 
    //                     if(dat == slotDate){
    //                         isMatchDate = true;
    //                         console.log('match');
    //                         return;
    //                     }else{
    //                         calenderDate = dat;
    //                     }
    //                 })
    //                 if (!slots[time][slotDate]) {                
    //                     slots[time][slotDate] = [];
    //                 }
    //                 // if (dateByDayArr.includes(slotDate)) {

    //                 // }else{
    //                 //     console.log(slotDate, 'esslse');

    //                 // }
    //                 if(isMatchDate){                        
    //                     slots[time][slotDate].push({
    //                         Date: slot.date,
    //                         Total_booked: slot.total_slot,
    //                         available: slot.available_slot,
    //                         sub_slots: detail,
    //                     });
    //                     console.log('slot of time if',slots[time]);
    //                     console.log(calenderDate,'calenderDate');
    //                 }else{
    //                     console.log('slot of time else',slots[time]);
    //                     slots[time][slotDate] = [];
    //                 }

    //                 headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //                     "total_slot": slot.total_slot,
    //                     "available_slot": slot.available_slot,
    //                     "Date": slot.date
    //                 };

    //                 console.log(slots, 'slots');
    //             }
    //         });
    //     });

    //     sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //         const timeA = new Date(`1970/01/01 ${a}`);
    //         const timeB = new Date(`1970/01/01 ${b}`);
    //         return timeA - timeB;
    //     });

    //     this.setState({
    //         headerTotalSlot,
    //         slots,
    //         sortedTimeArray,
    //         startOfWeek,
    //     });
    // };

    // clickNext = () => {
    //     const { allSlot, days } = this.state;
    //     const currentDate = new Date();
    //     const { language } = this.context;
    //     const currentDayIndex = currentDate.getDay();
    //     let sortedTimeArray = [];
    //     let headerTotalSlot = {};

    //     const moment = require('moment');
    //     const from_date = this.props.router.location.state.from_date; // from_date from props
    //     const to_date = this.props.router.location.state.to_date;

    //     const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(1, 'weeks');
    //     const endOfWeek = moment(to_date, 'YYYY-MM-DD').isoWeekday(7);
    //     console.log(endOfWeek, 'endOfWeek');
    //     if (startOfWeek.isAfter(endOfWeek)) {
    //       return;
    //     }

    //     const slots = {};
    //     const total_slot_empty = {};

    //     const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
    //     const dateByDayArr = [];

    //     days.forEach((day, i) => {
    //       const dateByDay = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
    //       console.log(dateByDay, 'dateByDay');
    //       dateByDayArr.push(dateByDay);
    //     });

    //     console.log(startOfWeek, 'startOfWeek');
    //     console.log(endOfCurrentWeek, 'endOfCurrentWeek');

    //     allSlot.forEach(slot => {
    //       slot.details.forEach(detail => {
    //         const slotDate = moment(slot.date).format('YYYY-MM-DD');
    //         console.log(slotDate,'slotDate');
    //         if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
    //           const time = detail.start_time;
    //           if (!slots[time]) {
    //             slots[time] = {};
    //           }

    //           // Check if the date exists in the array
    //           if (!slots[time][slotDate]) {
    //             slots[time][slotDate] = [];
    //           }

    //           // Push the slot information into the array
    //           slots[time][slotDate].push({
    //             Date: slot.date,
    //             Total_booked: slot.total_slot,
    //             available: slot.available_slot,
    //             sub_slots: detail,
    //           });

    //           headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
    //             "total_slot": slot.total_slot,
    //             "available_slot": slot.available_slot,
    //             "Date": slot.date
    //           };

    //           console.log(slots, 'slots');
    //         }
    //       });
    //     });

    //     sortedTimeArray = Object.keys(slots).sort((a, b) => {
    //       const timeA = new Date(`1970/01/01 ${a}`);
    //       const timeB = new Date(`1970/01/01 ${b}`);
    //       return timeA - timeB;
    //     });

    //     // Create empty arrays if they don't have any values
    //     dateByDayArr.forEach(date => {
    //       sortedTimeArray.forEach(time => {
    //         if (!slots[time][date]) {
    //           slots[time][date] = [];
    //         }
    //       });
    //     });

    //     this.setState({
    //       headerTotalSlot,
    //       slots,
    //       sortedTimeArray,
    //       startOfWeek,
    //     });
    //   };

    getCountOnNext = () => {
        this.setState({
            countOnClick: this.state.countOnClick + 1,
        },
            () => this.clickNext());
    }

    getCountOnPrevious = () => {
        this.setState({
            countOnClick: this.state.countOnClick - 1,
        },
            () => {
                if (this.state.countOnClick == 0) {
                    this.clickPrevious()
                }
                else {
                    this.clickPreviousByDate()
                }
            });
    }

    clickNext = () => {
        const { allSlot, days } = this.state;
        const currentDate = new Date();
        const { language } = this.context;
        const currentDayIndex = currentDate.getDay();
        let sortedTimeArray = [];
        let headerTotalSlot = {};

        const moment = require('moment');
        const from_date = this.props.router.location.state.from_date; // from_date from props
        const to_date = this.props.router.location.state.to_date;

        const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(this.state.countOnClick, 'weeks');
        const endOfWeek = moment(to_date, 'YYYY-MM-DD').isoWeekday(7);
        if (startOfWeek.isAfter(endOfWeek)) {
            return;
        }



        const slots = {};

        const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
        const dateByDayArr = [];

        days.forEach((day, i) => {
            const dateByDay = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
            dateByDayArr.push(dateByDay);
        });
        for (let i = 0; i < 7; i++) {
            const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');
            console.log(date, to_date, 'date');
            if (date == to_date) {
                this.setState({
                    isNextDisable: true
                })
            }
        }



        allSlot.forEach(slot => {
            slot.details.forEach(detail => {
                const slotDate = moment(slot.date).format('YYYY-MM-DD');
                if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
                    const time = detail.start_time;
                    if (!slots[time]) {
                        slots[time] = {};
                    }

                    // Check if the date exists in the array
                    if (!slots[time][slotDate]) {
                        slots[time][slotDate] = [];
                    }

                    // Push the slot information into the array
                    slots[time][slotDate].push({
                        Date: slot.date,
                        Total_booked: slot.total_slot,
                        available: slot.available_slot,
                        sub_slots: detail,
                    });

                    headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
                        "total_slot": slot.total_slot,
                        "available_slot": slot.available_slot,
                        "Date": slot.date
                    };


                }
            });
        });

        sortedTimeArray = Object.keys(slots).sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a}`);
            const timeB = new Date(`1970/01/01 ${b}`);
            return timeA - timeB;
        });

        // Create empty arrays if they don't have any values
        dateByDayArr.forEach(date => {
            sortedTimeArray.forEach(time => {
                if (!slots[time][date]) {
                    slots[time][date] = [];
                }
            });
        });

        this.setState({
            headerTotalSlot,
            slots,
            sortedTimeArray,
            startOfWeek,
            isPreviousDisable: false
        });
    };

    clickPrevious = () => {
        const { allSlot, days } = this.state
        const currentDate = new Date();
        const currentDayIndex = currentDate.getDay();
        let sortedTimeArray = [];
        let headerTotalSlot = {};


        const moment = require('moment');

        // const from_date = this.props.router.location.state.from_date;  // from_date from props
        const from_date = this.props.router.location.state.from_date;  // from_date from props
        const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1);
        // const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).subtract(1, 'weeks');

        for (let i = 0; i < 7; i++) {
            const date = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');

        }
        const dayOfWeek = moment(from_date, 'YYYY-MM-DD').format('dddd');


        const index = days.indexOf(dayOfWeek);




        const slots = {};

        const total_slot_empty = {}
        allSlot.forEach(slot => {

            slot.details.forEach(detail => {
                ;
                const dayIndex = days.indexOf(moment(slot.date, 'YYYY-MM-DD').format('dddd'));

                if (index <= dayIndex) {
                    headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
                        "total_slot": slot.total_slot,
                        "available_slot": slot.available_slot,
                        "Date": slot.date
                    }
                }
                this.setState({
                    headerTotalSlot: headerTotalSlot
                })
                const time = detail.start_time;
                const total_slot = detail.total_slot

                if (!slots[`${time}`]) {
                    slots[`${time}`] = {
                        Monday: [],
                        Tuesday: [],
                        Wednesday: [],
                        Thursday: [],
                        Friday: [],
                        Saturday: [],
                        Sunday: [],
                    };
                }

                if (!total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')]) {
                    total_slot_empty[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = []
                }

                if (index <= dayIndex) {
                    slots[`${time}`][moment(slot.date, 'YYYY-MM-DD').format('dddd')].push({
                        Date: slot.date,
                        Total_booked: slot.total_slot,
                        available: slot.available_slot,
                        sub_slots: detail
                    });
                }
                this.setState({
                    slots: slots
                })



                sortedTimeArray = Object.keys(slots).sort((a, b) => {
                    let timeA = new Date("1970/01/01 " + a);
                    let timeB = new Date("1970/01/01 " + b);
                    return timeA - timeB;
                });
                this.setState({
                    sortedTimeArray: sortedTimeArray,
                    startOfWeek,
                    isPreviousDisable: true,
                    isNextDisable: false,
                })
            });
        });

    }

    clickPreviousByDate = () => {
        const { allSlot, days } = this.state;
        const currentDate = new Date();
        const { language } = this.context;
        const currentDayIndex = currentDate.getDay();
        let sortedTimeArray = [];
        let headerTotalSlot = {};

        const moment = require('moment');
        const from_date = this.props.router.location.state.from_date; // from_date from props
        const to_date = this.props.router.location.state.to_date;

        const startOfWeek = moment(from_date, 'YYYY-MM-DD').isoWeekday(1).add(this.state.countOnClick, 'weeks');
        const endOfWeek = moment(to_date, 'YYYY-MM-DD').isoWeekday(7);

        if (startOfWeek.isAfter(endOfWeek)) {
            return;
        }

        const slots = {};

        const endOfCurrentWeek = moment(startOfWeek).isoWeekday(7);
        const dateByDayArr = [];

        days.forEach((day, i) => {
            const dateByDay = moment(startOfWeek).add(i, 'days').format('YYYY-MM-DD');

            dateByDayArr.push(dateByDay);
        });


        allSlot.forEach(slot => {
            slot.details.forEach(detail => {
                const slotDate = moment(slot.date).format('YYYY-MM-DD');

                if (moment(slotDate).isBetween(startOfWeek, endOfCurrentWeek, null, '[]')) {
                    const time = detail.start_time;
                    if (!slots[time]) {
                        slots[time] = {};
                    }

                    // Check if the date exists in the array
                    if (!slots[time][slotDate]) {
                        slots[time][slotDate] = [];
                    }

                    // Push the slot information into the array
                    slots[time][slotDate].push({
                        Date: slot.date,
                        Total_booked: slot.total_slot,
                        available: slot.available_slot,
                        sub_slots: detail,
                    });

                    headerTotalSlot[moment(slot.date, 'YYYY-MM-DD').format('dddd')] = {
                        "total_slot": slot.total_slot,
                        "available_slot": slot.available_slot,
                        "Date": slot.date
                    };


                }
            });
        });

        sortedTimeArray = Object.keys(slots).sort((a, b) => {
            const timeA = new Date(`1970/01/01 ${a}`);
            const timeB = new Date(`1970/01/01 ${b}`);
            return timeA - timeB;
        });

        // Create empty arrays if they don't have any values
        dateByDayArr.forEach(date => {
            sortedTimeArray.forEach(time => {
                if (!slots[time][date]) {
                    slots[time][date] = [];
                }
            });
        });

        this.setState({
            headerTotalSlot,
            slots,
            sortedTimeArray,
            startOfWeek,
            isNextDisable: false,
        });
    }

    OpenModal = (id) => {
        this.setState({ showModal: true, activeModalId: id });
    };

    openFinalModal = () => {
        this.setState({
            showFinalModal: true,
        })
    }

    checkBox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    bookAppointment = () => {
        this.setState({ isButtonClicked: true });
        const uuid = this.props?.router?.location?.state?.uuid
        const drive_through_id = this.state.driveinId
        const customer_id = this.props?.router?.location?.state?.customer_id
        const { is_email, is_sms, is_print_pdf, selectedAppointmentId } = this.state
        const is_booking = this.props?.router?.location?.state?.is_booking
        const pathname = this.props?.router?.location?.pathname;
        const helpDeskPage = pathname && pathname.startsWith('/helpdesk')

        if (is_booking == 0) {
            const url = configuration.appointBook;
            const payLoad = {
                uuid,
                appointment_id: selectedAppointmentId,
                drive_through_id,
                customer_id,
                is_email: is_email == true ? 1 : 0,
                is_sms: is_sms == true ? 1 : 0,
                is_print_pdf: is_print_pdf == true ? 1 : 0,
            }

            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            isOpenConfirmAppointmentModal: false,
                            isOpenAppointmentSuccessModal: !this.state.isOpenAppointmentSuccessModal,
                        });
                        // after 3 seconds redirect to next page
                        // setTimeout(() => {
                        //     const nextPage = helpDeskPage ? '/helpdesk/options' : '/thankyou';
                        //     const state = {
                        //         uuid: this.props.router.location.state.uuid,
                        //     };
                        //     this.props.router.navigate(nextPage, state);
                        // }, 3000);
                        this.getCalenderData();
                    }
                })
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });


        }
        else {
            const url = configuration.reschedule;
            const payLoad = {
                // uuid,
                appointment_id: selectedAppointmentId,
                drive_through_id,
                customer_id,
                is_email: is_email == true ? 1 : 0,
                is_sms: is_sms == true ? 1 : 0,
                is_print_pdf: is_print_pdf == true ? 1 : 0,
            }
            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            isOpenConfirmAppointmentModal: false,
                            isOpenAppointmentSuccessModal: !this.state.isOpenAppointmentSuccessModal,
                        });
                        // after 3 seconds redirect to next page
                        // setTimeout(() => {
                        //     const nextPage = helpDeskPage ? '/helpdesk/options' : '/thankyou';
                        //     const state = {
                        //         uuid: this.props.router.location.state.uuid,
                        //     };
                        //     this.props.router.navigate(nextPage, state);
                        // }, 3000);
                        this.getCalenderData();
                    }
                })
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });


        }
    }

    handleEdit = () => {
        // if (!this.state.confirmEdit) {
        this.setState({
            confirmEdit: !this.state.confirmEdit
        })
        // }
    }

    validateInputs = () => {
        const { lang } = this.context;
        let firstNameError = '';
        let lastNameError = '';
        let emailError = '';
        let mobileNumberError = '';
        let streetError = '';
        let houseNumberError = '';
        let countryError = '';
        let stateError = '';
        let cityError = '';
        let postalCodeError = '';
        let mileageError = '';

        if (!this.state.firstName.trim()) {
            firstNameError = `${lang("please_enter_your_first_name.")}`;
        }

        if (!this.state.lastName.trim()) {
            lastNameError = `${lang("please_enter_your_last_name.")}`;
        }

        if (!this.state.email.includes('@')) {
            emailError = `${lang("please_enter_your_email_id.")}`;
        }

        if (!this.state.mobileNumber.trim()) {
            mobileNumberError = `${lang("please_enter_your_mobile_number.")}`;
        }

        if (!this.state.street.trim()) {
            streetError = `${lang("please_enter_your_street_name.")}`;
        }

        if (!this.state.houseNumber.trim()) {
            houseNumberError = `${lang("please_enter_your_house_number.")}`;
        }

        if (!this.state.country.trim()) {
            countryError = `${lang("please_enter_your_country.")}`;
        }

        if (!this.state.state.trim()) {
            stateError = `${lang("please_enter_your_state.")}`;
        }

        if (!this.state.city.trim()) {
            cityError = `${lang("please_enter_your_city.")}`;
        }

        if (!this.state.postalCode.trim()) {
            postalCodeError = `${lang("please_enter_your_postal_code.")}`;
        }

        if (!this.state.km_stand.trim()) {
            mileageError = `${lang("please_enter_your_mileage.")}`;
        }

        this.setState({
            firstNameError,
            lastNameError,
            emailError,
            mobileNumberError,
            streetError,
            houseNumberError,
            countryError,
            stateError,
            cityError,
            postalCodeError,
            mileageError
        });

        return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !countryError && !stateError && !cityError && !postalCodeError && !mileageError;
    }

    handleChange = (e, name) => {

        if (name == 'mobileNumber') {
            this.setState({
                [name]: e
            })
        } else {
            if (e && e.target) {
                const { name, value } = e.target;
                this.setState({ [name]: value });
            }
        }
    }

    handleConfirmSubmit = async () => {
        const isValid = await this.validateInputs()
        this.setState({ buttonClicked: true });


        if (isValid) {
            let uuid = this.props.router.location.state.uuid
            const reg_no = this.props.router.location.state.reg_no
            const order_id = this.props.router.location.state.order_id
            const claim_number = this.props.router.location.state.claim_number
            // const country_code = this.props.router.location.state.country_code


            // let country_code = "123"
            const url = configuration.updateData;
            const { salutation, profession, firstName, lastName, email, country_code, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand } = this.state;
            const payLoad =
            {
                'reg_no': reg_no,
                'order_id': order_id,
                'claim_number': claim_number,
                'uuid': uuid,
                // 'country_code': country_code,
                'first_name': firstName,
                'last_name': lastName,
                'email_id': email,
                'contact_no': mobileNumber,
                'street': street,
                'house_no': houseNumber,
                'country': country,
                'state': state,
                'city': city,
                'pincode': postalCode,
                'km_stand': km_stand,
                'profession': profession,
                'salutation': salutation,
            }
            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        const { isOpenConfirmAppointmentModal, isOpenConfirmDetailsModal } = this.state;
                        this.setState({
                            isOpenConfirmDetailsModal: false,
                            isOpenConfirmAppointmentModal: !isOpenConfirmAppointmentModal
                        })
                    } else {
                        toastAlert("Something went to wrong.Please try again later.", 'error');
                    }
                })
                .catch(error => {
                    toastAlert(error?.response?.data?.message ? error.response.data.message : "Something went to wrong. Please try again later.", 'error');
                });

        }


    };

    handleToggle = (modalName, appointmentSlotTime, appointmentDay) => {

        this.setState({
            [modalName]: !this.state[modalName],
            appointmentSlotTime: appointmentSlotTime ?? '',
            appointmentDay: appointmentDay ?? ''
        });
        let bookingTag = document.querySelector(".booking-tag");

        document.body.classList.add("body-fixed");
        bookingTag.addEventListener("click", function () {

        });
    }

    handleConfirmDetailsModal = (selectedAppointmentTime, selectedAppointmentId) => {
        const { isOpenConfirmDetailsModal } = this.state;
        this.setState({
            isOpenConfirmDetailsModal: !isOpenConfirmDetailsModal,
            isOpenAppointmentListModal: false,
            selectedAppointmentTime: selectedAppointmentTime,
            selectedAppointmentId
        })
        let uuid = this.props.router.location.state.uuid

        const url = configuration.bookAppointment + uuid;
        axios.get(url).then(
            res => {
                if (res.data.success) {
                    this.setState({
                        userData: res.data.data,
                        firstName: res.data.data.first_name,
                        lastName: res.data.data.last_name,
                        email: res.data.data.email_id,
                        mobileNumber: res.data.data.contact_no,
                        street: res.data.data.street,
                        houseNumber: res.data.data.house_no,
                        country: res.data.data.country,
                        state: res.data.data.state,
                        city: res.data.data.city,
                        postalCode: res.data.data.pincode,
                        km_stand: res.data.data.km_stand,
                        country_code: res.data.data.country_code,
                        insurance_client_id: res.data.data.insurance_client_id,
                        salutation: res.data.data.salutation ? res.data.data.salutation : this.state.salutation,
                        profession: res.data.data.profession ? res.data.data.profession : this.state.profession,
                    });
                }
            }
        )
            .catch(error => {
                this.setState({ loading: false })
                toastAlert(error.response.data.message, 'error');
            });
    }

    closeModal = (modalName) => {
        document.body.classList.remove("body-fixed");
        this.setState({
            [modalName]: false,
            appointmentSlotTime: null,
            appointmentDay: null,
            selectedAppointmentTime: '',
            selectedAppointmentId: null,
            confirmEdit: false,
        }, () => {
            if (modalName === 'isOpenAppointmentSuccessModal' && !this.state.isOpenAppointmentListModal) {
                window.close();
            }
        })
    }

    viewPdf = () => {
        const customer_id = this.props.router.location.state.customer_id;
        const driveIn = this.props.router.location.state.driveIn;
        const appointment_id = this.state.selectedAppointmentId;

        const url = `${configuration.viewPdf}appointment_id=${appointment_id || ''}&customer_id=${customer_id}&drivin_id=${driveIn}`;
        axios.get(url)
            .then(res => {
                if (res.data.success) {
                    // const decodedPdfData = fromByteArray(res.data.data);
                    // const blob = new Blob([decodedPdfData], { type: 'application/pdf' });
                    // const pdfUrl = URL.createObjectURL(blob);
                    // const printWindow = window.open(pdfUrl, '_blank');
                    // printWindow.onload = () => {
                    //   printWindow.print();
                    // };

                    var windo = window.open("", "");
                    var objbuilder = '';
                    objbuilder += ('<embed width=\'100%\' height=\'100%\'  src="data:application/pdf;base64,');
                    objbuilder += (res.data.data);
                    objbuilder += ('" type="application/pdf" />');
                    windo.document.write(objbuilder);
                } else {
                    toastAlert('Data not Found', 'error');
                }
            })
            .catch(error => {
                // toastAlert('Failed to fetch PDF data', 'error');
                toastAlert(error.response.data.message, 'error');
            });
    };

    render() {
        const { lang, language } = this.context;
        const {
            allSlot,
            days,
            headerTotalSlot,
            sortedTimeArray,
            slots,
            startOfWeek,
            firstName,
            lastName,
            email,
            mobileNumber,
            street,
            houseNumber,
            country,
            state,
            city,
            postalCode,
            km_stand,
            userData,
            confirmEdit,
            // modal states
            isOpenAppointmentListModal,
            appointmentDay,
            appointmentSlotTime,
            isPreviousDisable,
            isOpenConfirmDetailsModal,
            selectedAppointmentTime,
            isOpenConfirmAppointmentModal,
            isOpenAppointmentSuccessModal,
            loading,
            buttonClicked,
            isNextDisable,
            is_email,
            is_sms,
            is_print_pdf,
            countOnClick,
            cityError, stateError, countryError, houseNumberError, streetError, mobileNumberError, emailError,
            lastNameError, firstNameError, postalCodeError, mileageError, viewPdf, salutation, profession, isButtonClicked
        } = this.state

        const oldFrom_date = this?.props?.router?.location?.state?.from_date;
        const dateFormat = Cookies.get('dateFormat')
        // const from_date = moment(oldFrom_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY');
        const from_date = moment(oldFrom_date).format(dateFormat);
        const oldto_date = this.props?.router?.location?.state?.to_date;
        // const to_date = moment(oldto_date).format(['DE', 'CH-DE', 'SL-SL'].includes(language) ? 'DD.MM.YYYY' : 'DD/MM/YYYY');
        const to_date = moment(oldto_date).format(dateFormat);
        const currentDate = new Date();
        const currentDayIndex = currentDate.getDay();
        const insurance_name = this.props?.router?.location?.state?.insurance_name;
        const claim_number = this.props?.router?.location?.state?.claim_number;
        const first_name = this.props?.router?.location?.state?.first_name;
        const last_name = this.props?.router?.location?.state?.last_name;
        const reg_no = this.props?.router?.location?.state?.reg_no;
        const country_code = this.props?.router?.location?.state?.country_code;


        return (
            <>
                <CalendarComponent
                    allSlot={allSlot}
                    countOnClick={countOnClick}
                    isButtonClicked={isButtonClicked}
                    salutation={salutation}
                    profession={profession}
                    postalCodeError={postalCodeError}
                    mileageError={mileageError}
                    cityError={cityError}
                    firstNameError={firstNameError}
                    lastNameError={lastNameError}
                    stateError={stateError}
                    emailError={emailError}
                    countryError={countryError}
                    houseNumberError={houseNumberError}
                    mobileNumberError={mobileNumberError}
                    streetError={streetError}
                    days={days}
                    country_code={country_code}
                    headerTotalSlot={headerTotalSlot}
                    sortedTimeArray={sortedTimeArray}
                    slots={slots}
                    startOfWeek={startOfWeek}
                    firstName={firstName}
                    isPreviousDisable={isPreviousDisable}
                    lastName={lastName}
                    email={email}
                    mobileNumber={mobileNumber}
                    street={street}
                    houseNumber={houseNumber}
                    country={country}
                    state={state}
                    city={city}
                    postalCode={postalCode}
                    km_stand={km_stand}
                    userData={userData}
                    confirmEdit={confirmEdit}
                    isOpenAppointmentListModal={isOpenAppointmentListModal}
                    appointmentDay={appointmentDay}
                    appointmentSlotTime={appointmentSlotTime}
                    isOpenConfirmDetailsModal={isOpenConfirmDetailsModal}
                    selectedAppointmentTime={selectedAppointmentTime}
                    isOpenConfirmAppointmentModal={isOpenConfirmAppointmentModal}
                    isOpenAppointmentSuccessModal={isOpenAppointmentSuccessModal}
                    loading={loading}
                    buttonClicked={buttonClicked}
                    isNextDisable={isNextDisable}
                    reg_no={reg_no}
                    last_name={last_name}
                    first_name={first_name}
                    claim_number={claim_number}
                    insurance_name={insurance_name}
                    currentDayIndex={currentDayIndex}
                    to_date={to_date}
                    from_date={from_date}
                    lang={lang}
                    is_email={is_email}
                    is_sms={is_sms}
                    is_print_pdf={is_print_pdf}
                    handleToggle={this.handleToggle}
                    closeModal={this.closeModal}
                    handleConfirmDetailsModal={this.handleConfirmDetailsModal}
                    handleConfirmSubmit={this.handleConfirmSubmit}
                    checkBox={this.checkBox}
                    clickPrevious={this.clickPrevious}
                    clickNext={this.clickNext}
                    handleEdit={this.handleEdit}
                    handleChange={(e, name) => this.handleChange(e, name)}
                    bookAppointment={this.bookAppointment}
                    PhoneInput={PhoneInput}
                    viewPdf={() => this.viewPdf()}
                    getCountOnNext={this.getCountOnNext}
                    getCountOnPrevious={this.getCountOnPrevious}
                />
            </>
        );
    }
}

export default withRouter(CustomerCalendar);
