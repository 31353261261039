import React, { Component } from 'react';
import '../customerLocationList/customerLocationList.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import withRouter from '../../withRouter';
import Location from "../../assets/images/select-location.svg"
import greenCircle from "../../assets/images/green-semi-circle.svg"
import redCircle from "../../assets/images/red-semi-circle.svg"
import orangeCircle from "../../assets/images/orange-semi-circle.svg"
import locationIcon from "../../assets/images/location.svg"
import LanguageContext from '../../context/LanguageProvider';
import moment from 'moment';
import toastAlert from '../../helper/ToastAlert/ToastAlert'
import { Spinner } from 'reactstrap';
import LocationComponent from '../../components/location/LocationComponent';
import { CookiesRemove } from '../../CookiesRemove';
export class CustomerLocationList extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            locationDestination: [],
            locationPagination: [],
            locationOrigin: [],
            clickedCardLocation: {},
            buttonOpen: false,
            driveIn: [],
            insurance_client_id: '',
            current_page: '',
            to_date: '',
            from_date: '',
            km_distance: '',
            moreLocation: [],
            showMoreLocationButton: true,
            loading: false,
            showMoreLocations: false,
            numLocations: 5,
            isMoreLocationVisible: false,
            toggleMoreLocation: false,
            locationKm: '',
            locationName: '',
            listViewData: [],
            currentPage: 1,
            totalPages: 0,
        }
    }

    handleToggle = () => {
        this.setState({
            toggle: !this.state.toggle,
        })

        const collapsibleElements = document.querySelectorAll('.collapse.show');
        collapsibleElements.forEach((el) => {
            el.classList.remove('show');
        });
    }

    componentDidMount = () => {
        this.getLocationData();

    }

    componentWillReceiveProps = (props) => {
        if (props?.router?.location?.state?.insurance_client_id)
            return {
                insurance_client_id: props?.router?.location?.state?.insurance_client_id
            }
    }

    getLocationData = () => {
        this.setState({ loading: true });
        let uuid = this.props?.router?.location?.state?.uuid;
        let insurance_client_id = this.props.router.location?.state?.insurance_client_id;
        let page_no = 1;
        const distances = [1, 2, 3];
        var moreLocation = [];

        distances.map(distance => {
            const url = `${configuration.locationData}${uuid}?insurance_client_id=${insurance_client_id || ''}&&page_no=${page_no || ''}&&km_distance=${distance}`;

            axios.get(url).then(
                res => {
                    if (res.data.success) {
                        // moreLocation = [...moreLocation,...res.data.data.destination.slice(6)]
                        this.setState(prevState => ({
                            locationDestination: {
                                ...prevState.locationDestination,
                                [distance]: res.data.data.destination
                            },
                            locationOrigin: {
                                ...prevState.locationOrigin,
                                [distance]: res.data.data.origin
                            },
                            insurance_client_id: insurance_client_id,
                            uuid: uuid,
                            from_date: res.data?.data?.destination[0]?.from_date,
                            to_date: res.data?.data?.destination[0]?.to_date,
                            km_distance: distance,
                            loading: false

                        }));
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }
            );
        });


    }

    handleMoreLocations = (MoreLocation, key) => {
        const clickMoreLocation = MoreLocation.slice(4)
        this.setState(prevState => ({
            showMoreLocations: !prevState.showMoreLocations,
            moreLocation: clickMoreLocation,
            isMoreLocationVisible: !prevState.isMoreLocationVisible
        }));
        const moreLocationDrawer = document.getElementsByClassName("more-location-drawer-wrapper")[key];
        if (moreLocationDrawer) {
            moreLocationDrawer.classList.toggle("show");
        }
    }

    handleMoreLocation = () => {
        var moreLocationHandle = []
        this.setState({
            toggle: !this.state.toggle,
        })
        Object.keys(this.state.locationDestination).map((destination) => {
            moreLocationHandle = [...moreLocationHandle, ...this.state.locationDestination[destination].slice(5)]
        })
        this.setState({
            moreLocation: moreLocationHandle,
        })

        let uuid = this.props.router.location.state.uuid
        let insurance_client_id = this.props.router.location.state.insurance_client_id
        let page_no = 2

        const url = `${configuration.locationData}${uuid}?insurance_client_id=${insurance_client_id || ''}&&page_no=${page_no || ''}`;

        axios.get(url).then(
            res => {
                if (res.data.success) {
                    this.setState({
                        locationPagination: res.data.data.destination,
                        locationOrigin: res.data.data.origin,
                        uuid: uuid
                    });

                }
            }
        )
    }

    handelClick = (location) => {
        this.setState({
            clickedCardLocation: location,
            toggle: !this.state.toggle,
            loading: true
        })
        let url = `${configuration.driveInLocation}?location=${location.location_name || ''}&country=${location.country_name || ''}&to_date=${location.to_date || ''}&from_date=${location.from_date || ''}&insurance_client_id=${this.props.router.location.state.insurance_client_id || ''}&cust_id=${this.props.router.location.state.uuid || ''}`
        axios.get(url)
            .then(
                res => {
                    if (res.data.success) {
                        this.setState({
                            driveIn: res.data.data.drivin,
                            loading: false
                        });
                    }
                }
            )
            .catch(error => {
                this.setState({ loading: false })
                toastAlert(error.response.data.message, 'error');
            });

    }

    handelListClick = async (listData) => {
        this.setState({
            clickedCardLocation: listData,
            toggle: !this.state.toggle,
            loading: true
        });
        let url = `${configuration.driveInLocation}?location=${listData.location_name || ''}&country=${listData.country_name || ''}&to_date=${listData.to_date || ''}&from_date=${listData.from_date || ''}&insurance_client_id=${this.props.router.location.state.insurance_client_id || ''}&cust_id=${this.props.router.location.state.uuid || ''}`;

        try {
            const response = await axios.get(url);
            if (response.data.success) {
                this.setState({
                    driveIn: response.data.data.drivin,
                    loading: false,
                    locationKm: listData.km,
                    locationName: listData.location_name,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            toastAlert(error.response.data.message, 'error');
            if (error.response.data.code === 401) {
                CookiesRemove();
                toastAlert(error.response.data.message, 'error');
                this.props.router.navigate('/login');
            }
        }
    }

    handelSlotsButton = () => {
        this.setState({
            buttonOpen: !this.state.buttonOpen,
        })
    }

    handleListView = async () => {
        this.setState({
            loading: true,
        });
        let uuid = this.props?.router?.location?.state?.uuid;
        let page_no = this.state.currentPage;
        const locationListView = `${configuration.locationListView}${uuid}?page_no=${page_no || ''}`;
        if (uuid) {
            try {
                const response = await axios.get(locationListView);
                if (response.data.success) {
                    this.setState({
                        listViewData: response.data.data.destination,
                        loading: false,
                        totalPages: response.data.data.total_page,
                    });
                }
            } catch (err) {
                this.setState({ loading: false });

                if (err.response.data.code === 401) {
                    CookiesRemove();
                    toastAlert(err.response.data.message, 'error');
                    this.props.router.navigate('/login');
                }

                toastAlert(err.response.data.message, 'error');
            }
        }
    }

    handlePageChange(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.handleListView();
        });
    }

    handlePreviousPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({
                currentPage: currentPage - 1
            }, () => {
                this.handleListView();
            });
        }
    }

    handleNextPage = () => {
        const { currentPage, totalPages } = this.state;
        if (currentPage < totalPages) {
            this.setState({
                currentPage: currentPage + 1
            }, () => {
                this.handleListView();
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({
            currentPage: page
        }, () => {
            this.handleListView();
        });
    };

    renderPagination = (eee) => {
        console.log(eee, 'eee');
        console.log(this.state, 'currentPage, totalPages');
        const { currentPage, totalPages } = this.state;
        const pageLinks = [];
        const pageSize = 3; // Number of page links to display at a time

        // Calculate the start and end page indices based on the current page
        let startPage = (Math.floor((currentPage - 1) / pageSize) * pageSize) + 1;
        let endPage = Math.min(startPage + pageSize - 1, totalPages);

        for (let page = startPage; page <= endPage; page++) {
            pageLinks.push(
                <li key={page} className={`page-item${page === currentPage ? ' active' : ''}`}>
                    <a className="page-link" onClick={() => this.handlePageChange(page)}>
                        {page}
                    </a>
                </li>
            );
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    {currentPage > 1 ? (
                        <li className="page-item">
                            <a className="page-link" onClick={this.handlePreviousPage} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                    ) : (
                        <li className="page-item">
                            <span className="page-link" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </span>
                        </li>
                    )}
                    {pageLinks}
                    {currentPage < totalPages ? (
                        <li className="page-item">
                            <a className="page-link" onClick={this.handleNextPage} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    ) : (
                        <li className="page-item">
                            <span className="page-link" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </span>
                        </li>
                    )}
                </ul>
            </nav>
        );
    }

    // renderPagination = () => {
    //     console.log('abc');
    // }



    render() {
        const locationOrigin = this.state.locationOrigin[1]?.location_name
        const { isMoreLocationVisible, moreLocation, toggle, locationDestination, locationPagination, clickedCardLocation, buttonOpen, driveIn, to_date, from_date, loading, toggleMoreLocation, listViewData, locationKm, locationName } = this.state;
        const { lang, language } = this.context
        return (
            <>
                {this.state.currentPage && <LocationComponent
                    listViewData={listViewData}
                    locationKm={locationKm}
                    locationName={locationName}
                    handelListClick={this.handelListClick}
                    handleListView={this.handleListView}
                    handlePageChange={this.handlePageChange}
                    handlePreviousPage={this.handlePreviousPage}
                    handleNextPage={this.handleNextPage}
                    handlePageClick={this.handlePageClick}
                    renderPagination={this.renderPagination}
                    locationOrigin={locationOrigin}
                    isMoreLocationVisible={isMoreLocationVisible}
                    moreLocation={moreLocation}
                    toggle={toggle}
                    locationDestination={locationDestination}
                    locationPagination={locationPagination}
                    clickedCardLocation={clickedCardLocation}
                    buttonOpen={buttonOpen}
                    driveIn={driveIn}
                    to_date={to_date}
                    from_date={from_date}
                    loading={loading}
                    toggleMoreLocation={toggleMoreLocation}
                    lang={lang}
                    language={language}
                    handelSlotsButton={this.handelSlotsButton}
                    handelClick={this.handelClick}
                    handleMoreLocation={this.handleMoreLocation}
                    handleMoreLocations={this.handleMoreLocations}
                    getLocationData={this.getLocationData}
                    componentWillReceiveProps={this.componentWillReceiveProps}
                    handleToggle={this.handleToggle}
                />
                }
            </>
        );
    }
}

export default withRouter(CustomerLocationList);
