import React, { Component } from 'react';
import '../addCustomerDetails/addCustomerDetails.css';
import { Link } from 'react-router-dom';
import withRouter from '../../withRouter';
import axios from 'axios';
import { configuration, url } from '../../appConfig';
import Swal from 'sweetalert2';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import user from "../../assets/images/user.svg";
import LanguageContext from '../../context/LanguageProvider';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { Spinner } from 'reactstrap';
// import 'flag-icon-css/css/flag-icon.min.css';    
import CustomerDetailComponent from '../../components/customerDetails/CustomerDetailComponent';



export class AddCustomerDetails extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            email: '',
            emailError: '',
            mobileNumber: '',
            mobileNumberError: '',
            street: '',
            streetError: '',
            houseNumber: '',
            houseNumberError: '',
            country: '',
            countryError: '',
            state: '',
            stateError: '',
            city: '',
            cityError: '',
            postalCode: '',
            postalCodeError: '',
            km_stand: '',
            mileageError: '',
            userData: {},
            uuid: "",
            insurance_client_id: '',
            loading: false,
            isButtonClicked: false,
            country_code: '',
            salutation: 'Mr.',
            salutationError: '',
            profession: 'not specified',
            professionError: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }

    handlePhone = (val) => {
        this.setState({
            mobileNumber: val
        })
    }

    validateInputs = () => {
        const { lang } = this.context;
        let firstNameError = '';
        let lastNameError = '';
        let emailError = '';
        let mobileNumberError = '';
        let streetError = '';
        let houseNumberError = '';
        let countryError = '';
        let stateError = '';
        let cityError = '';
        let postalCodeError = '';
        let mileageError = '';
        // let professionError = '';
        // let salutationError = '';

        if (!this.state.firstName) {
            firstNameError = `${lang("please_enter_your_first_name.")}`;
        }

        if (!this.state.lastName) {
            lastNameError = `${lang("please_enter_your_last_name.")}`;
        }

        if (!this.state.email.includes('@')) {
            emailError = `${lang("please_enter_your_email_id.")}`;
        }

        if (!this.state.mobileNumber) {
            mobileNumberError = `${lang("please_enter_your_mobile_number.")}`;
        }

        if (!this.state.street) {
            streetError = `${lang("please_enter_your_street_name.")}`;
        }

        if (!this.state.houseNumber) {
            houseNumberError = `${lang("please_enter_your_house_number.")}`;
        }

        if (!this.state.country) {
            countryError = `${lang("please_enter_your_country.")}`;
        }

        if (!this.state.state) {
            stateError = `${lang("please_enter_your_state.")}`;
        }

        if (!this.state.city) {
            cityError = `${lang("please_enter_your_city.")}`;
        }

        if (!this.state.postalCode) {
            postalCodeError = `${lang("please_enter_your_postal_code.")}`;
        }

        if (!this.state.km_stand) {
            mileageError = `${lang("please_enter_your_mileage.")}`;
        }

        // if (!this.state.salutation) {
        //     salutationError = `${lang("please_enter_your_salutation.")}`;
        // }

        // if (!this.state.profession) {
        //     professionError = `${lang("please_enter_your_profession.")}`;
        // }

        this.setState({
            firstNameError,
            lastNameError,
            emailError,
            mobileNumberError,
            streetError,
            houseNumberError,
            countryError,
            stateError,
            cityError,
            postalCodeError,
            mileageError,
            // salutationError,
            // professionError,
        });

        return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !countryError && !stateError && !cityError && !postalCodeError && !mileageError;
        // return !firstNameError && !lastNameError && !emailError && !mobileNumberError && !streetError && !houseNumberError && !countryError && !stateError && !cityError && !postalCodeError && !mileageError && !professionError && !salutationError;
    }

    componentDidMount = async () => {
        await this.getAppointmentData();
    }

    getAppointmentData = () => {
        this.setState({ loading: true });
        let uuid = this.props?.router?.location?.state?.uuid

        if (uuid) {
            const url = configuration.bookAppointment + uuid;
            axios.get(url).then(
                res => {
                    if (res.data.success) {
                        this.setState({
                            userData: res.data.data,
                            firstName: res.data.data.first_name,
                            lastName: res.data.data.last_name,
                            email: res.data.data.email_id,
                            mobileNumber: res.data.data.contact_no,
                            street: res.data.data.street,
                            houseNumber: res.data.data.house_no,
                            country: res.data.data.country,
                            state: res.data.data.state,
                            city: res.data.data.city,
                            postalCode: res.data.data.pincode,
                            km_stand: res.data.data.km_stand,
                            insurance_client_id: res.data.data.insurance_client_id,
                            salutation: res.data.data.salutation ? res.data.data.salutation : this.state.salutation,
                            profession: res.data.data.profession ? res.data.data.profession : this.state.profession,
                            loading: false
                        });
                    }
                }
            )
                .catch(error => {
                    this.setState({ loading: false });
                    toastAlert(error.response.data.message, 'error');
                });
        }
        else {

            const { claim_number, order_id, reg_no } = this.props.router.location.state
            let url = `${configuration.sendDataSearch}insurance_no=${claim_number || ''}&&order_id=${order_id || ''}&&reg_no=${reg_no || ''}`

            axios.get(url)
                .then(
                    res => {
                        if (res.data.success) {
                            this.setState({
                                userData: res.data.data,
                                firstName: res.data.data.first_name,
                                lastName: res.data.data.last_name,
                                email: res.data.data.email_id,
                                mobileNumber: res.data.data.contact_no,
                                street: res.data.data.street,
                                houseNumber: res.data.data.house_no,
                                country: res.data.data.country,
                                state: res.data.data.state,
                                city: res.data.data.city,
                                postalCode: res.data.data.pincode,
                                km_stand: res.data.data.km_stand,
                                uuid: res.data.data.uuid,
                                insurance_client_id: res.data.data.insurance_client_id,
                                salutation: res.data.data.salutation ? res.data.data.salutation : this.state.salutation,
                                profession: res.data.data.profession ? res.data.data.profession : this.state.profession,
                                loading: false
                            });
                        }
                    }
                )
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });
        }
    }

    handleNext = (e) => {
        e.preventDefault();
        const isValid = this.validateInputs();
        this.setState({ isButtonClicked: true });
        if (isValid) {
            let uuid = this.props.router.location.state.uuid ? this.props.router.location.state.uuid : this.state.uuid
            const reg_no = this.props.router.location.state.reg_no
            const order_id = this.props.router.location.state.order_id
            const claim_number = this.props.router.location.state.claim_number

            // let country_code = "123"
            let country_code = this.props.router.location.state.country_code

            const url = configuration.updateData;
            const { firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, salutation, profession } = this.state;

            const payLoad =
            {
                'reg_no': reg_no,
                'order_id': order_id,
                'claim_number': claim_number,
                'uuid': uuid,
                'country_code': country_code,
                'first_name': firstName,
                'last_name': lastName,
                'email_id': email,
                'contact_no': mobileNumber,
                'street': street,
                'house_no': houseNumber,
                'country': country,
                'state': state,
                'city': city,
                'pincode': postalCode,
                'km_stand': km_stand,
                'salutation': salutation,
                'profession': profession,
            }
            axios.post(url, payLoad)  //  <- Payload 
                .then(response => {
                    if (response.data.success) {
                        this.props.router.navigate('/customer/location-list', {
                            state: {
                                uuid: uuid,
                                insurance_client_id: this.state.insurance_client_id,
                                insurance_name: this.state.userData.insurance_name,
                                customer_id: this.state.userData.id,
                                first_name: this.state.userData.first_name,
                                last_name: this.state.userData.last_name,
                                reg_no: this.props.router.location.state.reg_no,
                                order_id: this.props.router.location.state.order_id,
                                claim_number: this.state.userData.claim_number,
                                is_booking: this.state.userData.is_booking,
                                country_code: this.state.userData.country_code,
                                // claim_number : this.props.router.location.state.claim_number,
                            }
                        });
                    }
                })
                .catch(error => {
                    toastAlert(error.response.data.message, 'error');
                });

            // this.props.router.navigate('/CustomerLocationList');

        }
    }

    render() {
        // const { contact_no,country_code ,isButtonClicked, userData, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, loading } = this.state;
        const { profession, salutationError, professionError, salutation, mobileNumberError, firstNameError, lastNameError, emailError, streetError, houseNumberError, countryError, stateError, cityError, postalCodeError, mileageError, handleUpdate, handleNext, isButtonClicked, userData, firstName, lastName, email, mobileNumber, street, houseNumber, country, state, city, postalCode, km_stand, loading, time, date, country_code } = this.state;
        const uuid = this.props?.router?.location?.state?.uuid || '';
        // const {profession,salutation}
        const { lang } = this.context;
        return (
            <CustomerDetailComponent
                mileageError={mileageError}
                salutationError={salutationError}
                professionError={professionError}
                postalCodeError={postalCodeError}
                stateError={stateError}
                countryError={countryError}
                houseNumberError={houseNumberError}
                streetError={streetError}
                emailError={emailError}
                lastNameError={lastNameError}
                firstNameError={firstNameError}
                mobileNumberError={mobileNumberError}
                cityError={cityError}
                isButtonClicked={isButtonClicked}
                userData={userData}
                firstName={firstName}
                salutation={salutation}
                profession={profession}
                lastName={lastName}
                email={email}
                country_code={country_code}
                mobileNumber={mobileNumber}
                street={street}
                houseNumber={houseNumber}
                country={country}
                state={state}
                city={city}
                postalCode={postalCode}
                km_stand={km_stand}
                loading={loading}
                uuid={uuid}
                lang={lang}
                handleUpdate={handleUpdate}
                handleNext={this.handleNext}
                handleChange={this.handleChange}
                handlePhone={this.handlePhone}
                validateInputs={this.validateInputs}
            />
        );
    }
}

export default withRouter(AddCustomerDetails);
