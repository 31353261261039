import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import CustomerBasicDetails from './pages/customerBasicDetails/CustomerBasicDetails';
import AddCustomerDetails from './pages/addCustomerDetails/AddCustomerDetails';
import CustomerLocationList from './pages/customerLocationList/CustomerLocationList';
import CustomerCalendar from './pages/customerCalendar/CustomerCalendar';
import CustomerReschedule from './pages/customerReschedule/CustomerReschedule';
import CustomerRescheduleDetails from './pages/customerRescheduleDetails/CustomerRescheduleDetails';
import AdminCalendar from './pages/adminCalendar/AdminCalendar';
import UpdateLang from './pages/language/UpdateLang';
import Login from './pages/login/Login';
import ThankyouPage from './pages/thankyouPage/ThankyouPage';
import DeleteAppointment from './pages/deleteAppointment/DeleteAppointment';
import PrivateRoute from './PrivateRoute';
import CreateAppointment from './pages/createAppointment/CreateAppointment';
import CreateAppointmentDetails from './pages/createAppointmentDetails/CreateAppointmentDetails';
import HelpdeskOptions from './pages/helpdeskOptions/HelpdeskOptions';
import HelpDeskCalendar from './pages/helpDeskCalendar/HelpDeskCalendar';
import HelpDeskLocation from './pages/heplDeskLocation/HelpDeskLocation';
import RescheduleHeplDesk from './pages/helpDeskReschedule/RescheduleHeplDesk';
import HelpDeskRescheduleDetails from './pages/helpDeskRescheduleDetails/HelpDeskRescheduleDetails';
import PrivateRouteLogin from './PrivateRouteLogin';
import PageNotFound from './components/pageNotFound/PageNotFound';
import Cookies from 'js-cookie';
import DefaultHeader from './components/header/DefaultHeader';
import DefaultFooter from './components/footer/DefaultFooter';
import UserList from './pages/userList/UserList';
import HelpDeskAdminView from './pages/helpDeskAdminView/HelpDeskAdminView';
import HelpdeskUserManagement from './pages/helpdeskUserManagement/HelpdeskUserManagement';
import BookingInfo from './pages/bookingInfo/BookingInfo';
import EmailSmsLog from './pages/email-sms-log/EmailSmsLog';

function AppRoutes() {



    return (
        <Suspense>
            <Routes>
                <Route exact path="/" element={<PrivateRouteLogin><Login /></PrivateRouteLogin>} />
                <Route exact path="/login" element={<PrivateRouteLogin><Login /></PrivateRouteLogin>} />
                <Route exact path="/helpdesk/user-management" element={<><HelpdeskUserManagement /></>} />
                <Route exact path="/helpdesk/options" element={<PrivateRoute><HelpdeskOptions /></PrivateRoute>} />
                <Route exact path="/helpdesk/reschedule" element={<PrivateRoute><RescheduleHeplDesk /></PrivateRoute>} />
                <Route exact path="/helpdesk/reschedule/details" element={<PrivateRoute><HelpDeskRescheduleDetails /></PrivateRoute>} />
                <Route exact path="/helpdesk/location-list" element={<PrivateRoute><HelpDeskLocation /></PrivateRoute>} />
                <Route exact path="/helpdesk/calendar" element={<PrivateRoute><HelpDeskCalendar /></PrivateRoute>} />
                <Route exact path="/helpdesk/create/appointment" element={<PrivateRoute><CreateAppointment /></PrivateRoute>} />
                <Route exact path="/helpdesk/create/appointment/details" element={<PrivateRoute><CreateAppointmentDetails /></PrivateRoute>} />
                <Route exact path="/helpdesk/userlist" element={<PrivateRoute><UserList /></PrivateRoute>}></Route>
                <Route exact path="/helpdesk/admin-view" element={<PrivateRoute><HelpDeskAdminView /></PrivateRoute>}></Route>
                <Route exact path="/helpdesk/userlist/bookinginfo" element={<PrivateRoute><BookingInfo /></PrivateRoute>}></Route>

                <Route path="*" element={<Navigate to="/404" />} />


                <Route exact path="/404" element={<PageNotFound />}></Route>

                <Route exact path="/appointment/book/:id" element={<CustomerBasicDetails />}></Route>
                <Route exact path="/appointment/book" element={<CustomerBasicDetails />}></Route>

                <Route exact path="/appointment/reschedule/:id" element={<CustomerReschedule />}></Route>
                {/* <Route exact path="helpDesk/appointment/reschedule" element={<CustomerReschedule />}></Route> */}

                <Route exact path="/customer/details" element={<AddCustomerDetails />}></Route>
                <Route exact path="/customer/details/reschedule" element={<AddCustomerDetails />}></Route>


                <Route exact path="/customer/reschedule/details" element={<CustomerRescheduleDetails />}></Route>

                <Route exact path="/customer/location-list" element={<CustomerLocationList />}></Route>

                <Route exact path="/customer/calendar" element={<CustomerCalendar />}></Route>
                <Route exact path="/emailsms-report" element={<PrivateRoute>{(Cookies.get('role_name') == 'admin' || Cookies.get('role_name') == 'superadmin') ?<EmailSmsLog />:<Navigate to="/404" />}</PrivateRoute>}></Route> 

                <Route exact path="/thankyou" element={<ThankyouPage />}></Route>
                <Route exact path="/delete/appointment/:id" element={<DeleteAppointment />}></Route>
                <Route exact path="/appointment/delete/:id" element={<DeleteAppointment />}></Route>


                <Route exact path="/AdminCalendar" element={<AdminCalendar />}></Route>
                <Route exact path="/updatelang" element={<UpdateLang />} />

            </Routes>
        </Suspense>
    )
};

export default AppRoutes;
