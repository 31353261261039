import React, { Component } from 'react';
import DefaultHeader from './components/header/DefaultHeader';
import DefaultFooter from './components/footer/DefaultFooter';
import AppRoutes from './AppRoutes'
import withRouter from './withRouter';
import HelpDeskHeader from './components/helpDeskHeader/HelpDeskHeader';
import Cookies from 'js-cookie';
import env from "react-dotenv";

export class App extends Component {

  render() {
    // console.log(process.env,env,"env data");
    const pathname = this.props?.router?.location?.pathname;
    // const HeaderComponent = pathname && pathname.startsWith('/helpdesk') ? HelpDeskHeader : DefaultHeader;
    // const HeaderComponent = DefaultHeader;
    const HeaderComponent = pathname === '/404' ? null : DefaultHeader;
    return (
      <>
        {/* <DefaultHeader /> */}
        {HeaderComponent && <HeaderComponent />}
        <AppRoutes />
        {(pathname && (pathname.includes('/appointment/book/') || pathname === '/thankyou' || pathname === '/' || pathname === '/login' || pathname === '/helpdesk/options' || pathname === '/helpdesk/reschedule' || pathname === '/helpdesk/create/appointment')) && <DefaultFooter />}
      </>
    );
  }
}

export default withRouter(App);
