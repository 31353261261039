import React, { Component } from "react";
import { configuration, url } from "../../appConfig";
import axios from "axios";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { Spinner } from "reactstrap";
import moment from "moment";
import Cookies from "js-cookie";
import LanguageContext from "../../context/LanguageProvider";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CookiesRemove } from "../../CookiesRemove";
import withRouter from "../../withRouter";
import UserlistChart from "../../components/userlistChart/UserlistChart";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import 'bootstrap/dist/css/bootstrap.css';

export class UserList extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      current_page: "",
      next_page: "1",
      prev_page: "",
      data: [],
      loading: false,
      from_date: moment().format("YYYY-MM-DD"),
      to_date: moment().format("YYYY-MM-DD"),
      isFiltered: false,
      chart_from_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      chart_to_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      chart_toDate: "",
      date: "",
      booked_slot: "",
      total_slot_book_by_users: "",
      total_slot: "",
      globleSerch: "",
      isModalOpen: false,
      isModalOpen1: false,
      deleteuuid: "",
      chart_week: "",
      downloadBookingPDFModal: false,
      bookingInfoBasePdf: '',
    };
  }
  componentDidMount = async () => {
    await this.getBookingAppointmentList();
    await this.bookingAppointmentChart();
    let charttoDate = moment().format("YYYY-MM-DD");
    const currentDate = moment();
    const currentDayOfWeek = currentDate.isoWeekday();

    const startDate = moment().clone().startOf("week");
    const endDate = moment().clone().endOf("week");
    // console.log(moment().week(),'moment().week( Number )')
    console.log(
      currentDate,
      currentDayOfWeek,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      "54545454"
    );
    this.setState({
      chart_toDate: charttoDate,
      chart_from_date: startDate.format("YYYY-MM-DD"),
      chart_to_date: endDate.format("YYYY-MM-DD"),
      chart_week: moment().week(),
    });
  };

  bookingAppointmentChart = () => {
    this.setState({ loading: true });
    // window.location.reload()
    // console.log(this.state.from_date, "from_date");
    const url = `${configuration.bookingAppointmentChart}?from_date=${this.state.chart_from_date}&to_date=${this.state.chart_to_date}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        // console.log(res.data.data.chart, "bookingAppointmentChart")
        const dateFormat = Cookies.get("dateFormat");
        let date = [];
        let booked_slot = [];
        let available_slot = [];
        let total_slot = [];

        res.data.data.chart.map((value, i) => {
          date.push(moment(value.date, "YYYY-MM-DD").format(dateFormat));
          booked_slot.push(value.booked_slot);
          available_slot.push(value.available_slot);
          total_slot.push(value.total_slot);
        });
        // console.log(total_slot,"total_slot")
        this.setState(
          {
            loading: false,
            date: date,
            booked_slot: booked_slot,
            total_slot_book_by_users: available_slot,
            total_slot: total_slot,
          },
          () => this.getSeriesData()
        );
      })
      .catch((err) => console.log(err, "err"));
  };
  handlePrevchart = async () => {
    const previousWeekStartDate = moment(this.state.chart_to_date, "YYYY-MM-DD")
      .subtract(7, "days")
      .startOf("isoWeek");
    const previousWeekEndDate = moment(this.state.chart_to_date, "YYYY-MM-DD")
      .subtract(7, "days")
      .endOf("isoWeek");

    console.log(previousWeekStartDate.format("YYYY-MM-DD")); // Previous week start date
    console.log(previousWeekEndDate.format("YYYY-MM-DD"));

    this.setState(
      {
        chart_from_date: previousWeekStartDate.format("YYYY-MM-DD"),
        chart_to_date: previousWeekEndDate.format("YYYY-MM-DD"),
        chart_week: moment(
          previousWeekStartDate.format("YYYY-MM-DD"),
          "YYYY-MM-DD"
        ).week(),
      },
      () => {
        this.bookingAppointmentChart();
      }
    );
  };

  handleNextchart = async () => {
    const nextWeekStartDate = moment(this.state.chart_to_date, "YYYY-MM-DD")
      .add(7, "days")
      .startOf("isoWeek");
    const nextWeekEndDate = moment(this.state.chart_to_date, "YYYY-MM-DD")
      .add(7, "days")
      .endOf("isoWeek");
    this.setState(
      {
        chart_from_date: nextWeekStartDate.format("YYYY-MM-DD"),
        chart_to_date: nextWeekEndDate.format("YYYY-MM-DD"),
        chart_week: moment(
          nextWeekStartDate.format("YYYY-MM-DD"),
          "YYYY-MM-DD"
        ).week(),
      },
      () => {
        this.bookingAppointmentChart();
      }
    );
  };

  getBookingAppointmentList = (page_no) => {
    this.setState({ loading: true });

    const url = `${configuration.bookingAppointmentList}?page_no=${
      page_no || "1"
    }&from_date=${this.state.from_date}&to_date=${this.state.to_date}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            next_page: res.data.data.next_page,
            prev_page: res.data.data.prev_page,
            loading: false,
          });
          // console.log(res.data, "getBookingAppointmentList");
        }
        //  else {
        //     this.setState({ loading: false });
        //     this.props.router.navigate('/login');
        // }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.data.code === 401) {
          CookiesRemove();
          this.props.router.navigate("/login");
        }
        toastAlert(error.response.data.message, "error");
      });
  };

  prevPage = async () => {
    let prev_page_no = this.state.prev_page;
    await this.getBookingAppointmentList(prev_page_no);
  };
  nextPage = async () => {
    const { isFiltered } = this.state;
    let next_page_no = this.state.next_page;
    if (isFiltered) {
      await this.handleFilterClick(next_page_no);
    } else {
      await this.getBookingAppointmentList(next_page_no);
    }
  };
  handleFilterClick = async (page) => {
    console.log(page, "page 79");
    this.setState({ loading: true });
    // await this.bookingAppointmentChart();
    const url = `${configuration.bookingAppointmentList}?page_no=${page}&from_date=${this.state.from_date}&to_date=${this.state.to_date}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            next_page: res.data.data.next_page,
            prev_page: res.data.data.prev_page,
            loading: false,
            isFiltered: true,
          });
          console.log(res.data, "getBookingAppointmentList");
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.data.code === 401) {
          CookiesRemove();
          this.props.router.navigate("/login");
        }
        toastAlert(error.response.data.message, "error");
      });
  };

  getSeriesData = () => {
    const { lang } = this.context;
    this.setState({
      series: [
        {
          name: lang("booked"),
          data: this.state.booked_slot || [],
        },
        {
          name: lang("free"),
          data: this.state.total_slot_book_by_users || [],
        },
        // {
        //   name: lang("total_slot"),
        //   data: this.state.total_slot || [],
        // },
      ],
    });
  };

  handleSerch = (e) => {
    this.setState({
      globleSerch: e.target.value,
    });
  };

  openModal = (item) => {
    console.log(item, "202line");
    this.setState({
      deleteuuid: item,
    });
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
  openModalReshadule = (item) => {
    console.log(item, "202line");
    this.setState({
      stateItem: item,
    });
    this.setState({ isModalOpen1: true });
  };

  closeModal1 = () => {
    this.setState({ isModalOpen1: false });
  };
  handleReshadule = () => {
    console.log("first");
    let uuid = this.state?.stateItem?.uuid;
    const isUuid =
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/.test(
        uuid
      );
    if (isUuid) {
      const url = configuration.bookAppointment + uuid;
      axios.get(url).then((res) => {
        console.log(res, "res233");
        if (res.data) {
          this.setState({ ...this.state.stateItem, value: res.data.data });
          this.props.router.navigate("/helpdesk/reschedule", {
            state: {
              value: res.data.data,
              stateItem: this.state.stateItem,
            },
          });
        }
      });
    }
  };
  //   closeModal1 = () => {
  //     console.log("first")
  //     let uuid = this.props.router.params.id;
  //     const isUuid = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/.test(uuid);
  //     if (isUuid) {
  //         const url = configuration.bookAppointment + uuid;
  //         axios.get(url).then(
  //             res => {
  //                 const is_booking = res.data.data.is_booking;
  //                 // const is_resudule = this.state.userData.is_resudule;
  //                 const is_resudule = res.data.data.is_resudule
  //                 if (res.data.success) {
  //                     this.setState({
  //                         userData: res.data.data,
  //                         loading: false
  //                     });
  //                     if (res.data.data.is_canceled == 1) {
  //                         this.props.router.navigate('/404')
  //                     }
  //                     else {
  //                         if (res.data.data.is_over_time_resudule == true) {
  //                             this.setState({
  //                                 isTimeReached: true,
  //                             })
  //                         }
  //                         else {
  //                             if (is_booking && is_resudule === 1) {
  //                                 this.setState({
  //                                     isButtonDisabled: true,
  //                                     isTimeReached: false,
  //                                 }, () => {
  //                                     this.setState({
  //                                         isModalOpen: true,
  //                                     })
  //                                 });
  //                                 const bookingInfoUrl = configuration.customerBookingInfo + uuid;
  //                                 axios.get(bookingInfoUrl).then(
  //                                     response => {
  //                                         this.setState({
  //                                             date: response.data.data.date,
  //                                             time: response.data.data.from_time,
  //                                             isTimeReached: false,
  //                                         })
  //                                     }
  //                                 ).catch(error => {
  //                                     toastAlert(error.response.data.message, 'error');
  //                                 });
  //                             }
  //                             else if (is_booking == 0) {
  //                                 this.setState({
  //                                     isButtonDisabled: true,
  //                                     isTimeReached: false,
  //                                 }, () => {
  //                                     this.setState({
  //                                         isBookingOpen: true,
  //                                     })
  //                                 });
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         ).catch(error => {
  //             toastAlert(error.response.data.message, 'error');
  //         });
  //     }
  // }

  handleDownloadAppointmentListPdf = async () => {
    this.setState({ loading: true });
    const url = `${configuration.bookingAppointmentPdf}?download_type='list_pdf'&from_date=${this.state.from_date}&to_date=${this.state.to_date}&lang_code=${this.context.language}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          let listView = res?.data?.data?.list_view;
          this.setState({ bookingInfoBasePdf: listView });
          this.setState({ downloadBookingPDFModal: true });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response.data.code === 401) {
          CookiesRemove();
          this.props.router.navigate("/login");
        }
        toastAlert(error.response.data.message, "error");
      });
  };
  downloadBookingPDFModalClose = () => {
    this.setState({ downloadBookingPDFModal: false });
  };

  handleDeleteButtonClick = () => {
    console.log(this.state.deleteuuid, "line123");
    let uuid = this.state.deleteuuid;
    const url = configuration.deleteAppointment + uuid;
    axios
      .get(url) //  <- Payload
      .then((response) => {
        if (response.data.success) {
          this.getBookingAppointmentList();
          Swal.fire(
            `${lang("deleted!")}!`,
            `${lang("your_appointment_has_been_deleted.")}`,
            "success"
          );
        }
      })
      .catch((error) => {
        Swal.fire(
          `${lang("deleted!")}!`,
          `${lang("you_already_deleted_your_appointment.")}`,
          "success"
        );
        this.setState({ loading: false });
      });
    this.closeModal();
  };

  render() {
    const { loading, isFiltered, from_date, to_date } = this.state;
    const { data, total_record } = this.state.data;
    const dateFormat = Cookies.get("dateFormat");
    const { lang, language } = this.context;
    const value = Cookies.get("roleId");
    let val = { from_date: from_date, to_date: to_date };
    let masterEmailId = process.env.REACT_APP_MASTER_EMAIL;
    console.log(masterEmailId,Cookies.get('email_id') , masterEmailId);
    const locale = {
      format: "yyyy-MM-dd",
      weekStartsOn: 1, // Set Monday as the first day of the week
    };

    return (
      <>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12 options-row">
              <div class="options-card-main">
                <div class="row justify-content-center">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex">
                    <div class="card option-card  d-flex">
                      <div class="card-body p-0">
                        {/* <img src="../assets/images/graph-1.png" alt="Graph Image" /> */}
                        <UserlistChart
                          series={this.state.series}
                          // total_day_slot={this.state.total_slot}
                          // total_slot_book_by_users={this.state.total_slot_book_by_users}
                          // booked_slot={this.state.booked_slot}
                          handleNextchart={this.handleNextchart}
                          handlePrevchart={this.handlePrevchart}
                          date={this.state.date}
                          getSeriesData={() => this.getSeriesData()}
                          chart_week={this.state.chart_week}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-6 col-md-6 col-sm-6 col-12  d-flex">
                                            <div class="card option-card  d-flex">
                                                <div class="card-body p-0">
                                                        <img src="../assets/images/graph-2.png" alt="Graph Image" />
                                             
                                                </div>
                                            </div>
                                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <main class="main-content">
            {loading && (
              <div class="loader-main">
                <Spinner color="light">
                  <span></span>
                </Spinner>
              </div>
            )}
            {/* <section class="breadcrumb-wrapper">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="breadcrumb-title">
                                        {lang('all_appointments')}
                                    </h4>

                                </div>
                            </div>
                        </div>
                    </section> */}

            <section class="card-table-wrapper">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="card card-main">
                      <div class="card-header">
                        <div class="table-filters">
                          {/* <h6 class="filter-title">{lang('filter_appointments')}</h6> */}
                          <div class="all-table-filter">
                            <div class="row align-items-end">
                              <div class="col-lg-6 col-md-8 col-sm-11">
                                <div className="user-list-date-range-main">
                                  <div class="custom-form-control">
                                    <div class="form-group mb-0">
                                      <label class="form-label ">
                                        {" "}
                                        {lang("filter_by_a_date_range")}{" "}
                                      </label>

                                      {/* <input class="form-control" type="text" name="daterange"
                                                                        value="01/01/2018 - 01/15/2018" /> */}
                                      {/* {console.log(typeof (dateFormat), 'dateFormat')} */}
                                      <div className="apply-date-range-and-btn">
                                        <DateRangePicker
                                          initialSettings={{
                                            locale: {
                                              // format: dateFormat !== 'undefined' ? 'DD.MM.YYYY' :  dateFormat,
                                              format:
                                                dateFormat !== "undefined"
                                                  ? dateFormat
                                                  : "DD.MM.YYYY",
                                              startDate: moment(
                                                from_date,
                                                "YYYY-MM-DD"
                                              ),
                                              endDate: moment(
                                                to_date,
                                                "YYYY-MM-DD"
                                              ),
                                            },
                                          }}
                                          onApply={(e, picker) => (
                                            console.log(
                                              picker.startDate.format(
                                                "YYYY-MM-DD"
                                              ),
                                              "from_date"
                                            ),
                                            this.setState({
                                              from_date:
                                                picker.startDate.format(
                                                  "YYYY-MM-DD"
                                                ),
                                              to_date:
                                                picker.endDate.format(
                                                  "YYYY-MM-DD"
                                                ),
                                            })
                                          )}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                          />
                                        </DateRangePicker>
                                        <div class="apply-date-range-btn">
                                          <button
                                            class="btn red-btn"
                                            onClick={() =>
                                              this.handleFilterClick(1)
                                            }
                                          >
                                            {lang("apply_date_range")}
                                          </button>
                                        </div>
                                        {Cookies.get('email_id') == masterEmailId ? <div class="apply-date-range-btn">
                                          <button
                                            class="btn red-btn"
                                            onClick={() =>
                                              this.handleDownloadAppointmentListPdf()
                                            }
                                          >
                                            <i class="fa fa-download" aria-hidden="true"></i>
                                          </button>
                                        </div> : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-1 col-sm-1 col-1"></div>
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                {/* <div className="pdf-search">
                                  <div class="input-group">
                                    <label for="" class="form-label">
                                      <span data-key="Insurancenumber">
                                        Globle search
                                      </span>
                                    </label>
                                    <div class="form-control-top">
                                      <input
                                        type="search"
                                        class="form-control"
                                        value={this.state.globleSerch}
                                        onChange={(e) => this.handleSerch(e)}
                                      />
                                    </div>
                                    <span class="error-message"></span>
                                  </div>
                                </div> */}
                                {value == 1 || value == 2 ? (
                                  <div className="download-pdf-top-button">
                                    <Link
                                      to={{
                                        pathname:
                                          "/helpdesk/userlist/bookinginfo",
                                        state: {
                                          ...val,
                                        },
                                      }}
                                      state={val}
                                    >
                                      <div class="apply-date-range-btn">
                                        <button class="btn red-btn">
                                          {lang("download_pdf")}
                                        </button>
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                              <div class="col-12">
                                <div class="filter-end-text">
                                  {isFiltered && from_date && to_date ? (
                                    <h6 class="filter-title">
                                      {lang("from")} :{" "}
                                      <span>
                                        {" "}
                                        &nbsp;
                                        {moment(from_date).format(
                                          dateFormat
                                        )}{" "}
                                        &nbsp; : &nbsp;
                                      </span>{" "}
                                      {lang("to")}: &nbsp;{" "}
                                      <span>
                                        {" "}
                                        {moment(to_date).format(dateFormat)}
                                      </span>
                                    </h6>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="custom-table user-list-table-wrapper">
                          <div class="table-responsive">
                            <table class="table table-main">
                              <thead>
                                <tr>
                                  <th scope="col">{lang("sr._no.")}</th>
                                  <th scope="col">{lang("date")}</th>
                                  <th scope="col">
                                    {lang("appointment_time")}
                                  </th>
                                  <th scope="col">{lang("workshop")}</th>
                                  <th scope="col">{lang("order_id")}</th>
                                  <th scope="col">
                                    {lang("registration_plate")}
                                  </th>
                                  <th scope="col">
                                    {lang("insurance_number")}
                                  </th>
                                  <th scope="col">{lang("customer_name")}</th>
                                  <th scope="col">{lang("booked_by")}</th>
                                  <th scope="col">{lang("status")}</th>
                                  {/* <th scope="col">{lang("action")}</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {data?.length > 0 ? (
                                  data.map((item, i) => (
                                    <tr>
                                      <th scope="row">{item.sr_no}</th>
                                      <td>
                                        {moment(item.date).format(dateFormat)}
                                      </td>
                                      {/* {moment(item.date).format(dateFormat) */}
                                      <td>{item.appointment_time}</td>
                                      <td>
                                        {item.workshop != ""
                                          ? item.workshop
                                          : "-"}
                                      </td>

                                      <td>{item.order_number}</td>
                                      <td>
                                        {item.reg_no != "" ? item.reg_no : "-"}
                                      </td>
                                      <td>
                                        {item.insurance_no != ""
                                          ? item.insurance_no
                                          : "-"}
                                      </td>
                                      <td>{item.customer_name}</td>
                                      <td>{item.booked_by}</td>
                                      <td>
                                        {item.status != "" ? item.status : "-"}
                                      </td>
                                      {/* <td>
                                        <div className="table-action-btn">
                                          <button
                                            className="btn red-btn"
                                            onClick={() =>
                                              this.openModalReshadule(item)
                                            }
                                          >
                                            <i className="fa fa-clock-o"></i>
                                          </button>
                                          <button
                                            className="btn delete-btn-color"
                                            onClick={() =>
                                              this.openModal(item.uuid)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </td> */}
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={10}>
                                      {" "}
                                      {lang("no_data_found")}{" "}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {data?.length > 0 && (
                        <div class="card-footer">
                          <div class="row card-footer-row userlist-pagination">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <p>
                                {lang("showing")} 1 {lang("to")}{" "}
                                {this.state.data.total_page} {lang("of")}{" "}
                                {total_record} {lang("entries")}{" "}
                              </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="custom-pagination">
                                <nav aria-label="...">
                                  <ul class="pagination">
                                    <li
                                      class={
                                        this.state.data.prev_page != "0"
                                          ? "page-item"
                                          : "page-item disabled"
                                      }
                                    >
                                      {/* <li class="page-item"> */}
                                      <a
                                        class="page-link"
                                        onClick={this.prevPage}
                                      >
                                        <i
                                          class="fa fa-angle-double-left"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </li>
                                    {this.state.data.prev_page != "0" ? (
                                      <li class="page-item">
                                        <a
                                          class="page-link"
                                          onClick={this.prevPage}
                                        >
                                          {this.state.data.prev_page}
                                        </a>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {/* <li class="page-item"><a class="page-link" >{this.state.data.prev_page}</a></li> */}
                                    <li
                                      class="page-item active"
                                      aria-current="page"
                                    >
                                      <a class="page-link">
                                        {this.state.data.current_page}
                                      </a>
                                    </li>
                                    {this.state.data.next_page != "" ? (
                                      <li class="page-item">
                                        <a
                                          class="page-link"
                                          onClick={this.nextPage}
                                        >
                                          {this.state.data.next_page}
                                        </a>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {/* <li class="page-item"><a class="page-link" >{this.state.data.next_page}</a></li> */}
                                    <li
                                      class={
                                        this.state.data.next_page != ""
                                          ? "page-item"
                                          : "page-item disabled"
                                      }
                                    >
                                      <a
                                        class="page-link"
                                        onClick={this.nextPage}
                                      >
                                        <i
                                          class="fa fa-angle-double-right"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={this.state.isModalOpen}
                toggle={this.closeModal}
                className="appointment-list-modal"
              >
                <ModalHeader>
                  {lang("cancel_appointment_confirmation")}
                </ModalHeader>
                <ModalBody>
                  <div className="booked-appointment-content">
                    {lang("are_you_sure_you_want_to_cancel_appointment?")}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn gray-btn"
                    color="secondary"
                    onClick={this.closeModal}
                  >
                    {"cancel"}
                  </button>
                  <button
                    className="btn red-btn"
                    color="danger"
                    onClick={this.handleDeleteButtonClick}
                  >
                    {"cancel_appointment"}
                  </button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={this.state.isModalOpen1}
                toggle={this.closeModal1}
                className="appointment-list-modal"
              >
                <ModalHeader>{lang("reschedule_confirmation")}</ModalHeader>
                <ModalBody>
                  <div className="booked-appointment-content">
                    {lang("are_you_sure_you_want_to_reschedule?")}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn gray-btn"
                    color="secondary"
                    onClick={this.closeModal1}
                  >
                    {lang("cancel")}
                  </button>
                  {/* <Link
                    to={{
                      pathname: "/helpdesk/reschedule",
                      state: {
                        ...this.state.stateItem,
                      },
                    }}
                    state={this.state.stateItem}
                  > */}
                  <button
                    color="danger"
                    className="btn red-btn"
                    onClick={this.handleReshadule}
                  >
                    {lang("reschedule")}
                  </button>
                  {/* </Link> */}
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={this.state.downloadBookingPDFModal}
                toggle={this.downloadBookingPDFModal}
                className="booking-list-pdf-download-modal"
                id="booking-list-pdf-download-modal_wrapper"
                fullscreen
                style={{ minWidth: "90%" }}
              >
                {/* <ModalHeader>
                  <button
                    className="btn gray-btn"
                    color="secondary"
                    onClick={this.downloadBookingPDFModalClose}
                  >
                    {"cancel"}
                  </button>
                </ModalHeader> */}
                <ModalBody>
                  <button className="btn gray-btn" style={{float:'right'}} onClick={this.downloadBookingPDFModalClose}><i className="fa fa-times"></i></button>
                  {this.state.bookingInfoBasePdf ? (
                    <iframe
                      src={`data:application/pdf;base64,${this.state.bookingInfoBasePdf}`}
                      title="Booking Info PDF"
                      width="100%"
                      height="800px"
                      frameBorder="0"
                    ></iframe>
                  ) : (
                    ""
                  )}
                </ModalBody>
              </Modal>
            </section>
          </main>
        </div>
      </>
    );
  }
}

export default withRouter(UserList);
