// export const url = 'http://192.168.1.103:8000/api';  // BalaSir Local
//  export const url = 'http://192.168.1.12:8001/api';  // BalaSir New Local
//  export const url = 'https://drivein-api.drivenscan.ca/api';  // BalaSir New Local
// export const url = 'http://192.168.1.147:8000/api'; // Prem Local
// export const url = 'http://dns-newtech-backend.driveandscan.de/api';  // Staging
 export const url = 'https://planner-api.demopod.in/api'; 

// /export const url = 'https://dns-newtech-backend.drivenscan.com/api';  // Production

//
// export const url = 'http://192.168.1.12:8001/api';  // BalaSir New Local

export const configuration = {
    bookAppointment :       url + '/v1/book_appointment/',
    sendDataSearch :        url + '/v1/search/customer_detail?',
    updateData :            url + '/v1/update/customer_detail',
    // locationData :          url + '/v1/near_by/customer-location/',
    locationData :          url + '/v2/customer-location-demo/',
    driveInLocation :       url + '/v1/get_drivein_from_location',
    calendarSlotGet :       url + '/v1/get-drivein-booking-slot?',
    appointBook :           url + '/v1/confirm-booked-slot',
    lang :                  url + '/v1/planner/language?lang_type=2',
    updateLang :            url + '/v1/planner/update/language-code',
    customerBookingInfo :   url + '/v1/get-customer-booking-info/',
    deleteAppointment :     url + '/v1/delete-appointment/',
    reschedule :            url + '/v1/reschedule-appointment',
    viewPdf :               url + '/v1/get-customer-booking-info-pdf?',
    heplDeskLogin :         url + '/v1/login',
    helpDeskLogout :        url + '/v1/logout',
    createAppointment :     url + '/v1/create/customer',
    getDaySlots :           url + '/v1/get-drivein-booking-slot-datewise?',
    helpDeskReschedule :    url + '/v1/helpdesk/reschedule-appointment',
    helpdeskAppointBook :   url + '/v1/helpdesk/confirm-booked-slot',
    orderIdExistsOrNot :    url + '/v1/helpdesk/order_id-exiting-or-not',
    getLanguageOption :     url + '/v1/planner/get-all-setting',
    locationListView :      url + '/v2/get_drivein_from_location/',
    bookingAppointmentList :      url + '/v1/helpdesk/booking-appointment-list',
    bookingAppointmentChart :      url + '/v1/helpdesk/dashboard',
    adminViewSlotMonthWise :      url + '/v1/get-drivein-booking-slot-monthwise',
    allLocationGet : url + '/v1/helpdesk/get-all-location',
    bookingAppointmentPdf : url + '/v1/helpdesk/booking-appointment-pdf',
    allBookingAppointmentPdf : url + '/v1/helpdesk/all-booking-info-pdf',
    userManagementListUser: url+'/v1/helpdesk/list/user',
    userManagementCreatUser: url+'/v1/helpdesk/create/user',
    userManagementUserRole: url+'/v1/list/role',
    userManagementCreateUserRole: url+'/v1/helpdesk/create/user',
    userManagementdeleteUserRole: url+'/v1/helpdesk/delete/user',
    userManagementUpdateUserRole: url+'/v1/helpdesk/update/user',
    updatePassword:url+'/v1/helpdesk/admin/change/user_password',
    emailSmsReport:url+'/v1/helpdesk/email-sms-report',
    emailSmsReportPdf:url+'/v1/helpdesk/email-sms-report-pdf',
}


    