import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  CustomInput,
  Input,
  Spinner,
} from "reactstrap";
import withRouter from "../../withRouter";
import axios from "axios";
import { configuration } from "../../appConfig";
import moment from "moment";
import LanguageContext from "../../context/LanguageProvider";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Cookies from "js-cookie";

export class BookingInfo extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      locationData: [],
      locationSelectName: "",
      locationId: "",
      date: "",
      base64url: "",
      cardbase64url: "",
      selectDate1:''
    };
  }
  componentDidMount = async () => {
    await this.getAllLocation();
    // await this.getTablePdf()
  };
  getAllLocation = () => {
    this.setState({
      loading: true,
    });
    const url = configuration.allLocationGet;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
          locationData: res.data.data,
        });
      });
  };
  //   getTablePdf = (date,location) => {
  // console.log(date,'date')
  //     this.setState({
  //       loading: true,
  //     });
  //     const url = `${configuration.bookingAppointmentPdf}?from_date=${date}&to_date=${date}&lang_code=${this.context.language}&location_id=${location}`
  //     const accessToken = localStorage.getItem("access_token");
  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data.data, "res12");
  //         this.setState({
  //           loading: false,
  //           base64url:res.data.data.list_view
  //         });
  //       });
  //   };
  getCardPdf = (date, location) => {
    this.setState({
      loading: true,
    });
    const url = `${configuration.bookingAppointmentPdf}?from_date=${date}&to_date=${date}&lang_code=${this.context.language}&location_id=${location}`;
    const accessToken = localStorage.getItem("access_token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // access token added in headers from local storage
        },
      })
      .then((res) => {
        console.log(res.data.data, "res122");
        this.setState({
          loading: false,
          base64url: res.data.data.list_view,
          cardbase64url: res.data.data.all_booking,
        });
      });
  };
  handleDateChange = async (event,picker) => {
    let selectDate = picker.startDate.format('YYYY-MM-DD');
    
    this.setState({
      date: selectDate,
      selectDate1 :picker.startDate.format( Cookies.get('dateFormat'))
    });
    if (this.state.locationSelectName != "" && event.target.value) {
      // await this.getTablePdf(selectDate,this.state.locationSelectName);
      await this.getCardPdf(selectDate, this.state.locationSelectName);
    }
  };

  handleNameChange = async (event) => {
    console.log(event.target.value, "p00");
    this.setState({
      locationSelectName: event.target.value,
    });

    if (this.state.date != "" && event.target.value) {
      // await this.getTablePdf(this.state.date,event.target.value);
      await this.getCardPdf(this.state.date, event.target.value);
    }
  };

  render() {
    const { lang } = this.context;
    const { loading, locationData, date } = this.state;
    return (
      <div>
        {loading && (
          <div class="loader-main">
            <Spinner color="light">
              <span></span>
            </Spinner>
          </div>
        )}
        <div className="pdf-filters-wrapper">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="pdf-inner-filter">
                  <div className="row filter-row">
                    {/* <div className='col-lg-2 col-md-4 col-sm-6 col-6'>
                      <div className='pdf-filters'>
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className='form-label'>Select Type</Label>
                            <Input type="select" name="select" className='form-control'>
                              <option selected>Select Type</option>
                              <option>Drive In</option>
                              <option >Drive Through</option>

                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-4 col-sm-6 col-6'>
                      <div className='pdf-filters'>
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className='form-label'>Insurance Client</Label>
                            <Input type="select" name="select" className='form-control'>
                              <option selected>Insurance Client</option>
                              <option>Die mobilear</option>
                              <option >SWS</option>

                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                      <div className="pdf-filters">
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className="form-label">
                              {lang("select_location")}
                            </Label>
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              onChange={(event) => this.handleNameChange(event)}
                            >
                              <option value={""}>
                                {lang("select_location")}
                              </option>
                              {locationData.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-lg-2 col-md-4 col-sm-6 col-6'>
                      <div className='pdf-filters'>
                        <div class="input-group custom-input-group selectdiv">
                          <FormGroup>
                            <Label className='form-label'>Select City</Label>
                            <Input type="select" name="select" className='form-control'>
                              <option selected>Select City</option>
                              <option>Bern</option>
                              <option>Zurich</option>
                              <option>Thun</option>
                              <option>Studen</option>
                              <option>Basel</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                      <div className="pdf-filters">
                        <div class="input-group custom-input-group">
                          <FormGroup>
                            <Label className="form-label">
                              {lang("select_date")}
                            </Label>
                            {/* <Input
                              type="date"
                              name="select"
                              className="form-control"
                              onChange={(e) => this.handleDateChange(e)}
                              value={this.state.date}
                              pattern="\d{4}-\d{2}-\d{2}"
                              // placeholder="DD.MM.YYYY"/
                            ></Input> */}
                            <DateRangePicker

onApply ={(e,picker)=>this.handleDateChange(e,picker)}
                           
                            initialSettings={{
                              locale: {
                                 format:  Cookies.get('dateFormat')||'DD.MM.YYYY',
                                },
                                // startDate: moment(),
                              
                              singleDatePicker: true,
                              autoApply: true,
                             
                           }}
                              
                            >
                              <input type="text" value={this.state.selectDate1} className="form-control" placeholder="DD.MM.YYYY" />
                            </DateRangePicker>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                      {this.state.date != "" &&
                      
                      this.state.locationSelectName != "" ? (
                        <Button>download Pdf</Button>
                      ) : (
                        ""
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="pdf-content-wrapper">
                  <div className="table-responsive">
                    {/* <table>
                      <tbody>
                        <tr>
                          <td>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td>
                                  <table>
                                    <tr>
                                      <td style={{ padding: "0px;" }}>
                                        <table>
                                          <tr>
                                            <td>
                                              <h3 style={{ fontSize: "18px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "5px" }}>
                                                TERMINUBERSICHT
                                              </h3>
                                              <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "5px", color: "#000" }}>
                                                MERCHING- 30.05.2023 - LINIE 2
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>

                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>



                                          </tr>
                                          <tr>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              <table style={{ border: " 1px solid black", width: " 100%", minWidth: '250px', maxWidth: "250px", maxHeight: "270px", minHeight: " 270px" }}>
                                                <thead>
                                                  <tr>
                                                    <th style={{ borderBottom: "1px solid black" }}>
                                                      <p style={{ fontSize: " 14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: "5px", padding: "5px", color: "#000" }}>
                                                        12:00 - <span style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "500", margin: " 5px", color: "#000" }}>12:05</span>
                                                      </p>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ padding: "10px" }}>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Schadennr
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        040-32-23112012-6
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        VN-Name
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        DOris Grondinger
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Kennzelchen
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        FDB-WB 2
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "600", margin: "0px", color: "#000" }}>
                                                        Telefonnumber
                                                      </p>
                                                      <p style={{ fontSize: "14px", fontFamily: "Poppins, sans-serif", fontWeight: "400", margin: "0px", marginBottom: "10px", color: "#000" }}>
                                                        +49 163 2628400
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                  </div>
                </div>
                <div className="pdf-content-tabs">
                  {this.state.date && this.state.locationSelectName ? (
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      {" "}
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link active"
                          id="table-view-tab"
                          data-toggle="tab"
                          href="#table-view"
                          role="tab"
                          aria-controls="table-view"
                          aria-selected="true"
                        >
                          {lang("table_view")}
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="card-view-tab"
                          data-toggle="tab"
                          href="#card-view"
                          role="tab"
                          aria-controls="card-view"
                          aria-selected="false"
                        >
                          {lang("card_view")}
                        </a>
                      </li>{" "}
                    </ul>
                  ) : (
                    ""
                  )}

                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="table-view"
                      role="tabpanel"
                      aria-labelledby="table-view-tab"
                    >
                      <div className="table-view-wrapper">
                        {this.state.base64url ? (
                          <iframe
                            src={`data:application/pdf;base64,${this.state.base64url}`}
                            title="Base64 Image"
                            width="100%"
                            height="800px"
                            frameBorder="0"
                          ></iframe>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="card-view"
                      role="tabpanel"
                      aria-labelledby="profcard-view"
                    >
                      <div className="card-view-wrapper">
                        {this.state.cardbase64url ? (
                          <iframe
                            src={`data:application/pdf;base64,${this.state.cardbase64url}`}
                            title="Base64 Image"
                            width="100%"
                            height="800px"
                            frameBorder="0"
                          ></iframe>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BookingInfo);
