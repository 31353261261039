import React, { Component } from 'react';
import withRouter from '../../withRouter';
import Location from "../../assets/images/select-location.svg"
import greenCircle from "../../assets/images/green-semi-circle.svg"
import redCircle from "../../assets/images/red-semi-circle.svg"
import orangeCircle from "../../assets/images/orange-semi-circle.svg"
import locationIcon from "../../assets/images/location.svg"
import moment from 'moment';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

export class TabletViewComponent extends Component {
    render() {
        const { locationOrigin, lang, handelClick, handleMoreLocations, locationDestination } = this.props;
        const { language } = this.context
        const dateFormat = Cookies.get('dateFormat')
        return (
            <>
                <div class="medium-device-locations">
                    <div class="location-main-content location-main-content-v2">
                        <section class="customer-location-wrapper">
                            <div class="container">
                                <div class="row all-locations-row">
                                    <div class="col-12">
                                        <div class="locations-top">
                                            {/* Green-location-::Start  */}
                                            <div class="green-location-top">
                                                <div class="green-location-row">
                                                    <div class="customer-location-title">
                                                        <div class="customer-location-image">
                                                            <img src={locationIcon} alt="Your Location" />
                                                        </div>
                                                        <h4 class="small-title-18">{locationOrigin}</h4>
                                                    </div>
                                                    <div class="green-location-img location-circle-img">
                                                        <img src={greenCircle} alt="Curve" />
                                                    </div>
                                                    <div class="green-location-name all-location-name">
                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(0, 1).map((location, i) => {
                                                            return (
                                                                <div class="location-inner green-location-0" onClick={() => handelClick(location)}>
                                                                    <div class="city-name-km-main">
                                                                        <div class="city-name-main left-side-location">
                                                                            <p class="small-text-black-16">{location.location_name}
                                                                                <span class="small-text-14">({location.km}km)</span>
                                                                            </p>
                                                                        </div>
                                                                        <div class="location-circle-main left-side-dots">
                                                                            <div class="location-circle-out">
                                                                                <div class="location-circle-in">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(1, 2).map((location, i) => {
                                                            return <div class="location-inner green-location-1" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[1] && locationDestination[1]?.length && locationDestination[1]?.slice(2, 3).map((location, i) => {
                                                            return <div class="location-inner green-location-2" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div
                                                                        class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Green-location-::End */}

                                            {/* Orange-location-::Start */}
                                            <div class="orange-location-top">
                                                <div class="orange-location-row">
                                                    <div class="orange-location-img location-circle-img">
                                                        <img src={orangeCircle} alt="Curve" />
                                                    </div>
                                                    <div class="orange-location-name all-location-name">
                                                        {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(0, 1).map((location, i) => {
                                                            return <div class="location-inner orange-location-0" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main left-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main left-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(1, 2).map((location, i) => {
                                                            return <div class="location-inner orange-location-1" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[2] && locationDestination[2]?.length && locationDestination[2]?.slice(2, 3).map((location, i) => {
                                                            return <div class="location-inner orange-location-2" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* Orange-location-::End */}

                                            {/* Red-location-::Start */}
                                            <div class="red-location-top">
                                                <div class="red-location-row">
                                                    <div class="red-location-img location-circle-img">
                                                        <img src={redCircle} alt="Curve" />
                                                    </div>
                                                    <div class="red-location-name all-location-name">
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(0, 1).map((location, i) => {
                                                            return <div class="location-inner red-location-0" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main left-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main left-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(1, 2).map((location, i) => {
                                                            return <div class="location-inner red-location-1" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main center-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main center-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {locationDestination[3] && locationDestination[3].length && locationDestination[3].slice(2, 3).map((location, i) => {
                                                            return <div class="location-inner red-location-2" onClick={() => handelClick(location)}>
                                                                <div class="city-name-km-main">
                                                                    <div class="city-name-main right-side-location">
                                                                        <p class="small-text-black-16">{location.location_name}
                                                                            <span class="small-text-14">({location.km}km)</span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="location-circle-main right-side-dots">
                                                                        <div class="location-circle-out">
                                                                            <div class="location-circle-in">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Red-location-::End */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="location-bottom-line"></div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(TabletViewComponent);
